import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import FaqApi from "../../../services/FaqApi"; // Adjust the import according to your structure
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import UserActivityAPi from "../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../Utils";

// Interface for form data
interface IForm {
  title: string;
  content: string;
}

const EditFaq: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>(); // Make id optional

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm<IForm>();
  
  const [faqData, setFaqData] = useState<IForm>({ title: "", content: "" });

  // Fetch the FAQ data when the component mounts
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await FaqApi.getFaqs();
        if (Array.isArray(response.data)) {
          // Check if id is defined before using it
          if (id) {
            // Find the FAQ with the specific ID
            const foundFaq = response.data.find((faq: { id: string }) => faq.id === id);
            if (foundFaq) {
              setFaqData(foundFaq);
              reset(foundFaq); // Reset the form with fetched FAQ data
            } else {
              throw new Error("FAQ not found");
            }
          } else {
            throw new Error("FAQ ID is not defined");
          }
        } else {
          throw new Error("Expected an array of FAQs");
        }
      } catch (error: any) {
        Swal.fire({
          title: "Error!",
          text: error.message || "Failed to fetch FAQs.",
          icon: "error",
        });
      }
    };

    fetchFaqs();
  }, [id, reset]);

  const onSubmit = async (data: IForm) => {
    try {
      if (id) {
        await FaqApi.updateFaq(id, data);
        Swal.fire({
          title: "Success!",
          text: "FAQ updated successfully",
          icon: "success",
        }).then( async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "FAQ's",
              `FAQ updated successfully`
            )
          );
          navigate(`${process.env.PUBLIC_URL}/dashboard/help-center/faq`);
        });
      } else {
        throw new Error("FAQ ID is not defined");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ borderBottom: "5px solid #eee" }}
                >
                  <CommonCardHeader
                    headClass="pb-0 card-no-border"
                    title={`Edit FAQ Details`}
                    titleClass="mb-3"
                  />
                  <Button
                    color="dark"
                    className="btn-md m-4 d-flex align-items-center"
                    onClick={() => {
                      navigate(
                        `${process.env.PUBLIC_URL}/dashboard/help-center/faq`
                      );
                    }}
                  >
                    <i
                      className="iconly-Arrow-Left icli"
                      style={{ marginRight: "0.5rem" }}
                    ></i>{" "}
                    Back
                  </Button>
                </div>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="g-3">
                    {/* Title Field */}
                    <Col md={6}>
                      <Label for="title">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${errors.title ? "is-invalid" : ""}`}
                        {...register("title", { required: "Please enter a valid title" })}
                        placeholder="Enter FAQ title"
                      />
                      {errors.title && (
                        <FormFeedback>{errors.title.message}</FormFeedback>
                      )}
                    </Col>

                    {/* Content Field */}
                    <Col md={12} className="mt-3">
                      <Label for="content">
                        Content <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="content"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Content is required",
                          validate: (value) =>
                            value && value !== "<p><br></p>" ? true : "Content is required",
                        }}
                        render={({ field }) => (
                          <ReactQuill
                            theme="snow"
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value === "<p><br></p>" ? "" : value); // Ensures empty content is considered invalid
                            }}
                            placeholder="Enter FAQ content"
                          />
                        )}
                      />
                      {errors.content && (
                        <FormFeedback className="d-block">
                          {errors.content.message}
                        </FormFeedback>
                      )}

                    </Col>
                  </Row>

                  {/* Action Buttons */}
                  <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Update & Save`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/help-center/faq`
                          );
                        }}
                        className="mt-3 btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditFaq;
