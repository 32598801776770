import { connect } from "react-redux";
import AdminProfile from "./AdminProfile";
import { SET_USER } from "../../../ReduxToolkit/Slices/Auth/AuthReducer";

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (value: any) => dispatch(SET_USER(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);
