import { createListenerMiddleware } from "@reduxjs/toolkit";
import { FETCH_VISITORS, SET_VISITORS } from "./ReportReducer";
import { fetchVisitors } from "./meditator";
export const ReportListener = createListenerMiddleware();

ReportListener.startListening({
  actionCreator: FETCH_VISITORS,
  effect: async (action, listenerAPI) => {
    let state: any = listenerAPI.getState();
    const { dispatch } = listenerAPI;
    const resp = await fetchVisitors(action.payload, dispatch);
    if (resp.isSuccess || resp?.data?.data?.visitors) {
      const data = resp.data.data;
      dispatch(
        SET_VISITORS({
          visitors: data.visitors,
          count: data.meta.totalCount,
        })
      );
    }
  },
});
