import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
  Badge,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // For styling
import { toast } from "react-toastify";
import AttendanceAPi from "../../../../services/AttendanceApi";
import { connectComponent } from "./connector";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";
import {
  convertTimeToDateTime,
  formatDateToYYYYMMDD,
  getUserActivityFormattedData,
} from "../../../../Utils";
import { widgetContentData } from "src/Data/Widgets/General";
import DashboardSvgIcon from "src/Utils/CommonComponents/DashboardSvgIcon";
import { H4 } from "src/AbstractElements";
import moment from "moment-timezone";
import "./EmployeeAttendance.css"; // Import the CSS file

function EmployeeAttendance(props: any) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState(""); // Filter text for search
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]); // Track selected employees by id
  const [modalOpen, setModalOpen] = useState(false); // Modal state
  const [time, setTime] = useState(""); // Time selection state
  const [action, setAction] = useState(""); // Time selection state
  const [selectedDate, setSelectedDate] = useState(new Date()); // Date picker state
  const [isIndeterminate, setIsIndeterminate] = useState(false); // "Select All" indeterminate state
  const [isAllSelected, setIsAllSelected] = useState(false); // "Select All" selected state
  const [clear, setClear] = useState(false);
  const [saveData, setSaveData] = useState([]); // "Select All" selected state
  const [isLoading, setIsLoading] = useState(false);

  // Filter data based on search text
  const filteredItems: any = saveData.filter((item: any) => {
    const filterObj = { firstName: item.firstName, lastName: item.lastName };
    return Object.values(filterObj).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  // Handle row checkbox selection
  const handleRowSelect = (state: { selectedRows: any[] }) => {
    const selectedIds = state.selectedRows.map((row: any) => {
      return { empId: row.id, attendanceId: row.attendance?.[0]?.id || "" };
    });
    setSelectedEmployees(selectedIds);
    setIsIndeterminate(
      selectedIds.length > 0 && selectedIds.length < filteredItems.length
    );
    setIsAllSelected(selectedIds.length === filteredItems.length);
    setClear(false);
  };

  // Handle Clock In/Out actions
  const handleClockInOut = (action: "clockIn" | "clockOut") => {
    if (selectedEmployees.length === 0) {
      toast.error("Please select employees first.");
      return;
    }
    setModalOpen(true); // Open the modal for time selection
    setAction(action);
  };

  const fetchAllEmployeeAttendance = async () => {
    try {
      const response = await AttendanceAPi.getAllEmployeeAttendance({
        nurseryId: props.nurseryId,
        employeeRole: ["EMPLOYEE", "ADMIN"],
        currentDate: formatDateToYYYYMMDD(selectedDate),
      });
      console.log('response: ', response);
      if (response.success && Array.isArray(response.data)) {
        setSaveData(response.data);
        setClear(false);
      } else {
        setSaveData([]);
        setClear(false);
      }
    } catch (error: any) {
      setSaveData([]);
      setClear(false);
    }
  };

  useEffect(() => {
    fetchAllEmployeeAttendance();
  }, [props.nurseryId, selectedDate]);

  const handleModal = () => {
    setAction("");
    setModalOpen(false);
  };

  const handleAttendance = async () => {
    if (time === "") return toast.error("Please select time");
    setIsLoading(true);
    try {
      const formattedDate = selectedDate.toISOString().split("T")[0];
      const customDateTime = moment
        .tz(`${formattedDate} ${time}`, "Europe/London")
        .format("YYYY-MM-DD HH:mm:00");
      let response: any;
      if (action === "clockIn") {
        response = await AttendanceAPi.addClockIn({
          nurseryId: props.nurseryId,
          employeesId: selectedEmployees,
          clockInTime: customDateTime,
          attendanceDate: formatDateToYYYYMMDD(selectedDate),
        });
      } else if (action === "clockOut") {
        response = await AttendanceAPi.addClockOut({
          nurseryId: props.nurseryId,
          employeesId: selectedEmployees,
          clockOutTime: customDateTime,
          attendanceDate: formatDateToYYYYMMDD(selectedDate),
        });
      }

      if (response?.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          // Add user activity log
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );

          // Clear selected employees
          setSelectedEmployees([]); // Unselect all employees
          setIsIndeterminate(false); // Reset the indeterminate state
          setIsAllSelected(false); // Reset the select-all checkbox state

          // Reset modal and time
          setModalOpen(false);
          setTime("");
          setAction("");
          setClear(true);

          // Fetch updated attendance data
          fetchAllEmployeeAttendance();
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (clear) setClear(false);
    }, 5000);
  }, [clear]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card className="attendance-card fade-in">
              {/* उन्नत हेडर सेक्शन */}
              <div className="attendance-header">
                <div className="header-content">
                  <CommonCardHeader
                    headClass="pb-0 card-no-border"
                    title={`Employee Attendance`}
                    subTitle={[]}
                    titleClass="mb-2 text-dark"
                  />
                  <div className="selected-count">
                    {selectedEmployees.length > 0 && (
                      <Badge color="info" pill className="selected-badge">
                        {selectedEmployees.length} Employees Selected
                      </Badge>
                    )}
                  </div>
                </div>

                <div className="action-buttons">
                  <Button
                    className="btn-clock-in"
                    onClick={() => handleClockInOut("clockIn")}
                  >
                    <DashboardSvgIcon
                      className="svg-fill svg-white icon-size-small"
                      iconId="clock"
                    />
                    <span>Clock In</span>
                  </Button>
                  <Button
                    className="btn-clock-out"
                    onClick={() => handleClockInOut("clockOut")}
                  >
                    <DashboardSvgIcon
                      className="svg-fill svg-white icon-size-small"
                      iconId="clock"
                    />
                    <span>Clock Out</span>
                  </Button>
                </div>
              </div>

              <CardBody className="attendance-body">
                <div className="filter-section slide-up">
                  <Row className="align-items-center">
                    <Col lg={8} md={8} sm={8} className="date-filter-col">
                      <Row>
                        <Col lg={12} md={12} sm={12} className="date-filter-col">
                          <div className="date-filter-wrapper">
                            <div className="date-label">Select Date:</div>
                            <FilterComponent
                              onFilter={(date: any) => {
                                setSelectedDate(date);
                              }}
                              filterText={selectedDate}
                              type="date"
                              showDateLabel={false}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg={12} md={12} sm={12} className="search-filter-col">
                          <div className="search-filter-wrapper">
                            <FilterComponent
                              onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFilterText(e.target.value)
                              }
                              filterText={filterText}
                              style={{ paddingLeft: "48px" }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>




                    <Col lg={4} md={12} className="attendance-stats">
                      <div className="stats-wrapper">
                        <div className="stat-item">
                          <div className="stat-label">Total</div>
                          <div className="stat-value">{saveData.length}</div>
                        </div>
                        <div className="stat-item">
                          <div className="stat-label">Present</div>
                          <div className="stat-value">
                            {saveData.filter((item: any) => item.attendance && item.attendance.length > 0 && item.attendance[0].clockInTime).length}
                          </div>
                        </div>
                        <div className="stat-item">
                          <div className="stat-label">Absent</div>
                          <div className="stat-value">
                            {saveData.filter((item: any) => !item.attendance || item.attendance.length === 0 || !item.attendance[0].clockInTime).length}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="table-container slide-up">
                  <DataTable
                    data={filteredItems}
                    className="attendance-table data-table-wrapper"
                    columns={columns({
                      handleStatusChange: (row: any) => { },
                    })}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 30, 50]}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Records per page:',
                      rangeSeparatorText: 'of',
                    }}
                    onSelectedRowsChange={handleRowSelect}
                    selectableRows
                    clearSelectedRows={clear}
                    selectableRowsHighlight
                    theme="bloomkidz"
                    customStyles={{
                      headRow: {
                        style: {
                          backgroundColor: '#f8f9fa',
                          borderBottom: '2px solid #eee',
                          fontWeight: 'bold',
                        },
                      },
                      rows: {
                        style: {
                          minHeight: '72px',
                          fontSize: '14px',
                        },
                      },
                      pagination: {
                        style: {
                          borderTop: '1px solid #eee',
                          padding: '12px',
                        },
                      },
                    }}
                    noDataComponent={
                      <div className="no-data-message">
                        <div className="icon">
                          <i className="fa fa-calendar-times-o"></i>
                        </div>
                        <h5>No attendance records found</h5>
                        <p>Try adjusting your filters</p>
                      </div>
                    }
                    progressPending={isLoading}
                    progressComponent={
                      <div className="table-loading">
                        <Spinner color="primary" />
                        <span>Loading attendance data...</span>
                      </div>
                    }
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.1)",
                            cursor: "pointer",
                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Time Selection Modal */}
      <Modal isOpen={modalOpen} toggle={handleModal} className="time-modal fade-in" backdrop="static">
        <ModalHeader toggle={handleModal} className="time-modal-header">
          {action === "clockIn" ? "Select Clock In Time" : "Select Clock Out Time"}
        </ModalHeader>
        <ModalBody className="time-modal-body">
          <div className="time-select-wrapper">
            <div className="time-icon">
              <DashboardSvgIcon
                className="svg-fill primary icon-size-medium"
                iconId="clock"
              />
            </div>
            <div className="time-input">
              <label>Select Time:</label>
              <Input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="time-field"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="time-modal-footer">
          <Button
            color="primary"
            disabled={isLoading}
            onClick={handleAttendance}
            className="submit-btn"
          >
            {isLoading ? (
              <span className="d-flex gap-2 align-items-center">
                <Spinner color="white" size="sm" /> Processing...
              </span>
            ) : (
              <>
                <i className="fa fa-check-circle"></i>
                <span>Confirm</span>
              </>
            )}
          </Button>
          <Button color="secondary" onClick={handleModal} className="cancel-btn">
            <i className="fa fa-times-circle"></i>
            <span>Cancel</span>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default connectComponent(EmployeeAttendance);
