import { connect } from "react-redux";
import EmployeeNewsFeed from ".";

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeNewsFeed);
