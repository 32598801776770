import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, H5, Image, P } from "../../../../AbstractElements";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Award,
  Briefcase,
  Calendar,
  Clock,
  File,
  FilePlus,
} from "react-feather/dist";
import Information from "./Components/Information";
import { connectComponent } from "./connector";
import UserApi from "src/services/UserApi";
import { commonKeysObject, dynamicImage } from "src/Utils";
import { statusColors } from "src/Utils/Constants";

function AddChildren(props: any) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [parentData, setParentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  const activeToggle = (tab: number) => {
    setActiveTab(tab);
  };

  const [initialState, setInitialState] = useState({
    id: "",
    firstName: "",
    lastName: "",
    dob: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
    gender: "",
    nationality: "United Kingdom",
    birthPlace: "",
    liveWith: [],
    parentalResponsibility: [],
    keyPerson: [],
    specialNote: "",
    nurseryId: props.nurseryId,
    familyId: id,
  });

  const [parentInfo, setParentInfo] = useState<any>({});

  useEffect(() => {
    setInitialState({ ...initialState, nurseryId: props.nurseryId });

    const fetchEmployees = async () => {
      try {
        const response = await UserApi.getAllEmployees({
          searchText: "",
          status: "all-status",
          nurseryId: props.nurseryId,
          jobTitleId: "all-job",
          pageNo: 1,
          pageSize: 10000,
        });
        if (response.success && Array.isArray(response.data)) {
          setEmployeeData(response.data);
        } else {
          setEmployeeData([]);
        }
      } catch (error: any) {
        setEmployeeData([]);
      }
    };
    fetchEmployees();
  }, [props.nurseryId]);

  const sections = [
    {
      id: 1,
      title: "Basic Info",
      icon: <FilePlus />,
    },
    {
      id: 2,
      title: "Health Information", // Corrected spelling
      icon: <Briefcase />,
    },
    {
      id: 3,
      title: "Sensitive Information",
      icon: <Award />, // Changed icon for better representation (you can revert to <DollarSign /> if needed)
    },
    {
      id: 4,
      title: "Registration and Rooms",
      icon: <Clock />, // Added Leave tab with a relevant icon
    },
    {
      id: 5,
      title: "Documents",
      icon: <File />,
    },
    {
      id: 6,
      title: "Children Permission",
      icon: <Calendar />,
    },
    {
      id: 7,
      title: "Set Collection Password",
      icon: <Calendar />,
    },
  ];

  useEffect(() => {
    const fetchParents = async () => {
      try {
        const response = await UserApi.getParentById(id);
        if (response.success && Array.isArray(response.data)) {
          const formData = response.data.map((row: any, index: any) => {
            const modifiedObj = commonKeysObject(row, {
              id: "",
              firstName: "",
              middleName: "",
              lastName: "",
              relation: "",
              phoneNo: "",
            });

            if (index === 0) {
              setParentInfo({
                parentNames: [
                  `${modifiedObj.firstName} ${modifiedObj.lastName}`,
                ],
                parentPhoneNo: [`${modifiedObj.phoneNo}`],
              });
            }

            return { ...modifiedObj, password: row.backupPassword };
          });
          setParentData(formData);
        }
      } catch (error: any) {
      } finally {
      }
    };
    fetchParents();
  }, [id]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                marginBottom: 0,
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Children Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="primart"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/parents/children/${id}`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>
            </Card>

            <Container fluid className="pt-3">
              <div className="edit-profile email-wrap bookmark-wrap">
                <Row>
                  <Col xl={3} className="box-col-4e" style={{ paddingLeft: 0 }}>
                    <Card>
                      <CommonCardHeader
                        titleClass="card-title"
                        title={"Profile"}
                      />
                      <CardBody className="p-2">
                        <div
                          className="profile-title"
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          <div className="d-flex gap-3">
                            <div className="position-relative">
                              <Image
                                className="img-70 rounded-circle"
                                alt=""
                                src={dynamicImage(`default-profile-old.jpg`)}
                              />
                            </div>
                            <div className="flex-grow-1">
                              <H5 className="mb-1">{`- -`}</H5>
                              <P
                                className="mb-0 font-weight-bold"
                                style={{ fontWeight: "bold" }}
                              >
                                {"Children"}
                              </P>

                              <span
                                className="badge-light-primary mt-2"
                                style={{
                                  padding: "0 1.6rem",
                                  borderRadius: "4px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  height: "29px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100px",
                                  backgroundColor: `${statusColors["active"]?.background}`,
                                  color: `${statusColors["active"]?.text}`,
                                }}
                              >
                                {"Active"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #eee",
                            paddingBottom: "1rem",
                            marginLeft: "15px",
                          }}
                        >
                          <ul className="d-flex flex-column gap-1 align-items-start">
                            <li>
                              <strong>
                                {" "}
                                <i className="fa-solid fa-user text-primary" />
                              </strong>{" "}
                              {parentInfo?.parentNames?.join(",")}
                            </li>
                            <li>
                              <strong>
                                {" "}
                                <i className="fa-solid fa-phone text-primary" />
                              </strong>{" "}
                              {parentInfo?.parentPhoneNo?.join(",")}
                            </li>
                          </ul>
                        </div>
                        <div
                          className="md-sidebar mt-0"
                          style={{
                            borderBottom: "1px solid #eee",
                            paddingBottom: "1rem",
                          }}
                        >
                          <Btn
                            color="primary"
                            className="md-sidebar-toggle"
                            onClick={() => { }}
                          >
                            {""}
                          </Btn>
                          <div
                            className={`md-sidebar-aside job-left-aside custom-scrollbar open`}
                          >
                            <div className="email-left-aside">
                              <div
                                className="email-app-sidebar left-bookmark task-sidebar"
                                style={{
                                  height: "100%",
                                }}
                              >
                                <Nav className="main-menu mt-3">
                                  {sections.map((nav) =>
                                    nav.id === 1 ? (
                                      <NavItem key={nav.id}>
                                        <a
                                          style={{ padding: "10px" }}
                                          href="javascript:void(0);"
                                          className={
                                            activeTab === nav.id
                                              ? "active badge-light-primary"
                                              : ""
                                          }
                                          onClick={() => activeToggle(nav.id)}
                                        >
                                          {/* Render the dynamic Font Awesome icon */}

                                          <span
                                            className="iconbg badge-light-primary p-2 fs-10 lh-0"
                                            style={{
                                              borderRadius: "100px",
                                              lineHeight: "0px",
                                            }}
                                          >
                                            {nav.icon}
                                          </span>

                                          <span className="title ms-2">
                                            {nav.title}
                                          </span>
                                        </a>
                                      </NavItem>
                                    ) : (
                                      <NavItem key={nav.id}>
                                        <a
                                          style={{ padding: "10px" }}
                                          href="javascript:void(0);"
                                          className={
                                            activeTab === nav.id
                                              ? "active badge-light-primary"
                                              : ""
                                          }
                                        >
                                          {/* Render the dynamic Font Awesome icon */}

                                          <span
                                            className="iconbg badge-light-primary p-2 fs-10 lh-0"
                                            style={{
                                              borderRadius: "100px",
                                              lineHeight: "0px",
                                            }}
                                          >
                                            {nav.icon}
                                          </span>

                                          <span className="title ms-2">
                                            {nav.title}
                                          </span>
                                        </a>
                                      </NavItem>
                                    )
                                  )}
                                </Nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={9} xl={9} md={12} className="box-col-12">
                    <div className="email-right-aside bookmark-tabcontent">
                      <div className="ps-0">
                        <TabContent activeTab={activeTab}>
                          <TabPane key={1} tabId={1}>
                            <Information
                              initialState={initialState}
                              parentData={parentData}
                              employeeData={employeeData}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(AddChildren);
