import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomHeader } from "./Helper/column";
import PricingBrandsAPi from "src/services/PricingBrandsApi";
import { connectComponent } from "./connector";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import { Btn, LI, UL } from "src/AbstractElements";
import Select from "react-select";
import SessionTimeApi from "../../../../services/SessionTimesApi";

interface PriceBrand {
  id: string;
  title: string;
  sessionTimes?: string[];
  price: number;
  adhocPrice: number;
  pricingType: string;
}

function PricingBands(props: any) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [priceBrand, setPriceBrand] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPriceBrand, setSelectedPriceBrand] = useState<Partial<PriceBrand>>({});
  const [sessionTimeOptions, setSessionTimeOptions] = useState<{ value: string; label: string }[]>([]);

  const filteredItems: any = priceBrand.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const fetchPricingBrands = async () => {
    try {
      const response = await PricingBrandsAPi.getPriceBand({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.data)) {
        setPriceBrand(response.data);
      } else setPriceBrand([]);
    } catch (error: any) {
      setPriceBrand([]);
    }
  };

  const fetchSessionTimes = async () => {
    try {
      const response = await SessionTimeApi.getSessionTimes(props.nurseryId);
      if (response.success && Array.isArray(response.data)) {
        const options = response.data.filter((r: any) => r.status == 0).map((time: any) => ({
          value: time.id,
          label: `${time.startTime} - ${time.endTime}`
        }));
        setSessionTimeOptions(options);
      }
    } catch (error) {
      console.error('Error fetching session times:', error);
    }
  };

  useEffect(() => {
    fetchPricingBrands();
    fetchSessionTimes();
  }, [props.nurseryId]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<PriceBrand>({
    defaultValues: {
      title: "",
      sessionTimes: [],
      price: 0,
      adhocPrice: 0,
      pricingType: ""
    }
  });

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    if (isModalOpen) {
      reset({
        title: "",
        sessionTimes: [],
        price: 0,
        adhocPrice: 0,
        pricingType: ""
      });
      setSelectedPriceBrand({});
    }
  };

  const handleEditPriceBrand = (priceBrand: PriceBrand) => {
    const formData = {
      ...priceBrand,
      sessionTimes: priceBrand.sessionTimes || []
    };
    setSelectedPriceBrand(formData);
    reset({
      title: priceBrand.title,
      sessionTimes: priceBrand.sessionTimes || [],
      price: priceBrand.price,
      adhocPrice: priceBrand.adhocPrice,
      pricingType: priceBrand.pricingType
    });
    toggleModal();
  };

  const onSubmit = async (data: PriceBrand) => {
    setIsLoading(true);
    try {
      setTimeout(async () => {
        if (selectedPriceBrand.id) {
          await PricingBrandsAPi.updatePriceBand({
            ...data,
            id: selectedPriceBrand.id,
            sessionTimes: data.sessionTimes
          });
          Swal.fire({
            title: "Success!",
            text: "Price Brand updated successfully",
            icon: "success",
          });
        } else {
          await PricingBrandsAPi.createPriceBand({
            ...data,
            nurseryId: props.nurseryId,
            sessionTimes: data.sessionTimes
          });
          Swal.fire({
            title: "Success!",
            text: "Price Brand created successfully",
            icon: "success",
          });
        }
        fetchPricingBrands();
        toggleModal();
      }, 2000);
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2300);
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      style: { maxWidth: "30%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Session Times" />,
      selector: (row) => row.sessionTimes,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
      cell: (row) => {
        const selectedTimes = sessionTimeOptions.filter(option =>
          row.sessionTimes?.includes(option.value)
        );
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {selectedTimes.map((time, index) => (
              <span
                key={time.value}
                style={{
                  padding: '2px 8px',
                  backgroundColor: '#e9ecef',
                  borderRadius: '4px',
                  fontSize: '12px',
                  whiteSpace: 'nowrap'
                }}
              >
                {time.label}
              </span>
            ))}
          </div>
        );
      }
    },
    {
      name: <CustomHeader title="Price" />,
      selector: (row) => row.price,
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Adhoc Price" />,
      selector: (row) => row.adhocPrice,
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Pricing Type" />,
      selector: (row) => row.pricingType,
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Action" style={{ marginLeft: "110px" }} />,
      sortable: true,
      center: true,
      style: { maxWidth: "20%" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              className="icon"
              href="javascript:void(0);"
              onClick={() => handleEditPriceBrand(row)}
            >
              <Btn color="primary" size="sm">
                <i
                  className="fa-solid fa-pencil text-white me-1"
                  style={{ fontSize: "12px" }}
                ></i>
                {"Edit"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Pricing Brands`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={toggleModal}
                >
                  Add New Pricing Brands
                </Button>
              </div>
              <CardBody>
                <Row className="justify-content-end">
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>

                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Modal for Adding Price Brand */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>
          {selectedPriceBrand.id ? "Edit Price Brand" : "Add Price Brand"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="title">
                Title <span className="text-danger">*</span>
              </Label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                id="title"
                {...register("title", {
                  required: "Price Brand Title is required",
                })}
                defaultValue={selectedPriceBrand.title}
              />
              {errors.title && (
                <FormFeedback>{errors.title.message}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="sessionTimes">
                Session Times <span className="text-danger">*</span>
              </Label>
              <Controller
                name="sessionTimes"
                control={control}
                rules={{ required: "Please select at least one session time" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti
                    options={sessionTimeOptions}
                    className={`${errors.sessionTimes ? "is-invalid" : ""}`}
                    placeholder="Select session times"
                    value={sessionTimeOptions.filter(option =>
                      value?.includes(option.value)
                    )}
                    onChange={(selected) => {
                      const selectedValues = selected ? selected.map(item => item.value) : [];
                      onChange(selectedValues);
                      setValue('sessionTimes', selectedValues);
                    }}
                  />
                )}
              />
              {errors.sessionTimes && (
                <FormFeedback>{errors.sessionTimes.message}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="price">
                Price <span className="text-danger">*</span>
              </Label>
              <input
                type="number"
                step="0.01"
                className={`form-control ${errors.price ? "is-invalid" : ""}`}
                id="price"
                {...register("price", {
                  required: "Price is required",
                  min: { value: 0, message: "Price must be greater than or equal to 0" }
                })}
                defaultValue={selectedPriceBrand.price}
              />
              {errors.price && (
                <FormFeedback>{errors.price.message}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="adhocPrice">
                Adhoc Price <span className="text-danger">*</span>
              </Label>
              <input
                type="number"
                step="0.01"
                className={`form-control ${errors.adhocPrice ? "is-invalid" : ""}`}
                id="adhocPrice"
                {...register("adhocPrice", {
                  required: "Adhoc Price is required",
                  min: { value: 0, message: "Adhoc Price must be greater than or equal to 0" }
                })}
                defaultValue={selectedPriceBrand.adhocPrice}
              />
              {errors.adhocPrice && (
                <FormFeedback>{errors.adhocPrice.message}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="pricingType">
                Pricing Type <span className="text-danger">*</span>
              </Label>
              <Controller
                name="pricingType"
                control={control}
                rules={{ required: "Pricing Type is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={[
                      { value: 'hourly', label: 'Hourly' },
                      { value: 'monthly', label: 'Monthly' },
                      { value: 'session', label: 'Session Wise' }
                    ]}
                    className={`${errors.pricingType ? "is-invalid" : ""}`}
                    placeholder="Select pricing type"
                    value={value ? { value, label: value.charAt(0).toUpperCase() + value.slice(1) } : null}
                    onChange={(selected) => onChange(selected?.value)}
                  />
                )}
              />
              {errors.pricingType && (
                <FormFeedback>{errors.pricingType.message}</FormFeedback>
              )}
            </FormGroup>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  <>
                    {selectedPriceBrand.id ? "Update Price Brand" : "Add Price Brand"}
                  </>
                )}
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default connectComponent(PricingBands);
