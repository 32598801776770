import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditForm, IForm } from "../../../Pages/SuperAdmin/Users/Helper/types";
import { addAdminData, updateAdminData } from "./meditator";
import { handleErrors } from "../../../Utils";

interface ISuperAdminUser {
  id: string;
  businessId: string;
  roleTitleId: string;
  companyName: string;
  joinDate: number;
  noOfChild: number;
  noOfNurseries: number;
  noOfAdmins: number;
  nurseryType: string;
  packageId: string;
  status: number;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  deletedBy: string;
  deletedAt: any;
  isDeleted: number;
  package: Package[] | [];
  nursery: Nursery[] | [];
  adminUsersData: AdminUsersData[] | [];
}
interface AdminUsersData {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  isActive: boolean;
  email: string;
  phoneNo: string;
  altPhoneNo: string;
  createdAt: string;
  businessId: string;
  isFreeTrail: boolean;
  role: string;
  designation: any;
  joinDate: number;
  backupPassword: string;
  companyName: string;
  nurseryType: string;
  noOfAdmins: number;
  noOfNurseries: number;
  noOfChild: number;
}
export interface Package {
  startedAt: number;
  expiredAt: number;
  isActive: boolean;
  package: Package2;
}

export interface Package2 {
  id: string;
  title: string;
  billing: string;
  fee: number;
}

export interface Nursery {
  id: string;
}

interface IInitialState {
  admins: ISuperAdminUser[] | [];
  count: number;
  nurseries: [];
  packages: [];
  packagesCount: number;
  isLoading: boolean;
  error: any;
  success: any;
}

const initialState: IInitialState = {
  admins: [],
  count: 0,
  nurseries: [],
  packages: [],
  packagesCount: 0,
  isLoading: false,
  error: {},
  success: {},
};

const AdminUserSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    SET_ADMIN: (state: IInitialState, action) => {
      state.admins = action.payload.admins;
      state.count = action.payload.count;
    },
    SET_NURSERIES: (state: IInitialState, action) => {
      state.nurseries = action.payload.nurseries;
    },
    SET_PACKAGES: (state: IInitialState, action) => {
      state.packages = action.payload.packages;
      state.packagesCount = action.payload.packagesCount;
    },
    LOAD_ADMIN_USER: (state, action) => {
      // * To get the user data
    },
    SET_MESSAGE: (state, action) => {
      state.error = action.payload.error;
      state.success = action.payload.success;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAdminUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addAdminUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addAdminUser.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const addAdminUser = createAsyncThunk(
  "addAdminUser",
  async (formData: IForm, thunkApi) => {
    const response = await addAdminData(formData, thunkApi);
    if (response?.data && response?.data?.success) {
      let { message } = response?.data;

      thunkApi.dispatch(
        SET_MESSAGE({ success: { status: true, message: message }, error: {} })
      );
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message: "Facing issue while adding admin.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const updateAdminUser = createAsyncThunk(
  "updateAdminUser",
  async (formData: EditForm, thunkApi) => {
    const response = await updateAdminData(formData, thunkApi);
    if (response?.data && response?.data?.success) {
      let { message } = response?.data;

      if (formData?.body?.status !== 0 && formData?.body?.status !== 1) {
        thunkApi.dispatch(
          SET_MESSAGE({
            success: { status: true, message: message },
            error: {},
          })
        );
      }

      thunkApi.dispatch(LOAD_ADMIN_USER(formData.filterObj));
    }
    if (
      response?.error &&
      response?.error?.data &&
      !response?.error?.data?.success
    ) {
      let error = response?.error?.data?.error || "Unknown Error";
      const dataErrors = error;
      const emptyErrors = handleErrors(error);
      thunkApi.dispatch(
        SET_MESSAGE({
          success: {},
          error: {
            status: false,
            message:
              typeof dataErrors === "string"
                ? dataErrors
                : "Facing issue while adding admin.",
            ...dataErrors,
            ...emptyErrors,
          },
        })
      );
    }
  }
);

export const {
  SET_ADMIN,
  LOAD_ADMIN_USER,
  SET_NURSERIES,
  SET_PACKAGES,
  SET_MESSAGE,
} = AdminUserSlice.actions;
export default AdminUserSlice.reducer;
