import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UserApi from "../../../services/UserApi";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";

interface IForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, watch } = useForm<IForm>();

  // State for password visibility toggling
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Watch newPassword and confirmPassword to validate matching
  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");

  const onSubmit = async (data: IForm) => {
    if (data.newPassword !== data.confirmPassword) {
      Swal.fire({
        title: "Error!",
        text: "New password and confirm password do not match.",
        icon: "error",
      });
      return;
    }

    try {
      await UserApi.changePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      Swal.fire({
        title: "Success!",
        text: "Password changed successfully",
        icon: "success",
      }).then(() => {
        navigate(`${process.env.PUBLIC_URL}/dashboard`); // Navigate to the dashboard or relevant page
      });
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Change Password`}
                  titleClass="mb-3"
                />
              </div>
              <CardBody>
                <div className="col-md-6"> {/* Centering the form */}
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="g-3">
                      {/* Old Password Field */}
                      <Col md={12}>
                        <Label for="oldPassword">Old Password</Label>
                        <div className="input-group">
                          <input
                            type={showOldPassword ? "text" : "password"}
                            {...register("oldPassword", { required: "Old password is required" })}
                            className={`form-control ${errors.oldPassword ? "is-invalid" : ""}`}
                          />
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          >
                            <i className={showOldPassword ? "iconly-Show" : "iconly-Hide"}></i>
                          </button>
                        </div>
                        {errors.oldPassword && (
                          <div className="invalid-feedback">{errors.oldPassword.message}</div>
                        )}
                      </Col>
                    </Row>

                    {/* New Password Field */}
                    <Row className="g-3 mt-2">
                      <Col md={12}>
                        <Label for="newPassword">New Password</Label>
                        <div className="input-group">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            {...register("newPassword", {
                              required: "New password is required",
                              minLength: {
                                value: 6,
                                message: "Password must be at least 6 characters long",
                              },
                            })}
                            className={`form-control ${errors.newPassword ? "is-invalid" : ""}`}
                          />
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          >
                            <i className={showNewPassword ? "iconly-Show" : "iconly-Hide"}></i>
                          </button>
                        </div>
                        {errors.newPassword && (
                          <div className="invalid-feedback">{errors.newPassword.message}</div>
                        )}
                      </Col>
                    </Row>

                    {/* Confirm Password Field */}
                    <Row className="g-3 mt-2">
                      <Col md={12}>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <div className="input-group">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            {...register("confirmPassword", {
                              required: "Please confirm your new password",
                            })}
                            className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                          />
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          >
                            <i className={showConfirmPassword ? "iconly-Show" : "iconly-Hide"}></i>
                          </button>
                        </div>
                        {errors.confirmPassword && (
                          <div className="invalid-feedback">{errors.confirmPassword.message}</div>
                        )}
                      </Col>
                    </Row>

                    {/* Submit Button */}
                    <Row className="g-3 mt-3">
                      <Col md={6}></Col>
                      <Col md={6}>
                        <Button
                          type="submit"
                          color="primary"
                          className="w-100"
                        >
                          Change Password
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChangePassword;
