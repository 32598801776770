import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Btn, LI, UL } from "src/AbstractElements";
import { statusColors } from "src/Utils/Constants";
import Status from "src/Components/Common/Status";
import { connectComponent } from "../PricingBrands/connector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SessionTimeApi from "../../../../services/SessionTimesApi";
import { commonKeysObject } from "src/Utils";
import { toast } from "react-toastify";
import TimeDropdown from "src/Components/TimeDropdown";

interface SessionTime {
    id: string;
    startTime: any;
    endTime: any;
    remark?: string;
    remarks?: string;
    status: number;
    nurseryId: string;
}


const CustomHeader = ({ title }: { title: string }) => (
    <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
        {title}
    </span>
);

function SessionTime(props: any) {
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState("");
    const [sessionTimes, setSessionTimes] = useState<SessionTime[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSessionTime, setSelectedSessionTime] = useState<Partial<SessionTime>>({});
    console.log('selectedSessionTime: ', selectedSessionTime);
    const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});
    const { nurseryId } = props;

    const toggleDropdownOne = (id: any) => {
        setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
    };

    const filteredItems = sessionTimes.filter((item) => {
        return Object.values(item).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const fetchSessionTimes = async () => {
        try {
            const response = await SessionTimeApi.getSessionTimes(nurseryId);
            console.log('response: ', response);
            if (response.success && Array.isArray(response.data)) {
                const formattedResponse = response.data.map((item: any) => ({
                    ...item,
                    startTime: item.startTime,
                    endTime: item.endTime
                }));
                console.log('formattedResponse: ', formattedResponse);
                setSessionTimes(formattedResponse);
            } else setSessionTimes([]);
        } catch (error: any) {
            setSessionTimes([]);
        }
    };

    useEffect(() => {
        fetchSessionTimes();
    }, [nurseryId]);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm<SessionTime>({
        defaultValues: {
            startTime: '',
            endTime: '',
            remark: "",
            status: 0
        }
    });

    const startTime = watch("startTime");
    const endTime = watch("endTime");

    const handleEditSessionTime = (sessionTime: SessionTime) => {
        const formData = {
            ...sessionTime,
            startTime: sessionTime.startTime,
            endTime: sessionTime.endTime
        };
        setSelectedSessionTime(formData);
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (selectedSessionTime.id) {
            reset({
                ...selectedSessionTime,
                remark: selectedSessionTime.remarks || "",
                startTime: selectedSessionTime.startTime,
                endTime: selectedSessionTime.endTime
            });
        }
    }, [selectedSessionTime, reset]);

    const toggleModal = () => {
        if (isModalOpen) {
            reset({
                startTime: "",
                endTime: "",
                remark: "",
                status: 0
            });
            setSelectedSessionTime({});
        }
        setIsModalOpen((prevState) => !prevState);
    };

    // Handle form submission for both create and update
    const onSubmit = async (data: SessionTime) => {
        console.log('data: ', data);
        if (!data?.startTime || !data?.endTime) {
            toast.error("Please select start and end time");
        } else {
            setIsLoading(true);
            try {
                setTimeout(async () => {
                    let modifiedData: any = commonKeysObject(data, { startTime: "startTime", endTime: "endTime" });

                    // Convert to HH:mm format
                    modifiedData.startTime = modifiedData.startTime;

                    modifiedData.endTime = modifiedData.endTime;

                    modifiedData.remarks = data.remark;
                    modifiedData.nurseryId = nurseryId;

                    if (selectedSessionTime.id) {
                        await SessionTimeApi.updateSessionTime(selectedSessionTime.id, { ...modifiedData });
                        Swal.fire({
                            title: "Success!",
                            text: "Session Time updated successfully",
                            icon: "success",
                        });
                    } else {
                        modifiedData.id = undefined;
                        await SessionTimeApi.createSessionTime({ ...modifiedData });
                        Swal.fire({
                            title: "Success!",
                            text: "Session Time created successfully",
                            icon: "success",
                        });
                    }
                    fetchSessionTimes();
                    toggleModal();
                }, 2000);
            } catch (error: any) {
                Swal.fire({
                    title: "Error!",
                    text: error.message || "Something went wrong",
                    icon: "error",
                });
            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                }, 2300);
            }
        }
    };

    const handleStatusChange = async (row: any, status: string) => {
        const currentStatus = status === "ACTIVE" ? 0 : 1;
        const id = row.id;
        try {
            await SessionTimeApi.updateSessionTime(id, {
                status: currentStatus,
                nurseryId
            });
            toggleDropdownOne(id);
            await fetchSessionTimes();
        } catch (error: any) {
            Swal.fire("Error", error.message || "Failed to update status", "error");
        }
    };

    const columns: TableColumn<any>[] = [
        {
            name: <CustomHeader title="Start Time" />,
            selector: (row) => row.startTime,
            style: { maxWidth: "50%" },
            cell: (row) => row.startTime,
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="End Time" />,
            selector: (row) => row.endTime,
            style: { maxWidth: "50%" },
            cell: (row) => row.endTime,
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Status" />,
            selector: (row) => (row.status === 0 ? "ACTIVE" : "INACTIVE"),
            style: { maxWidth: "50%" },
            sortable: true,
            center: false,
            cell: (row) => (
                <Status
                    position={row.status === 0 ? "ACTIVE" : "INACTIVE"}
                    color={row.status === 0 ? statusColors.active : statusColors.inactive}
                    row={row}
                    id={row.id}
                    newUI={true}
                    statusList={["ACTIVE", "INACTIVE"]}
                    handleStatusChange={handleStatusChange}
                    toggleDropdownOne={toggleDropdownOne}
                    dropdownOpenOne={dropdownOpenOne}
                />
            ),
        },
        {
            name: <CustomHeader title="Action" />,
            sortable: true,
            center: true,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <UL className="action simple-list flex-row">
                    <LI className="edit">
                        <a
                            className="icon"
                            href="javascript:void(0);"
                            onClick={() => handleEditSessionTime(row)}
                        >
                            <Btn color="primary" size="sm">
                                <i
                                    className="fa-solid fa-pencil text-white me-1"
                                    style={{ fontSize: "12px" }}
                                ></i>
                                {"Edit"}
                            </Btn>
                        </a>
                    </LI>
                </UL>
            ),
        },
    ];

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ borderBottom: "5px solid #eee" }}
                            >
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`View All Session Times`}
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                                <Button
                                    color="primary"
                                    className="btn-md m-4"
                                    onClick={toggleModal}
                                >
                                    Add New Session Time
                                </Button>
                            </div>
                            <CardBody>
                                <Row className="justify-content-end">
                                    <Col md={4}>
                                        <FilterComponent
                                            onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFilterText(e.target.value)
                                            }
                                            filterText={filterText}
                                        />
                                    </Col>
                                </Row>

                                <div className="table-responsive">
                                    <DataTable
                                        data={filteredItems}
                                        columns={columns}
                                        pagination
                                        className="display"
                                        conditionalRowStyles={[
                                            {
                                                when: (row) => true,
                                                style: {
                                                    "&:hover": {
                                                        backgroundColor: "rgba(48, 142, 135, 0.2)",
                                                        cursor: "pointer",
                                                    },
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Modal for Adding Session Time */}
            <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
                <ModalHeader toggle={toggleModal}>
                    {selectedSessionTime.id ? "Edit Session Time" : "Add Session Time"}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label for="startTime">
                                Start Time <span className="text-danger">*</span>
                            </Label>
                            <TimeDropdown name="startTime" control={control}
                                error={errors.startTime?.message as string} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="endTime">
                                End Time <span className="text-danger">*</span>
                            </Label>
                            <TimeDropdown name="endTime" control={control}
                                error={errors.endTime?.message as string} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="remark">
                                Remark
                            </Label>
                            <textarea
                                className={`form-control ${errors.remark ? "is-invalid" : ""}`}
                                id="remark"
                                {...register("remark")}
                                defaultValue={selectedSessionTime.remark}
                                rows={3}
                            />
                            {errors.remark && (
                                <FormFeedback>{errors.remark.message}</FormFeedback>
                            )}
                        </FormGroup>

                        <ModalFooter>
                            <Button color="primary" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <span className="d-flex gap-2">
                                        <Spinner color="white" size="sm" /> Loading...
                                    </span>
                                ) : (
                                    <>
                                        {selectedSessionTime.id ? "Update Session Time" : "Add Session Time"}
                                    </>
                                )}
                            </Button>
                            <Button color="secondary" onClick={toggleModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default connectComponent(SessionTime);
