import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup,
    Label, Input, Button, FormFeedback, Alert, Badge
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Upload, Save, Check, Image, Video, FileText } from 'react-feather';
import Select from 'react-select';
import ActivityLibraryApi from '../../../services/ActivityLibraryApi';
import UploadApi from '../../../services/UploadApi';
import EyfApi from '../../../services/EyfApi';
import './style.scss';
import Swal from 'sweetalert2';
import AsyncImage from '../../../Components/AsyncImage';
import { baseUrl } from 'src/Utils/Constants/config';

interface FormData {
    title: string;
    description: string;
    thumbnailUrl: string;
    thumbnailUrls: string[];
    mediaUrl: string;
    mediaUrls: string[];
    mediaType: string;
    ageRangeStart: string;
    ageRangeEnd: string;
    learningArea: string;
    language: string[];
    isPublic: boolean;
    visibilityLevel: 'self' | 'assigned' | 'public';
    content: string;
}

// Define language option interface for React Select
interface LanguageOption {
    value: string;
    label: string;
}

// Update the LearningArea interface to match the response from EyfApi
interface LearningArea {
    id: string;
    name: string;
    color: string;
    headingId: string;
    status: number;
}

const EditActivity: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const [formData, setFormData] = useState<FormData>({
        title: '',
        description: '',
        thumbnailUrl: '',
        thumbnailUrls: [],
        mediaUrl: '',
        mediaUrls: [],
        mediaType: 'IMAGE',
        ageRangeStart: '',
        ageRangeEnd: '',
        learningArea: '',
        language: ['en-GB'],
        isPublic: false,
        visibilityLevel: 'self',
        content: ''
    });

    const [learningAreas, setLearningAreas] = useState<LearningArea[]>([]);
    const [languages, setLanguages] = useState<{ id: string; name: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [thumbnailFiles, setThumbnailFiles] = useState<File[]>([]);
    const [currentThumbnailIndex, setCurrentThumbnailIndex] = useState(0);
    const [mediaFiles, setMediaFiles] = useState<File[]>([]);
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const [isDragOver, setIsDragOver] = useState(false);
    const [activeMediaType, setActiveMediaType] = useState<'image' | 'video' | 'pdf' | null>(null);

    const [dataLoaded, setDataLoaded] = useState(false);

    // Add these state variables to store existing media previews
    const [existingThumbnail, setExistingThumbnail] = useState<string>('');
    const [existingMedia, setExistingMedia] = useState<string>('');

    // Add tracking for removed media/thumbnails
    const [mediaRemoved, setMediaRemoved] = useState<boolean>(false);
    const [thumbnailRemoved, setThumbnailRemoved] = useState<boolean>(false);

    // First, add a reference to the hidden file input for media upload
    const mediaFileInputRef = React.useRef<any>(null);

    useEffect(() => {
        fetchLearningAreas();
        fetchLanguages();
        if (id) {
            fetchActivityById(id);
        }
    }, [id]);

    // useEffect(() => {
    //     if (existingThumbnail && !thumbnailFiles.length) {
    //         // Set up initial display for existing thumbnails
    //         // This will show the existing thumbnail in the same UI as new uploads
    //         const fetchAndCreateThumbnailPreview = async () => {
    //             try {
    //                 // Create a representation of the file that can be displayed
    //                 // in the same UI as uploaded files
    //                 const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}${existingThumbnail}`);
    //                 const blob = await response.blob();
    //                 const fileName = existingThumbnail.split('/').pop() || 'thumbnail.jpg';
    //                 const file = new File([blob], fileName, { type: blob.type });

    //                 setThumbnailFiles([file]);
    //             } catch (error) {
    //                 console.error('Error loading existing thumbnail:', error);
    //             }
    //         };

    //         fetchAndCreateThumbnailPreview();
    //     }

    //     if (existingMedia && !mediaFiles.length) {
    //         // Set up initial display for existing media
    //         const fetchAndCreateMediaPreview = async () => {
    //             try {
    //                 const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}${existingMedia}`);
    //                 const blob = await response.blob();
    //                 const fileName = existingMedia.split('/').pop() || 'media';
    //                 const file = new File([blob], fileName, { type: blob.type });

    //                 setMediaFiles([file]);
    //             } catch (error) {
    //                 console.error('Error loading existing media:', error);
    //             }
    //         };

    //         fetchAndCreateMediaPreview();
    //     }
    // }, [existingThumbnail, existingMedia, thumbnailFiles.length, mediaFiles.length]);

    const fetchLearningAreas = async () => {
        try {
            const response = await EyfApi.getSubHeadings();
            if (response && response.success) {
                setLearningAreas(response.data);
            }
        } catch (error) {
            console.error('Error fetching learning areas:', error);
        }
    };

    const fetchLanguages = async () => {
        try {
            const response = await ActivityLibraryApi.getLanguages();
            if (response && response.success) {
                setLanguages(response.data);
            }
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    // Add this utility function to get proper file URLs
    const getProperFileUrl = (path: string, folder: string) => {
        const fileName = path.split('/').pop();
        if (!fileName) return '';
        return `${baseUrl}/uploads/${folder}/${fileName}`;
    };

    // Update fetchActivityById to handle multiple thumbnails and media files
    const fetchActivityById = async (activityId: string) => {
        try {
            setLoading(true);
            const response = await ActivityLibraryApi.getActivityById(activityId);

            if (response && response.success) {
                const activity = response.data;
                console.log('Loaded activity:', activity);

                // Handle main thumbnail
                if (activity.thumbnailUrl) {
                    setExistingThumbnail(activity.thumbnailUrl);
                }

                // Handle main media
                if (activity.mediaUrl) {
                    setExistingMedia(activity.mediaUrl);
                }

                // Handle multiple thumbnails
                if (activity.thumbnailUrls && activity.thumbnailUrls.length > 0) {
                    // Load thumbnail files
                    const loadThumbnails = async () => {
                        try {
                            const loadedFiles: File[] = [];
                            for (const url of activity.thumbnailUrls) {
                                console.log('url: ', url);
                                const fileName = url.url.split('/').pop() || 'thumbnail.jpg';
                                const fileUrl = getProperFileUrl(url.url, 'activity-library');
                                try {
                                    const response = await fetch(fileUrl);
                                    const blob = await response.blob();
                                    const file = new File([blob], fileName, { type: blob.type || 'image/jpeg' });
                                    loadedFiles.push(file);
                                } catch (error) {
                                    console.error('Error loading thumbnail:', error);
                                }
                            }
                            setThumbnailFiles(loadedFiles);
                        } catch (error) {
                            console.error('Error loading thumbnails:', error);
                        }
                    };
                    loadThumbnails();
                } else if (activity.thumbnailUrl) {
                    // If no thumbnailUrls array but we have a single thumbnailUrl
                    const loadMainThumbnail = async () => {
                        try {
                            const fileName = activity.thumbnailUrl.split('/').pop() || 'thumbnail.jpg';
                            const fileUrl = getProperFileUrl(activity.thumbnailUrl, 'activity-library');
                            const response = await fetch(fileUrl);
                            const blob = await response.blob();
                            const file = new File([blob], fileName, { type: blob.type || 'image/jpeg' });
                            setThumbnailFiles([file]);
                        } catch (error) {
                            console.error('Error loading main thumbnail:', error);
                        }
                    };
                    loadMainThumbnail();
                }

                // Handle multiple media files
                if (activity.mediaUrls && activity.mediaUrls.length > 0) {
                    // Load media files
                    const loadMediaFiles = async () => {
                        try {
                            const loadedFiles: File[] = [];
                            for (const url of activity.mediaUrls) {
                                const fileName = url.url.split('/').pop() || 'media';
                                const fileUrl = getProperFileUrl(url.url, 'activity-library');
                                try {
                                    const response = await fetch(fileUrl);
                                    const blob = await response.blob();
                                    const file = new File([blob], fileName, {
                                        type: blob.type ||
                                            (url.endsWith('.mp4') ? 'video/mp4' :
                                                url.endsWith('.pdf') ? 'application/pdf' :
                                                    'application/octet-stream')
                                    });
                                    loadedFiles.push(file);
                                } catch (error) {
                                    console.error('Error loading media file:', error);
                                }
                            }
                            setMediaFiles(loadedFiles);
                        } catch (error) {
                            console.error('Error loading media files:', error);
                        }
                    };
                    loadMediaFiles();
                } else if (activity.mediaUrl) {
                    // If no mediaUrls array but we have a single mediaUrl
                    const loadMainMedia = async () => {
                        try {
                            const fileName = activity.mediaUrl.split('/').pop() || 'media';
                            const fileUrl = getProperFileUrl(activity.mediaUrl, 'activity-library');
                            const response = await fetch(fileUrl);
                            const blob = await response.blob();
                            const file = new File([blob], fileName, {
                                type: blob.type ||
                                    (activity.mediaUrl.endsWith('.mp4') ? 'video/mp4' :
                                        activity.mediaUrl.endsWith('.pdf') ? 'application/pdf' :
                                            'application/octet-stream')
                            });
                            setMediaFiles([file]);
                        } catch (error) {
                            console.error('Error loading main media:', error);
                        }
                    };
                    loadMainMedia();
                }

                // Determine the language codes properly from the API response
                let languageCodes = ['en-GB']; // Default

                console.log('activity.language: ', activity.languages);
                if (activity.languages && activity.languages.length > 0) {
                    // Use activityLanguages array (preferred format)
                    languageCodes = activity.languages.map((lang: any) => lang.languageCode);
                    console.log('languageCodes: ', languageCodes);
                } else if (Array.isArray(activity.language)) {
                    // Fallback to language array if present
                    languageCodes = activity.language;
                } else if (activity.languageCode) {
                    // Fallback to single languageCode
                    languageCodes = [activity.languageCode];
                }

                setFormData({
                    title: activity.title || '',
                    description: activity.description || '',
                    thumbnailUrl: activity.thumbnailUrl || '',
                    thumbnailUrls: activity.thumbnailUrls || [],
                    mediaUrl: activity.mediaUrl || '',
                    mediaUrls: activity.mediaUrls || [],
                    mediaType: 'IMAGE',
                    ageRangeStart: activity.ageRangeStart || '',
                    ageRangeEnd: activity.ageRangeEnd || '',
                    learningArea: activity.learningAreaId || activity.learningArea || '',
                    language: languageCodes,
                    isPublic: activity.isPublic || false,
                    visibilityLevel: activity.visibleToCreatorOnly ? 'self' :
                        activity.assignedToUserNurseries ? 'assigned' : 'public',
                    content: activity.content || ''
                });

                if (activity.mediaType) {
                    const mediaTypeVal = activity.mediaType.toUpperCase();
                    if (mediaTypeVal === 'IMAGE') setActiveMediaType('image');
                    else if (mediaTypeVal === 'VIDEO') setActiveMediaType('video');
                    else if (mediaTypeVal === 'PDF' || mediaTypeVal === 'DOCUMENT') setActiveMediaType('pdf');
                }

                setDataLoaded(true);
            } else {
                // Replace setError with SweetAlert
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to load activity data',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (err) {
            console.error('Error fetching activity:', err);
            // Replace setError with SweetAlert
            Swal.fire({
                title: 'Error',
                text: 'Error loading activity data. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;

        // Handle checkbox separately
        if (type === 'checkbox') {
            const checked = (e.target as HTMLInputElement).checked;
            setFormData({
                ...formData,
                [name]: checked
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }

        // Clear error for this field when user makes changes
        if (formErrors[name]) {
            setFormErrors({
                ...formErrors,
                [name]: ''
            });
        }
    };

    const handleThumbnailUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            if (index < thumbnailFiles.length) {
                // Replace existing thumbnail
                const updatedFiles = [...thumbnailFiles];
                updatedFiles[index] = files[0];
                setThumbnailFiles(updatedFiles);
            } else {
                // Add new thumbnail
                setThumbnailFiles([...thumbnailFiles, files[0]]);
            }

            // Set flag indicating thumbnails were changed
            setThumbnailRemoved(true);
        }
    };

    const removeThumbnail = (index: number) => {
        const updatedFiles = [...thumbnailFiles];
        updatedFiles.splice(index, 1);
        setThumbnailFiles(updatedFiles);

        // If removing all thumbnails, set the flag
        if (updatedFiles.length === 0) {
            setThumbnailRemoved(true);
        }
    };

    const addThumbnailSlide = () => {
        setCurrentThumbnailIndex(thumbnailFiles.length);
    };

    const navigateThumbnail = (direction: 'prev' | 'next') => {
        if (direction === 'prev' && currentThumbnailIndex > 0) {
            setCurrentThumbnailIndex(currentThumbnailIndex - 1);
        } else if (direction === 'next' && currentThumbnailIndex < thumbnailFiles.length) {
            setCurrentThumbnailIndex(currentThumbnailIndex + 1);
        }
    };

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files);
            const validFiles = files.filter(file =>
                file.type.startsWith('image/') ||
                file.type.startsWith('video/') ||
                file.type === 'application/pdf'
            );

            if (validFiles.length > 0) {
                setMediaFiles(prev => [...prev, ...validFiles]);

                // Set mediaType based on the first file type if no files exist yet
                if (mediaFiles.length === 0) {
                    const firstFile = validFiles[0];
                    if (firstFile.type.startsWith('image/')) {
                        setActiveMediaType('image');
                        setFormData(prev => ({
                            ...prev,
                            mediaType: 'IMAGE'
                        }));
                    } else if (firstFile.type.startsWith('video/')) {
                        setActiveMediaType('video');
                        setFormData(prev => ({
                            ...prev,
                            mediaType: 'VIDEO'
                        }));
                    } else if (firstFile.type === 'application/pdf') {
                        setActiveMediaType('pdf');
                        setFormData(prev => ({
                            ...prev,
                            mediaType: 'PDF'
                        }));
                    }
                }
            }
        }
    }, [mediaFiles]);

    const handleMediaUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setMediaFiles([...mediaFiles, ...Array.from(files)]);

            // Since we're uploading new media, we set the flag to indicate replacement
            setMediaRemoved(true);
        }
    };

    const removeMediaFile = (index: number) => {
        const updatedFiles = [...mediaFiles];
        updatedFiles.splice(index, 1);
        setMediaFiles(updatedFiles);

        // If we're removing all media files, set the flag
        if (updatedFiles.length === 0) {
            setMediaRemoved(true);
        }
    };

    // Replace or add this function to handle language changes correctly
    const handleLanguageChange = (selectedOptions: any) => {
        console.log('Selected language options:', selectedOptions);

        // Extract just the values (language codes) from the selected options
        const languageCodes = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];

        setFormData({
            ...formData,
            language: languageCodes
        });

        // Clear error when user makes changes
        if (formErrors.language) {
            setFormErrors({
                ...formErrors,
                language: ''
            });
        }
    };

    const validateForm = (formData: FormData) => {
        const errors: { [key: string]: string } = {};

        if (!formData.title.trim()) {
            errors.title = 'Title is required';
        }

        if (!formData.description.trim()) {
            errors.description = 'Description is required';
        }

        if (!formData.ageRangeStart) {
            errors.ageRangeStart = 'Starting age is required';
        }

        if (!formData.ageRangeEnd) {
            errors.ageRangeEnd = 'Ending age is required';
        } else if (parseInt(formData.ageRangeEnd) < parseInt(formData.ageRangeStart)) {
            errors.ageRangeEnd = 'Ending age must be greater than starting age';
        }

        if (!formData.learningArea) {
            errors.learningArea = 'Learning area is required';
        }

        if (!formData.language.length) {
            errors.language = 'At least one language is required';
        }

        if (mediaFiles.length === 0 && !formData.mediaUrl) {
            errors.mediaUrl = 'At least one media file is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const uploadFiles = async (formData: FormData) => {
        try {
            const uploads = [];
            const updatedFormData = { ...formData };
            let mainThumbnailUrl = '';
            let mainMediaUrl = '';

            // Upload thumbnail files
            if (thumbnailFiles.length > 0) {
                const thumbnailUrls = [];

                for (let i = 0; i < thumbnailFiles.length; i++) {
                    const file = thumbnailFiles[i];
                    const thumbnailFormData = new FormData();
                    thumbnailFormData.append('file', file);
                    thumbnailFormData.append("folder", "activity-library");
                    const thumbnailResponse = await UploadApi.uploadFile(thumbnailFormData, 'upload-activity-library');
                    console.log('thumbnailResponse: ', thumbnailResponse);

                    if (thumbnailResponse && thumbnailResponse.filePath) {
                        thumbnailUrls.push(thumbnailResponse.filePath);

                        // Save the first thumbnail as the main thumbnail
                        if (i === 0) {
                            mainThumbnailUrl = thumbnailResponse.filePath;
                        }

                        uploads.push({
                            field: 'thumbnailUrls',
                            url: thumbnailResponse.filePath
                        });
                    }
                }

                // Update form data with thumbnail URLs
                console.log('thumbnailUrls: ', thumbnailUrls);
                console.log('mainThumbnailUrl: ', mainThumbnailUrl);
                updatedFormData.thumbnailUrls = thumbnailUrls;
                updatedFormData.thumbnailUrl = mainThumbnailUrl;
            }

            // Upload media files
            if (mediaFiles.length > 0) {
                const mediaUrls = [];

                for (let i = 0; i < mediaFiles.length; i++) {
                    const file = mediaFiles[i];
                    const mediaFormData = new FormData();
                    mediaFormData.append('file', file);
                    mediaFormData.append("folder", "activity-library");
                    const mediaResponse = await UploadApi.uploadFile(mediaFormData, 'upload-activity-library');
                    console.log('mediaResponse: ', mediaResponse);

                    if (mediaResponse && mediaResponse.filePath) {
                        mediaUrls.push(mediaResponse.filePath);

                        // Save the first media as the main media
                        if (i === 0) {
                            mainMediaUrl = mediaResponse.filePath;
                        }

                        uploads.push({
                            field: 'mediaUrls',
                            url: mediaResponse.filePath
                        });
                    }
                }

                // Update form data with media URLs
                console.log('mediaUrls: ', mediaUrls);
                updatedFormData.mediaUrls = mediaUrls;
                console.log('mainMediaUrl: ', mainMediaUrl);
                updatedFormData.mediaUrl = mainMediaUrl;
            }

            // Update the state with the new URLs
            setFormData(updatedFormData);

            return uploads;
        } catch (error) {
            console.error('Error uploading files:', error);

            // Add SweetAlert2 notification instead
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'Failed to upload files. Please try again.',
                confirmButtonText: 'OK'
            });
            return [];
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateForm(formData)) {
            return;
        }

        try {
            setLoading(true);

            // Upload new files if any are present
            let updatedMediaUrls = formData.mediaUrls;
            let updatedThumbnailUrls = formData.thumbnailUrls;

            // If media files have been added or all removed
            if (mediaFiles.length > 0 || mediaRemoved) {
                const uploadedMediaUrls = await Promise.all(
                    mediaFiles.map(async file => {
                        const MediaFormData = new FormData();
                        MediaFormData.append('file', file);
                        MediaFormData.append("folder", "activity-library");
                        try {
                            const response = await UploadApi.uploadFile(MediaFormData, 'upload-activity-library');
                            return response.filePath;
                        } catch (error) {
                            console.error('Error uploading media file:', error);
                            throw error;
                        }
                    })
                );

                // Replace existing media URLs with new ones
                updatedMediaUrls = uploadedMediaUrls;
            }

            // If thumbnail files have been added or all removed
            if (thumbnailFiles.length > 0 || thumbnailRemoved) {
                const uploadedThumbnailUrls = await Promise.all(
                    thumbnailFiles.map(async file => {
                        const thumbnailFormData = new FormData();
                        thumbnailFormData.append('file', file);
                        thumbnailFormData.append("folder", "activity-library");
                        try {
                            const response = await UploadApi.uploadFile(thumbnailFormData, 'upload-activity-library');
                            return response.filePath;
                        } catch (error) {
                            console.error('Error uploading thumbnail file:', error);
                            throw error;
                        }
                    })
                );

                // Replace existing thumbnail URLs with new ones
                updatedThumbnailUrls = uploadedThumbnailUrls;
            }

            // Format languages in the format expected by the API
            const activityLanguagesData = formData.language.map(langCode => ({
                languageCode: langCode
            }));

            // Extract just the URL strings from objects
            const formattedThumbnailUrls = updatedThumbnailUrls.map((urlObj: any) =>
                typeof urlObj === 'string' ? urlObj : urlObj.url || urlObj
            );

            const formattedMediaUrls = updatedMediaUrls.map((urlObj: any) =>
                typeof urlObj === 'string' ? urlObj : urlObj.url || urlObj
            );

            // Update thumbnail and media URLs in the payload
            const payload = {
                ...formData,
                thumbnailUrls: formattedThumbnailUrls,
                mediaUrls: formattedMediaUrls,
                thumbnailUrl: formattedThumbnailUrls.length > 0 ? formattedThumbnailUrls[0] : '',
                mediaUrl: formattedMediaUrls.length > 0 ? formattedMediaUrls[0] : '',
                // Visibility settings based on radio selection
                isPublic: formData.visibilityLevel === 'public',
                visibleToCreatorOnly: formData.visibilityLevel === 'self',
                assignedToUserNurseries: formData.visibilityLevel === 'assigned',
                activityLanguages: activityLanguagesData
            };

            console.log('Sending update payload:', payload);

            const response = await ActivityLibraryApi.updateActivity(id!, payload);

            if (response && response.success) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Activity has been updated successfully',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/activity-library`);
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: response?.message || 'Failed to update activity',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (err: any) {
            console.error('Error updating activity:', err);
            Swal.fire({
                title: 'Error',
                text: err.message || 'An error occurred while updating the activity',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoading(false);
        }
    };

    // Make sure this is defined before the return statement
    const defaultLanguageOptions = languages.map((lang: any) => ({
        value: lang.id || lang.languageCode || lang.code,
        label: lang.name || getLanguageDisplayName(lang.id || lang.languageCode || lang.code)
    }));

    // Add this helper function if it's not already defined
    const getLanguageDisplayName = (code: string): string => {
        const languageMap: Record<string, string> = {
            'en-GB': 'English (UK)',
            'en-US': 'English (US)',
            'fr-FR': 'French',
            'de-DE': 'German',
            'es-ES': 'Spanish',
            'it-IT': 'Italian',
            'ar-SA': 'Arabic',
            'zh-CN': 'Chinese (Simplified)',
            'hi-IN': 'Hindi'
        };

        return languageMap[code] || code;
    };

    // Simplify to use document.getElementById which works reliably
    const handleAddMoreMedia = () => {
        const fileInput = document.getElementById('mediaFileInput');
        console.log('fileInput: ', fileInput);
        if (fileInput) {
            fileInput.click();
        }
    };

    console.log('FormData', formData);
    return (
        <Container className="activity-form-container py-4">
            <div className="d-flex align-items-center mb-4">
                <Button
                    color="primary"
                    className="p-0 me-3"
                    onClick={() => navigate(`${process.env.PUBLIC_URL}/dashboard/activity-library`)}
                >
                    <ArrowLeft size={20} />
                </Button>
                <h2 className="mb-0">{`Edit Activity`}</h2>
            </div>

            {error && (
                <Alert color="danger" className="mb-4">
                    {error}
                </Alert>
            )}

            <Form id="activity-form" onSubmit={handleSubmit}>
                <Row>
                    <Col md={8}>
                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Activity Details</CardTitle>
                                <FormGroup>
                                    <Label for="title">Title <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        invalid={!!formErrors.title}
                                    />
                                    <FormFeedback>{formErrors.title}</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="description">Description <span className="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        rows={3}
                                        name="description"
                                        id="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        invalid={!!formErrors.description}
                                    />
                                    <FormFeedback>{formErrors.description}</FormFeedback>
                                </FormGroup>
                            </CardBody>
                        </Card>

                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Activity Media <span className="text-danger">*</span></CardTitle>

                                {/* Always render this input, but keep it hidden */}
                                <Input
                                    type="file"
                                    id="mediaFileInput"
                                    className="d-none"
                                    accept={
                                        activeMediaType === 'image' ? 'image/*' :
                                            activeMediaType === 'video' ? 'video/*' :
                                                activeMediaType === 'pdf' ? '.pdf,.doc,.docx,.ppt,.pptx' :
                                                    '*/*'
                                    }
                                    onChange={handleMediaUpload}
                                    multiple
                                />

                                <div
                                    className={`activity-media-dropzone ${isDragOver ? 'drag-over' : ''}`}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    {mediaFiles.length > 0 ? (
                                        <div className="media-preview">
                                            <div className="selected-files-container">
                                                {mediaFiles.map((file, index) => (
                                                    <div key={index} className="selected-file-item">
                                                        {file.type.startsWith('image/') && (
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                alt={`Media preview ${index}`}
                                                                className="file-thumbnail"
                                                            />
                                                        )}
                                                        {file.type.startsWith('video/') && (
                                                            <Video size={24} className="file-icon" />
                                                        )}
                                                        {file.type === 'application/pdf' && (
                                                            <FileText size={24} className="file-icon" />
                                                        )}
                                                        <span className="file-name">{file.name}</span>
                                                        <Button
                                                            close
                                                            size="sm"
                                                            className="remove-file-btn"
                                                            onClick={() => removeMediaFile(index)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <Button
                                                color="outline-primary"
                                                size="sm"
                                                onClick={handleAddMoreMedia}
                                                className="mt-2"
                                            >
                                                <Upload size={14} className="me-1" /> Add More Files
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="media-upload-placeholder">
                                            <div className="upload-icon mb-3">
                                                <Upload size={48} />
                                            </div>
                                            <p>Drag & drop your files here or</p>
                                            <label htmlFor="mediaFileInput" className="btn btn-outline-primary mt-2">
                                                Browse Files
                                            </label>
                                            <p className="mt-3 text-muted small">
                                                Supported formats: Images, Videos, PDF
                                            </p>
                                        </div>
                                    )}
                                </div>
                                {formErrors.mediaUrl && (
                                    <div className="text-danger mt-2 small">{formErrors.mediaUrl}</div>
                                )}
                            </CardBody>
                        </Card>

                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Thumbnail Images</CardTitle>
                                <div className="thumbnail-slider-container">
                                    {thumbnailFiles.length > 0 ? (
                                        <div className="thumbnail-slider">
                                            <div className="thumbnail-slider-controls">
                                                <Button
                                                    color="light"
                                                    onClick={() => navigateThumbnail('prev')}
                                                    disabled={currentThumbnailIndex === 0}
                                                >
                                                    &lt;
                                                </Button>
                                                <span className="mx-2">
                                                    {currentThumbnailIndex + 1} / {thumbnailFiles.length + 1}
                                                </span>
                                                <Button
                                                    color="light"
                                                    onClick={() => navigateThumbnail('next')}
                                                    disabled={currentThumbnailIndex >= thumbnailFiles.length}
                                                >
                                                    &gt;
                                                </Button>
                                            </div>

                                            <div className="thumbnail-slide">
                                                {currentThumbnailIndex < thumbnailFiles.length ? (
                                                    <div className="thumbnail-preview">
                                                        <img
                                                            src={URL.createObjectURL(thumbnailFiles[currentThumbnailIndex])}
                                                            alt={`Thumbnail ${currentThumbnailIndex + 1}`}
                                                            className="img-fluid thumbnail-image"
                                                        />
                                                        <div className="thumbnail-actions mt-2">
                                                            <Button
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() => removeThumbnail(currentThumbnailIndex)}
                                                            >
                                                                Remove
                                                            </Button>
                                                            <label className="btn btn-primary btn-sm ms-2" htmlFor={`thumbnailUpload-${currentThumbnailIndex}`}>
                                                                Replace
                                                                <Input
                                                                    type="file"
                                                                    name={`thumbnailUpload-${currentThumbnailIndex}`}
                                                                    id={`thumbnailUpload-${currentThumbnailIndex}`}
                                                                    accept="image/*"
                                                                    onChange={(e) => handleThumbnailUpload(e, currentThumbnailIndex)}
                                                                    className="d-none"
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="thumbnail-upload-placeholder">
                                                        <div className="upload-icon mb-3">
                                                            <Image size={48} />
                                                        </div>
                                                        <p>Upload a thumbnail image</p>
                                                        <label className="btn btn-outline-primary" htmlFor={`thumbnailUploadNew-${currentThumbnailIndex}`}>
                                                            Choose Image
                                                            <Input
                                                                type="file"
                                                                name={`thumbnailUploadNew-${currentThumbnailIndex}`}
                                                                id={`thumbnailUploadNew-${currentThumbnailIndex}`}
                                                                accept="image/*"
                                                                onChange={(e) => handleThumbnailUpload(e, currentThumbnailIndex)}
                                                                className="d-none"
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="thumbnail-upload-placeholder">
                                            <div className="upload-icon mb-3">
                                                <Image size={48} />
                                            </div>
                                            <p>Upload thumbnail images for your activity</p>
                                            <label className="btn btn-outline-primary" htmlFor="thumbnailUploadInitial">
                                                Choose Image
                                                <Input
                                                    type="file"
                                                    name="thumbnailUploadInitial"
                                                    id="thumbnailUploadInitial"
                                                    accept="image/*"
                                                    onChange={(e) => handleThumbnailUpload(e, 0)}
                                                    className="d-none"
                                                />
                                            </label>
                                        </div>
                                    )}

                                    <small className="d-block mt-2 text-muted">
                                        Thumbnails will be displayed in the activity card. The first image will be used as the main thumbnail.
                                    </small>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Age Range <span className="text-danger">*</span></CardTitle>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="ageRangeStart">From (months)</Label>
                                            <Input
                                                type="number"
                                                name="ageRangeStart"
                                                id="ageRangeStart"
                                                value={formData.ageRangeStart}
                                                onChange={handleInputChange}
                                                min="0"
                                                invalid={!!formErrors.ageRangeStart}
                                            />
                                            <FormFeedback>{formErrors.ageRangeStart}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="ageRangeEnd">To (months)</Label>
                                            <Input
                                                type="number"
                                                name="ageRangeEnd"
                                                id="ageRangeEnd"
                                                value={formData.ageRangeEnd}
                                                onChange={handleInputChange}
                                                min="0"
                                                invalid={!!formErrors.ageRangeEnd}
                                            />
                                            <FormFeedback>{formErrors.ageRangeEnd}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Categories <span className="text-danger">*</span></CardTitle>
                                <FormGroup>
                                    <Label for="learningArea">Learning Area <span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        name="learningArea"
                                        id="learningArea"
                                        value={formData.learningArea}
                                        onChange={handleInputChange}
                                        invalid={!!formErrors.learningArea}
                                    >
                                        <option value="">Select Learning Area</option>
                                        {learningAreas.map((area) => (
                                            <option
                                                key={area.id}
                                                value={area.id}
                                                style={{
                                                    backgroundColor: area.color ? `${area.color}10` : 'transparent'
                                                }}
                                            >
                                                {area.name}
                                            </option>
                                        ))}
                                    </Input>
                                    <FormFeedback>{formErrors.learningArea}</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="language">Languages <span className="text-danger">*</span></Label>
                                    <Select
                                        id="language"
                                        name="language"
                                        isMulti
                                        options={defaultLanguageOptions}
                                        value={defaultLanguageOptions.filter(option =>
                                            formData.language.includes(option.value)
                                        )}
                                        onChange={handleLanguageChange}
                                        className={`react-select-container ${formErrors.language ? 'is-invalid' : ''}`}
                                        classNamePrefix="react-select"
                                        placeholder="Select languages..."
                                        aria-label="Languages"
                                    />
                                    {formErrors.language && (
                                        <div className="invalid-feedback d-block">
                                            {formErrors.language}
                                        </div>
                                    )}
                                    <small className="form-text text-muted">
                                        You can select multiple languages for this activity
                                    </small>
                                </FormGroup>

                                <CardBody>
                                    <CardTitle tag="h5">Visibility Settings</CardTitle>
                                    <FormGroup tag="fieldset">
                                        <legend className="h6">Who can see this activity?</legend>

                                        <FormGroup check className="mb-2">
                                            <Input
                                                type="radio"
                                                name="visibilityLevel"
                                                id="visibilitySelf"
                                                value="self"
                                                checked={formData.visibilityLevel === 'self'}
                                                onChange={handleInputChange}
                                            />
                                            <Label check for="visibilitySelf">
                                                <strong>Creator Only</strong>
                                                <div className="text-muted small">Only you can see and use this activity</div>
                                            </Label>
                                        </FormGroup>

                                        <FormGroup check className="mb-2">
                                            <Input
                                                type="radio"
                                                name="visibilityLevel"
                                                id="visibilityAssigned"
                                                value="assigned"
                                                checked={formData.visibilityLevel === 'assigned'}
                                                onChange={handleInputChange}
                                            />
                                            <Label check for="visibilityAssigned">
                                                <strong>Assigned Nurseries</strong>
                                                <div className="text-muted small">Only nurseries you assign can see and use this activity</div>
                                            </Label>
                                        </FormGroup>

                                        <FormGroup check>
                                            <Input
                                                type="radio"
                                                name="visibilityLevel"
                                                id="visibilityPublic"
                                                value="public"
                                                checked={formData.visibilityLevel === 'public'}
                                                onChange={handleInputChange}
                                            />
                                            <Label check for="visibilityPublic">
                                                <strong>All Nurseries</strong>
                                                <div className="text-muted small">All nurseries can see and use this activity</div>
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </CardBody>
                            </CardBody>
                        </Card>

                        <div className="d-flex justify-content-between mt-4">
                            <Button
                                color="outline-primary"
                                type="button"
                                className="px-4"
                                onClick={() => navigate(`${process.env.PUBLIC_URL}/dashboard/activity-library`)}
                            >
                                Cancel
                            </Button>

                            <Button
                                color="primary"
                                type="submit"
                                disabled={loading}
                                className="px-4"
                            >
                                {loading ? (
                                    <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Saving...</>
                                ) : (
                                    <><Save size={16} className="me-2" /> Update Activity</>
                                )}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default EditActivity; 