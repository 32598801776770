import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import Select from "react-select";
import EventAPi from "../../../services/EventApi";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../Utils";
import UploadAPi from "../../../services/UploadApi";
import { connectComponent } from "./connector";
import UserApi from "src/services/UserApi";

interface UserOption {
  value: string;
  label: string;
}

interface ParentUser {
  id: string;
  firstName: string;
  lastName: string;
  phoneNo: string;
}

interface EmployeeUser {
  id: string;
  firstName: string;
  lastName: string;
}

interface SelectOption {
  value: string;
  label: string;
}

interface UserTypeOption {
  value: 'PARENTS' | 'EMPLOYEE';
  label: string;
}

interface AddEvents {
  nurseryId?: string;
  title: string;
  eventDate: Date;
  startAt: Date;
  endAt: Date;
  visibility: string;
  selectedUserType?: 'PARENTS' | 'EMPLOYEE';
  selectedUsers?: SelectOption[];
  files: File[],
  description: string,
}

const visibilityOptions = [
  { value: 'EVERYONE', label: 'Everyone' },
  { value: 'EMPLOYEES', label: 'Staff and Employee Only' },
  { value: 'MYSELF', label: 'My Self' },
  { value: 'PARENTS', label: 'Parents' },
  { value: 'INDIVIDUAL', label: 'Individual' },
];

function AddEvents({ nurseryId }: any) {
  const navigate = useNavigate();
  const initialState: AddEvents = {
    title: "",
    eventDate: new Date(),
    startAt: new Date(),
    endAt: new Date(),
    visibility: "EVERYONE",
    selectedUserType: undefined,
    selectedUsers: [],
    files: [],
    description: "",
  };
  const [form, setForm] = useState<AddEvents>({ ...initialState });
  const [validate, setValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [parentOptions, setParentOptions] = useState<any[]>([]);
  const [employeeOptions, setEmployeeOptions] = useState<any[]>([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AddEvents>({
    defaultValues: {
      visibility: initialState.visibility,
    }
  });

  const onSubmit = async () => {
    setValidate(true);
    setIsLoading(true);
    try {
      let formattedData = JSON.parse(JSON.stringify(form));
      formattedData.endAt = `${new Date(formattedData.endAt).getTime()}`;
      formattedData.startAt = `${new Date(formattedData.startAt).getTime()}`;
      formattedData.eventDate = new Date(formattedData.eventDate).getTime();

      if (formattedData.visibility === 'INDIVIDUAL') {
        if (formattedData.selectedUserType === 'PARENTS') {
          formattedData.parentObjArray = formattedData.selectedUsers.map((user: any) => ({
            id: user.value
          }));
        } else {
          formattedData.empObjArray = formattedData.selectedUsers.map((user: any) => ({
            id: user.value
          }));
        }
      }

      delete formattedData.selectedUsers;

      const { mediaUrl, mediaType } = await uploadImage(form.files[0]);
      if (!mediaUrl && !mediaType) {
        delete formattedData.files;
        formattedData["mediaUrl"] = '';
        formattedData["mediaType"] = '';
        formattedData["creationType"] = "ADMIN";
        formattedData["nurseryId"] = nurseryId;

        // Call the API to create the event using EventApi
        const response = await EventAPi.createEvent(formattedData);

        if (response.success) {
          Swal.fire({
            title: "Success!",
            text: "Event created successfully",
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData(
                "Calendar",
                `Event created successfully`
              )
            );
            // Navigate to calendar on success
            navigate(`${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar`);
          });
        } else {
          throw new Error(response.message || "Failed to create event");
        }
      } else {
        delete formattedData.files;
        formattedData["mediaUrl"] = mediaUrl;
        formattedData["mediaType"] = mediaType;
        formattedData["creationType"] = "ADMIN";
        formattedData["nurseryId"] = nurseryId;

        // Call the API to create the event using EventApi
        const response = await EventAPi.createEvent(formattedData);

        if (response.success) {
          Swal.fire({
            title: "Success!",
            text: "Event created successfully",
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData(
                "Calendar",
                `Event created successfully`
              )
            );
            // Navigate to calendar on success
            navigate(`${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar`);
          });
        } else {
          throw new Error(response.message || "Failed to create event");
        }
      }
    } catch (error: any) {
      console.log('error: ', error);
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const uploadImage = async (file: any) => {
    const formData: any = new FormData();
    formData.append("file", file);
    formData.append("folder", "event");
    try {
      const response = await UploadAPi.uploadFile(formData, "upload-event");
      return { mediaUrl: response.filePath, mediaType: "IMAGE" };
    } catch (err) {
      console.error("err: ", err);
      return { mediaUrl: "", mediaType: "" };
    }
  };

  const fetchParents = async () => {
    setIsLoadingUsers(true);
    try {
      const response = await UserApi.dropDownAllEmployeesAndParents({ nurseryId });
      if (response.success && Array.isArray(response.data)) {
        const options = response.data.filter((user: any) => user.role === 'PARENT').map((parent: ParentUser) => ({
          value: parent.id,
          label: `${parent.firstName} ${parent.lastName}`
        }));
        setParentOptions(options);
      }
    } catch (error) {
      console.error('Error fetching parents:', error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const fetchEmployees = async () => {
    setIsLoadingUsers(true);
    try {
      const response = await UserApi.dropDownAllEmployeesAndParents({ nurseryId });
      if (response.success && Array.isArray(response.data)) {
        const options = response.data.filter((user: any) => user.role === 'EMPLOYEE').map((employee: EmployeeUser) => ({
          value: employee.id,
          label: `${employee.firstName} ${employee.lastName}`
        }));
        setEmployeeOptions(options);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center">
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Add Events Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md me-3"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli me-3"></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12}>
                      <Row className="mt-2">
                        <Col md={4} className="position-relative">
                          <Label>
                            {`Title`} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${errors.title ? "is-invalid" : ""}`}
                            placeholder="Enter title name"
                            {...register("title", {
                              required: "Please enter a valid title",
                            })}
                            value={form.title}
                            onChange={(e) =>
                              saveDataForm("title", e.target.value)
                            }
                          />
                          {errors.title && (
                            <FormFeedback style={{ display: "block", color: "orangered" }}>
                              {errors.title.message}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Date"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="eventDate"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <DatePicker
                                  className={`form-control flatpickr-input ${errors.eventDate ? "is-invalid" : ""
                                    }`}
                                  selected={field.value}
                                  onChange={(date) => {
                                    field.onChange(date);
                                    saveDataForm("eventDate", date);
                                  }}
                                  placeholderText="Select a date"
                                  dateFormat="MMMM dd,yyyy"
                                />
                              )}
                            />
                          </InputGroup>
                          {errors.eventDate && (
                            <FormFeedback style={{ display: "block", color: "orangered" }}>
                              {"Please select event date."}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {"Start Time"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="startAt"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <DatePicker
                                  className={`form-control flatpickr-input ${errors.startAt ? "is-invalid" : ""
                                    }`}
                                  selected={form.startAt}
                                  onChange={(date) => {
                                    field.onChange(date);
                                    saveDataForm("startAt", date);
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              )}
                            />

                          </InputGroup>
                          {errors.startAt && (
                            <FormFeedback style={{ display: "block", color: "orangered" }}>
                              {"Please select event start time."}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"End Time"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="endAt"
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <DatePicker
                                  className={`form-control flatpickr-input ${errors.endAt ? "is-invalid" : ""
                                    }`}
                                  selected={form.endAt}
                                  onChange={(date) => {
                                    field.onChange(date);
                                    saveDataForm("endAt", date);
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              )}
                            />
                          </InputGroup>
                          {errors.endAt && (
                            <FormFeedback style={{ display: "block", color: "orangered" }}>
                              {"Please select event end time."}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Visibility"} <span className="text-danger">*</span>
                          </Label>
                          <Controller
                            name="visibility"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="visibility"
                                className={`${errors.visibility ? "is-invalid" : ""
                                  }`}
                                options={visibilityOptions}
                                value={visibilityOptions.find(
                                  (option: any) =>
                                    option.value === form.visibility
                                )}
                                onChange={(selectedOption: any) => {
                                  field.onChange(selectedOption?.value);
                                  saveDataForm(
                                    "visibility",
                                    selectedOption?.value
                                  );
                                }}
                              />
                            )}
                          />
                          {errors.visibility && (
                            <FormFeedback style={{ display: "block", color: "orangered" }}>
                              {"Please select visibility"}
                            </FormFeedback>
                          )}

                        </Col>

                        {form.visibility === 'INDIVIDUAL' && (
                          <>
                            <Col md={4} className="position-relative mt-3">
                              <Label>
                                {"User Type"} <span className="text-danger">*</span>
                              </Label>
                              <Controller
                                name="selectedUserType"
                                control={control}
                                rules={{ required: form.visibility === 'INDIVIDUAL' }}
                                render={({ field: { onChange, value } }) => (
                                  <Select<UserTypeOption>
                                    value={[
                                      { value: 'PARENTS' as const, label: 'Parents' },
                                      { value: 'EMPLOYEE' as const, label: 'Employee' }
                                    ].find(option => option.value === form.selectedUserType)}
                                    options={[
                                      { value: 'PARENTS' as const, label: 'Parents' },
                                      { value: 'EMPLOYEE' as const, label: 'Employee' }
                                    ]}
                                    onChange={(option) => {
                                      onChange(option?.value);
                                      saveDataForm("selectedUserType", option?.value);
                                      if (option?.value === 'PARENTS') {
                                        fetchParents();
                                      } else {
                                        fetchEmployees();
                                      }
                                      setForm(prev => ({ ...prev, selectedUsers: [] }));
                                    }}
                                    isDisabled={isLoading}
                                  />
                                )}
                              />
                              {errors.selectedUserType && (
                                <FormFeedback style={{ display: "block", color: "orangered" }}>
                                  Please select user type
                                </FormFeedback>
                              )}
                            </Col>

                            {form.selectedUserType && (
                              <Col md={4} className="position-relative mt-3">
                                <Label>
                                  {`Select ${form.selectedUserType === 'PARENTS' ? 'Parents' : 'Employees'}`}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Controller
                                  name="selectedUsers"
                                  control={control}
                                  rules={{ required: form.visibility === 'INDIVIDUAL' }}
                                  render={({ field: { onChange, value } }) => (
                                    <Select<SelectOption, true>
                                      isMulti
                                      value={value}
                                      options={form.selectedUserType === 'PARENTS' ? parentOptions : employeeOptions}
                                      isLoading={isLoadingUsers}
                                      onChange={(selected) => {
                                        onChange(selected);
                                        setForm(prev => ({ ...prev, selectedUsers: selected as SelectOption[] }));
                                      }}
                                    />
                                  )}
                                />
                                {errors.selectedUsers && (
                                  <FormFeedback style={{ display: "block", color: "orangered" }}>
                                    Please select at least one user
                                  </FormFeedback>
                                )}
                              </Col>
                            )}
                          </>
                        )}

                        <Col md={4} className="position-relative mt-3">
                          <Label>{"Photo"}</Label>
                          <div style={{ display: "flex" }}>
                            <input
                              className={`form-control ${errors.files ? "is-invalid" : ""}`}
                              type="file"
                              accept="image/*"
                              {...register("files", { required: false })}
                              onChange={(e) => {
                                saveDataForm("files", e.target.files);
                              }}
                            />
                          </div>
                          {errors.files && (
                            <FormFeedback style={{ display: "block", color: "orangered" }}>
                              {"Please upload a file"}
                            </FormFeedback>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col md={12} className="position-relative mt-3">
                      <Label>
                        {`Description`} <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <ReactQuill
                            theme="snow"
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(
                                value === "<p><br></p>" ? "" : value
                              );
                              saveDataForm(
                                "description",
                                value === "<p><br></p>" ? "" : value
                              );
                            }}
                            onBlur={field.onBlur}
                            placeholder="Enter Event Description"
                          />
                        )}
                      />
                      {errors.description && (
                        <FormFeedback
                          className="d-block"
                          style={{
                            color: "orangered"
                          }}
                        >
                          {"Description is required"}
                        </FormFeedback>
                      )}
                    </Col>

                    <Col xs={12} className='d-flex justify-content-end'>
                      <Btn
                        color="primary"
                        className="btn-md"
                        style={{ marginRight: "0.5rem" }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span className="d-flex gap-2">
                            <Spinner color="white" size="sm" /> Loading...
                          </span>
                        ) : (
                          "Save & List"
                        )}
                      </Btn>
                      <Btn
                        color="danger"
                        outline={true}
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar`
                          );
                        }}
                        className="btn-md"
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(AddEvents);
