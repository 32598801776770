import api from "./api";

class WhatsNextApi {
    static async createWhatsNext(data: any) {
        try {
            const response = await api.post('/whats-next/create', data);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async updateWhatsNext(id: string, data: any) {
        try {
            const response = await api.put(`/whats-next/update/${id}`, data);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async deleteWhatsNext(id: string) {
        try {
            const response = await api.delete(`/whats-next/delete/${id}`);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async getWhatsNextList(params?: any) {
        try {
            const response = await api.get('/whats-next/list', { params });
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async getWhatsNextById(id: string) {
        try {
            const response = await api.get(`/whats-next/${id}`);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async updateWhatsNextStatus(id: string, data: { status: number }) {
        try {
            const response = await api.put(`/whats-next/update/status/${id}`, data);
            return response.data;
        } catch (error: any) {
            throw new Error(error.response?.data?.error || 'Failed to update what\'s next status');
        }
    }
}

export default WhatsNextApi; 