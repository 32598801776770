import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "../../../Utils/Constants/config";

export const AdminUserApiSlice = createApi({
  reducerPath: "adminUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addAdmin: builder.mutation({
      query: (credentials) => {
        return {
          url: "/user/create-admin-users",
          method: "POST",
          body: credentials,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateAdmin: builder.mutation({
      query: ({ id, body }) => ({
        url: `/user/updateAdminUsers/${id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getAdmin: builder.query({
      query: (params) => {
        return {
          url: "/user/getAllAdminUsers",
          method: "GET",
          params: params,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getAdminById: builder.query({
      query: (id) => {
        return {
          url: `/user/adminDetails/${id}`,
          method: "GET",
        };
      },
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getPackagesByAdminId: builder.query({
      query: (id) => {
        return {
          url: `/user/adminPackages/${id}`,
          method: "GET",
        };
      },
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
  }),
});
