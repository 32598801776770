import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Spinner,
  TabPane,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { UpdateProfile } from "../../../../Utils/Constants";
import DatePicker from "react-datepicker";
import {
  useMutation,
  UseMutationResult,
  useQuery,
} from "@tanstack/react-query";
import UserApi from "../../../../services/UserApi";
import Swal from "sweetalert2";
import CommonCardHeader from "src/Utils/CommonComponents/CommonCardHeader";

interface formData {
  firstName: string;
  lastName: string;
  joinDate: any;
  nationality: string;
  email: string;
  phoneNo: string;
  primaryAddress: string;
  country: string;
  city: string;
  postalCode: string;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNo: string;
  emergencyContactRelation: string;
  id: string;
}

function General(props: any) {
  const initialState = {
    firstName: "",
    lastName: "",
    joinDate: "",
    nationality: "",
    email: "",
    phoneNo: "",
    primaryAddress: "",
    country: "",
    city: "",
    postalCode: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactEmail: "",
    emergencyContactPhoneNo: "",
    emergencyContactRelation: "",
    id: "",
  };

  const [form, setForm] = useState<formData>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,

    formState: { errors },
  } = useForm();

  const onEditSubmit = async () => {
    setIsLoading(true);
    let formData = JSON.parse(JSON.stringify(form));
    formData.joinDate = new Date(formData.joinDate).getTime();
    try {
      const data = await UserApi.updateUserProfile(formData);
      if (data.success) {
        Swal.fire({
          title: "Successfully !!!",
          text: data.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            // Clear the success message after navigating

            props.setUser({
              ...data.user,
              role: data.user?.role?.toLowerCase(),
            });
          }
        });
      }
    } catch (err) {
      console.log("err: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.user) {
      let formData = {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        joinDate: Number(props.user.joinDate),
        nationality: props.user.nationality,
        email: props.user.email,
        phoneNo: props.user.phoneNo,
        primaryAddress: props.user.primaryAddress,
        country: props.user.country,
        city: props.user.city,
        postalCode: props.user.postalCode,
        emergencyContactFirstName: props.user.emergencyContactFirstName,
        emergencyContactLastName: props.user.emergencyContactLastName,
        emergencyContactEmail: props.user.emergencyContactEmail,
        emergencyContactPhoneNo: props.user.emergencyContactPhoneNo,
        emergencyContactRelation: props.user.emergencyContactRelation,
        id: props.user.id,
      };

      reset(formData);
      setForm(formData);
    } else {
      reset(initialState);
      setForm(initialState);
    }
  }, [props]);

  const saveDataForm = (name: any, value: any) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <TabPane tabId={`1`} key={0}>
      <Form onSubmit={handleSubmit(onEditSubmit)}>
        <Card className="card-absolute mt-5">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Personal Information"}
          />
          <CardBody>
            <Row className="mt-2">
              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"First Name"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.firstName ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="First Name"
                    {...register("firstName", { required: true })}
                    value={form.firstName}
                    onChange={(e) => saveDataForm("firstName", e.target.value)}
                  />
                  {errors.firstName && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"First Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Last Name"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.lastName ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Last Name"
                    {...register("lastName", { required: true })}
                    value={form.lastName}
                    onChange={(e) => saveDataForm("lastName", e.target.value)}
                  />
                  {errors.lastName && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Last Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Join Date"} <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    control={control}
                    name="joinDate"
                    rules={{
                      required: "Please select joining date.",
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          className={`form-control flatpickr-input ${
                            errors.joinDate ? "is-invalid" : ""
                          }`}
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            saveDataForm("joinDate", date);
                          }}
                          placeholderText="Select a date"
                          dateFormat="MMMM dd,yyyy"
                        />
                      );
                    }}
                  />
                  {errors.joinDate && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Join Date is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Nationality"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.nationality ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Nationality"
                    {...register("nationality", { required: true })}
                    value={form.nationality}
                    onChange={(e) =>
                      saveDataForm("nationality", e.target.value)
                    }
                  />
                  {errors.nationality && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Nationality is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Email"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Email"
                    {...register("email", {
                      required: "Please enter your email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Regular expression for basic email validation
                        message: "Please enter a valid email address",
                      },
                    })}
                    value={form.email}
                    onChange={(e) => saveDataForm("email", e.target.value)}
                  />
                  {errors.email && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {`${errors?.email?.message}`}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Phone"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.phoneNo ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Phone"
                    {...register("phoneNo", {
                      required: "Please enter phone No",
                      pattern: {
                        value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                        message: "Please enter a valid 11-digit phone number",
                      },
                    })}
                    value={form.phoneNo}
                    onChange={(e) => saveDataForm("phoneNo", e.target.value)}
                  />
                  {errors.phoneNo && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {`${errors?.phoneNo?.message}`}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="card-absolute mt-5">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Address"}
          />

          <CardBody>
            <Row className="mt-2">
              <Col md={8}>
                <FormGroup>
                  <Label>
                    {"Address"} <span className="text-danger">*</span>{" "}
                  </Label>
                  <input
                    className={`form-control ${
                      errors.primaryAddress ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Primary/ address"
                    {...register("primaryAddress", { required: true })}
                    value={form.primaryAddress}
                    onChange={(e) =>
                      saveDataForm("primaryAddress", e.target.value)
                    }
                  />
                  {errors.primaryAddress && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"PrimaryAddress is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Country"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.country ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Country"
                    {...register("country", { required: true })}
                    value={form.country}
                    onChange={(e) => saveDataForm("country", e.target.value)}
                  />
                  {errors.country && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Country is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"City"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.city ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="City"
                    {...register("city", { required: true })}
                    value={form.city}
                    onChange={(e) => saveDataForm("city", e.target.value)}
                  />
                  {errors.city && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"City is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Post Code"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.postalCode ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Post Code"
                    {...register("postalCode", { required: true })}
                    value={form.postalCode}
                    onChange={(e) => saveDataForm("postalCode", e.target.value)}
                  />
                  {errors.postalCode && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"PostalCode is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="card-absolute mt-5">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Emergency Contact"}
          />
          <CardBody>
            <Row className="mt-2">
              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"First Name"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.emergencyContactFirstName ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="First Name"
                    {...register("emergencyContactFirstName", {
                      required: true,
                    })}
                    value={form.emergencyContactFirstName}
                    onChange={(e) =>
                      saveDataForm("emergencyContactFirstName", e.target.value)
                    }
                  />
                  {errors.emergencyContactFirstName && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Emergency Contact First Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Last Name"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.emergencyContactLastName ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Last Name"
                    {...register("emergencyContactLastName", {
                      required: true,
                    })}
                    value={form.emergencyContactLastName}
                    onChange={(e) =>
                      saveDataForm("emergencyContactLastName", e.target.value)
                    }
                  />
                  {errors.emergencyContactLastName && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Emergency Contact Last Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Email"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.emergencyContactEmail ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Email"
                    {...register("emergencyContactEmail", {
                      required: "Please enter your Emergency Contact Email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Regular expression for basic email validation
                        message: "Please enter a valid email address",
                      },
                    })}
                    value={form.emergencyContactEmail}
                    onChange={(e) =>
                      saveDataForm("emergencyContactEmail", e.target.value)
                    }
                  />
                  {errors.emergencyContactEmail && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {`${errors?.emergencyContactEmail?.message}`}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Phone"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.emergencyContactPhoneNo ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Phone"
                    {...register("emergencyContactPhoneNo", {
                      required: "Please enter your Emergency Contact PhoneNo",
                      pattern: {
                        value: /^[0-9]{11}$/, // This regex ensures the phone number is exactly 10 digits
                        message: "Please enter a valid 11-digit phone number",
                      },
                    })}
                    value={form.emergencyContactPhoneNo}
                    onChange={(e) =>
                      saveDataForm("emergencyContactPhoneNo", e.target.value)
                    }
                  />
                  {errors.emergencyContactPhoneNo && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {`${errors?.emergencyContactPhoneNo?.message}`}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>
                    {"Relation"} <span className="text-danger">*</span>
                  </Label>
                  <input
                    className={`form-control ${
                      errors.emergencyContactRelation ? "is-invalid" : ""
                    }`}
                    type="text"
                    placeholder="Relation"
                    {...register("emergencyContactRelation", {
                      required: true,
                    })}
                    value={form.emergencyContactRelation}
                    onChange={(e) =>
                      saveDataForm("emergencyContactRelation", e.target.value)
                    }
                  />
                  {errors.emergencyContactRelation && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Emergency Contact Relation is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={12} className="mt-2">
                <Btn color="primary" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <span className="d-flex gap-2">
                      <Spinner color="white" size="sm" /> Loading...
                    </span>
                  ) : (
                    UpdateProfile
                  )}
                </Btn>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </TabPane>
  );
}

export default General;
