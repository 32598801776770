import { createListenerMiddleware } from "@reduxjs/toolkit";
import { LOAD_ADMIN_USER, SET_ADMIN } from "./AdminReducer";
import { getAdminData } from "./meditator";

export const AdminUserListener = createListenerMiddleware();

AdminUserListener.startListening({
  actionCreator: LOAD_ADMIN_USER,
  effect: async (action, listenerAPI) => {
    let state: any = listenerAPI.getState();
    const { dispatch } = listenerAPI;
    const resp = await getAdminData(action.payload, dispatch);
    console.log('resp: ', resp);
    if (resp.isSuccess) {
      const adminData = resp.data.data;
      dispatch(
        SET_ADMIN({
          admins: adminData.admins,
          count: adminData.meta.totalCount,
        })
      );
    }
  },
});
