import { Button, Col, Container, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react'
import { commonKeysObject, dynamicImage } from 'src/Utils';
import { Controller } from 'react-hook-form';
import Select from "react-select";
import DatePicker from "react-datepicker";
import UserApi from 'src/services/UserApi';
import moment from 'moment-timezone';
import { baseUrl } from 'src/Utils/Constants/config';
import { Direction, getTrackBackground, Range } from 'react-range';

function AccidentForm(props: any) {
    const [selectedImage, setSelectedImage] = useState("front");
    const [spots, setSpots] = useState<any>(props.form.spotsData[selectedImage]);
    const [newSpotSize, setNewSpotSize] = useState(10); // Size for the new spot
    const [selectedSpotId, setSelectedSpotId] = useState(null); // ID of the selected spot for editing
    const [isDragging, setIsDragging] = useState(false);
    const imageRef = useRef<any>(null);

    const images: any = {
        front: dynamicImage(`body/front.jpg`),
        back: dynamicImage(`body/back.jpg`),
        head: dynamicImage(`body/head.jpg`),
        sides: dynamicImage(`body/head-sides.jpg`),
    };

    const saveSpots = () => {
        props.saveDataForm('spotsData', { ...props.form.spotsData, [selectedImage]: spots });
    };

    // Add new spot to the list
    const handleImageClick = (e: any) => {
        // Prevent adding a new spot while dragging
        if (isDragging || !imageRef.current) {
            setIsDragging(false);
            return;
        } // Prevent adding a spot while dragging is enabled

        const rect = imageRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Ensure the spot is within the image boundaries
        if (x >= 0 && x <= rect.width && y >= 0 && y <= rect.height) {
            setSpots([
                ...spots,
                {
                    id: Date.now(),
                    x: x - newSpotSize / 4,
                    y: y - newSpotSize / 4,
                    size: newSpotSize,
                },
            ]);

            props.saveDataForm('spotsData', {
                ...props.form.spotsData, [selectedImage]: [
                    ...spots,
                    {
                        id: Date.now(),
                        x: x - newSpotSize / 4,
                        y: y - newSpotSize / 4,
                        size: newSpotSize,
                    },
                ]
            });
        }
    };

    // Toggle dragging mode
    const toggleDragging = () => {
        setIsDragging((prev) => !prev); // Toggle the dragging mode
    };

    // Adjust size of the new spot
    const handleNewSpotSizeChange = (value: number) => {
        // सुनिश्चित करें कि वैल्यू 0 और 100 के बीच ही रहे
        const clampedValue = Math.min(100, Math.max(0, value));
        setNewSpotSize(clampedValue);
    };

    // Adjust size of a selected spot
    const handleSelectedSpotSizeChange = (delta: any) => {
        setSpots((prevSpots: any) =>
            prevSpots.map((spot: any) =>
                spot.id === selectedSpotId
                    ? { ...spot, size: Math.max(5, spot.size + delta) } // Minimum size: 5px
                    : spot
            )
        );

        saveSpots();
    };


    const handleImageMouseMove = (e: any) => {
        if (!isDragging || !imageRef.current) return;

        const rect = imageRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Update the position of the dragged spot
        if (x >= 0 && x <= rect.width && y >= 0 && y <= rect.height) {
            setSpots((prevSpots: any) =>
                prevSpots.map((spot: any) => {
                    return spot.id === selectedSpotId
                        ? { ...spot, x: x - spot.size / 4, y: y - spot.size / 4 }
                        : spot
                }
                )
            );
        }
    };

    const clearAllSpots = () => {
        setSpots([]); // Clear the spots in the current view
        props.saveDataForm('spotsData', { ...props.form.spotsData, [selectedImage]: [] });
        setSelectedSpotId(null); // Deselect any selected spot
    };

    const handleImageChange = (image: string) => {
        // Save the current image's spots before switching
        saveSpots();
        setSelectedImage(image);
        // Load saved spots for the new image
        setSpots(props.form.spotsData[image] || []);
        setSelectedSpotId(null); // Deselect any selected spot
    };

    const [values4, setValues4] = useState([10.00]);

    return (
        <div>
            <Row className='mt-3'>
                <Col md={12}>
                    <Row className='mt-3'>
                        <Col className='position-relative' md={10}>
                            {/* Image Container */}
                            <div
                                className="image-container position-relative"
                                style={{ width: "450px", height: "450px", margin: "auto" }}
                                onMouseMove={handleImageMouseMove}
                                onClick={handleImageClick}
                            >
                                <img
                                    ref={imageRef}
                                    src={images[selectedImage]}
                                    alt={selectedImage}
                                    style={{ position: "relative" }}
                                    className='img-fluid'
                                />
                                {spots.map((spot: any, index: any) => (
                                    <div
                                        key={spot.id}
                                        style={{
                                            position: "absolute",
                                            left: `${spot.x}px`,
                                            top: `${spot.y}px`,
                                            width: `${spot.size}px`,
                                            height: `${spot.size}px`,
                                            backgroundColor: selectedSpotId === spot.id ? "blue" : "red",
                                            borderRadius: "50%",
                                            transform: "translate(-50%, -50%)",
                                            cursor: "pointer",
                                        }}
                                    ></div>
                                ))}
                            </div>
                        </Col>
                        <Col md={2}>
                            <Row>
                                <Col md={12}>
                                    <Button onClick={clearAllSpots} color='danger' type='button' size='sm'>
                                        Clear All Spots
                                    </Button>
                                </Col>
                                <Col md={12}>
                                    <div className="range-container">
                                        <Range
                                            values={[newSpotSize]}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={(values) => handleNewSpotSizeChange(values[0])}
                                            direction={Direction.Up}
                                            renderTrack={({ props, children }) => (
                                                <div
                                                    onMouseDown={props.onMouseDown}
                                                    onTouchStart={props.onTouchStart}
                                                    className="range-track"
                                                    style={{
                                                        height: "200px",
                                                        width: "10px",
                                                        display: "flex",
                                                        flexDirection: "column-reverse",
                                                        margin: "0 auto",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <div
                                                        ref={props.ref}
                                                        className="range-track-bar"
                                                        style={{
                                                            height: "100%",
                                                            width: "100%",
                                                            background: getTrackBackground({
                                                                values: [newSpotSize],
                                                                colors: ["#308e87", "#ccc"],
                                                                min: 0,
                                                                max: 100,
                                                                direction: Direction.Up,
                                                            }),
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                </div>
                                            )}
                                            renderThumb={({ props, isDragged }) => (
                                                <div
                                                    {...props}
                                                    className="range-thumb"
                                                    style={{
                                                        ...props.style,
                                                        height: "20px",
                                                        width: "20px",
                                                        backgroundColor: isDragged ? "#308e87" : "#ccc",
                                                        borderRadius: "50%",
                                                        border: "2px solid #fff",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className="range-output-container" style={{ textAlign: "center", marginTop: "10px" }}>
                                            <output className="range-output">{newSpotSize}</output>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col>
                            {/* Image Selector */}
                            <div className="image-selector d-flex flex-wrap justify-content-center" style={{ gap: '4rem' }}>
                                {Object.keys(images).map((key) => (
                                    <div onClick={() => handleImageChange(key)} className={`${selectedImage === key ? 'active' : ''}`} style={{ width: '120px', height: '120px', margin: '5px' }}>
                                        <img src={images[key]} alt={key} style={{ width: '120px', height: '120px', objectFit: 'cover', border: selectedImage === key ? "3px solid #308e87" : "3px solid #ccc", padding: '0.5rem', cursor: 'pointer' }} />
                                    </div>

                                ))}
                            </div>

                            {/* // <Button
                            //     key={key}
                            //     onClick={() => handleImageChange(key)}
                            //     className={selectedImage === key ? "active btn btn-dark ms-2" : "ms-2"}
                            //     type='button'
                            //     color='primary'
                            // >
                            //     {key}
                            // </Button> */}
                        </Col>
                    </Row>
                </Col>
                {/* <Col md={7}>
                    <Row>
                        <Col>
                            <div className="controls">
                                <h3>New Spot Controls</h3>
                                <div className='d-flex gap-3 mt-3'>
                                    <div className='d-flex gap-3'>
                                        <Button onClick={() => handleNewSpotSizeChange(-5)} color='dark' type='button'>-</Button>
                                        <p className='mb-0'>{newSpotSize}px</p>
                                        <Button onClick={() => handleNewSpotSizeChange(5)} color='dark' type='button'>+</Button>
                                    </div>
                                    <Button onClick={clearAllSpots} color='danger' type='button'>
                                        Clear All Spots
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Button onClick={clearAllSpots} color='danger' type='button'>
                                Clear All Spots
                            </Button>
                        </Col>
                        <Col md={12}>
                            <div className="range-container">
                                <Range
                                    values={[newSpotSize]}
                                    step={1}
                                    min={0}
                                    max={100}
                                    onChange={(values) => handleNewSpotSizeChange(values[0])}
                                    direction={Direction.Up}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            onMouseDown={props.onMouseDown}
                                            onTouchStart={props.onTouchStart}
                                            className="range-track"
                                            style={{
                                                height: "200px",
                                                width: "10px",
                                                display: "flex",
                                                flexDirection: "column-reverse",
                                                margin: "0 auto",
                                                position: "relative",
                                            }}
                                        >
                                            <div
                                                ref={props.ref}
                                                className="range-track-bar"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    background: getTrackBackground({
                                                        values: [newSpotSize],
                                                        colors: ["#308e87", "#ccc"],
                                                        min: 0,
                                                        max: 100,
                                                        direction: Direction.Up,
                                                    }),
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props, isDragged }) => (
                                        <div
                                            {...props}
                                            className="range-thumb"
                                            style={{
                                                ...props.style,
                                                height: "20px",
                                                width: "20px",
                                                backgroundColor: isDragged ? "#308e87" : "#ccc",
                                                borderRadius: "50%",
                                                border: "2px solid #fff",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                                            }}
                                        />
                                    )}
                                />
                                <div className="range-output-container" style={{ textAlign: "center", marginTop: "10px" }}>
                                    <output className="range-output">{newSpotSize}</output>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className='mt-3'>
                        <Col md={6}>
                            <div className="spot-list">
                                <h3>Spot List</h3>
                                {spots.map((spot: any) => (
                                    <div
                                        key={spot.id}
                                        style={{
                                            padding: "5px",
                                            border: selectedSpotId === spot.id ? "2px solid blue" : "1px solid gray",
                                            marginBottom: "5px",
                                            marginTop: "8px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setSelectedSpotId(spot.id)}
                                    >
                                        Spot {spot.id} - Size: {spot.size}px
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="controls">
                                <h3>Selected Spot Controls</h3>
                                {selectedSpotId ? (
                                    <div className='d-flex flex-column gap-3 align-items-baseline'>
                                        <p className='mb-0'>
                                            Selected Spot ID: {selectedSpotId} <br />
                                        </p>
                                        <div className='d-flex gap-3'>
                                            <Button onClick={() => handleSelectedSpotSizeChange(-5)} type='button' color='dark'>-</Button>
                                            <p className='mb-0'>
                                                {spots.find((spot: any) => spot.id === selectedSpotId)?.size}px
                                            </p>
                                            <Button onClick={() => handleSelectedSpotSizeChange(5)} type='button' color='dark'>+</Button>
                                        </div>
                                        <div>

                                            <Button onClick={() => setSelectedSpotId(null)} type='button' color='primary'>Deselect Spot</Button>
                                        </div>
                                        <div>

                                            <Button onClick={() => toggleDragging()} type='button' color={isDragging ? 'warning' : 'dark'}>{isDragging ? "Disable Dragging" : "Enable Dragging"}</Button>
                                        </div>

                                    </div>
                                ) : (<p>No Selected Spot !!!</p>)}
                            </div>
                        </Col>
                    </Row>
                </Col> */}
            </Row>
        </div>
    );
}



function AccidentBasicForm(props: any) {
    console.log('props: ', props);
    const [childrenData, setChildrenData] = useState<any>([]);
    const [employeeData, setEmployeeData] = useState([]);

    const fetchChildren = async () => {
        try {
            const response = await UserApi.getAllChildren({
                nurseryId: props.nurseryId,
            });
            if (response.success && Array.isArray(response.children)) {
                const formData = response.children.map((row: any, index: any) => {
                    const modifiedObj = commonKeysObject(row, {
                        id: "",
                        firstName: "",
                        lastName: "",
                    });
                    return {
                        label: `${modifiedObj.firstName} ${modifiedObj.lastName}`,
                        value: modifiedObj.id,
                    };
                });
                setChildrenData(formData);
            } else {
                setChildrenData([]);
            }
        } catch (error: any) {
            setChildrenData([]);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await UserApi.getAllEmployees({
                searchText: "",
                status: "all-status",
                nurseryId: props.nurseryId,
                jobTitleId: "all-job",
                pageNo: 1,
                pageSize: 10000,
            });
            if (response.success && Array.isArray(response.data)) {
                setEmployeeData(response.data.map((row: any) => ({
                    label: `${row.firstName} ${row.lastName}`,
                    value: row.id,
                })));
            } else {
                setEmployeeData([]);
            }
        } catch (error: any) {
            setEmployeeData([]);
        }
    };

    useEffect(() => {
        fetchChildren();
        fetchEmployees();
    }, [props.nurseryId]);

    return (
        <Row>
            <Col md={4} className="position-relative">
                <Label>
                    {"Child"} <span className="text-danger">*</span>
                </Label>
                <Controller
                    name="children"
                    control={props.control}
                    rules={{
                        required: "Please select a children",
                    }} // Validation rules
                    render={({ field }) => (
                        <Select
                            {...field}
                            id="children"
                            className={`${props.errors.children ? "is-invalid" : ""
                                }`}
                            options={childrenData} // children options
                            value={
                                childrenData.find(
                                    (option: any) => option.value === props.form.children.id
                                ) || null
                            } // Set current value, handle null
                            onChange={(selectedOption) => {
                                // Check if selectedOption is not null
                                if (selectedOption) {
                                    field.onChange(selectedOption.value); // Update the field value
                                    props.saveDataForm(
                                        "children",
                                        { id: selectedOption.value, name: selectedOption.label }
                                    ); // Save the data
                                }
                            }} // Handle value change
                        />
                    )}
                />
                {props.errors.children && (
                    <FormFeedback
                        style={{ display: "block", color: "#dc3545" }}
                    >
                        {`${props.errors?.children?.message}`}
                    </FormFeedback>
                )}
            </Col>
            <Col md={4} className="position-relative">
                <Label>
                    {"Kind"} <span className="text-danger">*</span>
                </Label>
                <Controller
                    name="kind"
                    control={props.control}
                    rules={{
                        required: "Please select a kind",
                    }} // Validation rules
                    render={({ field }) => (
                        <Select
                            {...field}
                            id="kind"
                            className={`${props.errors.kind ? "is-invalid" : ""
                                }`}
                            options={[
                                { value: "accident", label: "Accident" },
                                { value: "incident", label: "Incident" },
                                { value: "other", label: "Other" },
                            ]} // kind options
                            value={
                                [
                                    { value: "accident", label: "Accident" },
                                    { value: "incident", label: "Incident" },
                                    { value: "other", label: "Other" },
                                ].find(
                                    (option) => option.value === props.form.kind
                                ) || null
                            } // Set current value, handle null
                            onChange={(selectedOption) => {
                                // Check if selectedOption is not null
                                if (selectedOption) {
                                    field.onChange(selectedOption.value); // Update the field value
                                    props.saveDataForm(
                                        "kind",
                                        selectedOption.value
                                    ); // Save the data
                                }
                            }} // Handle value change
                        />
                    )}
                />
                {props.errors.kind && (
                    <FormFeedback
                        style={{ display: "block", color: "#dc3545" }}
                    >
                        {`${props.errors?.kind?.message}`}
                    </FormFeedback>
                )}
            </Col>
            <Col md={4} className="position-relative">
                <Label>
                    {"Date & Time"} <span className="text-danger">*</span>
                </Label>
                <InputGroup className="flatpicker-calender">
                    <Controller
                        control={props.control}
                        name="startDate"
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    className={`form-control flatpickr-input ${props.errors.dateTime ? "is-invalid" : ""
                                        }`}
                                    selected={props.form.dateTime}
                                    onChange={(date) => {
                                        field.onChange(date);
                                        props.saveDataForm("dateTime", date);
                                    }}
                                    placeholderText="Select a date"
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            );
                        }}
                    />
                    <InputGroupText className="list-light-primary custom-calender-icon">
                        <i className="fa-solid fa-calendar text-primary" />
                    </InputGroupText>
                </InputGroup>
                {props.errors.kind && (
                    <FormFeedback
                        style={{ display: "block", color: "#dc3545" }}
                    >
                        {`${props.errors?.kind?.message}`}
                    </FormFeedback>
                )}
            </Col>

            <Col md={4} className="position-relative mt-3">
                <Label>
                    {"location"} <span className="text-danger">*</span>
                </Label>
                <Controller
                    name="location"
                    control={props.control}
                    rules={{
                        required: "Please select a location",
                    }} // Validation rules
                    render={({ field }) => (
                        <Select
                            {...field}
                            id="location"
                            className={`${props.errors.location ? "is-invalid" : ""
                                }`}
                            options={[
                                { value: "nursery-room", label: "Nursery Room" },
                                { value: "garden", label: "Garden" },
                                { value: "toilet", label: "Toilet" },
                                { value: "hallway", label: "Hallway" },
                                { value: "Others", label: "Others" },
                            ]} // location options
                            value={
                                [
                                    { value: "nursery-room", label: "Nursery Room" },
                                    { value: "garden", label: "Garden" },
                                    { value: "toilet", label: "Toilet" },
                                    { value: "hallway", label: "Hallway" },
                                    { value: "Others", label: "Others" },
                                ].find(
                                    (option) => option.value === props.form.location
                                ) || null
                            } // Set current value, handle null
                            onChange={(selectedOption) => {
                                // Check if selectedOption is not null
                                if (selectedOption) {
                                    field.onChange(selectedOption.value); // Update the field value
                                    props.saveDataForm(
                                        "location",
                                        selectedOption.value
                                    ); // Save the data
                                }
                            }} // Handle value change
                        />
                    )}
                />
                {props.errors.location && (
                    <FormFeedback
                        style={{ display: "block", color: "#dc3545" }}
                    >
                        {`${props.errors?.location?.message}`}
                    </FormFeedback>
                )}
            </Col>
            <Col md={4} className="position-relative mt-3">
                <FormGroup>
                    <Label for="firstAid">
                        First aid administered <span className="text-danger">*</span>
                    </Label>
                    <input
                        type="text"
                        className={`form-control ${props.errors.firstAid ? "is-invalid" : ""
                            }`}
                        id="firstAid"
                        value={props.form.firstAid}
                        onChange={(e) => props.saveDataForm(
                            "firstAid",
                            e.target.value
                        )
                        }
                    />
                    {props.errors.firstAid && (
                        <FormFeedback>{`${props.errors.firstAid.message}`}</FormFeedback>
                    )}
                </FormGroup>
            </Col>
            <Col md={4} className="position-relative mt-3">
                <Label>
                    Reported to (not visible to parents)
                </Label>
                <Row>
                    <Col md={3}>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value="riddor"
                                checked={props.form.reportedTo?.riddor || false}
                                onChange={(e) =>
                                    props.saveDataForm(
                                        "reportedTo",
                                        {
                                            ...props.form.reportedTo,
                                            [e.target.value]: e.target.checked,
                                        }
                                    )
                                }
                            />
                            <Label className="form-check-label" htmlFor="riddor">
                                RIDDOR
                            </Label>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value="ofsted"
                                checked={props.form.reportedTo?.ofsted || false}
                                onChange={(e) =>
                                    props.saveDataForm(
                                        "reportedTo",
                                        {
                                            ...props.form.reportedTo,
                                            [e.target.value]: e.target.checked,
                                        }
                                    )
                                }
                            />
                            <Label className="form-check-label" htmlFor="ofsted">
                                Ofsted
                            </Label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                value="dofa"
                                checked={props.form.reportedTo?.dofa || false}
                                onChange={(e) =>
                                    props.saveDataForm(
                                        "reportedTo",
                                        {
                                            ...props.form.reportedTo,
                                            [e.target.value]: e.target.checked,
                                        }
                                    )
                                }
                            />
                            <Label className="form-check-label" htmlFor="dofa">
                                DOFA <small>(former LADO)</small>
                            </Label>
                        </div>
                    </Col>

                </Row>

            </Col>

            <Col md={6} className="position-relative">
                <Label>
                    {"Witnesses"}
                </Label>
                <Controller
                    name="witnesses"
                    control={props.control}
                    rules={{
                        required: "Please select a witnesses",
                    }} // Validation rules
                    render={({ field }) => (

                        <Select
                            {...field}
                            id="witnesses"
                            options={employeeData} // Options for selection
                            isMulti // Enable multiple selection
                            value={employeeData.filter((option: any) =>
                                props.form.witnesses?.some((witness: any) => witness.id === option.value)
                            )} // Match selected values with objects in the form state
                            onChange={(selectedOptions: any) => {
                                // Map selected options to the desired format
                                const selectedValues = selectedOptions
                                    ? selectedOptions.map((option: any) => ({
                                        id: option.value,
                                        name: option.label,
                                    }))
                                    : [];

                                const selectedValue = selectedOptions
                                    ? selectedOptions.map((option: any) => option.value)
                                    : [];
                                field.onChange(selectedValue); // Update the field value
                                props.saveDataForm("witnesses", selectedValues); // Save the data
                            }} // Handle value changes
                        />
                    )}
                />
                {props.errors.witnesses && (
                    <FormFeedback
                        style={{ display: "block", color: "orangeRed" }}
                    >
                        {`${props.errors?.witnesses?.message}`}
                    </FormFeedback>
                )}
            </Col>

            <Col md={6} className="position-relative">
                <Label>
                    {"Other staff present"}
                </Label>
                <Controller
                    name="otherStaff"
                    control={props.control}
                    rules={{
                        required: "Please select a otherStaff",
                    }} // Validation rules
                    render={({ field }) => (
                        <Select
                            {...field}
                            id="otherStaff"
                            options={employeeData} // Options for selection
                            isMulti // Enable multiple selection
                            value={employeeData.filter((option: any) =>
                                props.form.otherStaff?.some((witness: any) => witness.id === option.value)
                            )} // Match selected values with objects in the form state
                            onChange={(selectedOptions: any) => {
                                // Map selected options to the desired format
                                const selectedValues = selectedOptions
                                    ? selectedOptions.map((option: any) => ({
                                        id: option.value,
                                        name: option.label,
                                    }))
                                    : [];

                                const selectedValue = selectedOptions
                                    ? selectedOptions.map((option: any) => option.value)
                                    : [];
                                field.onChange(selectedValue); // Update the field value
                                props.saveDataForm("otherStaff", selectedValues); // Save the data
                            }} // Handle value changes
                        />
                    )}
                />
                {props.errors.otherStaff && (
                    <FormFeedback
                        style={{ display: "block", color: "orangeRed" }}
                    >
                        {`${props.errors?.otherStaff?.message}`}
                    </FormFeedback>
                )}
            </Col>

            <Col md={6} className='position-relative mt-3'>
                <Label>{"Nature of accident/incient"} <span className="text-danger">*</span></Label>
                <textarea
                    className={`form-control ${props.errors.natureOfAccident ? "is-invalid" : ""
                        }`}
                    placeholder="Enter cause of accident/incient"
                    value={props.form.natureOfAccident || ""}
                    onChange={(e) =>
                        props.saveDataForm("natureOfAccident", e.target.value)
                    }
                ></textarea>

                {props.errors.natureOfAccident && (
                    <FormFeedback
                        style={{ display: "block", color: "#dc3545" }}
                    >
                        {`${props.errors?.natureOfAccident?.message}`}
                    </FormFeedback>
                )}
            </Col>

            <Col md={6} className='position-relative mt-3'>
                <Label>{"When and how were parents notified"} <span className="text-danger">*</span></Label>
                <textarea
                    className={`form-control ${props.errors.parentNotified ? "is-invalid" : ""
                        }`}
                    placeholder="Enter parents notified"
                    value={props.form.parentNotified || ""}
                    onChange={(e) =>
                        props.saveDataForm("parentNotified", e.target.value)
                    }
                ></textarea>

                {props.errors.parentNotified && (
                    <FormFeedback
                        style={{ display: "block", color: "#dc3545" }}
                    >
                        {`${props.errors?.parentNotified?.message}`}
                    </FormFeedback>
                )}
            </Col>

            <Col md={6} className='position-relative mt-3'>
                <Label>{"Other notes"}</Label>
                <textarea
                    className="form-control"
                    placeholder=""
                    value={props.form.otherNotes || ""}
                    onChange={(e) =>
                        props.saveDataForm("otherNotes", e.target.value)
                    }
                ></textarea>
            </Col>
            <Col md={6} className='position-relative mt-3'>
                <Label>{"Upload"}</Label>
                <Input
                    type='file'
                    multiple
                    onChange={(e) => props.saveDataForm("files", e.target.files)}
                />
                {props.form.files && (
                    <div style={{ marginTop: "10px" }}>
                        <strong>Selected File:</strong> {props.form.files[0].name}
                    </div>
                )}
                {props.form.uploadUrl && props.form.uploadUrl.length > 0 && (
                    <div className='mt-2'>
                        <ul style={{ display: "flex", flexWrap: "wrap", gap: ".5rem" }}>
                            {props.form.uploadUrl.split(",").map((url: any, index: number) => (
                                <li key={index}>
                                    <a href={`${baseUrl}${url}`} target="_blank" rel="noopener noreferrer">
                                        View Image {index + 1}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

            </Col>
        </Row>
    );
}

function AccidentFinalForm(props: any) {
    console.log('props: ', props);
    const [selectedImage, setSelectedImage] = useState("front");

    const images: any = {
        front: dynamicImage(`body/front.jpg`),
        back: dynamicImage(`body/back.jpg`),
        head: dynamicImage(`body/head.jpg`),
        sides: dynamicImage(`body/head-sides.jpg`),
    };

    return (
        <Container className="p-4">
            <div>
                <Row>
                    <Col md={6}>
                        <Row className="mb-3">
                            <Col>
                                <strong>{props.form.children.name}</strong>
                                <br />
                                <span>{moment(props.form.dateTime).format("DD MMM YYYY")}</span>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="natureOfAccident" style={{ color: '#6c757d' }}>Nature of accident/incident</Label>
                                    <p>{props.form.natureOfAccident}</p>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="firstAid" style={{ color: '#6c757d' }}>First aid administered</Label>
                                    <p>{props.form.firstAid}</p>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="notificationDetails" style={{ color: '#6c757d' }}>
                                        When and how were parents notified
                                    </Label>
                                    <p>{props.form.parentNotified}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className="mb-3">
                            <Col md={12}>
                                <Label style={{ color: '#6c757d' }}>Kind</Label>
                                <FormGroup check>
                                    <Input type="radio" name="kind" id="accident" checked={props.form.kind === 'accident'} readOnly />
                                    <Label for="accident" check>
                                        Accident
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" name="kind" id="incident" checked={props.form.kind === 'incident'} readOnly />
                                    <Label for="incident" check>
                                        Incident
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" name="kind" id="others" checked={props.form.kind === 'others'} readOnly />
                                    <Label for="others" check>
                                        Others
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <Label style={{ color: '#6c757d' }}>Witnesses</Label>
                                <p>{props.form.witnesses.map((row: any) => row.name).join(",")}</p>
                            </Col>
                            <Col md={12}>
                                <Label style={{ color: '#6c757d' }}>Other staff present</Label>
                                <p>{props.form.otherStaff.map((row: any) => row.name).join(",")}</p>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={12}>
                                <Label style={{ color: '#6c757d' }}>Reported to</Label>
                                <FormGroup check>
                                    <Input type="checkbox" id="riddor" checked={!!props.form.reportedTo?.riddor} />
                                    <Label for="riddor" check>
                                        RIDDOR
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="checkbox" id="ofsted" checked={!!props.form.reportedTo?.ofsted} />
                                    <Label for="ofsted" check>
                                        Ofsted
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="checkbox" id="dofa" checked={!!props.form.reportedTo?.dofa} />
                                    <Label for="dofa" check>
                                        DOFA (former LADO)
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>




                <Row className="mb-3">
                    <Col>
                        <Label style={{ color: '#6c757d' }}>Body map</Label>
                        <div
                            className="image-container position-relative"
                            style={{ width: "450px", height: "450px", margin: "auto" }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50px",
                                    right: "50px",
                                    zIndex: '1',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}

                                className='dot-mark'
                            >
                                <span className='ms-1'>{props.form.spotsData[selectedImage].length}</span>
                            </div>

                            <img
                                src={images[selectedImage]}
                                alt={selectedImage}
                                style={{ position: "relative" }}
                                className='img-fluid'
                            />
                            {props.form.spotsData[selectedImage].map((spot: any) => (
                                <div
                                    key={spot.id}
                                    style={{
                                        position: "absolute",
                                        left: `${spot.x}px`,
                                        top: `${spot.y}px`,
                                        width: `${spot.size}px`,
                                        height: `${spot.size}px`,
                                        backgroundColor: "red",
                                        borderRadius: "50%",
                                        transform: "translate(-50%, -50%)",
                                        cursor: "pointer",
                                    }}
                                ></div>
                            ))}
                        </div>
                    </Col>
                    <Col className='d-flex align-items-center'>
                        {/* Image Selector */}
                        <div className="image-selector d-flex flex-wrap justify-content-center" style={{ gap: '1rem' }}>
                            {Object.keys(images).map((key) => props.form.spotsData[key].length > 0 ? (
                                <div onClick={() => setSelectedImage(key)} className={`${selectedImage === key ? 'active' : ''}`} style={{ width: '120px', height: '120px', margin: '5px' }}>
                                    <img src={images[key]} alt={key} style={{ width: '120px', height: '120px', objectFit: 'cover', border: selectedImage === key ? "3px solid #308e87" : "3px solid #ccc", padding: '0.5rem', cursor: 'pointer' }} />
                                </div>

                            ) : null)}
                        </div>

                        {/* <div className="image-selector">
                            {Object.keys(images).map((key) => props.form.spotsData[key].length > 0 ? (
                                <Button
                                    key={key}
                                    onClick={() => setSelectedImage(key)}
                                    className={selectedImage === key ? "active btn btn-dark ms-2" : "ms-2"}
                                    type='button'
                                    color='primary'
                                >
                                    {key}
                                </Button>
                            ) : (null))}
                        </div> */}
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export { AccidentForm, AccidentBasicForm, AccidentFinalForm };