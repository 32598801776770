import React from "react";
import { H3, H4, H5, H6, LI, P, UL } from "../../../../AbstractElements";
import { jobCardListData } from "../../../../Data/Dashboard/Default";
import DashboardSvgIcon from "../../../../Utils/CommonComponents/DashboardSvgIcon";

export default function JobCardList() {
  return (
    <UL className="d-flex align-center justify-content-center gap-3 simple-list flex-row">
      {jobCardListData.map((item, index) => (
        <LI key={item.id}>
          <div className="d-flex gap-2">
            <div className={`flex-shrink-0 bg-light-${item.color}`}>
              <DashboardSvgIcon className="stroke-icon" iconId={item.icon} />
            </div>
            <div className="flex-grow-1">
              <div className="d-flex gap-2">
                <H5>{item.count}</H5>
                <P>{item.text}</P>
              </div>
              {index == jobCardListData.length - 1 ? null : (
                <div className="d-flex gap-2">
                  <H5>{item.count}</H5>
                  <P>{"Staff"}</P>
                </div>
              )}
            </div>
          </div>
        </LI>
      ))}
    </UL>
  );
}
