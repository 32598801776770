import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table,
    Badge,
    Collapse,
    Spinner,
    InputGroup
} from 'reactstrap';
import { FaPlus, FaEllipsisV, FaChevronDown, FaEdit, FaTrash, FaEye, FaFilter, FaTimes } from 'react-icons/fa';
import './ListWhatsNext.css';
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import WhatsNextApi from 'src/services/WhatsNextApi';
import { connectComponent } from '../../MealPlan/connector';
import { baseUrl } from 'src/Utils/Constants/config';
import Swal from 'sweetalert2';
import EyfApi from 'src/services/EyfApi';
import { dynamicImage, fetchImage } from 'src/Utils';
import { Btn } from 'src/AbstractElements';
import Status from 'src/Components/Common/Status';
import { lowerCase } from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserApi from 'src/services/UserApi';

interface Props {
    nurseryId: string;
}

interface EyfsHeading {
    id: string;
    name: string;
    shortName: string;
    color: string;
    subHeadings: Array<{
        id: string;
        name: string;
        shortName: string;
        color: string;
    }>;
}

const statusColors: { [key: string]: { text: string; background: string } } = {
    pending: { text: "#fff", background: "#ffc107" },  // warning color
    completed: { text: "#fff", background: "#28a745" }, // success color
    draft: { text: "#fff", background: "#6c757d" }     // secondary color
};

const whatsNextStatus: { [key: string]: string } = {
    0: "PENDING",
    1: "COMPLETED",
    2: "DRAFT"
};

// Add new CSS styles
const tableStyles = {
    header: {
        backgroundColor: '#f8f9fa',
        borderBottom: '2px solid #dee2e6',
        padding: '12px 15px',
        fontWeight: 600,
        color: '#495057'
    },
    row: {
        transition: 'all 0.2s',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.02)'
        }
    },
    childImage: {
        width: '35px',
        height: '35px',
        objectFit: 'cover' as const,
        borderRadius: '50%',
        border: '2px solid #fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    childrenGroup: {
        display: 'flex',
        marginLeft: '-8px'
    },
    contentWrapper: {
        maxWidth: '350px'
    },
    description: {
        fontSize: '14px',
        color: '#6c757d',
        lineHeight: '1.5',
        marginBottom: '8px'
    },
    eyfsTag: {
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 500,
        marginRight: '4px',
        marginBottom: '4px',
        display: 'inline-block'
    }
};

function ListWhatsNext({ nurseryId }: Props) {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState<{ [key: number]: boolean }>({});
    const [actionDropdownOpen, setActionDropdownOpen] = useState<{ [key: number]: boolean }>({});
    const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({});
    const [showFilters, setShowFilters] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [whatsNextList, setWhatsNextList] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({
        status: 'all',
        fromDate: '',
        toDate: '',
        child: ''
    });
    const [headings, setHeadings] = useState<EyfsHeading[]>([]);
    const [isLoadingHeadings, setIsLoadingHeadings] = useState(false);
    const [dropdownOpenOne, setDropdownOpenOne] = useState<{ [key: string]: boolean }>({});
    const [children, setChildren] = useState<any[]>([]);
    const [isLoadingChildren, setIsLoadingChildren] = useState(false);

    const toggle = (id: number) => {
        setDropdownOpen(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const toggleAction = (id: number) => {
        setActionDropdownOpen(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const toggleExpand = (id: number) => {
        setExpandedRows(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const toggleDropdownOne = (id: string) => {
        setDropdownOpenOne(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const fetchWhatsNextList = async () => {
        try {
            setIsLoading(true);
            const response = await WhatsNextApi.getWhatsNextList({
                nurseryId,
                page,
                limit,
                ...filters,
                status: filters.status === 'all' ? undefined : Number(filters.status),
                childId: filters.child
            });

            if (response.success) {
                const dataWithImages = await Promise.all(
                    response.data.whatsNext.map(async (whatsNext: any) => {
                        const childrenWithImages = await Promise.all(
                            whatsNext.children.map(async (child: any) => {
                                let avatarUrl = child.avatarUrl;
                                if (avatarUrl && !avatarUrl.startsWith('http')) {
                                    try {
                                        avatarUrl = await fetchImage(baseUrl + avatarUrl);
                                    } catch (error) {
                                        avatarUrl = dynamicImage(`default-profile-old.jpg`);
                                    }
                                }
                                return { ...child, avatarUrl };
                            })
                        );
                        return { ...whatsNext, children: childrenWithImages };
                    })
                );

                setWhatsNextList(dataWithImages);
                setTotal(response.data.pagination.total);
            } else {
                toast.error('Failed to fetch what\'s next list');
            }
        } catch (error) {
            toast.error('Failed to fetch what\'s next list');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                const response = await WhatsNextApi.deleteWhatsNext(id);
                if (response.success) {
                    Swal.fire({
                        title: 'Success',
                        text: 'What\'s next deleted successfully',
                        icon: 'success'
                    });
                    fetchWhatsNextList();
                } else {
                    toast.error(response.message);
                }
            }
        } catch (error) {
            toast.error('Failed to delete what\'s next');
        }
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const getImageUrl = (path: string) => {
        if (!path) return '';
        if (path.startsWith('http')) return path;
        return `${baseUrl}/${path.replace(/^\//, '')}`;
    };

    const fetchHeadings = async () => {
        try {
            setIsLoadingHeadings(true);
            const response = await EyfApi.getHeadings();
            if (response.success) {
                setHeadings(response.data);
            } else {
                console.error('Failed to fetch EYFS headings');
            }
        } catch (error) {
            console.error('Failed to fetch EYFS headings');
        } finally {
            setIsLoadingHeadings(false);
        }
    };

    const getHeadingById = (id: string) => {
        return headings.find(heading => heading.id === id);
    };

    const getSubHeadingById = (headingId: string, subHeadingName: string) => {
        const heading = getHeadingById(headingId);
        return heading?.subHeadings.find(sub => sub.name === subHeadingName);
    };

    const stripHtmlTags = (html: string) => {
        const tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    const getStatusText = (status: number) => {
        switch (status) {
            case 0:
                return "PENDING";
            case 1:
                return "COMPLETED";
            default:
                return "DRAFT";
        }
    };

    const formatDate = (date: string) => {
        const d = new Date(date);
        return d.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    const handleStatusChange = async (whatsNext: any, newStatus: string) => {
        try {
            const response = await WhatsNextApi.updateWhatsNextStatus(whatsNext.id, {
                status: newStatus === "COMPLETED" ? 1 : 0
            });

            if (response.success) {
                Swal.fire({
                    title: 'Success',
                    text: 'Status updated successfully',
                    icon: 'success'
                });
                fetchWhatsNextList();
            } else {
                throw new Error(response.message || 'Failed to update status');
            }
        } catch (error: any) {
            toast.error(error.message || 'Failed to update status');
        } finally {
            toggleDropdownOne(whatsNext.id);
        }
    };

    const getStatusColor = (status: number) => {
        const statusText = whatsNextStatus[status]?.toLowerCase() || "pending";
        return statusColors[statusText];
    };

    const fetchChildren = async () => {
        try {
            setIsLoadingChildren(true);
            const response = await UserApi.getAllChildren({ nurseryId });
            console.log('response: ', response);
            if (response.success) {
                const formattedChildren = response.children.map((child: any) => ({
                    id: child.id,
                    name: `${child.firstName} ${child.lastName}`,
                    room: child.childrenRoomMoves?.[0]?.room?.roomName ? `Child in ${child.childrenRoomMoves[0].room.roomName}` : 'No Room Assigned',
                    image: child.avatarUrl || 'https://dummyimage.com/35x35/000/fff.png'
                }));
                setChildren(formattedChildren);
            } else {
                console.error('Failed to fetch children');
            }
        } catch (error) {
            console.error('Failed to fetch children');
        } finally {
            setIsLoadingChildren(false);
        }
    };

    useEffect(() => {
        fetchWhatsNextList();
        fetchHeadings();
        fetchChildren();
    }, [nurseryId, page, limit, filters]);

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "5px solid #eee" }}>
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title="View All What's Next"
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                                <div className="d-flex gap-3">
                                    <Button
                                        color="light"
                                        className="d-flex align-items-center gap-2"
                                        onClick={() => setShowFilters(!showFilters)}
                                    >
                                        <FaFilter size={14} />
                                        {showFilters ? 'Hide Filters' : 'Show Filters'}
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="btn-md m-4"
                                        onClick={() => navigate('/dashboard/learning/whats-next/add')}
                                    >
                                        Add New What's Next
                                    </Button>
                                </div>
                            </div>

                            <CardBody>
                                <div className="observation-content p-4">
                                    <Collapse isOpen={showFilters}>
                                        <div className="filters-section mb-4">
                                            <Form className="row g-3">
                                                <FormGroup className="col-md-3">
                                                    <Label for="status" className="text-muted small">Status</Label>
                                                    <Input
                                                        type="select"
                                                        name="status"
                                                        id="status"
                                                        className="form-select-sm"
                                                        value={filters.status}
                                                        onChange={handleFilterChange}
                                                    >
                                                        <option value="all">All</option>
                                                        <option value="0">Pending</option>
                                                        <option value="1">Completed</option>
                                                    </Input>
                                                </FormGroup>

                                                <FormGroup className="col-md-3">
                                                    <Label for="fromDate" className="text-muted small">From</Label>
                                                    <InputGroup className="input-group-sm">
                                                        <DatePicker
                                                            selected={filters.fromDate ? new Date(filters.fromDate) : null}
                                                            onChange={(date: Date) => setFilters(prev => ({ ...prev, fromDate: date ? date.toISOString().split('T')[0] : '' }))}
                                                            className="form-control"
                                                            placeholderText="Select date"
                                                            dateFormat="dd/MM/yyyy"
                                                            isClearable
                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup className="col-md-3">
                                                    <Label for="toDate" className="text-muted small">To</Label>
                                                    <InputGroup className="input-group-sm">
                                                        <DatePicker
                                                            selected={filters.toDate ? new Date(filters.toDate) : null}
                                                            onChange={(date: Date) => setFilters(prev => ({ ...prev, toDate: date ? date.toISOString().split('T')[0] : '' }))}
                                                            className="form-control"
                                                            placeholderText="Select date"
                                                            dateFormat="dd/MM/yyyy"
                                                            isClearable
                                                            minDate={filters.fromDate ? new Date(filters.fromDate) : undefined}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>

                                                <FormGroup className="col-md-3">
                                                    <Label for="child" className="text-muted small">Child</Label>
                                                    <Input
                                                        type="select"
                                                        name="child"
                                                        id="child"
                                                        className="form-select-sm"
                                                        value={filters.child}
                                                        onChange={handleFilterChange}
                                                    >
                                                        <option value="">All Children</option>
                                                        {children.map((child: any) => (
                                                            <option key={child.id} value={child.id}>
                                                                {child.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                    {isLoadingChildren && (
                                                        <div className="text-center mt-1">
                                                            <Spinner size="sm" color="primary" />
                                                        </div>
                                                    )}
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </Collapse>

                                    <div className="table-responsive">
                                        {isLoading ? (
                                            <div className="text-center py-5">
                                                <Spinner color="primary" />
                                            </div>
                                        ) : (
                                            <Table hover borderless className="mb-0 whats-next-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ ...tableStyles.header, width: '30%' }}>Children</th>
                                                        <th style={{ ...tableStyles.header, width: '40%' }}>What's Next Details</th>
                                                        <th style={{ ...tableStyles.header, width: '15%' }}>Status</th>
                                                        <th style={{ ...tableStyles.header, width: '15%', textAlign: 'right' }}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {whatsNextList.map((whatsNext) => (
                                                        <tr key={whatsNext.id} className="align-middle whats-next-row" style={tableStyles.row}>
                                                            <td className="py-3">
                                                                <div className="d-flex align-items-center">
                                                                    <div style={tableStyles.childrenGroup}>
                                                                        {whatsNext.children.map((child: any, index: number) => (
                                                                            <img
                                                                                key={child.id}
                                                                                src={child.avatarUrl || dynamicImage(`default-profile-old.jpg`)}
                                                                                alt={`${child.firstName} ${child.lastName}`}
                                                                                style={{
                                                                                    ...tableStyles.childImage,
                                                                                    marginLeft: index > 0 ? '-8px' : '0',
                                                                                    zIndex: whatsNext.children.length - index
                                                                                }}
                                                                                title={`${child.firstName} ${child.lastName}`}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                    <div className="ms-3">
                                                                        <div className="fw-semibold text-primary">
                                                                            {whatsNext.children.map((child: any) =>
                                                                                `${child.firstName} ${child.lastName}`
                                                                            ).join(', ')}
                                                                        </div>
                                                                        <small className="text-muted">
                                                                            {formatDate(whatsNext.createdAt)}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="py-3">
                                                                <div style={tableStyles.contentWrapper}>
                                                                    <div style={tableStyles.description} className={expandedRows[whatsNext.id] ? '' : 'text-truncate'}>
                                                                        {stripHtmlTags(whatsNext.content)}
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <Button
                                                                            color="link"
                                                                            size="sm"
                                                                            className="p-0 text-decoration-none"
                                                                            onClick={() => toggleExpand(whatsNext.id)}
                                                                        >
                                                                            {expandedRows[whatsNext.id] ? 'Show less' : 'Show more'}
                                                                        </Button>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap gap-2 mt-2">
                                                                        {whatsNext.eyfs.map((eyfs: any, index: number) => {
                                                                            const heading = getHeadingById(eyfs.id);
                                                                            if (!heading) return null;

                                                                            return (
                                                                                <div key={index} className="d-flex align-items-center gap-1">
                                                                                    <span
                                                                                        style={{
                                                                                            ...tableStyles.eyfsTag,
                                                                                            backgroundColor: heading.color,
                                                                                            color: '#fff'
                                                                                        }}
                                                                                    >
                                                                                        {heading.shortName || heading.name.split(' ').map((word: string) => word[0]).join('')}
                                                                                    </span>
                                                                                    {eyfs.subheadings.map((subName: string, subIndex: number) => {
                                                                                        const subHeading = getSubHeadingById(eyfs.id, subName);
                                                                                        if (!subHeading) return null;

                                                                                        return (
                                                                                            <span
                                                                                                key={subIndex}
                                                                                                style={{
                                                                                                    ...tableStyles.eyfsTag,
                                                                                                    backgroundColor: subHeading.color,
                                                                                                    color: '#fff'
                                                                                                }}
                                                                                            >
                                                                                                {subHeading.shortName || subHeading.name.split(' ').map((word: string) => word[0]).join('')}
                                                                                            </span>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="py-3">
                                                                <div>
                                                                    <Status
                                                                        position={whatsNextStatus[whatsNext.status] || "PENDING"}
                                                                        color={getStatusColor(whatsNext.status)}
                                                                        row={whatsNext}
                                                                        id={whatsNext.id}
                                                                        newUI={true}
                                                                        statusList={["PENDING", "COMPLETED"]}
                                                                        handleStatusChange={handleStatusChange}
                                                                        toggleDropdownOne={toggleDropdownOne}
                                                                        dropdownOpenOne={dropdownOpenOne}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="text-end py-3">
                                                                <div className="d-flex gap-2 justify-content-end">
                                                                    <Button
                                                                        color="primary"
                                                                        size="sm"
                                                                        className="d-flex align-items-center"
                                                                        onClick={() => navigate(`/dashboard/learning/whats-next/edit/${whatsNext.id}`)}
                                                                    >
                                                                        <i className="fa-solid fa-pencil me-2" style={{ fontSize: "12px" }}></i>
                                                                        Edit
                                                                    </Button>
                                                                    <Button
                                                                        color="danger"
                                                                        size="sm"
                                                                        className="d-flex align-items-center"
                                                                        onClick={() => handleDelete(whatsNext.id)}
                                                                    >
                                                                        <i className="fa-solid fa-trash me-2" style={{ fontSize: "12px" }}></i>
                                                                        Delete
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    {whatsNextList.length === 0 && (
                                                        <tr>
                                                            <td colSpan={4} className="text-center py-5">
                                                                <div className="d-flex flex-column align-items-center">
                                                                    <i className="fa-solid fa-clipboard-list mb-3" style={{ fontSize: '2rem', color: '#6c757d' }}></i>
                                                                    <p className="text-muted">No what's next found</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default connectComponent(ListWhatsNext);