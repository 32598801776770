import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup,
    Label, Input, Button, FormFeedback, Alert, Badge
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Upload, Save, Check, Image, Video, FileText } from 'react-feather';
import Select from 'react-select';
import ActivityLibraryApi from '../../../services/ActivityLibraryApi';
import UploadApi from '../../../services/UploadApi';
import EyfApi from '../../../services/EyfApi';
import './style.scss';
import Swal from 'sweetalert2';

interface FormData {
    title: string;
    description: string;
    thumbnailUrl: string;
    thumbnailUrls: string[];
    mediaUrl: string;
    mediaUrls: string[];
    mediaType: string;
    ageRangeStart: string;
    ageRangeEnd: string;
    learningArea: string;
    language: string[];
    isPublic: boolean;
    visibilityLevel: 'self' | 'assigned' | 'public';
    content: string;
}

// Define language option interface for React Select
interface LanguageOption {
    value: string;
    label: string;
}

// Update the LearningArea interface to match the response from EyfApi
interface LearningArea {
    id: string;
    name: string;
    color: string;
    headingId: string;
    status: number;
}

const NewActivity: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState<FormData>({
        title: '',
        description: '',
        thumbnailUrl: '',
        thumbnailUrls: [],
        mediaUrl: '',
        mediaUrls: [],
        mediaType: 'IMAGE',
        ageRangeStart: '',
        ageRangeEnd: '',
        learningArea: '',
        language: ['en-GB'],
        isPublic: false,
        visibilityLevel: 'self',
        content: ''
    });

    const [learningAreas, setLearningAreas] = useState<LearningArea[]>([]);
    const [languages, setLanguages] = useState<{ id: string; name: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [thumbnailFiles, setThumbnailFiles] = useState<File[]>([]);
    const [currentThumbnailIndex, setCurrentThumbnailIndex] = useState(0);
    const [mediaFiles, setMediaFiles] = useState<File[]>([]);
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const [isDragOver, setIsDragOver] = useState(false);
    const [activeMediaType, setActiveMediaType] = useState<'image' | 'video' | 'pdf' | null>(null);

    useEffect(() => {
        fetchLearningAreas();
        fetchLanguages();
    }, []);

    const fetchLearningAreas = async () => {
        try {
            const response = await EyfApi.getSubHeadings();
            if (response && response.success) {
                setLearningAreas(response.data);
            }
        } catch (error) {
            console.error('Error fetching learning areas:', error);
        }
    };

    const fetchLanguages = async () => {
        try {
            const response = await ActivityLibraryApi.getLanguages();
            if (response && response.success) {
                setLanguages(response.data);
            }
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;

        // Handle checkbox separately
        if (type === 'checkbox') {
            const checked = (e.target as HTMLInputElement).checked;
            setFormData({
                ...formData,
                [name]: checked
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }

        // Clear error for this field when user makes changes
        if (formErrors[name]) {
            setFormErrors({
                ...formErrors,
                [name]: ''
            });
        }
    };

    const handleThumbnailUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];

            // Create a copy of the current files array
            const updatedFiles = [...thumbnailFiles];

            // If the index exists, replace the file, otherwise add it
            if (index < updatedFiles.length) {
                updatedFiles[index] = newFile;
            } else {
                updatedFiles.push(newFile);
            }

            setThumbnailFiles(updatedFiles);

            // If we're adding a new thumbnail (at the end), update the index to show the next empty slot
            if (index >= thumbnailFiles.length) {
                setCurrentThumbnailIndex(updatedFiles.length);
            }
        }
    };

    const removeThumbnail = (index: number) => {
        setThumbnailFiles(prev => {
            const updatedFiles = [...prev];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });

        if (currentThumbnailIndex >= index && currentThumbnailIndex > 0) {
            setCurrentThumbnailIndex(currentThumbnailIndex - 1);
        }
    };

    const addThumbnailSlide = () => {
        setCurrentThumbnailIndex(thumbnailFiles.length);
    };

    const navigateThumbnail = (direction: 'prev' | 'next') => {
        if (direction === 'prev' && currentThumbnailIndex > 0) {
            setCurrentThumbnailIndex(currentThumbnailIndex - 1);
        } else if (direction === 'next' && currentThumbnailIndex < thumbnailFiles.length) {
            setCurrentThumbnailIndex(currentThumbnailIndex + 1);
        }
    };

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(true);
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);
    }, []);

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragOver(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files);
            const validFiles = files.filter(file =>
                file.type.startsWith('image/') ||
                file.type.startsWith('video/') ||
                file.type === 'application/pdf'
            );

            if (validFiles.length > 0) {
                setMediaFiles(prev => [...prev, ...validFiles]);

                // Set mediaType based on the first file type if no files exist yet
                if (mediaFiles.length === 0) {
                    const firstFile = validFiles[0];
                    if (firstFile.type.startsWith('image/')) {
                        setActiveMediaType('image');
                        setFormData(prev => ({
                            ...prev,
                            mediaType: 'IMAGE'
                        }));
                    } else if (firstFile.type.startsWith('video/')) {
                        setActiveMediaType('video');
                        setFormData(prev => ({
                            ...prev,
                            mediaType: 'VIDEO'
                        }));
                    } else if (firstFile.type === 'application/pdf') {
                        setActiveMediaType('pdf');
                        setFormData(prev => ({
                            ...prev,
                            mediaType: 'PDF'
                        }));
                    }
                }
            }
        }
    }, [mediaFiles]);

    const handleMediaUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const files = Array.from(e.target.files);
            setMediaFiles(prev => [...prev, ...files]);

            // Set mediaType based on the first file type if no files exist yet
            if (mediaFiles.length === 0) {
                const firstFile = files[0];
                const fileType = firstFile.type;

                if (fileType.startsWith('image/')) {
                    setFormData({
                        ...formData,
                        mediaType: 'IMAGE'
                    });
                    setActiveMediaType('image');
                } else if (fileType.startsWith('video/')) {
                    setFormData({
                        ...formData,
                        mediaType: 'VIDEO'
                    });
                    setActiveMediaType('video');
                } else if (fileType === 'application/pdf') {
                    setFormData({
                        ...formData,
                        mediaType: 'PDF'
                    });
                    setActiveMediaType('pdf');
                }
            }
        }
    };

    const removeFile = (index: number) => {
        setMediaFiles(prev => {
            const newFiles = [...prev];
            newFiles.splice(index, 1);

            // If all files are removed, reset the mediaType
            if (newFiles.length === 0) {
                setActiveMediaType(null);
            } else {
                // Otherwise, set the mediaType based on the first file
                const firstFile = newFiles[0];
                if (firstFile.type.startsWith('image/')) {
                    setActiveMediaType('image');
                    setFormData(prev => ({
                        ...prev,
                        mediaType: 'IMAGE'
                    }));
                } else if (firstFile.type.startsWith('video/')) {
                    setActiveMediaType('video');
                    setFormData(prev => ({
                        ...prev,
                        mediaType: 'VIDEO'
                    }));
                } else if (firstFile.type === 'application/pdf') {
                    setActiveMediaType('pdf');
                    setFormData(prev => ({
                        ...prev,
                        mediaType: 'PDF'
                    }));
                }
            }

            return newFiles;
        });
    };

    // Update the handleLanguageChange function to work with React Select
    const handleLanguageChange = (selectedOptions: any) => {
        const selectedLanguages = selectedOptions ? selectedOptions.map((option: LanguageOption) => option.value) : [];

        setFormData({
            ...formData,
            language: selectedLanguages
        });

        // Clear error
        if (formErrors.language) {
            setFormErrors({
                ...formErrors,
                language: ''
            });
        }
    };

    const validateForm = (formData: FormData) => {
        const errors: { [key: string]: string } = {};

        if (!formData.title.trim()) {
            errors.title = 'Title is required';
        }

        if (!formData.description.trim()) {
            errors.description = 'Description is required';
        }

        if (!formData.ageRangeStart) {
            errors.ageRangeStart = 'Starting age is required';
        }

        if (!formData.ageRangeEnd) {
            errors.ageRangeEnd = 'Ending age is required';
        } else if (parseInt(formData.ageRangeEnd) < parseInt(formData.ageRangeStart)) {
            errors.ageRangeEnd = 'Ending age must be greater than starting age';
        }

        if (!formData.learningArea) {
            errors.learningArea = 'Learning area is required';
        }

        if (!formData.language.length) {
            errors.language = 'At least one language is required';
        }

        if (mediaFiles.length === 0 && !formData.mediaUrl) {
            errors.mediaUrl = 'At least one media file is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const uploadFiles = async (formData: FormData) => {
        try {
            const uploads = [];
            const updatedFormData = { ...formData };
            let mainThumbnailUrl = '';
            let mainMediaUrl = '';

            // Upload thumbnail files
            if (thumbnailFiles.length > 0) {
                const thumbnailUrls = [];

                for (let i = 0; i < thumbnailFiles.length; i++) {
                    const file = thumbnailFiles[i];
                    const thumbnailFormData = new FormData();
                    thumbnailFormData.append('file', file);
                    thumbnailFormData.append("folder", "activity-library");
                    const thumbnailResponse = await UploadApi.uploadFile(thumbnailFormData, 'upload-activity-library');
                    console.log('thumbnailResponse: ', thumbnailResponse);

                    if (thumbnailResponse && thumbnailResponse.filePath) {
                        thumbnailUrls.push(thumbnailResponse.filePath);

                        // Save the first thumbnail as the main thumbnail
                        if (i === 0) {
                            mainThumbnailUrl = thumbnailResponse.filePath;
                        }

                        uploads.push({
                            field: 'thumbnailUrls',
                            url: thumbnailResponse.filePath
                        });
                    }
                }

                // Update form data with thumbnail URLs
                console.log('thumbnailUrls: ', thumbnailUrls);
                console.log('mainThumbnailUrl: ', mainThumbnailUrl);
                updatedFormData.thumbnailUrls = thumbnailUrls;
                updatedFormData.thumbnailUrl = mainThumbnailUrl;
            }

            // Upload media files
            if (mediaFiles.length > 0) {
                const mediaUrls = [];

                for (let i = 0; i < mediaFiles.length; i++) {
                    const file = mediaFiles[i];
                    const mediaFormData = new FormData();
                    mediaFormData.append('file', file);
                    mediaFormData.append("folder", "activity-library");
                    const mediaResponse = await UploadApi.uploadFile(mediaFormData, 'upload-activity-library');
                    console.log('mediaResponse: ', mediaResponse);

                    if (mediaResponse && mediaResponse.filePath) {
                        mediaUrls.push(mediaResponse.filePath);

                        // Save the first media as the main media
                        if (i === 0) {
                            mainMediaUrl = mediaResponse.filePath;
                        }

                        uploads.push({
                            field: 'mediaUrls',
                            url: mediaResponse.filePath
                        });
                    }
                }

                // Update form data with media URLs
                console.log('mediaUrls: ', mediaUrls);
                updatedFormData.mediaUrls = mediaUrls;
                console.log('mainMediaUrl: ', mainMediaUrl);
                updatedFormData.mediaUrl = mainMediaUrl;
            }

            // Update the state with the new URLs
            setFormData(updatedFormData);

            return uploads;
        } catch (error) {
            console.error('Error uploading files:', error);

            // Add SweetAlert2 notification instead
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'Failed to upload files. Please try again.',
                confirmButtonText: 'OK'
            });
            return [];
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateForm(formData)) {
            return;
        }

        setLoading(true);

        try {
            // First upload any files
            const uploads = await uploadFiles(formData);
            console.log('uploads: ', uploads);

            // Create a clean copy of the form data including the updated URLs
            const updatedFormData: any = { ...formData };

            // Apply all uploads to form data
            uploads.forEach(upload => {
                if (upload.field === 'thumbnailUrls' || upload.field === 'mediaUrls') {
                    if (!updatedFormData[upload.field]) {
                        updatedFormData[upload.field] = [];
                    }
                    if (!updatedFormData[upload.field].includes(upload.url)) {
                        updatedFormData[upload.field].push(upload.url);
                    }
                } else {
                    updatedFormData[upload.field] = upload.url;
                }
            });

            // Prepare final data structure for API
            const finalFormData = {
                title: updatedFormData.title,
                description: updatedFormData.description,
                content: updatedFormData.content || '',
                ageRangeStart: parseInt(updatedFormData.ageRangeStart),
                ageRangeEnd: parseInt(updatedFormData.ageRangeEnd),
                mediaType: updatedFormData.mediaType,
                mediaUrl: updatedFormData.mediaUrl || updatedFormData.mediaUrls[0] || '',
                mediaUrls: updatedFormData.mediaUrls || [],
                thumbnailUrl: updatedFormData.thumbnailUrl || updatedFormData.thumbnailUrls[0] || '',
                thumbnailUrls: updatedFormData.thumbnailUrls || [],
                learningArea: updatedFormData.learningArea,
                languages: updatedFormData.language,
                // Visibility settings based on radio selection
                isPublic: updatedFormData.visibilityLevel === 'public',
                assignedToUserNurseries: updatedFormData.visibilityLevel === 'assigned',
                visibleToCreatorOnly: updatedFormData.visibilityLevel === 'self'
            };

            console.log('Activity request payload:', finalFormData);

            const response = await ActivityLibraryApi.createActivity(finalFormData);

            if (response && response.success) {
                console.log('Activity created successfully:', response.data);

                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Activity created successfully',
                }).then(() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/activity-library`);
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response?.message || 'Failed to create activity',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error('Error creating activity:', error);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while creating the activity. Please try again.',
                confirmButtonText: 'OK'
            });
        } finally {
            setLoading(false);
        }
    };

    // Create default language options
    const defaultLanguageOptions = [
        { value: 'en-GB', label: 'English (UK)' },
        { value: 'en-US', label: 'English (US)' },
        { value: 'fr-FR', label: 'French' },
        { value: 'de-DE', label: 'German' },
        { value: 'es-ES', label: 'Spanish' },
        { value: 'it-IT', label: 'Italian' },
        { value: 'ar-SA', label: 'Arabic' },
        { value: 'zh-CN', label: 'Chinese (Simplified)' },
        { value: 'hi-IN', label: 'Hindi' }
    ];

    return (
        <Container fluid className="new-activity-container">
            <Row className="mb-3 pt-3">
                <Col>
                    <h3 className="mt-3">Add New Activity</h3>
                </Col>
            </Row>

            {error && (
                <Row className="mb-3">
                    <Col>
                        <Alert color="danger">{error}</Alert>
                    </Col>
                </Row>
            )}

            <Form id="activity-form" onSubmit={handleSubmit}>
                <Row>
                    <Col md={8}>
                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Activity Details</CardTitle>
                                <FormGroup>
                                    <Label for="title">Title <span className="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        invalid={!!formErrors.title}
                                    />
                                    <FormFeedback>{formErrors.title}</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="description">Description <span className="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        rows={3}
                                        name="description"
                                        id="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        invalid={!!formErrors.description}
                                    />
                                    <FormFeedback>{formErrors.description}</FormFeedback>
                                </FormGroup>
                            </CardBody>
                        </Card>

                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Activity Media <span className="text-danger">*</span></CardTitle>
                                <div
                                    className={`activity-media-dropzone ${isDragOver ? 'drag-over' : ''}`}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                >
                                    <Input
                                        type="file"
                                        id="mediaUpload"
                                        className="d-none"
                                        accept="image/*,video/*,application/pdf"
                                        onChange={handleMediaUpload}
                                        multiple
                                    />
                                    {mediaFiles.length > 0 ? (
                                        <div className="media-preview">
                                            <div className="selected-files-container">
                                                {mediaFiles.map((file, index) => (
                                                    <div key={index} className="selected-file-item">
                                                        {file.type.startsWith('image/') && (
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                alt={`Media preview ${index}`}
                                                                className="file-thumbnail"
                                                            />
                                                        )}
                                                        {file.type.startsWith('video/') && (
                                                            <Video size={24} className="file-icon" />
                                                        )}
                                                        {file.type === 'application/pdf' && (
                                                            <FileText size={24} className="file-icon" />
                                                        )}
                                                        <span className="file-name">{file.name}</span>
                                                        <Button
                                                            close
                                                            size="sm"
                                                            className="remove-file-btn"
                                                            onClick={() => removeFile(index)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mt-2"
                                                onClick={() => document.getElementById('mediaUpload')?.click()}
                                            >
                                                <Upload size={14} className="me-1" /> Add More Files
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="dropzone-placeholder">
                                            <div className="upload-icon">
                                                <Upload size={48} />
                                            </div>
                                            <p>Drag & drop your files here or</p>
                                            <label htmlFor="mediaUpload" className="btn btn-outline-primary mt-2">
                                                Browse Files
                                            </label>
                                            <p className="mt-3 text-muted small">
                                                Supported formats: Images, Videos, PDF
                                            </p>
                                        </div>
                                    )}
                                </div>
                                {formErrors.mediaUrl && (
                                    <div className="text-danger mt-2 small">{formErrors.mediaUrl}</div>
                                )}
                            </CardBody>
                        </Card>

                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Thumbnail Images</CardTitle>
                                <div className="thumbnail-slider-container">
                                    {thumbnailFiles.length > 0 ? (
                                        <div className="thumbnail-slider">
                                            <div className="thumbnail-slider-controls">
                                                <Button
                                                    color="light"
                                                    onClick={() => navigateThumbnail('prev')}
                                                    disabled={currentThumbnailIndex === 0}
                                                >
                                                    &lt;
                                                </Button>
                                                <span className="mx-2">
                                                    {currentThumbnailIndex + 1} / {thumbnailFiles.length + 1}
                                                </span>
                                                <Button
                                                    color="light"
                                                    onClick={() => navigateThumbnail('next')}
                                                    disabled={currentThumbnailIndex >= thumbnailFiles.length}
                                                >
                                                    &gt;
                                                </Button>
                                            </div>

                                            <div className="thumbnail-slide">
                                                {currentThumbnailIndex < thumbnailFiles.length ? (
                                                    <div className="thumbnail-preview">
                                                        <img
                                                            src={URL.createObjectURL(thumbnailFiles[currentThumbnailIndex])}
                                                            alt={`Thumbnail ${currentThumbnailIndex + 1}`}
                                                            className="img-fluid thumbnail-image"
                                                        />
                                                        <div className="thumbnail-actions mt-2">
                                                            <Button
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() => removeThumbnail(currentThumbnailIndex)}
                                                            >
                                                                Remove
                                                            </Button>
                                                            <label className="btn btn-primary btn-sm ms-2" htmlFor={`thumbnailUpload-${currentThumbnailIndex}`}>
                                                                Replace
                                                                <Input
                                                                    type="file"
                                                                    name={`thumbnailUpload-${currentThumbnailIndex}`}
                                                                    id={`thumbnailUpload-${currentThumbnailIndex}`}
                                                                    accept="image/*"
                                                                    onChange={(e) => handleThumbnailUpload(e, currentThumbnailIndex)}
                                                                    className="d-none"
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="thumbnail-upload-placeholder">
                                                        <div className="upload-icon mb-3">
                                                            <Image size={48} />
                                                        </div>
                                                        <p>Upload a thumbnail image</p>
                                                        <label className="btn btn-outline-primary" htmlFor={`thumbnailUploadNew-${currentThumbnailIndex}`}>
                                                            Choose Image
                                                            <Input
                                                                type="file"
                                                                name={`thumbnailUploadNew-${currentThumbnailIndex}`}
                                                                id={`thumbnailUploadNew-${currentThumbnailIndex}`}
                                                                accept="image/*"
                                                                onChange={(e) => handleThumbnailUpload(e, currentThumbnailIndex)}
                                                                className="d-none"
                                                            />
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="thumbnail-upload-placeholder">
                                            <div className="upload-icon mb-3">
                                                <Image size={48} />
                                            </div>
                                            <p>Upload thumbnail images for your activity</p>
                                            <label className="btn btn-outline-primary" htmlFor="thumbnailUploadInitial">
                                                Choose Image
                                                <Input
                                                    type="file"
                                                    name="thumbnailUploadInitial"
                                                    id="thumbnailUploadInitial"
                                                    accept="image/*"
                                                    onChange={(e) => handleThumbnailUpload(e, 0)}
                                                    className="d-none"
                                                />
                                            </label>
                                        </div>
                                    )}

                                    <small className="d-block mt-2 text-muted">
                                        Thumbnails will be displayed in the activity card. The first image will be used as the main thumbnail.
                                    </small>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Age Range <span className="text-danger">*</span></CardTitle>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="ageRangeStart">From (months)</Label>
                                            <Input
                                                type="number"
                                                name="ageRangeStart"
                                                id="ageRangeStart"
                                                value={formData.ageRangeStart}
                                                onChange={handleInputChange}
                                                min="0"
                                                invalid={!!formErrors.ageRangeStart}
                                            />
                                            <FormFeedback>{formErrors.ageRangeStart}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="ageRangeEnd">To (months)</Label>
                                            <Input
                                                type="number"
                                                name="ageRangeEnd"
                                                id="ageRangeEnd"
                                                value={formData.ageRangeEnd}
                                                onChange={handleInputChange}
                                                min="0"
                                                invalid={!!formErrors.ageRangeEnd}
                                            />
                                            <FormFeedback>{formErrors.ageRangeEnd}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="mb-4">
                            <CardBody>
                                <CardTitle tag="h5">Categories <span className="text-danger">*</span></CardTitle>
                                <FormGroup>
                                    <Label for="learningArea">Learning Area <span className="text-danger">*</span></Label>
                                    <Input
                                        type="select"
                                        name="learningArea"
                                        id="learningArea"
                                        value={formData.learningArea}
                                        onChange={handleInputChange}
                                        invalid={!!formErrors.learningArea}
                                    >
                                        <option value="">Select Learning Area</option>
                                        {learningAreas.map((area) => (
                                            <option
                                                key={area.id}
                                                value={area.id}
                                                style={{
                                                    backgroundColor: area.color ? `${area.color}10` : 'transparent'
                                                }}
                                            >
                                                {area.name}
                                            </option>
                                        ))}
                                    </Input>
                                    <FormFeedback>{formErrors.learningArea}</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="language">Languages <span className="text-danger">*</span></Label>
                                    <Select
                                        id="language"
                                        name="language"
                                        isMulti
                                        options={defaultLanguageOptions}
                                        value={defaultLanguageOptions.filter(option =>
                                            formData.language.includes(option.value)
                                        )}
                                        onChange={handleLanguageChange}
                                        className={`react-select-container ${formErrors.language ? 'is-invalid' : ''}`}
                                        classNamePrefix="react-select"
                                        placeholder="Select languages..."
                                        aria-label="Languages"
                                    />
                                    {formErrors.language && (
                                        <div className="invalid-feedback d-block">
                                            {formErrors.language}
                                        </div>
                                    )}
                                    <small className="form-text text-muted">
                                        You can select multiple languages for this activity
                                    </small>
                                </FormGroup>

                                <CardBody>
                                    <CardTitle tag="h5">Visibility Settings</CardTitle>
                                    <FormGroup tag="fieldset">
                                        <legend className="h6">Who can see this activity?</legend>

                                        <FormGroup check className="mb-2">
                                            <Input
                                                type="radio"
                                                name="visibilityLevel"
                                                id="visibilitySelf"
                                                value="self"
                                                checked={formData.visibilityLevel === 'self'}
                                                onChange={handleInputChange}
                                            />
                                            <Label check for="visibilitySelf">
                                                <strong>Creator Only</strong>
                                                <div className="text-muted small">Only you can see and use this activity</div>
                                            </Label>
                                        </FormGroup>

                                        <FormGroup check className="mb-2">
                                            <Input
                                                type="radio"
                                                name="visibilityLevel"
                                                id="visibilityAssigned"
                                                value="assigned"
                                                checked={formData.visibilityLevel === 'assigned'}
                                                onChange={handleInputChange}
                                            />
                                            <Label check for="visibilityAssigned">
                                                <strong>Assigned Nurseries</strong>
                                                <div className="text-muted small">Only nurseries you assign can see and use this activity</div>
                                            </Label>
                                        </FormGroup>

                                        <FormGroup check>
                                            <Input
                                                type="radio"
                                                name="visibilityLevel"
                                                id="visibilityPublic"
                                                value="public"
                                                checked={formData.visibilityLevel === 'public'}
                                                onChange={handleInputChange}
                                            />
                                            <Label check for="visibilityPublic">
                                                <strong>All Nurseries</strong>
                                                <div className="text-muted small">All nurseries can see and use this activity</div>
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </CardBody>
                            </CardBody>
                        </Card>

                        <div className="d-flex justify-content-between mt-4">
                            <Button
                                color="outline-primary"
                                type="button"
                                className="px-4"
                                onClick={() => navigate(`${process.env.PUBLIC_URL}/dashboard/activity-library`)}
                            >
                                Cancel
                            </Button>

                            <Button
                                color="primary"
                                type="submit"
                                disabled={loading}
                                className="px-4"
                            >
                                {loading ? (
                                    <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Saving...</>
                                ) : (
                                    <><Save size={16} className="me-2" /> Create Activity</>
                                )}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default NewActivity; 