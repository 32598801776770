import { connect } from "react-redux";
import { FETCH_ALL_EMPLOYEES } from "../../../ReduxToolkit/Slices/User/UserReducer";

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    nurseryId: state.auth.selectedNursery,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAllEmployees: (value: any) => dispatch(FETCH_ALL_EMPLOYEES(value)),
  };
};

export const connectComponent = (Component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
};
