import api from "./api"; // Use the Axios instance with interceptor

export default class MedicationAPi {
    static async createMedication(data: any) {
        try {
            const response = await api.post("/medication/create", data); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(JSON.stringify(errorMessage));
        }
    }

    static async updateMedication(credentials: any) {
        const { id, ...data } = credentials;
        const body = { ...data };
        try {
            const response = await api.patch(`/medication/${id}`, body);
            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async getMedications(nurseryId: any, params: any) {
        try {
            const response = await api.get("/medication/" + nurseryId, { params });

            console.log(response); // Log the fetched data
            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async getMedicationById(id: any) {
        try {
            const response = await api.get(`/medication/${id}`);

            console.log(response); // Log the fetched data
            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }
}
