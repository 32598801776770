import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "./UserApiSlice";

interface IChildHelper {
  id: string;
  firstName: string;
  lastName: string;
  nursery: {
    id: string;
    name: string;
  };
  room: {
    id: string;
    roomName: string;
  };
}

interface IUser extends Record<string, unknown> {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: "admin" | "employee" | "parent" | "super_admin";
  avatarUrl: string;
  createdAt: string;
  employeeRooms?: any[];
  jobTitle: any;
  children: IChildHelper[];
}

interface IDynamicFields {
  roomId: string;
  assignedAs: string;
}

interface IAddEmployeeForm {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  role?: string;
  joinDate: any;
  dob?: any;
  nationality: string;
  packageId?: string;
  timeZone?: string;
  avatarUrl: string;
  employeeId?: string;
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  postalCode: string;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactEmail: string;
  emergencyContactPhoneNo: string;
  emergencyContactRelation: string;
  employeeType: string;
  jobTitleId: string;
  monthlySalary: string;
  nurseryId: string;
  empRoomArrObj: IDynamicFields[];
  docFolderId: string;
  fileName: string;
  mediaUrl: string;
  mediaType: string;
  size: string;
  roleTitleId: string;
}

interface IUsersParentData {
  id: string | number;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  emergencyContactFirstName: string;
  emergencyContactPhoneNo: string;
  parentStatus: string;
  packageId: string;
  title?: string;
  isSelected?: boolean;
}

interface IAddParentForm {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  joinDate: Date;
  timeZone: string;
  nationality: string;
  avatarUrl: string;
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  postalCode: string;
  emergencyContactFirstName: string;
  emergencyContactLastName: string;
  emergencyContactPhoneNo: string;
  emergencyContactRelation: string;
  emergencyContactEmail: string;
  docFolderId: string;
  fileName: string;
  mediaType: string;
  mediaUrl: string;
  size: string;
}

interface IInitialState {
  users: IUser[] | [];
  employees: {
    data: IAddEmployeeForm[];
    count: number;
    active: number;
    fullTime: number;
    onBoarding: number;
    onLeave: number;
    partTime: number;
    probation: number;
    suspended: number;
    inactive: number;
    editItem: null | IAddEmployeeForm;
  };
  parents: {
    data: IUsersParentData[];
    count: number;
    active: number;
    inactive: number;
    probation: number;
    editItem: null | IAddParentForm;
  };
  count: number;
}

const initialState: IInitialState = {
  users: [],
  employees: {
    data: [],
    count: 0,
    active: 0,
    fullTime: 0,
    onBoarding: 0,
    onLeave: 0,
    partTime: 0,
    probation: 0,
    suspended: 0,
    inactive: 0,
    editItem: null,
  },
  parents: {
    data: [],
    count: 0,
    active: 0,
    inactive: 0,
    probation: 0,
    editItem: null,
  },
  count: 0,
};

const UserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    FETCH_ALL_EMPLOYEES: (state, action) => {
      // * To get the user data
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getAllUsers.matchFulfilled,
      (state: IInitialState, { payload }: any) => {
        const { list, total } = payload.data;
        state.users = list;
        state.count = total;
      }
    );

    //EMPLOYEE
    builder.addMatcher(
      userApi.endpoints.getAllEmployees.matchFulfilled,
      (state: IInitialState, { payload }: any) => {
        const {
          data,
          meta,
          active,
          fullTime,
          onBoarding,
          onLeave,
          partTime,
          probation,
          suspended,
          inactive,
        } = payload;
        state.employees.data = data;
        state.employees.count = meta.totalCount;
        state.employees.active = active;
        state.employees.fullTime = fullTime;
        state.employees.onBoarding = onBoarding;
        state.employees.onLeave = onLeave;
        state.employees.partTime = partTime;
        state.employees.probation = probation;
        state.employees.suspended = suspended;
        state.employees.inactive = inactive;
      }
    );

    builder.addMatcher(
      userApi.endpoints.getEmployeeById.matchFulfilled,
      (state: IInitialState, { payload }: any) => {
        const { data } = payload;
        state.employees.editItem = data;
      }
    );

    // PARENT
    builder.addMatcher(
      userApi.endpoints.getAllParents.matchFulfilled,
      (state: IInitialState, { payload }: any) => {
        const { data, meta, active, inactive, probation } = payload;
        state.parents.data = data;
        state.parents.count = meta.totalCount;
        state.parents.active = active;
        state.parents.inactive = inactive;
        state.parents.probation = probation;
      }
    );

    builder.addMatcher(
      userApi.endpoints.getParentById.matchFulfilled,
      (state: IInitialState, { payload }: any) => {
        const { data } = payload;
        state.parents.editItem = data;
      }
    );
  },
});
export const { FETCH_ALL_EMPLOYEES } = UserSlice.actions;

export default UserSlice.reducer;
