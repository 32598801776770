import api from "./api"; // Use the Axios instance with interceptor

export default class AttendanceAPi {
  static async addClockIn(data: any) {
    try {
      const response = await api.post("/attendance/clockIn", data); // Changed to /faq/
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
  static async getAllEmployeeAttendance(params: any) {
    try {
      const response = await api.get("/attendance/empAllAttendance", {
        params,
      });

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async addClockOut(data: any) {
    try {
      const response = await api.post("/attendance/clockOut", data); // Changed to /faq/

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async checkUserClockIn(params: any) {
    try {
      const response = await api.get("/attendance/checkUserClockIn", {
        params,
      });

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getEmpMonthlyAttendance(params: any) {
    try {
      const response = await api.get("/attendance", {
        params,
      });

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async childrenGeneralActivity(data: any) {
    try {
      const response = await api.post("/generalActivity/createNew", data); // Changed to /faq/
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }
}
