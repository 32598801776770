import React, { useState, useEffect } from 'react';
import { fetchImage } from '../Utils';
import { baseUrl } from 'src/Utils/Constants/config';

interface AsyncImageProps {
    src: string;
    alt: string;
    folder: string;
    className?: string;
    onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    style?: React.CSSProperties;
}

const AsyncImage: React.FC<AsyncImageProps> = ({ src, alt, folder, className, onError, style }) => {
    const [imageSrc, setImageSrc] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        const fileName = src.split('/').pop();
        const newSrc = `${baseUrl}/uploads/${folder}/${fileName}`;


        const loadImage = async () => {
            try {
                const resolvedSrc = await fetchImage(newSrc);


                if (isMounted) {
                    setImageSrc(resolvedSrc);
                    setLoading(false);
                }
            } catch (error) {

                setError(true);
                setLoading(false);

                if (onError && isMounted) {
                    const event: any = { target: { onerror: null, src: '' } } as any;
                    onError(event);
                }
            }
        };

        if (src) {
            loadImage();
        } else {
            setLoading(false);
            setError(true);
        }

        return () => { isMounted = false; };
    }, [src, onError]);

    if (loading) {
        return <div className="image-placeholder loading">Loading...</div>;
    }

    if (error || !imageSrc) {
        return (
            <div className="image-placeholder error">
                <img
                    src="/images/placeholder-image.png"
                    alt={alt}
                    className={className || ''}
                />
            </div>
        );
    }

    return (
        <img
            src={imageSrc}
            alt={alt}
            className={className || ''}
            onError={onError}
            style={style}
        />
    );
};

export default AsyncImage; 