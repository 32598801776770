import { Button, Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { flattenDeep, forEach, isArray, isObject } from "lodash";
import { flattenObject, getDataFromArray } from "../../../Utils";
import { connectComponent } from "./connector";
import Swal from "sweetalert2";
import { FaUser, FaClock } from 'react-icons/fa';

const Users = (props: any) => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [saveData, setSaveData] = useState([]);
  const filteredItems: any = saveData.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const [filterObj, setFilterObj] = useState({
    searchText: "",
    status: "",
    startDate: "",
    endDate: "",
    pageNo: 1,
    pageSize: 100,
  });

  const [showAdminModal, setShowAdminModal] = useState(false);
  const [selectedAdminData, setSelectedAdminData] = useState<any[]>([]);

  useEffect(() => {
    props.loadAdminData({});
  }, []);

  useEffect(() => {
    let data: any = [...props.admins].sort(
      (a: any, b: any) => b.joinDate - a.joinDate
    );
    data = data.map((row: any) => {
      // Save original adminUsersData before flattening
      const adminUsersData = row.adminUsersData;
      // Flatten the object but exclude adminUsersData
      const flattenedRow = flattenObject({
        ...row,
        adminUsersData: undefined
      });
      // Add back the original adminUsersData
      return {
        ...flattenedRow,
        adminUsersData
      };
    });

    setSaveData(data);
    console.log('props.admins: ', props.admins, data);
  }, [props.admins]);

  const handleDetailPage = (id: number | string) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/users/edit/${id}`);
  };

  const handleStatusChange = (row: any) => {
    const selectedValue = row?.status === 0 ? 1 : 0;
    const id = row.id;
    props.updateForm({
      id: id,
      body: {
        status: selectedValue,
      },
      filterObj,
    });
  };

  const toggleAdminModal = () => {
    setShowAdminModal(!showAdminModal);
    if (showAdminModal) {
      setSelectedAdminData([]);
    }
  };

  const handleViewAdmins = (adminUsersData: any[]) => {
    setSelectedAdminData(adminUsersData);
    console.log('adminUsersData: ', adminUsersData);
    toggleAdminModal();
  };

  useEffect(() => {
    if (props.error && props.error?.message) {
      Swal.fire({
        title: "Error !!!",
        text: props.error.message,
        icon: "error",
      });

      // Clear the error message after displaying it
      props.setMessage({ success: {}, error: {} });
    }
  }, [props.success, props.error, navigate]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Users Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/users/add`);
                  }}
                >
                  Add New User
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <div className="table-responsive">
                  {useMemo(() => {
                    return (
                      <>
                        <DataTable
                          data={filteredItems}
                          columns={columns({
                            handleDetailPage: (id: number | string) =>
                              handleDetailPage(id),
                            handleStatusChange: (row: any) =>
                              handleStatusChange(row),
                            handleViewAdmins: (adminUsersData: any[]) =>
                              handleViewAdmins(adminUsersData),
                          })}
                          pagination
                          className="display"
                          conditionalRowStyles={[
                            {
                              when: (row) => true,
                              style: {
                                "&:hover": {
                                  backgroundColor: "rgba(48, 142, 135, 0.2)",
                                  cursor: "pointer",
                                },
                              },
                            },
                          ]}
                        />
                      </>
                    );
                  }, [props.isLoading, props.success, saveData])}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={showAdminModal} toggle={toggleAdminModal} size="lg" backdrop="static">
          <ModalHeader toggle={toggleAdminModal} className="bg-primary text-white">
            <FaUser className="me-2" /> Admin Users List
          </ModalHeader>
          <ModalBody className="p-4">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead className="bg-light">
                  <tr>
                    <th className="border-0">
                      <div className="d-flex align-items-center">
                        <FaUser className="me-2 text-primary" />
                        Admin Name
                      </div>
                    </th>
                    <th className="border-0">
                      <div className="d-flex align-items-center">
                        <FaClock className="me-2 text-primary" />
                        Last Login
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedAdminData.map((admin, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="rounded-circle bg-light-primary text-primary d-flex align-items-center justify-content-center me-3"
                            style={{ width: '40px', height: '40px', fontSize: '18px' }}>
                            {admin.firstName?.[0]?.toUpperCase() || 'A'}
                          </div>
                          <div>
                            <div className="fw-bold">{`${admin.firstName} ${admin.lastName}`}</div>
                            <div className="text-muted small">{admin.email || 'No email'}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100">
                          {admin.lastLogin ? (
                            <div>
                              <div>{new Date(admin.lastLogin).toLocaleDateString()}</div>
                              <div className="text-muted small">{new Date(admin.lastLogin).toLocaleTimeString()}</div>
                            </div>
                          ) : (
                            <span className="text-muted">Never logged in</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {selectedAdminData.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center py-5">
                        <div className="text-muted">
                          <FaUser size={40} className="mb-3 text-light" />
                          <div>No admin users found</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="text-muted small mt-3">
              Total Admins: {selectedAdminData.length}
            </div>
          </ModalBody>
        </Modal>
      </Container>
      <style>
        {`
          .bg-light-primary {
            background-color: rgba(48, 142, 135, 0.1);
          }
          .modal-content {
            border: none;
            border-radius: 8px;
            box-shadow: 0 2px 20px rgba(0,0,0,0.1);
          }
          .modal-header {
            border-bottom: none;
            border-radius: 8px 8px 0 0;
          }
          .table > :not(caption) > * > * {
            padding: 1rem;
          }
          .badge {
            padding: 0.5em 1em;
            font-weight: 500;
          }
        `}
      </style>
    </div>
  );
};

export default connectComponent(Users);
