import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";

// Dummy room data for dropdown options
const roomOptions = [
  { id: 1, name: "Room 1" },
  { id: 2, name: "Room 2" },
  { id: 3, name: "Room 3" },
];

// Interface for form data
interface IForm {
  whenLeaving: string;
  children: string;
  nextRoom?: string;
  moveRule: string;
}

const RoomMoves: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>();
  const childrenSelection = watch("children"); // Watches 'children' field to conditionally render `Next Room`
  const [isLoading, setIsLoading] = useState(false);

  // Dummy function to handle form submission
  const onSubmit = (data: IForm) => {
    setIsLoading(true);
    Swal.fire({
      title: "Form Submitted",
      text: JSON.stringify(data, null, 2),
      icon: "success",
    });
    setIsLoading(false);
  };

  return (
    <Card className="card-absolute">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ borderBottom: "5px solid #eee" }}
      >
        <CommonCardHeader
          headClass="bg-primary"
          titleClass="text-light"
          title="Room Moves"
        />
      </div>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3 mt-1">
            {/* When Leaving: Rooms Dropdown */}
            <Col md={3}>
              <Label for="whenLeaving">When Leaving</Label>
            </Col>
            <Col md={9}>
              <Controller
                name="whenLeaving"
                control={control}
                defaultValue=""
                rules={{ required: "Please select a room" }}
                render={({ field }) => (
                  <select
                    {...field}
                    className={`form-control ${
                      errors.whenLeaving ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Select Room</option>
                    {roomOptions.map((room) => (
                      <option key={room.id} value={room.name}>
                        {room.name}
                      </option>
                    ))}
                  </select>
                )}
              />
              {errors.whenLeaving && (
                <FormFeedback>{errors.whenLeaving.message}</FormFeedback>
              )}
            </Col>

            {/* Children: Radio Options */}
            <Col md={3}>
              <Label>Children</Label>
            </Col>
            <Col md={9}>
              <div className="d-flex align-items-center">
                <Label className="me-3 d-flex gap-2">
                  <input
                    type="radio"
                    className="form-check-input mt-0"
                    value="Advance"
                    {...register("children", { required: "Select an option" })}
                  />{" "}
                  <span>Advance to the Next Group</span>
                </Label>
                <Label className="d-flex gap-2">
                  <input
                    type="radio"
                    className="form-check-input mt-0"
                    value="Leave"
                    {...register("children")}
                  />{" "}
                  <span>Leave the Nursery</span>
                </Label>
              </div>
              {errors.children && (
                <FormFeedback className="d-block">
                  {errors.children.message}
                </FormFeedback>
              )}
            </Col>

            {/* Next Room: Rooms Dropdown (only shows when "Advance" is selected) */}
            {childrenSelection === "Advance" && (
              <>
                <Col md={3}>
                  <Label for="nextRoom">Next Room</Label>
                </Col>
                <Col md={9}>
                  <Controller
                    name="nextRoom"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select a room" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${
                          errors.nextRoom ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">Select Room</option>
                        {roomOptions.map((room) => (
                          <option key={room.id} value={room.name}>
                            {room.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.nextRoom && (
                    <FormFeedback>{errors.nextRoom.message}</FormFeedback>
                  )}
                </Col>
              </>
            )}

            {/* Move Rules: Radio Options */}
            <Col md={3}>
              <Label>Move Rules</Label>
            </Col>
            <Col md={9}>
              <div className="d-flex align-items-center">
                <Label className="me-3 d-flex gap-2">
                  <input
                    type="radio"
                    className="form-check-input mt-0"
                    value="Specific Date"
                    {...register("moveRule", { required: "Select an option" })}
                  />{" "}
                  <span>On a Specific Date after reaching a certain age</span>
                </Label>
                <Label className="d-flex gap-2">
                  <input
                    type="radio"
                    className="form-check-input mt-0"
                    value="Certain Age"
                    {...register("moveRule")}
                  />{" "}
                  <span>When reaching a certain age</span>
                </Label>
              </div>
              {errors.moveRule && (
                <FormFeedback className="d-block">
                  {errors.moveRule.message}
                </FormFeedback>
              )}
            </Col>
          </Row>

          {/* Action Buttons */}
          <Row className="mt-4">
            <Col xs={12} className="text-end">
              <Button
                type="submit"
                color="primary"
                className="btn-md me-3"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Save & Update"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default RoomMoves;
