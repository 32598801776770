import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { H6, Image, LI, P, UL } from "../../../../AbstractElements";
import { dynamicImage, formatDateToYYYYMMDD, getUserActivityFormattedData } from "../../../../Utils";
import { settingIconData } from "../../../../Data/Layout/RightHeader";
import SvgIcon from "../../../../Utils/CommonComponents/CommonSvgIcons";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";
import { Modal, ModalBody, Button } from "reactstrap";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import AttendanceAPi from "../../../../services/AttendanceApi";

export default function UserWrap(props: any) {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [ukTime, setUKTime] = useState(
    moment.tz("Europe/London").format("HH:mm")
  );
  const [ukDateTime, setUKDateTime] = useState(
    moment.tz("Europe/London").format("YYYY-MM-DD HH:mm:00")
  );

  const navigate = useNavigate();
  const toggle = () => {
    setOpen(!open);
  };

  const clockInOutToggle = () => {
    setModal(!modal);
  }
  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserActivityAPi.addUserActivity(
          getUserActivityFormattedData(
            "Logout",
            `${props?.userName} is logout successfully.`
          )
        );
        navigate(`${process.env.PUBLIC_URL}/login`, {
          replace: true,
        });
        props.logout();
      }
    });
  };

  const loadProfileUrl = () => {
    if (
      props?.user?.avatarUrl &&
      !props?.user?.avatarUrl.includes("cloudflare-ipfs.com")
    ) {
    } else {
      return dynamicImage(`default-profile.png`);
    }
  };

  const handleEmployeeAttendanceClockOut = async () => {
    try {
      const response: any = await AttendanceAPi.addClockOut({
        nurseryId: props.selectedNursery,
        employeesId: [
          { empId: props?.user?.id, attendanceId: props.attendanceId },
        ],
        clockOutTime: ukDateTime,
        attendanceDate: formatDateToYYYYMMDD(new Date()),
      });

      if (response?.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          // Add user activity log
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );
          clockInOutToggle();
          props.setEmpClockOut({ empClockOut: ukDateTime, userClockOut: true });
        });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setUKTime(moment.tz("Europe/London").format("HH:mm"));
      setUKDateTime(moment.tz("Europe/London").format("YYYY-MM-DD HH:mm:00"));
    }, 1000);

    return () => clearInterval(interval); // Cleanup
  }, []);

  return (
    <li className="profile-nav custom-dropdown"
      style={{
        backgroundColor: "#d6e8e7",
        padding: "5px 15px",
        borderRadius: "10px",
      }}
    >
      <div className="user-wrap" onClick={toggle} >
        <div className="user-img">
          <Image src={loadProfileUrl()} alt="user" />
        </div>
        <div className="user-content">
          <H6>{props.userName}</H6>
          <P className="mb-0">
            {props?.user?.role.replace(/_/g, " ").toUpperCase()}
            <i className="fa-solid fa-chevron-down" />
          </P>
        </div>
      </div>
      <div className={`custom-menu overflow-hidden ${open ? "show" : ""}`} style={{
        top: "30px",
        width: "170px",
      }}>
        <UL className="profile-body simple-list">
          {settingIconData.map((item) => (
            <LI className="d-flex" key={item.id}>
              <SvgIcon className="svg-color" iconId={item.icon} />
              <Link
                className="ms-2"
                to={`${process.env.PUBLIC_URL}/${item.link}`}
              >
                {item.text}
              </Link>
            </LI>
          ))}

          {/* impliment here clock in */}

          <LI className="d-flex" onClick={clockInOutToggle}>
            <i data-feather="clock"></i>
            <div className="ms-2">{"Clock In / Out"}</div>
          </LI>

          <LI className="d-flex" onClick={logout}>
            <SvgIcon className="svg-color" iconId="Login" />
            <div className="ms-2">{"Log Out"}</div>
          </LI>
        </UL>
      </div>

      <Modal
        isOpen={modal}
        toggle={clockInOutToggle}
        backdrop="static"
        centered
      >
        <ModalBody>
          <div className="d-flex flex-column gap-1">
            <h2 className="h2">
              Are you sure you want to clock out ?{" "}
            </h2>
            <h6 className="h6">
              Date & Time:{" "}
              {moment
                .tz("Europe/London")
                .format("DD-MM-YYYY")}
              {", "}
              {ukTime}
            </h6>
          </div>
          <div className="mt-3 d-flex justify-content-center gap-2">
            <Button
              color="primary"
              type="button"
              onClick={handleEmployeeAttendanceClockOut}
            >
              Yes, I want
            </Button>
            <Button color="secondary" onClick={clockInOutToggle}>
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </li>
  );
}
