import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "../../../Utils/Constants/config";

export const PermissionApiSlice = createApi({
  reducerPath: "PermissionApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getPermissionByRoleTitleId: builder.query({
      query: (id) => ({
        url: `/role/permissions/${id}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
  }),
});
