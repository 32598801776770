import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "../../../Utils/Constants/config";

interface IUserFilters {
  role?: string;
  searchText?: string;
  sort?: string;
  pageNo?: number;
  pageSize?: number;
  visibility?: string;
  status: string;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),

  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({
        role,
        searchText,
        status,
        pageNo,
        pageSize,
      }: IUserFilters) => ({
        url: `/user/all?role=${role}&searchText=${searchText}&status=${status}&No=${pageNo}&pageSize=${pageSize}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getUserById: builder.query({
      query: ({ id }) => ({
        url: `/user/info/${id}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `/user/info/${id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/user/info",
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateAvatar: builder.mutation({
      query: ({ id, body }) => ({
        url: `/user/updateUserAvatar/${id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateSuperAdmin: builder.mutation({
      query: ({ id, body }) => ({
        url: `/user/info/superAdmin/${id}`,
        method: "PATCH",
        body: body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    // EMPLOYEE
    createUser: builder.mutation({
      query: (credentials) => ({
        url: "/user/signup",
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateEmployee: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/user/updateEmployee/${id}`,
        method: "PATCH",
        body: { ...body },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    getAllEmployees: builder.query({
      query: (params) => ({
        url: `/user/allEmployees`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getEmployeeById: builder.query({
      query: ({ id }) => ({
        url: `/user/getEmpById/${id}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getEmployeeShiftDetail: builder.query({
      query: ({ id }) => ({
        url: `/user/empShift/${id}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateEmployeeShiftDetail: builder.mutation({
      query: ({ id, body }) => ({
        url: `/user/empShift/${id}`,
        method: "PATCH",
        body: { ...body },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    // PARENT
    createParent: builder.mutation({
      query: (body) => ({
        url: "/user/create-parent",
        method: "POST",
        body: body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    updateParent: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/user/updateParent/${id}`,
        method: "PATCH",
        body: { ...body },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getAllParents: builder.query({
      query: (params) => ({
        url: `/user/allParents`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getParentById: builder.query({
      query: ({ id }) => ({
        url: `/user/getParentById/${id}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    // Status

    updateStatus: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/user/updateUserStatus/${id}`,
        method: "PATCH",
        body: { ...body },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
  }),
});
