import React, { useEffect, useState } from "react";
import PackageApi from "../../../services/PackageApi"; // Adjusted path
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { LI, UL } from "../../../AbstractElements";
import Swal from "sweetalert2";
import Status from "../../../Components/Common/Status";
import { statusColors } from "../../../Utils/Constants";

interface Plan {
  id: string;
  title: string;
  subTitle?: string;
  billing: string;
  fee: number | string;
  maxNumOfChild: number | string;
  minNumOfChild: number | string;
  description: string;
  isActive: boolean;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ListPlan: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await PackageApi.getPackagePlan({});
        if (response.success && Array.isArray(response.data.list)) {
          setPlans(response.data.list);
        } else {
          throw new Error("Expected an array of Plans");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching plans");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const filteredItems = plans.filter((plan) =>
    plan.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleDetailPage = (id: string) => {
    navigate(`/dashboard/plans/edit/${id}`);
  };
  const handleStatusChange = async (row: Plan) => {
    const currentStatus = row.isActive ? "active" : "inactive"; // Determine the current status as a string
    const updatedStatus = currentStatus === "active" ? false : true; // true for "active", false for "inactive"
    try {
      // Call the API to update the status, passing the boolean value
      await PackageApi.updatePlan(row.id, { isActive: updatedStatus });

      // Update the local state to reflect the status change
      setPlans((prevPlans) =>
        prevPlans.map((plan) =>
          plan.id === row.id ? { ...plan, isActive: updatedStatus } : plan
        )
      );
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      style: { maxWidth: "50%" },
      sortable: true,
      cell: (row) => (
        <div
          style={{
            maxWidth: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.title}
        </div>
      ),
    },
    {
      name: <CustomHeader title="Billing" />,
      selector: (row) => row.billing,
      style: { maxWidth: "50%" },
      sortable: true,
    },
    {
      name: <CustomHeader title="Fee" />,
      selector: (row) => row.fee,
      style: { maxWidth: "50%" },
      sortable: true,
    },
    {
      name: <CustomHeader title="Min No. Of Children" />,
      selector: (row) => row.minNumOfChild,
      style: { maxWidth: "100%" },
      center: true,
      sortable: true,
    },
    {
      name: <CustomHeader title="Max No. Of Children" />,
      selector: (row) => row.maxNumOfChild,
      style: { maxWidth: "100%" },
      center: true,
      sortable: true,
    },
    {
      name: <CustomHeader title="Description" />,
      selector: (row) => row.description,
      style: { maxWidth: "50%" },
      sortable: true,
      cell: (row) => (
        <div
          style={{
            maxWidth: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          dangerouslySetInnerHTML={{ __html: row.description }}
        />
      ),
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => (row?.isActive === true ? "ACTIVE" : "INACTIVE"),
      sortable: true,
      center: true,
      cell: (row) => (
        <Status
          position={row?.isActive === true ? "ACTIVE" : "INACTIVE"}
          color={row.isActive ? statusColors.active : statusColors.inactive}
          row={row}
          id={row.id}
          handleStatusChange={handleStatusChange}
        />
      ),
    },

    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "150px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "150px", // Set consistent width
      maxWidth: "150px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href={"javascript:void(0);"}
              onClick={() => handleDetailPage(row.id)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Plans`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(`/dashboard/plans/add`);
                  }}
                >
                  Add New Plan
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  highlightOnHover
                  striped
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListPlan;
