import { TableColumn } from "react-data-table-component";
import { Badges, Btn, LI, UL } from "../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";
import moment from "moment";
import Status from "src/Components/Common/Status";
import { announcementStatus, statusColors } from "src/Utils/Constants";
import { camelCase, lowerCase } from "lodash";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns = ({
  handleStatusChange,
  toggleDropdownOne,
  dropdownOpenOne
}: {
  handleStatusChange: (row: any, status: string) => void;
  toggleDropdownOne: (row: any) => void;
  dropdownOpenOne: any;
}) => {
  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Date" />,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
      width: '10%'
    },
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      sortable: true,
      style: {
        width: "300px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => row.status,
      width: '12%',
      cell: (row: any) => {
        return (
          <Status
            position={
              Object.keys(announcementStatus).includes(lowerCase(row?.status))
                ? announcementStatus[lowerCase(row?.status)]
                : ""
            }
            color={statusColors[lowerCase(row?.status)]}
            row={row}
            id={row.id}
            newUI={true}
            statusList={["DRAFT", "PUBLISHED"]}
            handleStatusChange={handleStatusChange}
            toggleDropdownOne={toggleDropdownOne}
            dropdownOpenOne={dropdownOpenOne}
          />
        );
      },
      sortable: true,
    },
    {
      name: <CustomHeader title="Share With" />,
      selector: (row) => row.visibility,
      width: '12%',
      sortable: true
    },

    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      width: '10%',
      cell: (row) => (
        <UL className="action simple-list flex-row ">
          <LI className="edit me-1">
            <a
              href={`/dashboard/announcements/edit/${row.id}`}
            >
              <Btn color="primary" size="sm">
                <i className="fa-solid fa-pencil" style={{ fontSize: "12px", color: "white" }}></i>
                {/* {"Edit"} */}
              </Btn>
            </a>
          </LI>
          <LI className="delete">
            <a>
              <Btn color="danger" size="sm">
                <i className="fa-solid fa-trash" style={{ fontSize: "12px", color: "white" }}></i>
                {/* {"Delete"} */}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return columns;
};
