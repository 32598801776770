import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectComponent } from "../connector";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Btn, LI, UL } from "src/AbstractElements";
import EyfApi from "src/services/EyfApi";
import { statusColors } from "src/Utils/Constants";
import Select from "react-select";
import Status from "src/Components/Common/Status";

interface SubHeading2 {
    id: string;
    headingId: string;
    subHeadingId: string;
    name: string;
    color: string;
    status: number;
}

const CustomHeader = ({ title }: { title: string }) => (
    <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
        {title}
    </span>
);

function SubHeading2(props: any) {
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState("");
    const [subHeading2, setSubHeading2] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSubHeading2, setSelectedSubHeading2] = useState<Partial<SubHeading2>>({});
    const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});
    const [heading, setHeading] = useState<any[]>([]);
    const [subHeading, setSubHeading] = useState<any[]>([]);
    const [subHeadingOptions, setSubHeadingOptions] = useState<any[]>([]);
    const toggleDropdownOne = (id: any) => {
        setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
    };

    const filteredItems: any = subHeading2.filter((item: any) => {
        return Object.values(item).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const fetchSubHeading2 = async () => {
        try {
            const response = await EyfApi.getSubHeadings2();
            if (response.success && Array.isArray(response.data)) {
                setSubHeading2(response.data);
            } else setSubHeading2([]);
        } catch (error: any) {
            setSubHeading2([]);
        }
    };

    const fetchHeading = async () => {
        try {
            const response = await EyfApi.getHeadings();
            if (response.success && Array.isArray(response.data)) {
                const heading = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                setHeading(heading);

                // Extract subHeadings from heading data
                const subHeadings = response.data.flatMap((item: any) =>
                    item.subHeadings.map((subItem: any) => ({
                        value: subItem.id,
                        label: subItem.name,
                        headingId: subItem.headingId
                    }))
                );
                setSubHeading(subHeadings);
            } else {
                setHeading([]);
                setSubHeading([]);
            }
        } catch (error: any) {
            setHeading([]);
            setSubHeading([]);
        }
    };

    useEffect(() => {
        fetchSubHeading2();
        fetchHeading();
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
    } = useForm<SubHeading2>();


    const toggleModal = (subHeading2: Partial<SubHeading2> = {}) => {
        setIsModalOpen((prevState) => !prevState);
        // When modal closes, reset the form

        if (isModalOpen) {
            reset({}); // Reset form to empty state when closing
            setSelectedSubHeading2({}); // Clear selected Price Brand
        } else {
            // If opening modal, reset with selected Price Brand data for edit, otherwise with empty data for add
            reset(subHeading2);
        }
    };

    const handleEditHeading = (subHeading2: SubHeading2) => {
        const filteredSubHeadings = subHeading
            .filter((item: any) => item.headingId === subHeading2?.headingId)
            .map((item: any) => ({
                value: item.value,
                label: item.label,
            }));
        setSubHeadingOptions(filteredSubHeadings);
        setSelectedSubHeading2(subHeading2);
        toggleModal(subHeading2);
    };

    const handleHeadingChange = async (selectedOption: any, field: any) => {
        console.log('selectedOption?.value: ', selectedOption?.value);
        field.onChange(selectedOption?.value);

        // Reset subHeading field
        setValue('subHeadingId', '');

        // Filter subHeadings based on selected heading
        try {
            console.log('subHeading: ', subHeading);
            const filteredSubHeadings = subHeading
                .filter((item: any) => item.headingId === selectedOption?.value)
                .map((item: any) => ({
                    value: item.value,
                    label: item.label,
                }));
            console.log('filteredSubHeadings: ', filteredSubHeadings);
            setSubHeadingOptions(filteredSubHeadings);
        } catch (error) {

            setSubHeadingOptions([]);
        }
    };

    // Handle form submission for both create and update
    const onSubmit = async (data: SubHeading2) => {
        setIsLoading(true);
        try {
            setTimeout(async () => {
                if (selectedSubHeading2.id) {
                    // Update Price Brand
                    await EyfApi.updateSubHeading2(selectedSubHeading2.id, { ...data });
                    Swal.fire({
                        title: "Success!",
                        text: "Sub Heading 2 updated successfully",
                        icon: "success",
                    });
                } else {
                    // Create new Price Brand
                    await EyfApi.createSubHeading2({ ...data });
                    Swal.fire({
                        title: "Success!",
                        text: "Sub Heading 2 created successfully",
                        icon: "success",
                    });

                }
                // Reload the data after successful operation
                fetchSubHeading2();
                toggleModal();
            }, 2000);
        } catch (error: any) {
            Swal.fire({
                title: "Error!",
                text: error.message || "Something went wrong",
                icon: "error",
            });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2300);
        }
    };

    const handleStatusChange = async (row: any, status: string) => {
        const currentStatus = status === "ACTIVE" ? 0 : 1; // Determine the current status as a string
        const id = row.id;
        try {
            // Call the API to update the status, passing the number value
            await EyfApi.updateSubHeading2(id, {
                status: currentStatus,
            });
            toggleDropdownOne(id);
            // Update the local state to reflect the status change
            await fetchSubHeading2();
        } catch (error: any) {
            Swal.fire("Error", error.message || "Failed to update status", "error");
        }
    };

    const columns: TableColumn<any>[] = [
        {
            name: <CustomHeader title="Heading" />,
            selector: (row) => row.headingId,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div>
                    {heading.find(
                        (option: any) => option.value === row.headingId
                    )?.label ?? "N/A"}
                </div>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Sub Heading 1" />,
            selector: (row) => row.subHeadingId,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div>
                    {subHeading.find(
                        (option: any) => option.value === row.subHeadingId
                    )?.label ?? "N/A"}
                </div>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Name" />,
            selector: (row) => row.name,
            style: { maxWidth: "50%" },
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Color" />,
            selector: (row) => row.color,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div style={{ backgroundColor: row.color, width: "25px", height: "25px", borderRadius: "50%" }}></div>
            ),
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => (row.status === 0 ? "ACTIVE" : "INACTIVE"),
            style: { maxWidth: "50%" },
            sortable: true,
            center: false,
            cell: (row) => (
                <Status
                    position={row.status === 0 ? "ACTIVE" : "INACTIVE"}
                    color={row.status === 0 ? statusColors.active : statusColors.inactive}
                    row={row}
                    id={row.id}
                    newUI={true}
                    statusList={["ACTIVE", "INACTIVE"]}
                    handleStatusChange={handleStatusChange}
                    toggleDropdownOne={toggleDropdownOne}
                    dropdownOpenOne={dropdownOpenOne}
                />
            ),
        },
        {
            name: <CustomHeader title="Action" />,
            sortable: true,
            center: true,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <UL className="action simple-list flex-row">
                    <LI className="edit">
                        <a
                            className="icon"
                            href="javascript:void(0);"
                            onClick={() => handleEditHeading(row)}
                        >
                            <Btn color="primary" size="sm">
                                <i
                                    className="fa-solid fa-pencil text-white me-1"
                                    style={{ fontSize: "12px" }}
                                ></i>
                                {"Edit"}
                            </Btn>
                        </a>
                    </LI>
                </UL>
            ),
        },
    ];

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ borderBottom: "5px solid #eee" }}
                            >
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`View All Sub Heading 2`}
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                                <Button
                                    color="primary"
                                    className="btn-md m-4"
                                    onClick={() => {
                                        toggleModal();
                                    }}
                                >
                                    Add New Sub Heading 2
                                </Button>
                            </div>
                            <CardBody>
                                <Row className="justify-content-end">
                                    <Col md={4}>
                                        <FilterComponent
                                            onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFilterText(e.target.value)
                                            }
                                            filterText={filterText}
                                        />
                                    </Col>
                                </Row>

                                <div className="table-responsive">
                                    <DataTable
                                        data={filteredItems}
                                        columns={columns}
                                        pagination
                                        className="display"
                                        conditionalRowStyles={[
                                            {
                                                when: (row) => true, // This applies to all rows
                                                style: {
                                                    "&:hover": {
                                                        backgroundColor: "rgba(48, 142, 135, 0.2)",
                                                        cursor: "pointer",
                                                    },
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Modal for Adding Price Brand */}
            <Modal isOpen={isModalOpen} toggle={() => toggleModal()} backdrop="static">
                <ModalHeader toggle={() => toggleModal()}>
                    {selectedSubHeading2.id ? "Edit Sub Heading 2" : "Add Sub Heading 2"}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label>
                                {"Heading"}{" "}
                                <span className="text-danger">*</span>
                            </Label>
                            <Controller
                                name="headingId"
                                control={control}
                                rules={{
                                    required: "Please select any one heading",
                                }}
                                render={({ field }) => {


                                    return (

                                        <Select
                                            {...field}
                                            id="headingId"
                                            className={`${errors.headingId ? "is-invalid" : ""}`}
                                            options={heading}
                                            placeholder="Select Heading"
                                            value={heading.find(
                                                (option: any) => option.value === (field.value || selectedSubHeading2.headingId)
                                            ) ?? null}
                                            onChange={(selectedOption) => handleHeadingChange(selectedOption, field)}
                                        />
                                    )
                                }}
                            />
                            {errors.headingId && errors.headingId.message && (
                                <FormFeedback style={{ display: "block", color: "#dc3545" }}>
                                    <>{errors?.headingId?.message}</>
                                </FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                {"Sub Heading 1"}{" "}
                                <span className="text-danger">*</span>
                            </Label>
                            <Controller
                                name="subHeadingId"
                                control={control}
                                rules={{
                                    required: "Please select any one heading",
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id="subHeadingId"
                                        className={`${errors.subHeadingId ? "is-invalid" : ""}`}
                                        options={subHeadingOptions}
                                        placeholder="Select Sub Heading 1"
                                        value={subHeadingOptions.find(
                                            (option: any) => option.value === (field.value || selectedSubHeading2.subHeadingId)
                                        ) ?? null}
                                        onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                    />
                                )}
                            />
                            {errors.subHeadingId && errors.subHeadingId.message && (
                                <FormFeedback style={{ display: "block", color: "#dc3545" }}>
                                    <>{errors?.subHeadingId?.message}</>
                                </FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="title">
                                Name <span className="text-danger">*</span>
                            </Label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? "is-invalid" : ""
                                    }`}
                                id="name"
                                {...register("name", {
                                    required: "Heading Name is required",
                                })}
                                defaultValue={selectedSubHeading2.name}
                            />
                            {errors.name && (
                                <FormFeedback>{errors.name.message}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="color">
                                Color <span className="text-danger">*</span>
                            </Label>
                            <input
                                type="color"
                                className={`form-control ${errors.color ? "is-invalid" : ""
                                    }`}
                                id="color"
                                {...register("color", {
                                    required: "Color is required",
                                })}
                                defaultValue={selectedSubHeading2.color}
                            />
                            {errors.color && (
                                <FormFeedback>{errors.color.message}</FormFeedback>
                            )}
                        </FormGroup>

                        <ModalFooter>
                            <Button color="primary" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <span className="d-flex gap-2">
                                        <Spinner color="white" size="sm" /> Loading...
                                    </span>
                                ) : (
                                    <>
                                        {selectedSubHeading2.id ? "Update Sub Heading 2" : "Add Sub Heading 2"}
                                    </>
                                )}
                            </Button>
                            <Button color="secondary" onClick={() => toggleModal()}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default connectComponent(SubHeading2);