import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectComponent } from "../connector";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Btn, LI, UL } from "src/AbstractElements";
import EyfApi from "src/services/EyfApi";
import { statusColors } from "src/Utils/Constants";
import Select from "react-select";
import Status from "src/Components/Common/Status";
import { commonKeysObject } from "src/Utils";

interface Eyfs {
    id: string;
    headingId: string;
    subHeadingId: string;
    subHeadingId2: string;
    ageBandId: string;
    name: string;
    description: string;
    status: number;
}

const CustomHeader = ({ title }: { title: string }) => (
    <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
        {title}
    </span>
);

function Eyfs(props: any) {
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState("");
    const [eyfs, setEyfs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEyfs, setSelectedEyfs] = useState<Partial<Eyfs>>({});
    const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});
    const [heading, setHeading] = useState<any[]>([]);
    const [subHeading, setSubHeading] = useState<any[]>([]);
    const [ageBand, setAgeBand] = useState<any[]>([]);
    const [ageBandOptions, setAgeBandOptions] = useState<any[]>([]);
    const [subHeadingOptions, setSubHeadingOptions] = useState<any[]>([]);
    const [subHeading2, setSubHeading2] = useState<any[]>([]);
    const [subHeading2Options, setSubHeading2Options] = useState<any[]>([]);
    const toggleDropdownOne = (id: any) => {
        setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
    };

    const filteredItems: any = eyfs.filter((item: any) => {
        return Object.values(item).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const fetchEyfs = async () => {
        try {
            const response = await EyfApi.getEyfs();
            if (response.success && Array.isArray(response.data)) {
                setEyfs(response.data);
            } else setEyfs([]);
        } catch (error: any) {
            setEyfs([]);
        }
    };

    const fetchHeading = async () => {
        try {
            const response = await EyfApi.getHeadings();
            if (response.success && Array.isArray(response.data)) {
                const heading = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                setHeading(heading);

                // Extract subHeadings from heading data
                const subHeadings = response.data.flatMap((item: any) =>
                    item.subHeadings.map((subItem: any) => ({
                        value: subItem.id,
                        label: subItem.name,
                        headingId: subItem.headingId
                    }))
                );
                setSubHeading(subHeadings);

                // Extract subHeading2 from heading data
                const subHeading2 = response.data.flatMap((item: any) =>
                    item.subHeading2.map((subItem: any) => ({
                        value: subItem.id,
                        label: subItem.name,
                        headingId: subItem.headingId,
                        subHeadingId: subItem.subHeadingId,
                    }))
                );
                setSubHeading2(subHeading2);

                // Extract ageBands from heading data
                const ageBand = response.data.flatMap((item: any) =>
                    item.ageBands.map((subItem: any) => ({
                        value: subItem.id,
                        label: subItem.name,
                        headingId: subItem.headingId,
                        subHeadingId: subItem.subHeadingId,
                        subHeadingId2: subItem.subHeadingId2,
                    }))
                );
                setAgeBand(ageBand);
            } else {
                setHeading([]);
                setSubHeading([]);
                setSubHeading2([]);
                setAgeBand([]);
            }
        } catch (error: any) {
            setHeading([]);
            setSubHeading([]);
            setSubHeading2([]);
            setAgeBand([]);
        }
    };

    useEffect(() => {
        fetchEyfs();
        fetchHeading();
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
    } = useForm<Eyfs>();

    const toggleModal = (eyfs: Partial<Eyfs> = {}) => {
        setIsModalOpen((prevState) => !prevState);
        // When modal closes, reset the form

        if (isModalOpen) {
            reset({}); // Reset form to empty state when closing
            setSelectedEyfs({}); // Clear selected Price Brand
        } else {
            // If opening modal, reset with selected Price Brand data for edit, otherwise with empty data for add
            reset(eyfs);
        }
    };

    const handleEditHeading = (eyfs: Eyfs) => {
        const filteredSubHeadings = subHeading
            .filter((item: any) => item.headingId === eyfs?.headingId)
            .map((item: any) => ({
                value: item.value,
                label: item.label,
            }));
        setSubHeadingOptions(filteredSubHeadings);

        const filteredSubHeading2 = subHeading2
            .filter((item: any) => item.headingId === eyfs?.headingId && item.subHeadingId === eyfs?.subHeadingId)
            .map((item: any) => ({
                value: item.value,
                label: item.label,
            }));
        setSubHeading2Options(filteredSubHeading2);

        console.log('eyfs: ', eyfs);
        setSelectedEyfs(eyfs);
        toggleModal(eyfs);
    };

    const handleHeadingChange = async (selectedOption: any, field: any) => {
        field.onChange(selectedOption?.value);

        // Reset subHeading field
        setValue('subHeadingId', 'undefined');
        setValue('subHeadingId2', 'undefined');
        setValue('ageBandId', 'undefined');

        // Filter subHeadings based on selected heading
        try {
            const filteredSubHeadings = subHeading
                .filter((item: any) => item.headingId === selectedOption?.value)
                .map((item: any) => ({
                    value: item.value,
                    label: item.label,
                }));
            setSubHeadingOptions(filteredSubHeadings);

            // Filter subHeading2 based on selected subHeading
            const filteredSubHeading2 = subHeading2
                .filter((item: any) => item.subHeadingId === selectedOption?.value)
                .map((item: any) => ({
                    value: item.value,
                    label: item.label,
                }));
            setSubHeading2Options(filteredSubHeading2);
        } catch (error) {
            setSubHeadingOptions([]);
            setSubHeading2Options([]);
        }
    };

    const handleSubHeading2Change = async (selectedOption: any, field: any) => {
        field.onChange(selectedOption?.value);

        // Reset subHeading field
        setValue('ageBandId', 'undefined');

        // Filter ageBands based on selected subHeading2
        try {
            const filteredAgeBand = ageBand
                .filter((item: any) => item.subHeadingId2 === selectedOption?.value)
                .map((item: any) => ({
                    value: item.value,
                    label: item.label,
                }));
            setAgeBandOptions(filteredAgeBand);
        } catch (error) {
            setAgeBandOptions([]);
        }
    };

    const handleSubHeadingChange = async (selectedOption: any, field: any) => {
        field.onChange(selectedOption?.value);

        // Reset subHeading field
        setValue('subHeadingId2', 'undefined');
        setValue('ageBandId', 'undefined');

        // Filter subHeading2 based on selected subHeading
        try {
            const filteredSubHeading2 = subHeading2
                .filter((item: any) => item.subHeadingId === selectedOption?.value)
                .map((item: any) => ({
                    value: item.value,
                    label: item.label,
                }));
            setSubHeading2Options(filteredSubHeading2);
        } catch (error) {
            setSubHeading2Options([]);
        }
    };

    // Handle form submission for both create and update
    const onSubmit = async (data: Eyfs) => {

        setIsLoading(true);
        try {
            setTimeout(async () => {
                if (selectedEyfs.id) {
                    // Update Price Brand
                    console.log('data: ', data);
                    let modifiedData = commonKeysObject(data, {
                        headingId: 'heading',
                        subHeadingId: 'subHeadingId',
                        subHeadingId2: 'subHeadingId2',
                        ageBandId: 'ageBand',
                        name: 'name',
                        description: 'content',
                        status: 'status',
                        id: 'id',
                    });

                    modifiedData.ageBandId = modifiedData.ageBandId === 'undefined' ? null : modifiedData.ageBandId || '';
                    modifiedData.subHeadingId = modifiedData.subHeadingId === 'undefined' ? null : modifiedData.subHeadingId || '';
                    modifiedData.subHeadingId2 = modifiedData.subHeadingId2 === 'undefined' ? null : modifiedData.subHeadingId2 || '';
                    modifiedData.headingId = modifiedData.headingId === 'undefined' ? null : modifiedData.headingId || '';

                    await EyfApi.updateEyfs(selectedEyfs.id, { ...modifiedData });
                    Swal.fire({
                        title: "Success!",
                        text: "Eyfs updated successfully",
                        icon: "success",
                    });
                } else {
                    // Create new Price Brand
                    let modifiedData: any = { ...data };
                    modifiedData.ageBandId = modifiedData.ageBandId === 'undefined' ? null : modifiedData.ageBandId || '';
                    modifiedData.subHeadingId = modifiedData.subHeadingId === 'undefined' ? null : modifiedData.subHeadingId || '';
                    modifiedData.subHeadingId2 = modifiedData.subHeadingId2 === 'undefined' ? null : modifiedData.subHeadingId2 || '';
                    modifiedData.headingId = modifiedData.headingId === 'undefined' ? null : modifiedData.headingId || '';
                    await EyfApi.createEyfs(modifiedData);
                    Swal.fire({
                        title: "Success!",
                        text: "Eyfs created successfully",
                        icon: "success",
                    });

                }
                // Reload the data after successful operation
                fetchEyfs();
                toggleModal();
            }, 2000);
        } catch (error: any) {
            Swal.fire({
                title: "Error!",
                text: error.message || "Something went wrong",
                icon: "error",
            });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2300);
        }
    };

    const handleStatusChange = async (row: any, status: string) => {
        const currentStatus = status === "ACTIVE" ? 0 : 1; // Determine the current status as a string
        const id = row.id;
        try {
            // Call the API to update the status, passing the number value
            await EyfApi.updateEyfs(id, {
                status: currentStatus,
            });
            toggleDropdownOne(id);
            // Update the local state to reflect the status change
            await fetchEyfs();
        } catch (error: any) {
            Swal.fire("Error", error.message || "Failed to update status", "error");
        }
    };

    const columns: TableColumn<any>[] = [
        {
            name: <CustomHeader title="Heading" />,
            selector: (row) => row.headingId,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div>
                    {heading.find(
                        (option: any) => option.value === row.headingId
                    )?.label ?? "N/A"}
                </div>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Sub Heading 1" />,
            selector: (row) => row.subHeadingId,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div>
                    {subHeading.find(
                        (option: any) => option.value === row.subHeadingId
                    )?.label ?? "N/A"}
                </div>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Sub Heading 2" />,
            selector: (row) => row.subHeadingId2,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div>
                    {subHeading2.find(
                        (option: any) => option.value === row.subHeadingId2
                    )?.label ?? "N/A"}
                </div>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Age Band" />,
            selector: (row) => row.ageBandId,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div>
                    {ageBand.find(
                        (option: any) => option.value === row.ageBandId
                    )?.label ?? "N/A"}
                </div>
            ),
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Name" />,
            selector: (row) => row.name,
            style: { maxWidth: "50%" },
            sortable: true,
            center: false,
        },
        {
            name: "Status",
            selector: (row) => (row.status === 0 ? "ACTIVE" : "INACTIVE"),
            style: { maxWidth: "50%" },
            sortable: true,
            center: false,
            cell: (row) => (
                <Status
                    position={row.status === 0 ? "ACTIVE" : "INACTIVE"}
                    color={row.status === 0 ? statusColors.active : statusColors.inactive}
                    row={row}
                    id={row.id}
                    newUI={true}
                    statusList={["ACTIVE", "INACTIVE"]}
                    handleStatusChange={handleStatusChange}
                    toggleDropdownOne={toggleDropdownOne}
                    dropdownOpenOne={dropdownOpenOne}
                />
            ),
        },
        {
            name: <CustomHeader title="Action" />,
            sortable: true,
            center: true,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <UL className="action simple-list flex-row">
                    <LI className="edit">
                        <a
                            className="icon"
                            href="javascript:void(0);"
                            onClick={() => handleEditHeading(row)}
                        >
                            <Btn color="primary" size="sm">
                                <i
                                    className="fa-solid fa-pencil text-white me-1"
                                    style={{ fontSize: "12px" }}
                                ></i>
                                {"Edit"}
                            </Btn>
                        </a>
                    </LI>
                </UL>
            ),
        },
    ];

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ borderBottom: "5px solid #eee" }}
                            >
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`View All Reference Material`}
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                                <Button
                                    color="primary"
                                    className="btn-md m-4"
                                    onClick={() => {
                                        toggleModal();
                                    }}
                                >
                                    Add New Reference Material
                                </Button>
                            </div>
                            <CardBody>
                                <Row className="justify-content-end">
                                    <Col md={4}>
                                        <FilterComponent
                                            onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFilterText(e.target.value)
                                            }
                                            filterText={filterText}
                                        />
                                    </Col>
                                </Row>

                                <div className="table-responsive">
                                    <DataTable
                                        data={filteredItems}
                                        columns={columns}
                                        pagination
                                        className="display"
                                        conditionalRowStyles={[
                                            {
                                                when: (row) => true, // This applies to all rows
                                                style: {
                                                    "&:hover": {
                                                        backgroundColor: "rgba(48, 142, 135, 0.2)",
                                                        cursor: "pointer",
                                                    },
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Modal for Adding Price Brand */}
            <Modal isOpen={isModalOpen} toggle={() => toggleModal()} backdrop="static">
                <ModalHeader toggle={() => toggleModal()}>
                    {selectedEyfs.id ? "Edit Reference Material" : "Add Reference Material"}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label>
                                {"Heading"}{" "}
                                <span className="text-danger">*</span>
                            </Label>
                            <Controller
                                name="headingId"
                                control={control}
                                rules={{
                                    required: "Please select any one heading",
                                }}
                                render={({ field }) => {


                                    return (

                                        <Select
                                            {...field}
                                            id="headingId"
                                            className={`${errors.headingId ? "is-invalid" : ""}`}
                                            options={heading}
                                            placeholder="Select Heading"
                                            value={heading.find(
                                                (option: any) => option.value === (field.value || selectedEyfs.headingId)
                                            ) ?? null}
                                            onChange={(selectedOption) => handleHeadingChange(selectedOption, field)}
                                        />
                                    )
                                }}
                            />
                            {errors.headingId && errors.headingId.message && (
                                <FormFeedback style={{ display: "block", color: "#dc3545" }}>
                                    <>{errors?.headingId?.message}</>
                                </FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                {"Sub Heading 1"}{" "}

                            </Label>
                            <Controller
                                name="subHeadingId"
                                control={control}
                                rules={{
                                    required: false,
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id="subHeadingId"
                                        className={`${errors.subHeadingId ? "is-invalid" : ""}`}
                                        options={subHeadingOptions}
                                        placeholder="Select Sub Heading 1"
                                        value={subHeadingOptions.find(
                                            (option: any) => option.value === (field.value || selectedEyfs.subHeadingId)
                                        ) ?? null}
                                        onChange={(selectedOption) => handleSubHeadingChange(selectedOption, field)}
                                    />
                                )}
                            />
                            {errors.subHeadingId && errors.subHeadingId.message && (
                                <FormFeedback tooltip style={{ display: "block" }}>
                                    <>{errors?.subHeadingId?.message}</>
                                </FormFeedback>
                            )}
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                {"Sub Heading 2"}{" "}

                            </Label>
                            <Controller
                                name="subHeadingId2"
                                control={control}
                                rules={{
                                    required: false,
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id="subHeadingId2"
                                        className={`${errors.subHeadingId2 ? "is-invalid" : ""}`}
                                        options={subHeading2Options}
                                        placeholder="Select Sub Heading 2"
                                        value={subHeading2Options.find(
                                            (option: any) => option.value === (field.value || selectedEyfs.subHeadingId2)
                                        ) ?? null}
                                        onChange={(selectedOption) => handleSubHeading2Change(selectedOption, field)}
                                    />
                                )}
                            />
                            {errors.subHeadingId2 && errors.subHeadingId2.message && (
                                <FormFeedback style={{ display: "block", color: "#dc3545" }}>
                                    <>{errors?.subHeadingId2?.message}</>
                                </FormFeedback>
                            )}
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                {"Age Band"}{" "}

                            </Label>
                            <Controller
                                name="ageBandId"
                                control={control}
                                rules={{
                                    required: false,
                                }}
                                render={({ field }) => {
                                    console.log('field: ', field);
                                    return (
                                        <Select
                                            {...field}
                                            id="ageBandId"
                                            className={`${errors.ageBandId ? "is-invalid" : ""}`}
                                            options={ageBand}
                                            placeholder="Select Age Band"
                                            value={ageBand.find(
                                                (option: any) => option.value === (field.value || selectedEyfs.ageBandId)
                                            ) ?? null}
                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                        />
                                    )
                                }}
                            />
                            {errors.ageBandId && errors.ageBandId.message && (
                                <FormFeedback style={{ display: "block", color: "#dc3545" }}>
                                    <>{errors?.ageBandId?.message}</>
                                </FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="title">
                                Name <span className="text-danger">*</span>
                            </Label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? "is-invalid" : ""
                                    }`}
                                id="name"
                                {...register("name", {
                                    required: "Heading Name is required",
                                })}
                                defaultValue={selectedEyfs.name}
                            />
                            {errors.name && (
                                <FormFeedback>{errors.name.message}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">
                                Description <span className="text-danger">*</span>
                            </Label>
                            <textarea
                                className={`form-control ${errors.description ? "is-invalid" : ""
                                    }`}
                                {...register("description", {
                                    required: "Description is required",
                                })}
                                defaultValue={selectedEyfs.description}
                            />
                            {errors.description && (
                                <FormFeedback>{errors.description.message}</FormFeedback>
                            )}
                        </FormGroup>

                        <ModalFooter>
                            <Button color="primary" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <span className="d-flex gap-2">
                                        <Spinner color="white" size="sm" /> Loading...
                                    </span>
                                ) : (
                                    <>
                                        {selectedEyfs.id ? "Update Reference Material" : "Add Reference Material"}
                                    </>
                                )}
                            </Button>
                            <Button color="secondary" onClick={() => toggleModal()}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default connectComponent(Eyfs);
