import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { flattenDeep, forEach, isArray, isObject } from "lodash";
import { flattenObject, getDataFromArray } from "../../../Utils";
import { connectComponent } from "./connector";
import Swal from "sweetalert2";
import SuperAdminApi from "../../../services/SuperAdminApi";

const SuperAdmin = (props: any) => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [saveData, setSaveData] = useState([]);
  const [superAdmins, setSuperAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const filteredItems: any = saveData.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const [filterObj, setFilterObj] = useState({
    searchText: "",
    status: "",
    startDate: "",
    endDate: "",
    pageNo: 1,
    pageSize: 100,
  });

  useEffect(() => {
    const fetchSuperAdmin = async () => {
      try {
        const response = await SuperAdminApi.getSuperAdmins();
        if (response.success && Array.isArray(response.data.admins)) {
          setSuperAdmins(response.data.admins);
        } else {
          throw new Error("Expected an array of Super Admin");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching Super Admin");
      } finally {
        setLoading(false);
      }
    };

    fetchSuperAdmin();
  }, []);

  useEffect(() => {
    let data: any = [...superAdmins].sort(
      (a: any, b: any) => b.joinDate - a.joinDate
    );
    data = data.map((row: any) => {
      return flattenObject(row);
    });

    setSaveData(data);
  }, [superAdmins]);

  const handleDetailPage = (id: number | string) => {
    navigate(`${process.env.PUBLIC_URL}/dashboard/admin/edit/${id}`);
  };

  const handleStatusChange = async (row: any) => {
    const currentStatus = row?.isActive === true ? false : true;
    const id = row.id;

    try {
      // Call the API to update the status, passing the boolean value
      await SuperAdminApi.updateSuperAdmin(row.id, {
        isActive: currentStatus,
      });

      // Update the local state to reflect the status change
      setSuperAdmins((prevSuperAdmin: any) =>
        prevSuperAdmin.map((superAdmin: any) =>
          superAdmin.id === row.id
            ? { ...superAdmin, isActive: currentStatus }
            : superAdmin
        )
      );
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  useEffect(() => {
    if (props.error && props.error?.message) {
      Swal.fire({
        title: "Error !!!",
        text: props.error.message,
        icon: "error",
      });

      // Clear the error message after displaying it
      props.setMessage({ success: {}, error: {} });
    }
  }, [props.success, props.error, navigate]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Super Admins Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/admin/add`);
                  }}
                >
                  Add New User
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <div className="table-responsive">
                  {useMemo(() => {
                    return (
                      <>
                        <DataTable
                          data={filteredItems}
                          columns={columns({
                            handleDetailPage: (id: number | string) =>
                              handleDetailPage(id),
                            handleStatusChange: (row: any) =>
                              handleStatusChange(row),
                          })}
                          pagination
                          className="display"
                          conditionalRowStyles={[
                            {
                              when: (row) => true, // This applies to all rows
                              style: {
                                "&:hover": {
                                  backgroundColor: "rgba(48, 142, 135, 0.2)",
                                  cursor: "pointer",
                                },
                              },
                            },
                          ]}
                        />
                      </>
                    );
                  }, [loading, props.success, saveData, superAdmins])}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(SuperAdmin);
