import { TableColumn } from "react-data-table-component";
import { Badges, Btn, LI, UL } from "../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";

const CustomHeader = ({ title, style }: { title: string; style?: any }) => (
  <span
    style={{
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "14px",
      ...style,
    }}
  >
    {title}
  </span>
);

export const columns = (
  tab: string,
  handleViewFolder: (row: any) => void,
  downloadFile: (row: any) => void,
  selectedFolder: any,
  handleViewPersonFolder: (row: any) => void,
  viewFiles: string
) => {
  const empColumns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      right: true,
      style: { maxWidth: "50%" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="view">
            <a
              className="icon"
              href={`javascript:void(0);`}
              onClick={() => handleViewPersonFolder(row)}
            >
              <Btn color="transparent" size="sm" className=" mr-2">
                <i className="fa-solid fa-upload me-1"></i>
                {"View all folders"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  const childColumns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: (
        <CustomHeader
          title="Action"
          style={{
            justifyContent: "flex-end",
            width: "100%",
            display: "flex",
            paddingRight: "2rem",
          }}
        />
      ),
      sortable: true,
      right: true,
      style: { maxWidth: "50%" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="view">
            <a
              className="icon"
              href={`javascript:void(0);`}
              onClick={() => handleViewPersonFolder(row)}
            >
              <Btn color="transparent" size="sm" className=" mr-2">
                <i className="fa-solid fa-upload me-1"></i>
                {"View all folders"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  const compColumns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Folder Name" />,
      selector: (row) => `${row.folderName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: (
        <CustomHeader
          title="Action"
          style={{
            justifyContent: "flex-end",
            width: "100%",
            display: "flex",
            paddingRight: "2rem",
          }}
        />
      ),
      sortable: true,
      right: true,
      style: { paddingLeft: "6px", paddingRight: "6px", justifyContent: "end" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="view">
            <a
              className="icon"
              href={`javascript:void(0);`}
              onClick={() => handleViewFolder(row)}
            >
              <Btn color="transparent" size="sm" className=" mr-2">
                <i className="fa-solid fa-upload me-1"></i>
                {"View all documents"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  const columnFolders: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Folder Name" />,
      selector: (row) => `${row.folderName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: (
        <CustomHeader
          title="Action"
          style={{
            justifyContent: "flex-end",
            width: "100%",
            display: "flex",
            paddingRight: "2rem",
          }}
        />
      ),
      sortable: true,
      center: true,
      style: { paddingLeft: "6px", paddingRight: "6px", justifyContent: "end" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="view">
            <a
              className="icon"
              href={`javascript:void(0);`}
              onClick={() => handleViewFolder(row)}
            >
              <Btn color="transparent" size="sm" className=" mr-2">
                <i className="fa-solid fa-upload me-1"></i>
                {"View all documents"}
              </Btn>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  const columnsFiles: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => `${row.fileName}`,
      style: {
        minWidth: "420px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "420px", // Set consistent width
      maxWidth: "420px",
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Size" />,
      selector: (row) => `${row.size}`,
      style: {
        minWidth: "100px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "100px", // Set consistent width
      maxWidth: "100px",
      sortable: true,
      center: false,
    },
    {
      name: (
        <CustomHeader
          title="Action"
          style={{
            justifyContent: "flex-end",
            width: "100%",
            display: "flex",
            paddingRight: "2rem",
          }}
        />
      ),
      sortable: true,
      center: true,
      style: { justifyContent: "end", paddingRight: "3.5rem" },
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="view">
            <a
              href={`javascript:void(0);`}
              onClick={() => downloadFile(row)}
              className="btn btn-primary d-flex justify-content-center align-items-center"
              style={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                marginRight: 6,
              }}
            >
              <i className="icon-download text-white" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  const columns: any = {
    EMPLOYEE: empColumns,
    CHILDREN: childColumns,
    COMPANY: compColumns,
  };

  if (tab === "COMPANY" && Object.keys(selectedFolder).length > 0) {
    return columnsFiles;
  }

  if ((tab === "CHILDREN" || tab === "EMPLOYEE") && viewFiles) {
    if (Object.keys(selectedFolder).length > 0) {
      return columnsFiles;
    } else {
      return columnFolders;
    }
  }

  return columns[tab];
};

export const columnsDetail: TableColumn<any>[] = [
  {
    name: <CustomHeader title="Name" />,
    selector: (row) => row.title,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="Size" />,
    selector: (row) => row.billing,
    style: { maxWidth: "50%" },
    sortable: true,
    center: false,
  },
  {
    name: <CustomHeader title="Action" />,
    sortable: true,
    center: true,
    style: { maxWidth: "50%" },
    cell: (row) => (
      <UL className="action simple-list flex-row">
        <LI className="edit">
          <a
            href={`/dashboard/documents/all/view/${row.id}`}
            className="btn btn-info d-flex justify-content-center align-items-center"
            style={{ borderRadius: "50%", width: "40px", height: "40px" }}
          >
            <i className="fa-solid fa-download text-light"></i>
          </a>
        </LI>
        <LI className="edit">
          <a
            href={`/dashboard/documents/all/edit/${row.id}`}
            className="btn btn-light d-flex justify-content-center align-items-center"
            style={{ borderRadius: "50%", width: "40px", height: "40px" }}
          >
            <i className="icon-pencil-alt text-dark" />
          </a>
        </LI>
      </UL>
    ),
  },
];
