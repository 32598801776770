import React from "react";
import { FormFeedback, Label } from "reactstrap";
import Select from "react-select";
import {
  DropItemChangeProp,
  DropItemProps,
  Option,
} from "../../../Types/Forms.type";
import { LooksGood } from "../../../Utils/Constants";
import { options } from "../../../Data/Forms/FormControls";

export default function DropItem({
  selectedOption,
  setSelectedOption,
  validate,
  tip,
  colClass,
  className,
  id,
  title,
  options,
}: DropItemProps) {
  const handleChange: DropItemChangeProp = (newValue, actionMeta) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear"
    ) {
      const newSelectedOption = newValue as Option;
      setSelectedOption(newSelectedOption?.value || null);
    }
  };
  return (
    <div className={colClass}>
      {title && (
        <Label htmlFor={id}>
          {title}
          {validate && (
            <>
              {""} <span className="text-danger">*</span>
            </>
          )}
        </Label>
      )}
      <Select
        id={id}
        options={options}
        value={options.find((option: any) => option.value === selectedOption)}
        onChange={handleChange}
        className={`${id}-form`}
      />
      {validate && !selectedOption && (
        <FormFeedback tooltip={tip} style={{ display: "block" }}>
          {`Please select a valid ${title}.`}
        </FormFeedback>
      )}
      {validate && selectedOption && (
        <FormFeedback tooltip={tip} valid style={{ display: "block" }}>
          {LooksGood}
        </FormFeedback>
      )}
    </div>
  );
}
