import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Tooltip } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectComponent } from "./connector";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Btn, LI, UL } from "src/AbstractElements";
import DatePicker from "react-datepicker";
import Select from "react-select";
import React from "react";
import DropDownAPi from "src/services/DropdownApi";
import HolidayAPi from "src/services/HolidaysApi";
import { commonKeysObject } from "src/Utils";
import moment from "moment";

const CustomHeader = ({ title, className }: any) => (
  <span
    className={className ? className : ''} // Apply className if provided
    style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}
  >
    {title}
  </span>
);

interface Holidays {
  id: string;
  name: string;
  description: string;
  deadline: any;
  whoHasTo: any;
  periodStart: any;
  periodEnd: any;
  nurseryId: string;
}

function Holidays(props: any) {
  const initialForm: Holidays = {
    periodStart: null,
    periodEnd: null,
    id: "",
    name: "",
    description: "",
    deadline: null,
    whoHasTo: null,
    nurseryId: props.nurseryId,
  };
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [holidays, setHolidays] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [whoHasToOptions, setWhoHasToOptions] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState<any>({});
  const toggleTooltip = (id: any) => {
    setTooltipOpen((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const [form, setForm] = useState<Holidays>(initialForm);

  const saveDataForm = (key: string, value: any) => {
    setForm((prevForm) => ({
      ...prevForm,
      [key]: value,
    }));
  };

  const filteredItems: any = holidays.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const fetchHolidays = async () => {
    try {
      const response = await HolidayAPi.fetchHoliday({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.data)) {
        setHolidays(response.data);
      } else setHolidays([]);
    } catch (error: any) {
      setHolidays([]);
    }
  };

  const fetchClassRoomsOptions = async () => {
    try {
      const response = await DropDownAPi.getClassRoomsOptions(
        props.nurseryId || ""
      );

      if (response.success && response?.data?.length > 0) {
        const roomNameOptions = response?.data?.map((item: any) => ({
          label: item.roomName,
          value: item.id,
        }));
        setWhoHasToOptions(roomNameOptions);
      } else {
        setWhoHasToOptions([]);
      }
    } catch (error: any) {
      setWhoHasToOptions([]);
    }
  };



  useEffect(() => {
    fetchClassRoomsOptions();
    fetchHolidays();
  }, [props.nurseryId]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Holidays>();
  console.log('errors: ', errors);

  const toggleModal = (holiday?: Holidays) => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      setForm(initialForm); // Clear selected Price Brand
    } else {
      // If opening modal, reset with selected Price Brand data for edit, otherwise with empty data for add
      reset(holiday || initialForm);
      setForm(holiday || initialForm);
    }
  };

  const handleEditHolidays = (holiday: Holidays) => {
    const holidayData: any = commonKeysObject(holiday, form);
    console.log('holidayData: ', holidayData, holiday);
    toggleModal(holidayData);
  };

  // Handle form submission for both create and update
  const onSubmit = async () => {
    console.log(form);
    setIsLoading(true);
    try {
      setTimeout(async () => {
        if (form.id) {
          // Update Price Brand
          await HolidayAPi.updateHoliday(form.id, { ...form });
          Swal.fire({
            title: "Success!",
            text: "Holiday updated successfully",
            icon: "success",
          });
        } else {
          // Create new Price Brand
          await HolidayAPi.createHoliday({ ...form, nurseryId: props.nurseryId, id: undefined });
          Swal.fire({
            title: "Success!",
            text: "Holiday created successfully",
            icon: "success",
          });

        }
        // Reload the data after successful operation
        fetchHolidays();
        toggleModal();
      }, 2000);
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2300);
    }
  };

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await HolidayAPi.deleteHoliday(id); // API call to delete the comment
          fetchHolidays();
          Swal.fire("Deleted!", "Holiday has been deleted.", "success");
        } catch (error: any) {
          Swal.fire(
            "Error!",
            error.message || "Failed to delete the comment.",
            "error"
          );
        }
      }
    });
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name of the Holiday" />,
      selector: (row) => row.name,
      style: { maxWidth: "60%" },
      sortable: true,
      center: true,
    },
    {
      name: <CustomHeader title="Deadline for replies" />,
      selector: (row) => row.deadline ? moment(row.deadline).format('DD-MM-YYYY') : '',
      style: { maxWidth: "50%" },
      sortable: true,
      center: true,
    },
    {
      name: <CustomHeader title="Who has to reply" />,
      selector: (row) => row?.room?.roomName ? row?.room?.roomName : '',
      style: { maxWidth: "50%" },
      sortable: true,
      center: true,
    },
    {
      name: <CustomHeader title="Period Start" />,
      selector: (row) => row.periodStart ? moment(row.periodStart).format('DD-MM-YYYY') : '',
      style: { maxWidth: "50%" },
      sortable: false,
      center: true,
    },
    {
      name: <CustomHeader title="Period End" />,
      selector: (row) => row.periodEnd ? moment(row.periodEnd).format('DD-MM-YYYY') : '',
      style: { maxWidth: "50%" },
      sortable: false,
      center: true,
    },
    {
      name: <CustomHeader title="Action" className="justify-content-end w-70" />,
      sortable: false,
      center: true,
      style: {
        minWidth: "120px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",

      cell: (row) => (
        <UL
          className="action simple-list w-100 d-flex justify-content-end"
          style={{ flexDirection: "row" }}
        >
          <LI className="edit" style={{ marginRight: "0.5rem" }}>
            <a
              href="javascript:void(0);"
              onClick={() => handleEditHolidays(row)}
              className="icon"
            >
              <Btn color="primary" size="sm" id={`editTooltip_${row.id}`}>
                <i
                  className="fa-solid fa-pencil me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
              </Btn>
              <Tooltip
                isOpen={tooltipOpen[`editTooltip_${row.id}`]}
                toggle={() => toggleTooltip(`editTooltip_${row.id}`)}
                target={`editTooltip_${row.id}`}
              >
                Edit
              </Tooltip>
            </a>
          </LI>
          <LI className="delete">
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                e.preventDefault(); // Prevent page refresh
                handleDelete(row?.id); // Call your delete handler
              }}
              className="icon"
            >
              <Btn color="danger" size="sm" id={`deleteTooltip_${row.id}`}>
                <i
                  className="fa-solid fa-trash me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
              </Btn>
              <Tooltip
                isOpen={tooltipOpen[`deleteTooltip_${row.id}`]}
                toggle={() => toggleTooltip(`deleteTooltip_${row.id}`)}
                target={`deleteTooltip_${row.id}`}
              >
                Delete
              </Tooltip>
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Holidays`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => toggleModal()}
                >
                  Add New Holidays
                </Button>
              </div>
              <CardBody>
                <Row className="justify-content-end">
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>

                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Modal for Adding Price Brand */}
      <Modal isOpen={isModalOpen} toggle={() => toggleModal()} backdrop="static">
        <ModalHeader toggle={() => toggleModal()}>
          {form.id ? "Edit Holiday RSVPS" : "Add Holiday RSVPS"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="name">
                Name of the Holiday <span className="text-danger">*</span>
              </Label>
              <input
                type="text"
                className={`form-control ${errors.name ? "is-invalid" : ""
                  }`}
                id="name"
                {...register("name", {
                  required: "Name is required",
                })}
                onChange={(e) => {
                  saveDataForm("name", e.target.value);
                }}
                defaultValue={form.name}
                placeholder="Name of the Holiday"
              />
              {errors.name && (
                <FormFeedback>{errors.name.message}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="description">
                Description
              </Label>
              <Input
                type="textarea"
                id="description"
                {...register("description")}
                placeholder="Description"
                onChange={(e) => {
                  saveDataForm("description", e.target.value);
                }}
                defaultValue={form.description}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Deadline for replies
              </Label>
              <InputGroup className="flatpicker-calender">
                <Controller
                  control={control}
                  name="deadline"
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        className={`form-control`}
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          saveDataForm("deadline", date);
                        }}
                        placeholderText="Select a date"
                        dateFormat="MMMM dd,yyyy"
                      />
                    );
                  }}
                />
                <InputGroupText className="list-light-primary custom-calender-icon">
                  <i className="fa-solid fa-calendar text-primary" />
                </InputGroupText>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>
                Who has to reply
              </Label>
              <Controller
                name="whoHasTo"
                control={control}
                rules={{
                  required: false,
                }} // Validation rules
                render={({ field }) => (
                  <Select
                    {...field}
                    id="whoHasTo"
                    options={whoHasToOptions}
                    value={whoHasToOptions.find(
                      (option: any) =>
                        option.value === form.whoHasTo
                    ) ?? ""} // Set current value
                    onChange={(selectedOption: any) => {
                      field.onChange(selectedOption?.value);
                      saveDataForm("whoHasTo", selectedOption?.value);
                    }} // Update the value using Controller's onChange
                  />
                )}
              />
            </FormGroup>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Period Start
                  </Label>
                  <InputGroup className="flatpicker-calender">
                    <Controller
                      control={control}
                      name="periodStart"
                      rules={{
                        required: false,
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            className={`form-control`}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("periodStart", date);
                            }}
                            placeholderText="Select a start date"
                            dateFormat="MMMM dd,yyyy"
                          />
                        );
                      }}
                    />
                    <InputGroupText className="list-light-primary custom-calender-icon">
                      <i className="fa-solid fa-calendar text-primary" />
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Period End
                  </Label>
                  <InputGroup className="flatpicker-calender">
                    <Controller
                      control={control}
                      name="periodEnd"
                      rules={{
                        required: false,
                        validate: (value) =>
                          !form.periodStart ||
                          value >= form.periodStart ||
                          "End date cannot be before start date.",
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            className={`form-control ${errors.periodEnd ? "is-invalid" : ""
                              }`}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("periodEnd", date);
                            }}
                            placeholderText="Select a end date"
                            dateFormat="MMMM dd,yyyy"
                          />
                        );
                      }}
                    />
                    <InputGroupText className="list-light-primary custom-calender-icon">
                      <i className="fa-solid fa-calendar text-primary" />
                    </InputGroupText>
                  </InputGroup>
                  {errors.periodEnd && (
                    <FormFeedback style={{ color: '#dc3545', display: 'block' }}>{`${errors.periodEnd.message}`}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <ModalFooter>
              <Button color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  <>
                    {form.id ? "Update Holiday RSVPS" : "Add Holiday RSVPS"}
                  </>
                )}
              </Button>
              <Button color="secondary" onClick={() => toggleModal()}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default connectComponent(Holidays);
