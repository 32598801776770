import api from "./api"; // Use the Axios instance with interceptor

export default class EyfApi {
  // Fetch all EYFS records with optional pagination
  static async getAll(params: {
    limit?: number;
    offset?: number;
    ageRange?: string;
  }) {
    try {
      const response = await api.get("/efys", { params });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Fetch a specific EYFS record by ID
  static async getById(id: string) {
    try {
      const response = await api.get(`/efys/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Create a new EYFS record
  static async create(data: any) {
    try {
      const response = await api.post("/efys", data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Update an existing EYFS record by ID
  static async update(id: string, data: any) {
    try {
      const response = await api.patch(`/efys/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Delete an EYFS record by ID
  static async delete(id: string) {
    try {
      const response = await api.delete(`/efys/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Heading
  static async createHeading(data: any) {
    try {
      const response = await api.post("/efys/headings", data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateHeading(id: string, data: any) {
    try {
      const response = await api.patch(`/efys/headings/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getHeadings() {
    try {
      const response = await api.get("/efys/headings");
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Sub Heading
  static async createSubHeading(data: any) {
    try {
      const response = await api.post("/efys/sub-heading", data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateSubHeading(id: string, data: any) {
    try {
      const response = await api.patch(`/efys/sub-heading/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getSubHeadings() {
    try {
      const response = await api.get("/efys/sub-heading");
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Sub Heading 2
  static async createSubHeading2(data: any) {
    try {
      const response = await api.post("/efys/sub-heading-2", data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateSubHeading2(id: string, data: any) {
    try {
      const response = await api.patch(`/efys/sub-heading-2/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getSubHeadings2() {
    try {
      const response = await api.get("/efys/sub-heading-2");
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Age Band
  static async createAgeBand(data: any) {
    try {
      const response = await api.post("/efys/age-band", data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateAgeBand(id: string, data: any) {
    try {
      const response = await api.patch(`/efys/age-band/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getAgeBands() {
    try {
      const response = await api.get("/efys/age-band");
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // EYFS
  static async createEyfs(data: any) {
    try {
      const response = await api.post("/efys", data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getEyfs() {
    try {
      const response = await api.get("/efys");
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateEyfs(id: string, data: any) {
    try {
      const response = await api.patch(`/efys/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

}
