import React, { useState, useEffect, useRef, FormEvent } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Input,
    InputGroup,
    Button,
    DropdownToggle,
    DropdownItem,
    Dropdown,
    DropdownMenu,
    Form,
    TabContent,
    Nav,
    NavItem,
    TabPane,
    NavLink,
    InputGroupText,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import './Chat.css';
import ConversationApi from 'src/services/ConversationApi';
import { Href } from 'src/Utils/Constants';
import { Badges, Btn, Image, LI, P, UL } from 'src/AbstractElements';
import { dynamicImage, fetchImage } from 'src/Utils';
import { MoreVertical, Search } from 'react-feather/dist';
import EmojiPicker from 'emoji-picker-react/dist';
import DashboardSvgIcon from 'src/Utils/CommonComponents/DashboardSvgIcon';
import UserApi from 'src/services/UserApi';
import { connectComponent } from './connector';
import { baseUrl } from 'src/Utils/Constants/config';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

interface SelectedConversation {
    id: string;
    type: string;
    receiver: Receiver;
}

interface Receiver {
    id: string;
    lastName: string;
    avatarUrl: string;
    firstName: string;
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    type: string;
}

interface Message {
    id: string;
    fromUserId: string;
    toUserId: string;
    createdAt: string;
    updatedAt: string;
    content: string;
    contentType: string;
    conversationId: string
}

const ChatComponent: React.FC = (props: any) => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(1);
    const [showEmployeeModal, setShowEmployeeModal] = useState(false);
    const [employeeSearchKeyword, setEmployeeSearchKeyword] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [dropdown, setDropdown] = useState(false);
    const [messageInput, setMessageInput] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [employeeList, setEmployeeList] = useState<User[]>([]);
    const [parentList, setParentList] = useState<User[]>([]);
    const [userList, setUserList] = useState<User[]>([]);
    const [conversationList, setConversationList] = useState<SelectedConversation[]>([]);
    const [selectedChat, setSelectedChat] = useState<Message[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<SelectedConversation | null>(null);

    const bottomRef = useRef<null | HTMLDivElement>(null);

    const fetchEmployees = async () => {
        try {
            const response = await UserApi.getAllEmployees({
                searchText: "",
                status: "all-status",
                nurseryId: props.nurseryId,
                jobTitleId: "all-job",
                pageNo: 1,
                pageSize: 10000,
            });
            if (response.success && Array.isArray(response.data)) {
                const dataWithImage = await Promise.all(
                    response.data.map(async (row: any) => ({
                        id: row.id,
                        firstName: row.firstName,
                        lastName: row.lastName,
                        avatarUrl: row.avatarUrl ? await fetchImage(baseUrl + row.avatarUrl) : "",
                        type: 'employee'
                    }))
                );
                setEmployeeList(dataWithImage);
            }
        } catch (error) {
            setEmployeeList([]);
        }
    };

    const fetchParents = async () => {
        try {
            const response = await UserApi.getAllFamilies({ nurseryId: props.nurseryId });
            if (response.success && Array.isArray(response.family)) {
                const dataWithImage = await Promise.all(
                    response.family.map(async (row: any) => ({
                        id: row.parent?.[0]?.id,
                        firstName: row.firstName,
                        middleName: row.middleName,
                        lastName: row.lastName,
                        avatarUrl: row.parent?.[0]?.avatarUrl ? await fetchImage(baseUrl + row.parent[0].avatarUrl) : null,
                        type: 'parent'
                    }))
                );
                setParentList(dataWithImage);
            }
        } catch (error) {
            setParentList([]);
        }
    };

    const fetchConversation = async () => {
        try {
            const response = await ConversationApi.getConversations(props.user.id);
            if (response.success && Array.isArray(response.data.list)) {
                const dataWithImage: any = await Promise.all(
                    response.data.list.map(async (row: any) => ({
                        ...row,
                        receiver: {
                            ...row.receiver,
                            avatarUrl: row.receiver.avatarUrl ? await fetchImage(baseUrl + row.receiver.avatarUrl) : ""
                        }
                    }))
                );
                console.log('dataWithImage: ', dataWithImage);
                setConversationList(dataWithImage);
            }
        } catch (error) {
            setConversationList([]);
        }
    };

    const fetchMessages = async (conversationId: string) => {
        try {
            setIsLoading(true);
            const response = await ConversationApi.getMessages(conversationId);
            if (response.success && Array.isArray(response.data.totalMessages)) {
                setSelectedChat(response.data.totalMessages);
            }
        } catch (error) {
            setSelectedChat([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUserList = (tab: number) => {
        setUserList(tab === 1 ? employeeList : parentList);
        setActiveTab(tab);
        setSelectedConversation(null);
    };

    const handleMessagePress = async () => {
        if (messageInput.trim() === '') {
            toast.error('Please enter a message');
            return;
        }

        const response = await ConversationApi.createMessage({
            conversationId: selectedConversation?.id || '',
            content: messageInput,
            contentType: 'text',
            toUserId: selectedConversation?.receiver?.id
        });

        if (response.success) {
            toast.success('Message sent successfully');
            fetchConversation();
            setMessageInput('');
            fetchMessages(selectedConversation?.id || '');
        }
    };

    const startConversation = async (employeeId: string, employeeType: string) => {
        const senderId = props.user.id;
        const conversation = await ConversationApi.checkConversationExists(employeeId, senderId, props.nurseryId);

        if (conversation.success && !conversation.conversation) {
            const conversationData = await ConversationApi.createConversation({
                userOneId: senderId,
                userTwoId: employeeId,
                type: employeeType,
                nurseryId: props.nurseryId,
            });
            if (conversationData.success) {
                setSelectedConversation(conversationData.conversation[0]);
            }
        } else {
            setSelectedConversation(conversation.conversation[0]);
        }

        setShowEmployeeModal(false);
        fetchConversation();
    };

    useEffect(() => {
        fetchEmployees();
        fetchParents();
        fetchConversation();
    }, [props.nurseryId]);

    useEffect(() => {
        setUserList(activeTab === 1 ? employeeList : parentList);
    }, [employeeList, parentList, activeTab]);

    useEffect(() => {
        if (selectedConversation) {
            fetchMessages(selectedConversation.id);
        }
    }, [selectedConversation]);

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedChat]);

    useEffect(() => {
        if (id) {
            const conversation = conversationList.find(conversation => conversation.id === id);
            console.log('conversation: ', conversation);
            if (conversation) {
                setSelectedConversation(conversation);
            }
        }
    }, [id, conversationList]);

    const filteredEmployees = userList
        .filter((member: any) => conversationList.every((conversation: any) =>
            conversation.receiver.id !== member.id))
        .filter((member: any) =>
            (`${member.firstName} ${member?.middleName || ''} ${member.lastName}`)
                .toLowerCase()
                .includes(employeeSearchKeyword.toLowerCase())
        );

    const filteredConversation = conversationList.filter(member => (activeTab === 1 ? member.type === 'employee' : member.type === 'parent')).filter((member: any) =>
        (`${member.receiver.firstName} ${member.receiver?.middleName || ''} ${member.receiver.lastName}`)
            .toLowerCase()
            .includes(searchKeyword.toLowerCase()));

    const addEmoji = (emoji: string) => {
        const text = `${messageInput}${emoji}`;
        setShowEmojiPicker(false);
        setMessageInput(text);
    };

    const handleMessageChange = (message: string) => {
        setMessageInput(message);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleMessagePress()
    }

    const handleSearchKeyword = (keyword: string) => {
        setSearchKeyword(keyword);
    };

    const changeChatClick = (selectedConversation: any) => {
        setSelectedConversation(selectedConversation);
    };

    const toggleEmployeeModal = () => {
        setShowEmployeeModal(!showEmployeeModal);
    };

    const allowedUsers = () => {
        if (props.user.role === 'admin') {
            return true;
            // } else if (props.user.role === 'employee') {
            //     return true;
            // } else if (props.user.role === 'parent') {
            //     return true;
        } else {
            return false;
        }
    };

    return (
        <div className='page-body' >
            <Container fluid>
                <Row className='g-0 pt-3 pb-4'>
                    <Col xxl={3} xl={4} md={5} className="box-col-5">
                        <Card className="left-sidebar-wrapper" style={{
                            height: '100%',
                            maxHeight: 'calc(85vh - -53px)'
                        }}>
                            <div className="left-sidebar-chat">
                                <InputGroup>
                                    <InputGroupText>
                                        <Search className="search-icon text-gray" />
                                    </InputGroupText>
                                    <Input type="text" placeholder="Search here.." value={searchKeyword} onChange={(e) => handleSearchKeyword(e.target.value)} style={{ paddingLeft: '22px' }} />
                                </InputGroup>
                            </div>
                            <div className="advance-options">
                                {allowedUsers() && (<Nav className="border-tab">
                                    <NavItem>
                                        <NavLink href={Href} className={activeTab === 1 ? 'active' : ''} onClick={() => handleUserList(1)}>{'Employees'}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href={Href} className={activeTab === 2 ? 'active' : ''} onClick={() => handleUserList(2)}>{'Parents'}</NavLink>
                                    </NavItem>
                                </Nav>
                                )}
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1}>
                                        <>
                                            <div className="common-space">
                                                <P>{'Recent chats'}</P>
                                                <div className="header-top">
                                                    {allowedUsers() && (<Btn color="light-primary badge-light-primary" className="f-w-500" onClick={toggleEmployeeModal}>
                                                        <i className="fa-solid fa-plus" />
                                                    </Btn>)}
                                                </div>
                                            </div>
                                            {filteredConversation && filteredConversation.length > 0 ? (
                                                <UL className="chats-user simple-list" style={{ height: 520 }}>
                                                    {filteredConversation
                                                        .map((item: any, i: number) => {
                                                            return (
                                                                <LI key={i} className={`common-space ${selectedConversation === item.id && 'active'}`} onClick={() => changeChatClick(item)}>
                                                                    <div className='chat-time'>
                                                                        <div className="active-profile">
                                                                            <Image src={item.receiver.avatarUrl || dynamicImage('blog/comment.jpg')} className='img-fluid rounded-circle' alt='' />
                                                                            <div className={`status bg-${item.online === true ? 'success' : 'warning'}`}></div>
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-capitalize text-dark'>{`${item.receiver.firstName} ${item.receiver.middleName || ''} ${item.receiver.lastName}`}</span>
                                                                            <P>{item?.lastMessage || 'No message'}</P>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex flex-column justify-content-center align-items-end'>
                                                                        <P className='text-capitalize text-truncate'>{moment(item.createdAt).fromNow() || 'time'}</P>
                                                                        {item?.lastMessage?.messageType && <Badges color='light-success'>{item?.lastMessage?.messageType}</Badges>}
                                                                    </div>
                                                                </LI>
                                                            );
                                                        })}
                                                </UL>
                                            ) : (<div className="text-center">{'No User Present'}</div>)}
                                        </>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <>
                                            <div className="common-space">
                                                <P>{'Recent chats'}</P>
                                                <div className="header-top">
                                                    <Btn color="light-primary badge-light-primary" className="f-w-500" onClick={toggleEmployeeModal}>
                                                        <i className="fa-solid fa-plus" />
                                                    </Btn>
                                                </div>
                                            </div>
                                            {filteredConversation && filteredConversation.length > 0 ? (
                                                <UL className="chats-user simple-list" style={{ height: 520 }}>
                                                    {filteredConversation
                                                        .map((item: any, i: number) => {
                                                            return (
                                                                <LI key={i} className={`common-space ${selectedConversation === item.id && 'active'}`} onClick={() => changeChatClick(item)}>
                                                                    <div className='chat-time'>
                                                                        <div className="active-profile">
                                                                            <Image src={item.receiver.avatarUrl || dynamicImage('blog/comment.jpg')} className='img-fluid rounded-circle' alt='' />
                                                                            <div className={`status bg-${item.online === true ? 'success' : 'warning'}`}></div>
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-capitalize text-dark'>{`${item.receiver.firstName} ${item.receiver.middleName || ''} ${item.receiver.lastName || ''}`}</span>
                                                                            <P>{item?.lastMessage || 'No message'}</P>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex flex-column justify-content-center align-items-end'>
                                                                        <P className='text-capitalize text-truncate'>{moment(item.createdAt).fromNow() || 'time'}</P>
                                                                        {item?.lastMessage?.messageType && <Badges color='light-success'>{item?.lastMessage?.messageType}</Badges>}
                                                                    </div>
                                                                </LI>
                                                            );
                                                        })}
                                                </UL>
                                            ) : (<div className="text-center">{'No User Present'}</div>)}
                                        </>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Card>
                    </Col>
                    <Col xxl={9} xl={8} md={7} className="box-col-7">
                        <Card className="right-sidebar-chat" style={{
                            height: '100%',
                            maxHeight: 'calc(85vh - -53px)',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {selectedConversation ? (<>
                                <div className="right-sidebar-title">
                                    <div className="common-space">
                                        <div className="chat-time">
                                            <div className="active-profile">
                                                <Image className="img-fluid rounded-circle" src={selectedConversation?.receiver?.avatarUrl || dynamicImage('blog/comment.jpg')} alt="user" />
                                                <div className="status bg-success" />
                                            </div>
                                            <div>
                                                <span>{selectedConversation?.receiver?.firstName || 'No Name'}</span>
                                                <P>{'Online'}</P>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                        </div>
                                    </div>
                                </div>
                                <div className="right-sidebar-Chats" style={{ flex: 1, overflowY: 'hidden' }}>
                                    <div className="msger">
                                        <>
                                            {!isLoading ? (
                                                <div className="msger-chat" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {selectedChat && selectedChat.length > 0 ? (
                                                        [...selectedChat].reverse().map((item: any, index: number) => {
                                                            let name = "";
                                                            let avatar = "";
                                                            if (item.fromUserId === props.user.id) {
                                                                name = `${props.user.firstName} ${props.user.lastName}`;
                                                                avatar = props.user.avatarUrl || dynamicImage('blog/comment.jpg');
                                                            } else {
                                                                name = `${selectedConversation.receiver?.firstName} ${selectedConversation.receiver?.lastName}`;
                                                                avatar = selectedConversation.receiver?.avatarUrl || dynamicImage('blog/comment.jpg');
                                                            }

                                                            return (
                                                                <div className={`msg ${item.fromUserId === props.user.id ? "right" : "left"}-msg`} key={index}>
                                                                    <div className='msg-img'>
                                                                        <Image src={avatar} className={`rounded-circle ${item.fromUserId !== props.user.id ? 'float-start' : 'float-end'} chat-user-img img-30 h-auto`} alt='' />
                                                                    </div>
                                                                    <div className="msg-bubble">
                                                                        <div className="msg-info">
                                                                            <div className="msg-info-name">{name}</div>
                                                                            <div className="msg-info-time">{moment(item.createdAt).fromNow()}</div>
                                                                        </div>
                                                                        <div className="msg-text">{item.content}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (<div className="text-center p-4">
                                                        <p className="text-muted">No messages yet. Start a conversation!</p>
                                                    </div>)}
                                                    <div ref={bottomRef} style={{ marginTop: 'auto' }}></div>
                                                </div>
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                        <Form className="msger-inputarea" onSubmit={(e) => handleSubmit(e)}>
                                            {/* <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)}>
                                                <DropdownToggle className="dropdown-form p-0" color="transparent"><i className="icon-plus" /></DropdownToggle>
                                                <DropdownMenu className="chat-icon" start="true">
                                                    <DropdownItem tag='div' className="mb-2"><DashboardSvgIcon iconId='camera' /></DropdownItem>
                                                    <DropdownItem tag='div'><DashboardSvgIcon iconId='attchment' /></DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown> */}
                                            <Input type="text" className="msger-input two uk-textarea" placeholder="Type a message......" value={messageInput}
                                                onChange={(e) => handleMessageChange(e.target.value)} />
                                            <div>
                                                {showEmojiPicker ? (<EmojiPicker onEmojiClick={(e) => addEmoji(e.emoji)} />) : null}
                                            </div>
                                            <div className="open-emoji">
                                                <div className="second-btn uk-button" onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
                                            </div>
                                            <Btn color='primary' className='msger-send-btn'>
                                                <i className="fa-solid fa-location-arrow" />
                                            </Btn>
                                        </Form>
                                    </div>
                                </div></>) : (
                                <div className='d-flex flex-column align-items-center justify-content-center' style={{
                                    flex: 1,
                                    padding: '2rem',
                                    background: '#f8f9fa'
                                }}>
                                    <div className="text-center">
                                        <h3 className="mb-3" style={{ color: '#1e88e5', fontWeight: 600 }}>Start a New Conversation</h3>
                                        <p className="text-muted mb-4" style={{ maxWidth: '400px', margin: '0 auto' }}>
                                            Select a contact from the list or start a new conversation by clicking the plus button
                                        </p>
                                        <Btn
                                            color="primary"
                                            className="d-inline-flex align-items-center gap-2 mx-auto"
                                            onClick={toggleEmployeeModal}
                                            style={{
                                                borderRadius: '50px',
                                                padding: '12px 25px',
                                                boxShadow: '0 4px 15px rgba(30,136,229,0.2)',
                                                transition: 'all 0.3s ease'
                                            }}
                                        >
                                            <i className="fa-solid fa-plus" />
                                            Start New Chat
                                        </Btn>
                                    </div>
                                </div>
                            )}


                        </Card>
                    </Col>
                </Row>
            </Container >

            {/* Employee Modal */}
            <Modal isOpen={showEmployeeModal} toggle={toggleEmployeeModal} size="lg">
                <ModalHeader toggle={toggleEmployeeModal}>
                    Start New Conversation
                </ModalHeader>
                <ModalBody>
                    <div className="mb-4">
                        <InputGroup>
                            <Input
                                type="text"
                                placeholder="Search by name or status..."
                                value={employeeSearchKeyword}
                                onChange={(e) => setEmployeeSearchKeyword(e.target.value)}
                                style={{ paddingLeft: '22px' }}
                            />
                        </InputGroup>
                    </div>
                    <div className="employee-list" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {filteredEmployees.length > 0 ? (
                            filteredEmployees.map((employee: any) => {

                                return (
                                    <div key={employee.id} className="d-flex align-items-center justify-content-between p-3 border-bottom">
                                        <div className="d-flex align-items-center">
                                            <div className="position-relative">
                                                <Image
                                                    src={employee.avatarUrl || dynamicImage('blog/comment.jpg')}
                                                    className="rounded-circle"
                                                    alt={`${employee.firstName} ${employee?.middleName || ''} ${employee.lastName}`}
                                                    style={{ width: '50px', height: '50px' }}
                                                />
                                                <div className={`status bg-${employee.online ? 'success' : 'warning'}`}
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '2px',
                                                        right: '2px',
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            </div>
                                            <div className="ms-3">
                                                <h6 className="mb-1">{`${employee.firstName} ${employee?.middleName || ''} ${employee.lastName}`}</h6>
                                            </div>
                                        </div>
                                        <Button
                                            color="primary"
                                            size="sm"
                                            onClick={() => startConversation(employee.id, employee.type)}
                                        >
                                            Start Chat
                                        </Button>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="text-center p-4">
                                <p className="text-muted">No employees found</p>
                            </div>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
};

export default connectComponent(ChatComponent); 