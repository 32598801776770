import { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap";
import { Href } from "../../../../Utils/Constants";
import General from "./General";
import Password from "./Password";
import Notification from "./Notification";

export default function EditProfile(props: any) {
  const [basicTab, setBasicTab] = useState("1");
  const materialStyleNav = [
    {
      id: "1",
      icon: <i className="iconly-Profile icli" />,
      text: "General",
    },
    {
      id: "2",
      icon: <i className="iconly-Document icli" />,
      text: "Password & Pin",
    },
    {
      id: "3",
      icon: <i className="iconly-Star icli" />,
      text: "Notification",
    },
  ];

  return (
    <Col xl={12}>
      <Card style={{ borderRadius: "0" }}>
        <CardBody className="pb-2">
          <Nav tabs className="border-tab border-0 mb-0 nav-primary">
            {materialStyleNav.map((item) => (
              <NavItem key={item.id}>
                <NavLink
                  href={Href}
                  className={`nav-border text-primary nav-primary ${
                    item.id === "1" ? "pt-0" : ""
                  } ${basicTab === item.id ? "active" : ""}`}
                  onClick={() => setBasicTab(item.id)}
                >
                  {item.icon} {item.text}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardBody>
      </Card>

      <TabContent activeTab={basicTab}>
        <General user={props.user} setUser={props.setUser} />
        <Password user={props.user} />
        <Notification user={props.user} setUser={props.setUser} />
      </TabContent>
    </Col>
  );
}
