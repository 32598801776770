import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { countryCode } from "../../../../../Utils/CountryCode";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Btn } from "src/AbstractElements";
import UserApi from "src/services/UserApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function Information(props: any) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    id: "",
    firstName: "",
    lastName: "",
    dob: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
    gender: "",
    nationality: "United Kingdom",
    birthPlace: "",
    liveWith: [],
    parentalResponsibility: [],
    keyPerson: [],
    specialNote: "",
    nurseryId: props.nurseryId,
    familyId: "",
  };

  const countryOptions = countryCode.map(({ country }) => ({
    label: country,
    value: country,
  }));

  const parentOptions = props.parentData.map((row: any) => ({
    label: `${row.firstName} ${row.middleName} ${row.lastName}`,
    value: row.id,
  }));

  const employeeOptions = props.employeeData.map((row: any) => ({
    label: `${row.firstName} ${row.lastName}`,
    value: row.id,
  }));

  const [form, setForm] = useState<any>(props.initialState);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await UserApi.createAndUpdateChild({
        ...form,
        dob: moment(form.dob).format("YYYY-MM-DD"),
        liveWith: form.liveWith.join(","),
        parentalResponsibility: form.parentalResponsibility.join(","),
        keyPerson: form.keyPerson.join(","),
      });
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: response.message,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Children", response.message)
          );

          if (!form.id) {
            // Navigate to plan list on success
            navigate(
              `${process.env.PUBLIC_URL}/dashboard/users/children/${props.initialState.familyId}`
            );
          }
        });
      }
    } catch (error: any) {
      const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
      const tt: any =
        typeof errorDetails == "string"
          ? errorDetails
          : Object.values(errorDetails)[0];
      Swal.fire({
        title: "Error!",
        text: tt || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const modifiedObj = commonKeysObject(props.initialState, initialState);
    setForm(modifiedObj);
    reset(modifiedObj);
  }, [props.initialState]);
  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <Col md={12} xl={12}>
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Basic Info"}
          />
          <CardBody>
            <Row className="mt-3">
              <Col md={4}>
                <FormGroup>
                  <Label>{"First Name"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${errors.firstName ? "is-invalid" : ""
                      }`}
                    type="text"
                    placeholder="First Name"
                    {...register("firstName", { required: true })}
                    value={form.firstName}
                    onChange={(e) => saveDataForm("firstName", e.target.value)}
                  />
                  {errors.firstName && (
                    <FormFeedback
                      style={{
                        color: "orangeRed",
                        display: "block",
                      }}
                    >
                      {"First Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>{"Last Name"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${errors.lastName ? "is-invalid" : ""
                      }`}
                    type="text"
                    placeholder="Last Name"
                    {...register("lastName", { required: true })}
                    value={form.lastName}
                    onChange={(e) => saveDataForm("lastName", e.target.value)}
                  />
                  {errors.lastName && (
                    <FormFeedback
                      style={{
                        color: "orangeRed",
                        display: "block",
                      }}
                    >
                      {"Last Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4} className="position-relative">
                <Label>{"Date of Birth"}</Label>{" "}
                <span className="text-danger">*</span>
                <InputGroup className="flatpicker-calender">
                  <Controller
                    control={control}
                    name="dob"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          className={`form-control flatpickr-input ${errors.dob ? "is-invalid" : ""
                            }`}
                          selected={form.dob}
                          onChange={(date) => {
                            field.onChange(date);
                            saveDataForm("dob", date);
                          }}
                          placeholderText="Select a date"
                          dateFormat="MMMM dd,yyyy"
                        />
                      );
                    }}
                  />

                  {errors.dob && (
                    <FormFeedback
                      style={{
                        color: "orangeRed",
                        display: "block",
                      }}
                    >
                      {"Dob is required"}{" "}
                    </FormFeedback>
                  )}
                </InputGroup>
              </Col>

              <Col md={4} className="position-relative">
                <Label>{"Nationality"}</Label>{" "}
                <span className="text-danger">*</span>
                <Controller
                  name="nationality"
                  control={control}
                  rules={{
                    required: "Please select any one nationality",
                  }} // Validation rules
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="nationality"
                      className={``}
                      options={countryOptions}
                      value={countryOptions.find(
                        (option: any) => option.value === form.nationality
                      )} // Set current value
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption?.value);
                        saveDataForm("nationality", selectedOption.value);
                      }} // Update the value using Controller's onChange
                    />
                  )}
                />
                {errors.nationality && (
                  <FormFeedback
                    style={{
                      color: "orangeRed",
                      display: "block",
                    }}
                  >
                    {"Nationality is required"}{" "}
                  </FormFeedback>
                )}
              </Col>

              <Col md={4} className="position-relative">
                <FormGroup>
                  <Label>{"Gender"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <Controller
                    name="gender"
                    control={control}
                    rules={{
                      required: "Please select a gender",
                    }} // Validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="gender"
                        options={[
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                          { value: "other", label: "Other" },
                        ]} // Gender options
                        value={
                          [
                            { value: "male", label: "Male" },
                            { value: "female", label: "Female" },
                            { value: "other", label: "Other" },
                          ].find((option) => option.value === form.gender) ||
                          null
                        } // Set current value, handle null
                        onChange={(selectedOption) => {
                          // Check if selectedOption is not null
                          if (selectedOption) {
                            field.onChange(selectedOption.value); // Update the field value
                            saveDataForm("gender", selectedOption.value); // Save the data
                          }
                        }} // Handle value change
                      />
                    )}
                  />
                  {errors.gender && (
                    <FormFeedback
                      style={{
                        color: "orangeRed",
                        display: "block",
                      }}
                    >
                      {"Gender is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col md={4} className="position-relative">
                <FormGroup>
                  <Label>{"Birth Place"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${errors.birthPlace ? "is-invalid" : ""
                      }`}
                    type="text"
                    placeholder="Birth Place"
                    {...register("birthPlace", { required: true })}
                    value={form.birthPlace}
                    onChange={(e) => saveDataForm("birthPlace", e.target.value)}
                  />
                  {errors.birthPlace && (
                    <FormFeedback
                      style={{
                        color: "orangeRed",
                        display: "block",
                      }}
                    >
                      {"Birth Place is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Live With"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <Controller
                    name="liveWith"
                    control={control}
                    rules={{
                      required: "Please select at least one option",
                    }} // Validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="liveWith"
                        options={parentOptions} // Options for selection
                        isMulti // Enable multiple selection
                        value={parentOptions.filter((option: any) =>
                          form.liveWith?.includes(option.value)
                        )} // Set current values, handling multiple selected options
                        onChange={(selectedOptions: any) => {
                          // Check if selectedOptions is not null
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option: any) => option.value)
                            : [];
                          field.onChange(selectedValues); // Update the field value
                          saveDataForm("liveWith", selectedValues); // Save the data
                        }} // Handle value changes
                      />
                    )}
                  />
                  {errors.liveWith && (
                    <FormFeedback
                      style={{
                        color: "orangeRed",
                        display: "block",
                      }}
                    >
                      {"Live With is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Parental Responsibility"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <Controller
                    name="parentalResponsibility"
                    control={control}
                    rules={{
                      required: "Please select at least one option",
                    }} // Validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="parentalResponsibility"
                        options={parentOptions} // Options for selection
                        isMulti // Enable multiple selection
                        value={parentOptions.filter((option: any) =>
                          form.parentalResponsibility?.includes(option.value)
                        )} // Set current values, handling multiple selected options
                        onChange={(selectedOptions: any) => {
                          // Check if selectedOptions is not null
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option: any) => option.value)
                            : [];
                          field.onChange(selectedValues); // Update the field value
                          saveDataForm(
                            "parentalResponsibility",
                            selectedValues
                          ); // Save the data
                        }} // Handle value changes
                      />
                    )}
                  />
                  {errors.parentalResponsibility && (
                    <FormFeedback
                      style={{
                        color: "orangeRed",
                        display: "block",
                      }}
                    >
                      {"Parental Responsibility is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Key Person"}</Label>{" "}
                  <Controller
                    name="keyPerson"
                    control={control}
                    rules={{
                      required: false,
                    }} // Validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="keyPerson"
                        options={employeeOptions} // Options for selection
                        isMulti // Enable multiple selection
                        value={employeeOptions.filter((option: any) =>
                          form.keyPerson?.includes(option.value)
                        )} // Set current values, handling multiple selected options
                        onChange={(selectedOptions: any) => {
                          // Check if selectedOptions is not null
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option: any) => option.value)
                            : [];
                          field.onChange(selectedValues); // Update the field value
                          saveDataForm("keyPerson", selectedValues); // Save the data
                        }} // Handle value changes
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Special Note"}</Label>
                  <textarea
                    rows={5}
                    className={`form-control`}
                    placeholder="Enter your notes here"
                    {...register("specialNote")}
                    value={form.specialNote || ""}
                    onChange={(e) =>
                      saveDataForm("specialNote", e.target.value)
                    }
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>

            <CardFooter className="text-end">
              {" "}
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Save & Update"
                )}
              </Btn>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
    </Form>
  );
}

export default Information;
