import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../AbstractElements";
import {
  CompanyName,
  FirstName,
  LastName,
  LooksGood,
} from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connectComponent } from "./connector";
import { IForm, ValidKeys } from "./Helper/types";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import SuperAdminApi from "../../../services/SuperAdminApi";
import UserActivityAPi from "../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../Utils";

const EditSuperAdmin = (props: any) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    startedAt: new Date().getTime(),
    expiredAt: new Date().setFullYear(new Date().getFullYear() + 1),
    joinDate: new Date(),
    paymentStatus: "PAID",
    nurseryObjArr: [
      {
        name: "",
      },
    ],
    packageId: "",
    nurseryType: "singleNursery",
    companyName: "",
    noOfNurseries: "",
    noOfChild: "",
    noOfAdmins: "1",
    password: "",
  };

  const [form, setForm] = useState<IForm>({ ...initialState });
  const [superAdmins, setSuperAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [validate, setValidate] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IForm>();

  const onSubmit = async () => {
    setValidate(true);
    form.joinDate = new Date(form.joinDate).getTime();
    form.backupPassword = form.password;

    const editItem: any = superAdmins.filter((admin: any) => admin.id === id);
    if (editItem.length > 0) {
      if (form.backupPassword === editItem[0].backupPassword) {
        delete form.backupPassword;
        delete form.password;
      }
    }

    try {
      // Call the API to create the SUPER ADMIN using SuperAdminApi
      await SuperAdminApi.updateSuperAdmin(id, form);
      Swal.fire({
        title: "Success!",
        text: "Super Admin updated successfully",
        icon: "success",
      }).then(async () => {
        await UserActivityAPi.addUserActivity(
          getUserActivityFormattedData(
            "Super Admins",
            `Super Admin updated successfully`
          )
        );
        // Navigate to SUPER ADMIN list on success
        navigate(`${process.env.PUBLIC_URL}/dashboard/admin`);
      });
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    const fetchSuperAdmin = async () => {
      try {
        const response = await SuperAdminApi.getSuperAdmins();
        if (response.success && Array.isArray(response.data.admins)) {
          setSuperAdmins(response.data.admins);
        } else {
          throw new Error("Expected an array of Super Admin");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching Super Admin");
      } finally {
        setLoading(false);
      }
    };

    fetchSuperAdmin();
  }, []);

  useEffect(() => {
    const editItem: any = superAdmins.filter((admin: any) => admin.id === id);
    if (editItem.length > 0) {
      const formData = {
        firstName: editItem?.[0]?.firstName,
        lastName: editItem[0]?.lastName,
        email: editItem[0]?.email,
        phoneNo: editItem[0].phoneNo,
        joinDate: new Date(editItem[0].joinDate),
        password: editItem[0].backupPassword,
      };

      reset(formData); // Reset the form with fetched user data
      setForm(formData);
    }
  }, [id, reset, superAdmins]);

  const saveDataForm = (name: ValidKeys, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    props.fetchPlans({});
  }, []);

  return (
    <div className="page-body">
      {props.isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1",
          }}
        >
          <Spinner size="lg" color="primary">
            Loading...
          </Spinner>
        </div>
      )}
      <Container fluid className={`${props.isLoading ? "custom-opacity" : ""}`}>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Super Admin Details`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/admin/`);
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form
                  className="needs-validation custom-input tooltip-valid validation-forms"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <Row className="g-3">
                    <Col md={12} className="mb-3">
                      <span className="h4">
                        <i className="icon-angle-double-right text-primary text-bold"></i>{" "}
                        Personal Detail
                      </span>
                      <Row className="mt-3">
                        <Col md={4} className="position-relative">
                          <Label>
                            {FirstName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${errors.firstName ? "is-invalid" : ""
                              } ${validate && !errors.firstName ? "is-valid" : ""
                              }`}
                            placeholder="Enter first name"
                            {...register("firstName", {
                              required: "Please enter valid first name",
                            })}
                            value={form.firstName}
                            onChange={(e) =>
                              saveDataForm("firstName", e.target.value)
                            }
                          />
                          {errors.firstName && (
                            <FormFeedback tooltip>
                              {errors.firstName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.firstName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>
                        <Col md={4} className="position-relative">
                          <Label>
                            {LastName} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${errors.lastName ? "is-invalid" : ""
                              } ${validate && !errors.lastName ? "is-valid" : ""
                              }`}
                            {...register("lastName", {
                              required: "Please enter valid last name",
                            })}
                            placeholder="Enter last name"
                            value={form.lastName}
                            onChange={(e) =>
                              saveDataForm("lastName", e.target.value)
                            }
                          />
                          {errors.lastName && (
                            <FormFeedback tooltip>
                              {errors.lastName.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.lastName && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative">
                          <Label>
                            {"Password"} <span className="text-danger">*</span>
                          </Label>
                          <input
                            type="text"
                            className={`form-control ${errors.password ? "is-invalid" : ""
                              } ${validate && !errors.password ? "is-valid" : ""
                              }`}
                            {...register("password", {
                              required: "Please enter valid password",
                            })}
                            placeholder="Enter password"
                            value={form.password}
                            onChange={(e) =>
                              saveDataForm("password", e.target.value)
                            }
                          />
                          {errors.password && (
                            <FormFeedback tooltip>
                              {errors.password.message}
                            </FormFeedback>
                          )}
                          {validate && !errors.password && (
                            <FormFeedback tooltip valid>
                              {LooksGood}
                            </FormFeedback>
                          )}
                        </Col>

                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Email"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="email"
                              className={`form-control ${errors.email ? "is-invalid" : ""
                                } ${validate && !errors.email ? "is-valid" : ""}`}
                              {...register("email", {
                                required: "Please enter valid email",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                  message: "Invalid email address",
                                },
                              })}
                              placeholder="Enter your email"
                              value={form.email}
                              onChange={(e) =>
                                saveDataForm("email", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-envelope text-primary" />
                            </InputGroupText>
                            {errors.email && (
                              <FormFeedback tooltip>
                                {errors.email.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.email && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Mobile"} <span className="text-danger">*</span>
                          </Label>
                          <InputGroup>
                            <input
                              type="mobile"
                              className={`form-control ${errors.phoneNo ? "is-invalid" : ""
                                } ${validate && !errors.phoneNo ? "is-valid" : ""
                                }`}
                              {...register("phoneNo", {
                                required: "Mobile number is required", // Mobile number is required
                                pattern: {
                                  value: /^[0-9]{11}$/, // Validate only digits
                                  message:
                                    "Mobile number must contain only digits",
                                },
                                minLength: {
                                  value: 11, // Minimum length (for example 10 digits)
                                  message:
                                    "Mobile number must be at least 11 digits long",
                                },
                                maxLength: {
                                  value: 11, // Maximum length (for example 10 digits)
                                  message:
                                    "Mobile number must not exceed 11 digits",
                                },
                              })}
                              placeholder="Enter your mobile"
                              value={form.phoneNo}
                              onChange={(e) =>
                                saveDataForm("phoneNo", e.target.value)
                              }
                            />
                            <InputGroupText className="list-light-primary">
                              <i className="fa-solid fa-phone text-primary" />
                            </InputGroupText>
                            {errors.phoneNo && (
                              <FormFeedback tooltip>
                                {errors.phoneNo.message}
                              </FormFeedback>
                            )}
                            {validate && !errors.phoneNo && (
                              <FormFeedback tooltip valid>
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                        <Col md={4} className="position-relative mt-3">
                          <Label>
                            {"Start date"}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <InputGroup className="flatpicker-calender">
                            <Controller
                              control={control}
                              name="joinDate"
                              rules={{
                                required: "Please select starting date.",
                              }}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    className={`form-control flatpickr-input ${errors.joinDate ? "is-invalid" : ""
                                      } ${validate && !errors.joinDate
                                        ? "is-valid"
                                        : ""
                                      }`}
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      saveDataForm("joinDate", date);
                                    }}
                                    placeholderText="Select a date"
                                    dateFormat="MMMM dd,yyyy"
                                  />
                                );
                              }}
                            />

                            {errors.joinDate && (
                              <FormFeedback
                                tooltip
                                style={{ display: "block" }}
                              >
                                <>{`${errors?.joinDate?.message}`}</>
                              </FormFeedback>
                            )}
                            {validate && !errors.joinDate && (
                              <FormFeedback
                                tooltip
                                valid
                                style={{ display: "block" }}
                              >
                                {LooksGood}
                              </FormFeedback>
                            )}
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                      <Btn
                        color="dark"
                        className="mt-3 btn-md"
                        style={{ marginRight: "0.5rem" }}
                      >
                        {`Update & Save`}
                      </Btn>
                      <Btn
                        color="primary"
                        outline={true}
                        className="mt-3 btn-md"
                        type="button"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/admin/`
                          );
                        }}
                      >
                        {`Cancel`}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(EditSuperAdmin);
