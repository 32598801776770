import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../../AbstractElements";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";
import PricingBrandsAPi from "src/services/PricingBrandsApi";
import { connectComponent } from "./connector";
import Select from "react-select";

interface IForm {
  id?: string;
  title: string;
  sessionTimes: { value: string; label: string }[];
}

const sessionTimeOptions = [
  { value: "morning", label: "Morning (8:00 AM - 12:00 PM)" },
  { value: "afternoon", label: "Afternoon (12:00 PM - 4:00 PM)" },
  { value: "evening", label: "Evening (4:00 PM - 8:00 PM)" },
];

function EditPricingBands(props: any) {
  const { id } = useParams();
  const initialState = {
    id: "",
    title: "",
    sessionTimes: [],
  };
  const [form, setForm] = useState<IForm>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IForm>();

  const onSubmit = () => {
    try {
      setIsLoading(true);
      setTimeout(async () => {
        await PricingBrandsAPi.updatePriceBand({
          ...form,
          sessionTimeIds: form.sessionTimes.map(st => st.value)
        });
        Swal.fire({
          title: "Success!",
          text: "Price Brand updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Pricing brands",
              `Price Brand updated successfully`
            )
          );

          navigate(`${process.env.PUBLIC_URL}/dashboard/plans/pricing-bands`);
        });
      }, 2000);
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2300);
    }
  };

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const fetchPriceBrandById = async () => {
      try {
        const response = await PricingBrandsAPi.getPriceBandById(id ?? "");
        if (response.success && Object.keys(response.data).length > 0) {
          const modifiedObj = commonKeysObject(response.data, form);

          // Convert sessionTimeIds to sessionTimes format
          const sessionTimes = response.data.sessionTimeIds?.map((timeId: string) => {
            const option = sessionTimeOptions.find(opt => opt.value === timeId);
            return option || null;
          }).filter(Boolean) || [];

          const formData = {
            ...modifiedObj,
            sessionTimes
          };

          setForm(formData as IForm);
          reset(formData);
        } else {
          setForm({ ...initialState });
        }
      } catch (error: any) {
        setForm({ ...initialState });
      }
    };

    fetchPriceBrandById();
  }, [id]);

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Edit Price Brand`}
                  titleClass="mb-3"
                />
                <Button
                  color="dark"
                  className="btn-md m-4 d-flex align-items-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/plans/pricing-bands`
                    );
                  }}
                >
                  <i
                    className="iconly-Arrow-Left icli"
                    style={{ marginRight: "0.5rem" }}
                  ></i>{" "}
                  Back
                </Button>
              </div>

              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="g-3">
                    <Col md={4}>
                      <Label for="title">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className={`form-control ${errors.title ? "is-invalid" : ""
                          }`}
                        {...register("title", {
                          required: "Please enter a valid title",
                        })}
                        placeholder="Enter title"
                        value={form.title}
                        onChange={(e) => saveDataForm("title", e.target.value)}
                      />
                      {errors.title && (
                        <FormFeedback>{errors.title.message}</FormFeedback>
                      )}
                    </Col>
                    <Col md={8}>
                      <Label for="sessionTimes">
                        Session Times <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="sessionTimes"
                        control={control}
                        rules={{ required: "Please select at least one session time" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isMulti
                            options={sessionTimeOptions}
                            className={`${errors.sessionTimes ? "is-invalid" : ""}`}
                            placeholder="Select session times"
                            value={form.sessionTimes}
                            onChange={(selected) => {
                              field.onChange(selected);
                              saveDataForm("sessionTimes", selected);
                            }}
                          />
                        )}
                      />
                      {errors.sessionTimes && (
                        <FormFeedback>{errors.sessionTimes.message}</FormFeedback>
                      )}
                    </Col>
                    <Row className="mt-4">
                      <Col xs={12} style={{ borderTop: "5px solid #eee" }}>
                        <Btn
                          color="dark"
                          className="mt-3 btn-md"
                          style={{ marginRight: "0.5rem" }}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <span className="d-flex gap-2">
                              <Spinner color="white" size="sm" /> Loading...
                            </span>
                          ) : (
                            "Update & Save"
                          )}
                        </Btn>
                        <Btn
                          color="primary"
                          outline={true}
                          type="button"
                          onClick={() => {
                            navigate(
                              `${process.env.PUBLIC_URL}/dashboard/plans/pricing-bands`
                            );
                          }}
                          className="mt-3 btn-md"
                        >
                          {`Cancel`}
                        </Btn>
                      </Col>
                    </Row>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(EditPricingBands);
