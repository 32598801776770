import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader'
import FilterComponent from 'src/Components/Tables/DataTables/Common/FilterComponent'
import DataTable, { TableColumn } from 'react-data-table-component'
import moment from 'moment-timezone'
import { Btn, LI, UL } from 'src/AbstractElements'
import { Controller, useForm } from 'react-hook-form'

import StepperHorizontal from 'src/Components/Forms/FormLayout/FormWizard1/NumberingWizard/StepperHorizontal'
import { Back, OtherNotes } from 'src/Utils/Constants'
import { toast } from 'react-toastify'
import { AccidentBasicForm, AccidentFinalForm, AccidentForm } from './form'
import AccidentAPi from 'src/services/AccidentApi'
import Swal from 'sweetalert2'
import UserActivityAPi from 'src/services/UserActivityApi'
import { commonKeysObject, getUserActivityFormattedData } from 'src/Utils'
import UploadAPi from 'src/services/UploadApi'

const CustomHeader = ({ title }: { title: string }) => (
    <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
        {title}
    </span>
);

type AccidentForm = {
    id?: string;
    children: { id: string, name: string },
    kind: string,
    dateTime: any,
    location: string,
    firstAid: string,
    reportedTo: string,
    witnesses: string[],
    otherStaff: string[],
    natureOfAccident: string,
    parentNotified: string,
    otherNotes: string,
    uploadUrl: string | string[],
    files: any,
    spotsData: {
        front: string[],
        back: string[],
        head: string[],
        sides: string[],
    }
};

function AccidentList(props: any) {
    const initialState = {
        children: { id: "", name: "" },
        kind: "",
        dateTime: "",
        location: "",
        firstAid: "",
        reportedTo: "",
        witnesses: [],
        otherStaff: [],
        natureOfAccident: "",
        parentNotified: "",
        otherNotes: "",
        uploadUrl: "",
        files: "",
        spotsData: {
            front: [],
            back: [],
            head: [],
            sides: [],
        }
    };

    const [accident, setAccident] = useState<any>([]);
    const [filterText, setFilterText] = useState("");
    const filteredItems: any = accident?.filter((item: any) => {
        item = {
            name: `${item.children.firstName} ${item.children.lastName}`,
            kind: `${item.kind}`,
            location: `${item.location}`,
        };
        return Object.values(item).some(
            (value) =>
                value &&
                value
                    .toString()
                    .toLowerCase()
                    .includes(filterText.toLowerCase())
        );
    });

    const [form, setForm] = useState<AccidentForm>(initialState);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [errors, setErrors] = useState<any>({});

    const [step, setStep] = useState(1);
    const [showFinish, setShowFinish] = useState(false);
    const [finishClicked, setFinishClicked] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleBackButton = () => {
        setShowFinish(false);
        setStep((prev) => prev - 1)
        setFinishClicked(false);
    };

    const handleError = (formData: any) => {
        let updateError = { ...errors }; // Start with the existing errors

        Object.keys(formData).forEach((row) => {
            if (formData[row] === "") {
                // Add or update the error message for empty fields
                updateError[row] = {
                    ...errors[row],
                    message: "This field is required",
                };
            } else {
                // Remove the field from the errors object if data is valid
                delete updateError[row];
            }
        });

        console.log('updateError: ', updateError);
        setErrors(updateError);
    };

    const handleNextButton = () => {
        const { children, kind, dateTime, location, firstAid, natureOfAccident, parentNotified, spotsData } = form;
        if (children.id !== "" && kind !== "" && dateTime !== "" && location !== "" && firstAid !== "" && natureOfAccident !== "" && parentNotified !== "" && step === 1) {
            setStep(2)
        } else if ((spotsData.front.length != 0 || spotsData.back.length != 0 || spotsData.head.length != 0 || spotsData.sides.length != 0) && step === 2) {
            setStep(3);
            setShowFinish(true);
        }
        else {
            step === 1 && handleError({ children: children.id, kind: kind, dateTime: dateTime, location: location, firstAid: firstAid, natureOfAccident: natureOfAccident, parentNotified: parentNotified });
            return toast.error("please fill out all the fields before moving on to the next step");
        }
    };

    const handleFinishButton = async () => {
        setFinishClicked(true);
        try {
            setIsLoading(true);
            if (!form.id) {
                let uploadUrls: string[] = [];

                if (form.files && form.files.length > 0) {
                    const filesArray = Array.from(form.files); // or [...form.files]
                    // Create an array of promises for uploading all the files
                    const uploadPromises = filesArray.map(async (file: any) => {
                        try {
                            const { mediaUrl } = await uploadImage(file);
                            return { status: 'fulfilled', mediaUrl }; // Return the mediaUrl if successful
                        } catch (error) {
                            return { status: 'rejected', error }; // Return the error if failed
                        }
                    });

                    // Wait for all promises to settle
                    const results: any = await Promise.allSettled(uploadPromises);

                    // Process the results
                    results.forEach((result: any) => {
                        if (result.status === 'fulfilled') {
                            uploadUrls.push(result.value.mediaUrl); // Add the mediaUrl to the array if successful
                        } else {
                            console.error('File upload failed', result.error); // Handle the error as needed
                        }
                    });
                }

                const response = await AccidentAPi.createAccident({
                    ...form,
                    files: undefined,
                    uploadUrl: uploadUrls.join(","),
                    nurseryId: props.nurseryId,
                });
                if (response.success) {
                    Swal.fire({
                        title: "Success!",
                        text: response.message,
                        icon: "success",
                    }).then(async () => {
                        await UserActivityAPi.addUserActivity(
                            getUserActivityFormattedData("Safegurading", response.message)
                        );
                        toggleModal();
                        await fetchAccident();
                    });
                }
            } else {

                let uploadUrls: any = [];

                if (form.files && form.files.length > 0) {
                    // Create an array of promises for uploading all the files
                    console.log('form.files: ', form);
                    const filesArray = Array.from(form.files); // or [...form.files]

                    const uploadPromises = filesArray.map(async (file: any) => {
                        console.log('file: ', file);
                        try {
                            const { mediaUrl } = await uploadImage(file);
                            return { status: 'fulfilled', mediaUrl }; // Return the mediaUrl if successful
                        } catch (error) {
                            return { status: 'rejected', error }; // Return the error if failed
                        }
                    });

                    // Wait for all promises to settle
                    const results: any = await Promise.allSettled(uploadPromises);
                    console.log('results: ', results);

                    // Process the results
                    results.forEach((result: any) => {
                        if (result.status === 'fulfilled') {
                            uploadUrls.push(result.value.mediaUrl); // Add the mediaUrl to the array if successful
                            console.log('uploadUrls: ', uploadUrls);
                        } else {
                            console.error('File upload failed', result.error); // Handle the error as needed
                        }
                    });
                }


                // If form.uploadUrl already has data, append it with the new uploadUrls
                if (form.uploadUrl != "") {
                    console.log('form.uploadUrl: ', form.uploadUrl);
                    const previousData: any = JSON.parse(JSON.stringify(form.uploadUrl));
                    form.uploadUrl = [...previousData.split(","), ...uploadUrls].join(","); // Assuming form.uploadUrl is an array
                } else {
                    console.log('uploadUrls: ', uploadUrls);
                    form.uploadUrl = uploadUrls.join(","); // If form.uploadUrl is empty, set it as the uploadUrls array
                }

                const response = await AccidentAPi.updateAccident({
                    ...form,
                    files: undefined,
                    nurseryId: props.nurseryId,
                });
                if (response.success) {
                    Swal.fire({
                        title: "Success!",
                        text: response.message,
                        icon: "success",
                    }).then(async () => {
                        await UserActivityAPi.addUserActivity(
                            getUserActivityFormattedData("Safegurading", response.message)
                        );
                        toggleModal();
                        await fetchAccident();
                    });
                }
            }
        } catch (error: any) {
            setFinishClicked(false);
            const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
            const tt: any =
                typeof errorDetails == "string"
                    ? errorDetails
                    : Object.values(errorDetails)[0];
            Swal.fire({
                title: "Error!",
                text: tt || "Something went wrong",
                icon: "error",
            });

        } finally {
            setIsLoading(false);
        }
    };

    const uploadImage = async (file: any) => {
        const formData: any = new FormData();
        formData.append("file", file);
        formData.append("folder", "accident");
        try {
            const response = await UploadAPi.uploadFile(formData, "upload-accident");

            return { mediaUrl: response.filePath, mediaType: "IMAGE" };
        } catch (err) {
            return { mediaUrl: "", mediaType: "" };
        }
    };

    const {
        control,
        reset,
    } = useForm<any>();

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setErrors({});
        setForm(initialState);
        setFinishClicked(false);
        setStep(1);
        setShowFinish(false);
        reset(initialState);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <CustomHeader title="Child Name" />,
            selector: (row) => `${row.children.firstName} ${row.children.lastName}`,
            sortable: true,
            style: {
                minWidth: "42%", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "flex-start", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "42%", // Set consistent width
            maxWidth: "42%",
        },
        {
            name: <CustomHeader title="Kind" />,
            selector: (row) => row.kind,
            sortable: true,
            style: {
                minWidth: "20%", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "flex-start", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "20%", // Set consistent width
            maxWidth: "20%",
        },
        {
            name: <CustomHeader title="location" />,
            selector: (row) => row.location,
            sortable: true,
            style: {
                minWidth: "20%", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "flex-start", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "20%", // Set consistent width
            maxWidth: "20%",
        },
        {
            name: <CustomHeader title="Action" />,
            style: {
                minWidth: "120px", // Set a minimum width
                textAlign: "center", // Center the content
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
            },
            minWidth: "120px", // Set consistent width
            maxWidth: "120px",
            center: true, // Center-aligns content in both header and cells
            cell: (row) => (
                <UL className="action simple-list flex-row">
                    <LI className="edit">
                        <a
                            href="javascript:void(0);"
                            onClick={() => handleEditAccident(row)}
                            className="icon"
                        >
                            <Btn color="primary" size="sm">
                                <i
                                    className="fa-solid fa-pencil me-1 text-white"
                                    style={{ fontSize: "12px" }}
                                ></i>
                                {"Edit"}
                            </Btn>
                        </a>
                    </LI>
                </UL>
            ),
        },
    ];

    const handleEditAccident = (row: any) => {
        const modifiedObj: any = commonKeysObject(row, form);
        console.log('modifiedObj: ', modifiedObj);
        setForm({ ...modifiedObj, id: row.id, children: { id: modifiedObj.children.id, name: `${modifiedObj.children.firstName} ${modifiedObj.children.lastName}` }, witnesses: modifiedObj.witnesses.map((r: any) => ({ id: r.id, name: `${r.firstName} ${r.lastName}` })), otherStaff: modifiedObj.otherStaff.map((r: any) => ({ id: r.id, name: `${r.firstName} ${r.lastName}` })), dateTime: new Date(modifiedObj.dateTime) });
        reset({ ...modifiedObj, children: { id: modifiedObj.children.id, name: `${modifiedObj.children.firstName} ${modifiedObj.children.lastName}` }, witnesses: modifiedObj.witnesses.map((r: any) => ({ id: r.id, name: `${r.firstName} ${r.lastName}` })), otherStaff: modifiedObj.otherStaff.map((r: any) => ({ id: r.id, name: `${r.firstName} ${r.lastName}` })), dateTime: new Date(modifiedObj.dateTime) });
        setIsModalOpen(!isModalOpen);
    };

    const saveDataForm = (name: any, value: any) => {
        setForm({ ...form, [name]: value });
    };

    const fetchAccident = async () => {
        try {
            const response = await AccidentAPi.getAccidents(props.nurseryId, {});
            if (response.success && Array.isArray(response.data)) {
                setAccident(response.data);
            } else setAccident([]);
        } catch (error: any) {
            setAccident([]);
        }
    };

    useEffect(() => {
        fetchAccident();
    }, [props.nurseryId]);

    return (
        <>
            <Card className="card-absolute">
                <div
                    className="d-flex justify-content-end align-items-center"
                    style={{ borderBottom: "5px solid #eee" }}
                >
                    <CommonCardHeader
                        title="Accident"
                        headClass="bg-primary"
                        titleClass="text-light"
                    />
                    <Button
                        color="primary"
                        className="btn-md m-4 mb-3 mt-3"
                        onClick={toggleModal}
                    >
                        <i className="fa fa-plus me-2" /> Add
                    </Button>
                </div>
                <CardBody>
                    <Row className="justify-content-end">
                        <Col md={6}>
                            <FilterComponent
                                onFilter={(e) => setFilterText(e.target.value)}
                                filterText={filterText}
                            />
                        </Col>
                    </Row>
                    <div className="table-responsive">
                        <DataTable
                            data={filteredItems}
                            columns={columns}
                            pagination
                            className="display"
                            conditionalRowStyles={[
                                {
                                    when: (row) => true,
                                    style: {
                                        "&:hover": {
                                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                                            cursor: "pointer",
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </CardBody>
            </Card>

            {/* Modal for Adding Job Title */}
            <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static" size='xl'>

                <ModalHeader toggle={toggleModal}>
                    Add Accident
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <div className="basic-wizard important-validation">
                            <div className="stepper-horizontal" id="stepper1">
                                <StepperHorizontal step={step} finishClicked={finishClicked} />
                            </div>
                            <div id="msform">
                                {step === 1 && (<AccidentBasicForm control={control} errors={errors} form={form} nurseryId={props.nurseryId} saveDataForm={saveDataForm} />)}
                                {step === 2 && (<AccidentForm form={form} saveDataForm={saveDataForm} />)}
                                {step === 3 && (<AccidentFinalForm form={form} />)}
                            </div>
                            <div className="wizard-footer d-flex gap-2 justify-content-end">
                                {step > 1 && (<Btn color="primary" className='text-light' onClick={handleBackButton}>{Back}</Btn>)}
                                <Btn disabled={finishClicked} color="primary" onClick={showFinish ? handleFinishButton : handleNextButton}>{showFinish ? isLoading ? <span className="d-flex gap-2">
                                    <Spinner color="white" size="sm" /> Loading...
                                </span> : "Finish" : "Next"}</Btn>
                            </div>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >
        </>
    )
}

export default AccidentList