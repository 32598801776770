import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";

export default function NurseryDropDown(props: any) {
  const [nurseryData, setNurseryData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const disableUrl = [
    "employees/edit",
    "children/add",
    "children/edit",
    "children/",
  ];

  useEffect(() => {
    if (
      props.user &&
      props.user.role != "super_admin" &&
      props?.user?.nursery?.length > 0
    ) {
      const data = props.user.nursery.map((nursery: any) => {
        return {
          label: `${nursery.name}`,
          value: nursery.id,
        };
      });
      setNurseryData(data);
    }
  }, [props.user]);

  const handleChange: any = (newValue: any, actionMeta: any) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear"
    ) {
      const newSelectedOption = newValue as any;
      props.setNursery(newSelectedOption?.value || null);
    }
  };

  useEffect(() => {
    setDisabled(disableUrl.some((url) => location.pathname.includes(url)));
  }, [location.pathname]);

  return (
    <div className="d-lg-block">
      <div className="u-posRelative d-flex align-items-center">
        <Select
          id={"nursery-dropdown"}
          options={nurseryData}
          value={nurseryData.find(
            (option: any) => option.value === props.selectedNursery
          )}
          onChange={handleChange}
          isDisabled={disabled}
          className={`nursery-dropdown-form w-100`}
          styles={{
            control: (base, state) => ({
              ...base,
              borderRadius: "20px",
              backgroundColor: state.isDisabled ? "#f5f5f5" : "#fafafa",
              borderColor: state.isFocused ? "#308e87" : "#ddd",
              boxShadow: state.isFocused ? "0 0 5px rgba(48, 142, 135, 0.5)" : "none",
              "&:hover": {
                borderColor: "#308e87",
              },
              width: "340px", // Full width
              transition: "all 0.3s ease",
              padding: '5px'
            }),
            menu: (base) => ({
              ...base,
              borderRadius: "20px",
              backgroundColor: "#fff",
              width: "100%", // Full width dropdown
              overflow: "hidden", // Prevents overflow issues
            }),
            menuList: (base) => ({
              ...base,
              borderRadius: "20px",
              padding: "5px",
              maxHeight: "none", // Remove height limit
              overflow: "visible", // Ensures no scrolling
            }),
            option: (base, { isFocused, isSelected }) => ({
              ...base,
              backgroundColor: isSelected
                ? "#308e87"
                : isFocused
                  ? "rgb(48 142 135 / 20%)"
                  : "#fff",
              color: isSelected ? "#fff" : "#333",
              padding: "15px 15px",
              margin: "5px 0",
              borderRadius: "15px",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "rgb(48 142 135 / 20%)",
                color: "#000",
                borderRadius: "15px",
              },
            }),
          }}
        />
      </div>
    </div>
  );
}
