import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "src/AbstractElements";
import UserApi from "src/services/UserApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";
import DatePicker from "react-datepicker";
import Select from "react-select";
import DropDownAPi from "src/services/DropdownApi";
import moment from "moment";

function Rooms(props: any) {
  const initialState = {
    id: "",
    roomId: "",
    moveDate: "",
    startDate: "",
    lastDate: "",
    room: {},
  };

  const [form, setForm] = useState<any>(initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roomNames, setRoomNames] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await UserApi.addAndUpdateChildrenRoomMoves({
        id: form.id,
        startDate: moment(form.startDate).format("YYYY-MM-DD HH:mm:00"),
        lastDate: moment(form.lastDate).format("YYYY-MM-DD HH:mm:00"),
      });
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: "Room Move updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Children",
              "Room Move updated successfully"
            )
          );
          await fetchChildrenRoomMoves();
        });
      }
    } catch (error: any) {
      const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
      const tt: any =
        typeof errorDetails == "string"
          ? errorDetails
          : Object.values(errorDetails)[0];
      Swal.fire({
        title: "Error!",
        text: tt || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const initialRoomMoves = {
    id: "",
    childrenId: props.initialState.id,
    roomId: "",
    moveDate: "",
  };
  const [roomMoves, setRoomMoves] = useState<any>(initialRoomMoves);

  const {
    register: registerRoomMoves,
    handleSubmit: handleSubmitRoomMoves,
    control: handleControlRoomMoves,
    reset: handleResetRoomMoves,
    formState: { errors: errorsRoomMoves },
  } = useForm<any>();

  useEffect(() => {
    setRoomMoves({ ...roomMoves, childrenId: props.initialState.id });
  }, [props.initialState]);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      handleResetRoomMoves({}); // Reset form to empty state when closing
      setRoomMoves(initialRoomMoves);
    } else {
      // If opening modal, reset with selected Job Title data for edit, otherwise with empty data for add
      handleResetRoomMoves(roomMoves);
    }
  };

  const fetchChildrenRoomMoves = async () => {
    try {
      const response = await UserApi.getChildrenRoomMoves({
        childrenId: roomMoves.childrenId,
      });

      if (response.success && response?.children?.length > 0) {
        setNoDataFound(false);
        const res = response.children[0];
        setForm({
          id: res.id,
          roomId: res.roomId,
          room: res.room,
          moveDate: new Date(res.moveDate),
          startDate: new Date(res.startDate),
          lastDate:
            !!res.lastDate && res.lastDate != "Invalid date"
              ? new Date(res.lastDate)
              : "",
        });
      } else {
        setForm(initialState);
        setNoDataFound(true);
      }
    } catch (error: any) {
      console.error("error: ", error);
    } finally {
    }
  };

  const fetchClassRoomsOptions = async () => {
    try {
      const response = await DropDownAPi.getClassRoomsOptions(
        props.initialState.nurseryId || ""
      );

      if (response.success && response?.data?.length > 0) {
        const roomNameOptions = response?.data?.map((item: any) => ({
          label: item.roomName,
          value: item.id,
        }));
        setRoomNames(roomNameOptions);
      } else {
        setRoomNames([]);
      }
    } catch (error: any) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchClassRoomsOptions();
    }
    fetchChildrenRoomMoves();
  }, [isModalOpen]);

  useEffect(() => {
    fetchChildrenRoomMoves();
  }, [props]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const formattedDate = moment(roomMoves.moveDate).format(
        "YYYY-MM-DD HH:mm:00"
      );
      const response = await UserApi.addAndUpdateChildrenRoomMoves({
        ...roomMoves,
        moveDate: formattedDate,
        startDate: formattedDate,
      });
      if (response.success) {
        Swal.fire({
          title: "Success!",
          text: "Room Move updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Children",
              "Room Move updated successfully"
            )
          );

          toggleModal();
        });
      }
    } catch (error: any) {
      const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
      const tt: any =
        typeof errorDetails == "string"
          ? errorDetails
          : Object.values(errorDetails)[0];
      Swal.fire({
        title: "Error!",
        text: tt || "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setRoomMoves({
      id: form.id,
      roomId: form.roomId,
      moveDate: form.moveDate,
    });
    handleResetRoomMoves({
      id: form.id,
      roomId: form.roomId,
      moveDate: form.moveDate,
    });
    setIsModalOpen(true);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onEditSubmit)}>
        <Col md={12} xl={12}>
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Registration and Rooms"}
            />
            <CardBody>
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  className="btn-md"
                  style={{ marginLeft: "10px" }}
                  onClick={() => toggleModal()}
                >
                  <i className="fa fa-plus me-2" /> New Room Move
                </Button>
              </div>
              <hr />

              {!noDataFound && (
                <>
                  <div className="mt-3 d-flex justify-content-between">
                    <div>{form.room.roomName}</div>
                    <div>{moment(form.moveDate).format("DD-MM-YYYY")}</div>
                    <div>
                      <Btn color="primary" type="button" onClick={handleEdit}>
                        Change
                      </Btn>
                    </div>
                  </div>
                  <hr />
                  <Row className="mt-2">
                    <Col md={6}>
                      <Label>{`Start Date`}</Label>{" "}
                      <InputGroup className="flatpicker-calender">
                        <Controller
                          control={control}
                          name="startDate"
                          rules={{
                            required: false,
                          }}
                          render={({ field }) => {
                            return (
                              <DatePicker
                                className={`form-control flatpickr-input`}
                                selected={form.startDate}
                                onChange={(date) => {
                                  field.onChange(date);
                                  saveDataForm("startDate", date);
                                }}
                                placeholderText="Select a date"
                                dateFormat="MMMM dd,yyyy"
                              />
                            );
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={6}>
                      <Label>{`Last Day`}</Label>{" "}
                      <InputGroup className="flatpicker-calender">
                        <Controller
                          control={control}
                          name="lastDate"
                          rules={{
                            required: false,
                          }}
                          render={({ field }) => {
                            return (
                              <DatePicker
                                className={`form-control flatpickr-input`}
                                selected={form.lastDate}
                                onChange={(date) => {
                                  field.onChange(date);
                                  saveDataForm("lastDate", date);
                                }}
                                placeholderText="Select a date"
                                dateFormat="MMMM dd,yyyy"
                              />
                            );
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={12}>
                      <Label>{`Nursery`}</Label> <br />
                      <span>{props?.initialState?.nursery?.name}</span>
                    </Col>
                  </Row>

                  <CardFooter className="text-end">
                    {" "}
                    <Btn color="primary" type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <span className="d-flex gap-2">
                          <Spinner color="white" size="sm" /> Loading...
                        </span>
                      ) : (
                        "Save & Update"
                      )}
                    </Btn>
                  </CardFooter>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Form>

      {/* Modal for Adding Job Title */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>
          {props.initialState.firstName} {props.initialState.lastName} <br />
          <h6>Current Room</h6>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmitRoomMoves(onSubmit)}>
            <Row className="mb-3">
              <Col md={12} className="position-relative">
                <Label>{"Select Room"}</Label>{" "}
                <span className="text-danger">*</span>
                <Controller
                  name="roomId"
                  control={handleControlRoomMoves}
                  rules={{
                    required: "Please select any one room Id",
                  }} // Validation rules
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="roomId"
                      className={``}
                      options={roomNames}
                      value={roomNames.find(
                        (option: any) => option.value === roomMoves.roomId
                      )} // Set current value
                      onChange={(selectedOption: any) => {
                        field.onChange(selectedOption?.value);
                        setRoomMoves({
                          ...roomMoves,
                          roomId: selectedOption?.value,
                        });
                      }} // Update the value using Controller's onChange
                    />
                  )}
                />
                {errorsRoomMoves.roomId && (
                  <FormFeedback
                    style={{ color: "orangeRed", display: "block" }}
                  >
                    {"Please select any one room Id"}{" "}
                  </FormFeedback>
                )}
              </Col>
              <Col md={12} className="position-relative mt-3">
                <Label>{"Move Date"}</Label>{" "}
                <span className="text-danger">*</span>
                <InputGroup className="flatpicker-calender">
                  <Controller
                    control={handleControlRoomMoves}
                    name="moveDate"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          className={`form-control flatpickr-input ${
                            errorsRoomMoves.moveDate ? "is-invalid" : ""
                          }`}
                          selected={roomMoves.moveDate}
                          onChange={(date) => {
                            field.onChange(date);
                            setRoomMoves({ ...roomMoves, moveDate: date });
                          }}
                          placeholderText="Select a date"
                          dateFormat="MMMM dd,yyyy"
                        />
                      );
                    }}
                  />

                  {errorsRoomMoves.moveDate && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Move Date is required"}{" "}
                    </FormFeedback>
                  )}
                </InputGroup>
              </Col>
            </Row>
            <ModalFooter>
              <Btn color="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="d-flex gap-2">
                    <Spinner color="white" size="sm" /> Loading...
                  </span>
                ) : (
                  "Save & Update"
                )}
              </Btn>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Rooms;
