import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import React, { useState } from 'react'
import { FaCarCrash, FaNotesMedical, FaUserCircle } from 'react-icons/fa';
import Accident from './Accident';
import { connectComponent } from '../MealPlan/connector';
import Medication from './Medication';

function Safeguarding(props: any) {

    const [selectedSection, setSelectedSection] = useState<string>("Accident");

    // Render the corresponding component based on selectedSection
    const renderComponent = () => {
        switch (selectedSection) {
            case "Accident":
                return (
                    <Accident nurseryId={props.nurseryId} />
                );
            case "Medication":
                return (
                    <Medication nurseryId={props.nurseryId} />
                );
            default:
                return null;
        }
    };

    const handleClick = (section: string) => {
        setSelectedSection(section);
    };

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    {/* Left Sidebar with Tabs */}
                    <Col md={3}>
                        <Card className="shadow-sm rounded border-0">
                            <CardBody>
                                <ul className="main-menu mt-3 setting-menu">
                                    {/* Accident Section */}
                                    <li className="mb-2">
                                        <button
                                            onClick={() => {
                                                handleClick("Accident");
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                background: selectedSection != "Accident" ? "#fff" : "",
                                                border: "none",
                                                color: "#000",
                                                fontSize: "16px",
                                                padding: "10px 15px",
                                                cursor: "pointer",
                                                transition: "background 0.3s ease", // Smooth background transition
                                            }}
                                            className={`text-dark ${selectedSection === "Accident"
                                                ? "badge-light-primary"
                                                : ""
                                                }`}
                                        >
                                            <div>
                                                <span
                                                    className="iconbg badge-light-primary p-2 fs-10 lh-0"
                                                    style={{
                                                        borderRadius: "100px",
                                                        lineHeight: "0px",
                                                        marginRight: "0.5rem",
                                                    }}
                                                >
                                                    <FaCarCrash />
                                                </span>
                                                <span
                                                    className=""
                                                    style={{
                                                        color: "#495057",
                                                        fontWeight: 600,
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Accident
                                                </span>
                                            </div>
                                        </button>
                                    </li>

                                    {/* Medication Section */}
                                    <li className="mb-2">
                                        <button
                                            onClick={() => {
                                                handleClick("Medication");
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                background: selectedSection != "Medication" ? "#fff" : "",
                                                border: "none",
                                                color: "#000",
                                                fontSize: "16px",
                                                padding: "10px 15px",
                                                cursor: "pointer",
                                                transition: "background 0.3s ease", // Smooth background transition
                                            }}
                                            className={`text-dark ${selectedSection === "Medication"
                                                ? "badge-light-primary"
                                                : ""
                                                }`}
                                        >
                                            <div>
                                                <span
                                                    className="iconbg badge-light-primary p-2 fs-10 lh-0"
                                                    style={{
                                                        borderRadius: "100px",
                                                        lineHeight: "0px",
                                                        marginRight: "0.5rem",
                                                    }}
                                                >
                                                    <FaNotesMedical />
                                                </span>
                                                <span
                                                    className=""
                                                    style={{
                                                        color: "#495057",
                                                        fontWeight: 600,
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Medication
                                                </span>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>

                    {/* Right Content for each tab */}
                    <Col md={9}>
                        {/* Render the selected section component */}
                        {renderComponent()}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default connectComponent(Safeguarding);