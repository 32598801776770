import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Row, Col, Card, CardBody, CardHeader,
    Input, Button, Badge, FormGroup, Label, UncontrolledTooltip,
    Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink,
    InputGroup, Alert, Table, TabContent, TabPane
} from 'reactstrap';
import { Calendar, ChevronLeft, ChevronRight, Save, Send, Edit, Plus, PlusCircle, Square, Package, Search, Filter, X } from 'react-feather';
import moment from 'moment';
import ActivityLibraryApi from '../../../services/ActivityLibraryApi';
import './style.scss';
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader';
import EyfApi from 'src/services/EyfApi';
import AsyncImage from 'src/Components/AsyncImage';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';

interface Activity {
    id: string;
    title: string;
    description: string;
    thumbnailUrl?: string;
    ageRangeStart: number;
    ageRangeEnd: number;
    learningArea: string;
    language: any;
    isPublic: boolean;
    badges?: string[];
}

interface Room {
    id: string;
    name: string;
}

interface TimeSlot {
    start: string;
    end: string;
}

interface DailyActivity {
    id: string;
    activityId: string;
    title: string;
    description: string;
    badges: {
        text: string;
        color: string;
    }[];
}

interface ActivityPlan {
    id?: string;
    activityId: string;
    roomId: string;
    date: string;
    timeSlot: TimeSlot;
    activity?: Activity;
}

interface ActivitySection {
    id: string;
    title: string;
    activities: { [day: string]: DailyActivity | null };
}

interface ActivityPlannerProps {
    user: any;
    nurseryId: string;
}

// Add new interface for LibraryActivity
interface LibraryActivity {
    id: string;
    title: string;
    description: string;
    thumbnailUrl?: string;
    ageRangeStart: number;
    ageRangeEnd: number;
    learningAreaId: string;
    languages: any;
    isPublic: boolean;
}

// Add interface for learning area
interface LearningArea {
    id: string;
    name: string;
    color?: string; // Optional color property
}

// Add interface for language
interface Language {
    id: string;
    name: string;
}

// Add interface for Nursery
interface Nursery {
    id: string;
    name: string;
}

// Define an interface for WeeklyPlan
interface WeeklyPlan {
    weekStartDate: string;
    weekEndDate: string;
    roomId: string | null;
    sections: Array<{
        sectionId: string;
        sectionTitle: string;
        activities: Array<{
            day: string;
            activities: DailyActivity[];
        }>
    }>;
    status: 'draft' | 'published';
}

const ActivityPlanner: React.FC<ActivityPlannerProps> = ({ user, nurseryId }) => {
    console.log('user: ', user);
    console.log('nurseryId: ', nurseryId);
    const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY-MM-DD'));
    console.log('selectedDate: ', selectedDate);
    const [weekDates, setWeekDates] = useState<{ [key: string]: string }>({});
    const [activities, setActivities] = useState<Activity[]>([]);
    const [rooms, setRooms] = useState<Room[]>([]);
    const [activityPlans, setActivityPlans] = useState<ActivityPlan[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<{ roomId: string | null }>({ roomId: null });

    // New state for daily activities
    const [dailyActivities, setDailyActivities] = useState<{ [day: string]: DailyActivity[] }>({});

    // State for activity sections
    const [activitySections, setActivitySections] = useState<ActivitySection[]>([]);

    // Add a new state to track sections
    const [sections, setSections] = useState<{ id: string, title: string }[]>([
        { id: 'daily-activities', title: 'Daily Activities' }
    ]);

    // Day names for the planner
    const days = ['MON', 'TUE', 'WED', 'THU', 'FRI'] as const;
    const fullDayNames = {
        'MON': 'Monday',
        'TUE': 'Tuesday',
        'WED': 'Wednesday',
        'THU': 'Thursday',
        'FRI': 'Friday'
    };

    // Add new state for modal
    const [isAddActivityModalOpen, setIsAddActivityModalOpen] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<string>('');
    const [selectedSectionId, setSelectedSectionId] = useState<string>('');
    const [activeModalTab, setActiveModalTab] = useState<string>('public');
    const [libraryActivities, setLibraryActivities] = useState<LibraryActivity[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [fromAge, setFromAge] = useState<string>('');
    const [toAge, setToAge] = useState<string>('');
    const [learningArea, setLearningArea] = useState<string>('');
    const [language, setLanguage] = useState<string>('');
    const [learningAreas, setLearningAreas] = useState<LearningArea[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [userNurseries, setUserNurseries] = useState<Nursery[]>([]);
    // Add state for edit mode and selected activity
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [selectedActivity, setSelectedActivity] = useState<DailyActivity | null>(null);

    // Add a new state variable to store all fetched activities
    const [allLibraryActivities, setAllLibraryActivities] = useState<LibraryActivity[]>([]);

    // Add new state for weekly plan
    const [weeklyPlan, setWeeklyPlan] = useState<any>(null);
    const [weeklyPlanId, setWeeklyPlanId] = useState<string | null>(null);

    // Initialize library activities, fetch languages and user nurseries
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await ActivityLibraryApi.getLanguages();
                if (response.success && Array.isArray(response.data)) {
                    setLanguages(response.data);
                }
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        // Initialize library activities
        setLibraryActivities([]);
        // Fetch languages
        fetchLanguages();
        // Set user nurseries from user prop
        if (user && user.nursery) {
            setUserNurseries(user.nursery);
        }
    }, [user]);

    // Fetch learning areas on component mount
    useEffect(() => {
        const fetchLearningAreas = async () => {
            try {
                const response = await EyfApi.getSubHeadings();
                if (response.success && Array.isArray(response.data)) {
                    const formattedAreas = response.data.map((area: { id: string; name: string | null }) => ({
                        id: area.id,
                        name: area.name || ''
                    }));
                    setLearningAreas(formattedAreas);
                }
            } catch (error) {
                console.error('Error fetching learning areas:', error);
            }
        };

        fetchLearningAreas();
    }, []);

    // Fetch activities and rooms on component mount
    useEffect(() => {
        fetchActivities();
        fetchRooms();

        // No need to fetch weekly plan here as it will be triggered by the other useEffect
        // that watches selectedDate and filter.roomId
    }, []);

    // This useEffect handles fetching weekly plan when date or room changes
    useEffect(() => {
        if (selectedDate) {
            const weekDays = getWeekDates(selectedDate);
            setWeekDates(weekDays);

            console.log('Fetching weekly plan due to date/room change:', selectedDate, filter.roomId, weekDays, nurseryId);

            // Only fetch if we have valid data
            if (weekDays['MON'] && weekDays['FRI'] && nurseryId) {
                fetchWeeklyPlan(weekDays);
            }
        }
    }, [selectedDate, filter.roomId, nurseryId]);

    const getWeekDates = (date: string, format: string = 'YYYY-MM-DD') => {
        const selectedMoment = moment(date);
        const monday = selectedMoment.clone().startOf('week').add(1, 'days');
        const weekDays: { [key: string]: string } = {};

        days.forEach((day, index) => {
            const currentDate = monday.clone().add(index, 'days');
            weekDays[day] = currentDate.format(format);
        });

        return weekDays;
    };

    const fetchActivities = async () => {
        setIsLoading(true);
        try {
            const response = await ActivityLibraryApi.getActivities();
            if (response.success && Array.isArray(response.data)) {
                setActivities(response.data);
            }
        } catch (error) {
            console.error('Error fetching activities:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRooms = async () => {
        // Mock data for rooms - replace with actual API call
        // In a real implementation, you would fetch rooms from the API using nurseryId
        setRooms([
            { id: 'room1', name: 'Sunshine Room' },
            { id: 'room2', name: 'Rainbow Room' },
            { id: 'room3', name: 'Star Room' },
        ]);
    };

    const handlePreviousWeek = () => {
        const newDate = moment(selectedDate).subtract(7, 'days').format('YYYY-MM-DD');
        setSelectedDate(newDate);
    };

    const handleNextWeek = () => {
        const newDate = moment(selectedDate).add(7, 'days').format('YYYY-MM-DD');
        setSelectedDate(newDate);
    };

    const handleRoomFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const roomId = e.target.value === '' ? null : e.target.value;
        setFilter({ ...filter, roomId });
    };

    const handleSave = async (publish: boolean = false) => {
        try {
            // Prepare the weekly plan data
            const weeklyPlan = {
                id: weeklyPlanId || undefined,
                weekStartDate: weekDates['MON'],
                weekEndDate: weekDates['FRI'],
                roomId: filter.roomId,
                nurseryId: nurseryId,
                sections: sections.map(section => ({
                    sectionId: section.id,
                    sectionTitle: section.title,
                    activities: days.map(day => ({
                        day,
                        activities: section.id === 'daily-activities'
                            ? (dailyActivities[day] || [])
                            : (dailyActivities[`${section.id}-${day}`] || [])
                    }))
                })),
                status: (publish ? 'published' : 'draft') as 'published' | 'draft'
            };

            console.log('Saving weekly plan:', weeklyPlan);
            let response;

            // If we have an existing plan ID, update it
            if (weeklyPlanId) {
                console.log(`Updating existing weekly plan with ID: ${weeklyPlanId}`);
                response = await ActivityLibraryApi.updateWeeklyPlan(weeklyPlanId, weeklyPlan, publish);
            } else {
                // Otherwise create a new plan
                console.log('Creating new weekly plan');
                response = await ActivityLibraryApi.saveWeeklyPlan(weeklyPlan, publish);
            }

            if (response.success) {
                // If this was a new plan, update the ID
                if (!weeklyPlanId && response.data?.id) {
                    console.log(`Setting new plan ID: ${response.data.id}`);
                    setWeeklyPlanId(response.data.id);
                }

                // Show success message using SweetAlert
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: publish
                        ? 'Plan published successfully!'
                        : weeklyPlanId
                            ? 'Plan updated successfully!'
                            : 'Plan saved successfully!',
                    timer: 2000,
                    showConfirmButton: false
                });
            } else {
                throw new Error(response.message || 'Failed to save plan');
            }
        } catch (error) {
            console.error('Error saving plan:', error);
            // Show error message using SweetAlert
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to save plan. Please try again.',
                confirmButtonText: 'OK'
            });
        }
    };

    const handlePublish = () => {
        handleSave(true);
    };

    const formatDate = (dateString: string) => {
        return moment(dateString).format('D MMM');
    };

    const handleAddNewSection = () => {
        // Generate a unique ID for the new section
        const newSectionId = `section-${Date.now()}`;

        // Add the new section to the sections array
        const newSection = {
            id: newSectionId,
            title: 'New Section'
        };

        setSections([...sections, newSection]);

        // Initialize empty activities for this section
        const newActivities = { ...dailyActivities };
        days.forEach(day => {
            // Create a placeholder empty activity for this section
            if (!newActivities[`${newSectionId}-${day}`]) {
                newActivities[`${newSectionId}-${day}`] = [];
            }
        });

        setDailyActivities(newActivities);
    };

    const handleAddActivity = (sectionId: string, day: string) => {
        console.log(`Adding activity for section ${sectionId}, day ${day}`);
        setSelectedSectionId(sectionId);
        setSelectedDay(day);
        setIsEditMode(false);
        setSelectedActivity(null);
        toggleAddActivityModal();
    };

    const handleEditActivity = (sectionId: string, day: string, activity?: DailyActivity) => {
        console.log(`Editing activity for section ${sectionId}, day ${day}`);

        // If activity is provided directly, use it
        if (activity) {
            setSelectedActivity(activity);
        } else {
            // Find the activity in the section/day
            const activities = sectionId === 'daily-activities'
                ? dailyActivities[day]
                : dailyActivities[`${sectionId}-${day}`];

            if (activities && activities.length > 0) {
                // For simplicity, assume we're editing the first activity
                // In a real implementation, you might want to show a selection dialog
                setSelectedActivity(activities[0]);
            }
        }

        setSelectedSectionId(sectionId);
        setSelectedDay(day);
        setIsEditMode(true);
        toggleAddActivityModal();
    };

    const handleViewDetails = (sectionId: string, day: string) => {
        console.log(`Viewing details for section ${sectionId}, day ${day}`);
        // For now, viewing details is the same as editing (in a real app, this might show a read-only view)
        handleEditActivity(sectionId, day);
    };

    // Function to toggle modal
    const toggleAddActivityModal = () => {
        console.log('isAddActivityModalOpen: ', isAddActivityModalOpen);
        setIsAddActivityModalOpen(!isAddActivityModalOpen);
        // Reset filters and fetch activities when opening modal
        if (!isAddActivityModalOpen) {
            setSearchTerm('');
            setFromAge('');
            setToAge('');
            setLearningArea('');
            setLanguage('');
            setActiveModalTab('public');
            // Fetch activities for public tab
            filterLibraryActivities('public');
        }
    };

    // Function to handle tab change
    const handleModalTabChange = (tab: string) => {
        setActiveModalTab(tab);
        // Reset filters when changing tabs
        setSearchTerm('');
        setFromAge('');
        setToAge('');
        setLearningArea('');
        setLanguage('');
        // Fetch activities for the selected tab
        // Pass the new tab value directly to ensure we're using the latest value
        filterLibraryActivities(tab);
    };

    // Modify the handleLibrarySearch function to just update state without filtering
    const handleLibrarySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        // Don't apply filters automatically
    };

    // Update the handleFilterButtonClick function to apply all filters
    const handleFilterButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        applyClientSideFilters();
    };

    // Add the handleFilterReset function back (it was accidentally removed)
    const handleFilterReset = () => {
        setSearchTerm('');
        setFromAge('');
        setToAge('');
        setLearningArea('');
        setLanguage('');
        // Show all activities for the current tab
        setLibraryActivities(allLibraryActivities);
    };

    // Add a new function to apply client-side filtering
    const applyClientSideFilters = () => {
        let filteredActivities = [...allLibraryActivities];

        // Apply search term filter
        if (searchTerm) {
            filteredActivities = filteredActivities.filter(activity =>
                activity.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                activity.description.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Apply age filter
        if (fromAge) {
            filteredActivities = filteredActivities.filter(activity =>
                activity.ageRangeStart >= parseInt(fromAge)
            );
        }

        if (toAge) {
            filteredActivities = filteredActivities.filter(activity =>
                activity.ageRangeEnd <= parseInt(toAge)
            );
        }

        // Apply learning area filter
        if (learningArea) {
            filteredActivities = filteredActivities.filter(activity =>
                activity.learningAreaId === learningArea
            );
        }

        // Apply language filter
        if (language) {
            filteredActivities = filteredActivities.filter(activity =>
                activity.languages && activity.languages.some((lang: any) =>
                    lang.languageCode === language
                )
            );
        }

        // Set the filtered activities to display
        setLibraryActivities(filteredActivities);
    };

    // Update the filterLibraryActivities to only fetch activities, not apply client-side filters
    const filterLibraryActivities = async (tabOverride?: string) => {
        try {
            // Use the override tab value if provided, otherwise use the state variable
            const currentTab = tabOverride || activeModalTab;

            // Prepare filter parameters - only keep visibility and nurseryId as server-side filters
            const params = {
                visibility: currentTab === 'public' ? 'all' :
                    currentTab === 'my-activities' ? 'self' :
                        currentTab.startsWith('nursery-') ? 'assigned' : undefined,
                nurseryId: currentTab.startsWith('nursery-') ? currentTab.replace('nursery-', '') : undefined
            };

            console.log('Fetching activities with params:', params);

            // Call the API with only tab-related filters
            const response = await ActivityLibraryApi.getActivities(params);
            if (response.success && Array.isArray(response.data.data)) {
                // Store all fetched activities
                setAllLibraryActivities(response.data.data);
                // Initially show all fetched activities without applying any client-side filters
                setLibraryActivities(response.data.data);

                // Reset search and filter values when changing tabs
                if (tabOverride) {
                    setSearchTerm('');
                    setFromAge('');
                    setToAge('');
                    setLearningArea('');
                    setLanguage('');
                }
            }
        } catch (error) {
            console.error('Error filtering activities:', error);
            setAllLibraryActivities([]);
            setLibraryActivities([]);
        }
    };

    // Function to add activity to planner
    const addActivityToPlan = (activity: LibraryActivity) => {
        console.log(`Adding activity ${activity.id} to ${selectedDay} in section ${selectedSectionId}`);

        // Create a new activity object
        const newActivity: DailyActivity = {
            id: `${selectedDay.toLowerCase()}-${Date.now()}`,
            activityId: activity.id,
            title: activity.title,
            description: `Activity: ${activity.description}`,
            badges: generateBadgesFromLearningArea(activity.learningAreaId)
        };

        // Add the new activity to the daily activities
        const updatedActivities = { ...dailyActivities };
        if (selectedSectionId === 'daily-activities') {
            updatedActivities[selectedDay] = [...(updatedActivities[selectedDay] || []), newActivity];
        } else {
            // For other sections, handle differently if needed
            const sectionDayKey = `${selectedSectionId}-${selectedDay}`;
            updatedActivities[sectionDayKey] = [...(updatedActivities[sectionDayKey] || []), newActivity];
        }

        setDailyActivities(updatedActivities);
        toggleAddActivityModal(); // Close the modal after adding
    };

    // Helper function to generate badges from learning area
    const generateBadgesFromLearningArea = (learningAreaId: string): { text: string, color: string }[] => {
        const badges: { text: string, color: string }[] = [];

        // Get the full learning area name from the ID
        const areaName = getLearningAreaName(learningAreaId);

        if (areaName) {
            // Split the learning area name into words
            const words = areaName.split(' ');

            // Generate badges from words (2-3 letters per badge)
            words.forEach(word => {
                if (word.length >= 2) {
                    // Take first 2-3 letters based on word length
                    const badgeText = word.substring(0, Math.min(3, word.length)).toUpperCase();

                    // Use predefined reactstrap colors since learning areas might not have color property
                    // Map learning area to a consistent color
                    const colorMap = learningAreas.find(a => a.id === learningAreaId);

                    // Use the mapped color or default to primary
                    const badgeColor = colorMap?.color || 'primary';

                    badges.push({ text: badgeText, color: badgeColor });
                }
            });
        }

        // If no badges were created (e.g., area name was too short), add a default badge
        if (badges.length === 0) {
            badges.push({ text: 'LA', color: 'primary' });
        }

        return badges;
    };

    // Helper function to get learning area name
    const getLearningAreaName = (learningArea: string): string => {
        const area = learningAreas.find(a => a.id === learningArea);
        return area ? area.name : learningArea;
    };

    // Helper function to get learning area name
    const getLanguageName = (language: string): string => {
        const area = languages.find(a => a.id === language);
        return area ? area.name : language;
    };

    // Function to remove activity from planner
    const removeActivityFromPlan = () => {
        if (!selectedActivity) return;

        // Remove the activity from the dailyActivities state
        const updatedActivities = { ...dailyActivities };
        const key = selectedSectionId === 'daily-activities' ? selectedDay : `${selectedSectionId}-${selectedDay}`;

        if (updatedActivities[key]) {
            updatedActivities[key] = updatedActivities[key].filter(
                activity => activity.id !== selectedActivity.id
            );

            setDailyActivities(updatedActivities);
        }

        // Close the modal
        toggleAddActivityModal();
    };

    // Add function to fetch weekly plan
    const fetchWeeklyPlan = async (weekDays: { [key: string]: string }) => {
        console.log('weekDays: ', weekDays);
        if (!weekDays['MON'] || !weekDays['FRI'] || !nurseryId) {
            console.warn('Missing required data to fetch weekly plan:', {
                monday: weekDays['MON'],
                friday: weekDays['FRI'],
                nurseryId
            });
            return;
        }

        try {
            setIsLoading(true);
            console.log('Fetching weekly plan with params:', {
                startDate: weekDays['MON'],
                endDate: weekDays['FRI'],
                nurseryId,
                roomId: filter.roomId
            });

            const response = await ActivityLibraryApi.getWeeklyPlanByDateRange(
                weekDays['MON'],
                weekDays['FRI'],
                nurseryId,
                filter.roomId
            );

            console.log('Weekly plan API response:', response);

            if (response && response.success) {
                if (response.data) {
                    setWeeklyPlan(response.data);
                    // Set the weeklyPlanId from the response
                    if (response.data.id) {
                        setWeeklyPlanId(response.data.id);
                    }
                    // Update daily activities based on the weekly plan
                    const updatedDailyActivities = mapWeeklyPlanToDailyActivities(response.data);
                    setDailyActivities(updatedDailyActivities);
                    console.log('Loaded daily activities:', updatedDailyActivities);
                } else {
                    console.log('No existing weekly plan found, creating a new one');
                    setWeeklyPlan(null);
                    setWeeklyPlanId(null);
                    setDailyActivities({});
                }
            } else {
                console.error('Failed to fetch weekly plan:', response?.message || 'Unknown error');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response?.message || 'An error occurred while fetching the weekly plan',
                    confirmButtonText: 'OK'
                });
                setWeeklyPlan(null);
                setWeeklyPlanId(null);
                setDailyActivities({});
            }
        } catch (error) {
            console.error('Exception in fetchWeeklyPlan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred while fetching the weekly plan',
                confirmButtonText: 'OK'
            });
            setWeeklyPlan(null);
            setWeeklyPlanId(null);
            setDailyActivities({});
        } finally {
            setIsLoading(false);
        }
    };

    // Function to map weekly plan data to dailyActivities format
    const mapWeeklyPlanToDailyActivities = (plan: any) => {
        const newDailyActivities: { [day: string]: DailyActivity[] } = {};

        // Initialize empty activities for all days and sections
        days.forEach(day => {
            newDailyActivities[day] = [];
            sections.forEach(section => {
                if (section.id !== 'daily-activities') {
                    newDailyActivities[`${section.id}-${day}`] = [];
                }
            });
        });

        // Populate activities from the plan
        if (plan.sections) {
            plan.sections.forEach((sectionData: any) => {
                // Find the matching section
                const sectionExists = sections.find(s => s.id === sectionData.sectionId);

                // If section doesn't exist, add it
                if (!sectionExists && sectionData.sectionId) {
                    setSections(prev => [...prev, {
                        id: sectionData.sectionId,
                        title: sectionData.sectionTitle || 'Section'
                    }]);
                }

                // Add activities to the correct section/day
                sectionData.activities.forEach((dayData: any) => {
                    if (dayData.day && dayData.activities) {
                        const key = sectionData.sectionId === 'daily-activities'
                            ? dayData.day
                            : `${sectionData.sectionId}-${dayData.day}`;

                        newDailyActivities[key] = dayData.activities;
                    }
                });
            });
        }

        return newDailyActivities;
    };

    // Initialize empty activities for all days and sections
    const initializeEmptyActivities = () => {
        const emptyActivities: { [day: string]: DailyActivity[] } = {};

        days.forEach(day => {
            emptyActivities[day] = [];
            sections.forEach(section => {
                if (section.id !== 'daily-activities') {
                    emptyActivities[`${section.id}-${day}`] = [];
                }
            });
        });

        setDailyActivities(emptyActivities);
    };

    return (
        <div className="page-body">
            <Container fluid className="activity-planner-container pt-4">
                <Card className="shadow-sm">
                    <CardHeader className="planner-card-header">
                        <CommonCardHeader
                            headClass="pb-0 card-no-border"
                            title={`Weekly Schedule`}
                            subTitle={[]}
                            titleClass="mb-3"
                        />

                        {/* Improved header layout with clearer sections */}
                        <Row className="align-items-center">
                            <Col xs={12} md={5} className="mb-3 mb-md-0">
                                <div className="d-flex align-items-center week-navigation justify-content-center">
                                    <Button
                                        color="light"
                                        className="btn-icon me-2"
                                        onClick={handlePreviousWeek}
                                    >
                                        <ChevronLeft size={18} />
                                    </Button>
                                    <div className="week-selector-container">
                                        <span className="week-selector">
                                            <Calendar size={16} className="me-1" />
                                            {formatDate(weekDates['MON'])} - {formatDate(weekDates['FRI'])}
                                        </span>
                                    </div>
                                    <Button
                                        color="light"
                                        className="btn-icon ms-2"
                                        onClick={handleNextWeek}
                                    >
                                        <ChevronRight size={18} />
                                    </Button>
                                </div>
                            </Col>

                            <Col xs={12} md={7}>
                                <div className="d-flex align-items-center justify-content-md-end">
                                    <div className="room-filter me-3">
                                        <FormGroup className="d-flex align-items-center" noMargin>
                                            <Label for="roomFilter" className="me-2 mb-0">Room:</Label>
                                            <div style={{ width: '180px' }}>
                                                <Input
                                                    id="roomFilter"
                                                    type="select"
                                                    bsSize="sm"
                                                    value={filter.roomId || ''}
                                                    onChange={handleRoomFilterChange}
                                                >
                                                    <option value="">All Rooms</option>
                                                    {rooms.map(room => (
                                                        <option key={room.id} value={room.id}>
                                                            {room.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </div>
                                        </FormGroup>
                                    </div>

                                    <div className="action-buttons">
                                        <Button
                                            color="outline-primary"
                                            className="me-2"
                                            onClick={() => handleSave()}
                                        >
                                            <Save size={16} className="me-1" />
                                            Save
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => handlePublish()}
                                        >
                                            <Send size={16} className="me-1" />
                                            Publish
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>
                        <div className="weekly-activity-planner">
                            {/* Improved planner header with more distinct styling */}
                            <div className="planner-header">
                                <div className="label-column">
                                    <div className="label-title">Activities</div>
                                </div>
                                {days.map(day => (
                                    <div key={day} className="day-column">
                                        <div className="day-name">{fullDayNames[day]}</div>
                                        <div className="day-date">{formatDate(weekDates[day])}</div>
                                    </div>
                                ))}
                            </div>

                            {/* Show loading indicator when fetching data */}
                            {isLoading ? (
                                <div className="text-center py-5">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p className="mt-3">Loading weekly plan...</p>
                                </div>
                            ) : (
                                <>
                                    {/* Table Body with activities - improved spacing and organization */}
                                    <div className="planner-body">
                                        {sections.map((section) => (
                                            <div key={section.id} className="activity-row">
                                                <div className="label-column">
                                                    <div className="section-title">{section.title}</div>
                                                </div>
                                                {days.map(day => {
                                                    // Determine the correct key to access activities for this section/day
                                                    const activitiesKey = section.id === 'daily-activities' ? day : `${section.id}-${day}`;
                                                    const sectionActivities = dailyActivities[activitiesKey] || [];

                                                    return (
                                                        <div key={day} className="day-activities">
                                                            {sectionActivities.length > 0 ? (
                                                                <div className="activities-container">
                                                                    {sectionActivities.map(activity => (
                                                                        <div key={activity.id} className="activity-block">
                                                                            <div className="activity-description">
                                                                                {activity.description}
                                                                            </div>
                                                                            <div className="activity-card">
                                                                                <div className="card-content">
                                                                                    <div className="card-header">
                                                                                        <h6 className="card-title">
                                                                                            {activity.title}
                                                                                        </h6>
                                                                                        <div className="card-actions">
                                                                                            <button
                                                                                                className="icon-button edit-button"
                                                                                                onClick={() => handleEditActivity(section.id, day, activity)}
                                                                                                title="Edit Activity"
                                                                                            >
                                                                                                <Edit size={14} />
                                                                                            </button>
                                                                                            <button
                                                                                                className="icon-button package-button"
                                                                                                onClick={() => handleViewDetails(section.id, day)}
                                                                                                title="View Details"
                                                                                            >
                                                                                                <Package size={14} />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="card-tags">
                                                                                        {activity.badges.map((badge, idx) => (
                                                                                            <Badge key={idx} color={badge.color} className="activity-badge">
                                                                                                {badge.text}
                                                                                            </Badge>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {/* Add activity button with improved styling */}
                                                                    <div className="add-more-activity">
                                                                        <Button
                                                                            color="light"
                                                                            className="add-activity-btn"
                                                                            onClick={() => handleAddActivity(section.id, day)}
                                                                        >
                                                                            <Plus size={14} className="me-1" />
                                                                            Add Activity
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="empty-activity">
                                                                    <Button
                                                                        color="light"
                                                                        className="add-activity-btn"
                                                                        onClick={() => handleAddActivity(section.id, day)}
                                                                    >
                                                                        <Plus size={14} className="me-1" />
                                                                        Add Activity
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Add Section Button with improved styling */}
                                    <div className="add-section-container">
                                        <Button
                                            color="light"
                                            className="add-section-btn"
                                            onClick={handleAddNewSection}
                                        >
                                            <Plus size={16} className="me-2" />
                                            Add section
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </CardBody>
                </Card>

                {/* Add Activity Modal - improved layout */}
                <Modal isOpen={isAddActivityModalOpen} toggle={toggleAddActivityModal} size="xl">
                    <ModalHeader toggle={toggleAddActivityModal} className="modal-header-with-border">
                        {isEditMode ? `Edit Activity - ${selectedActivity?.title}` : `Add Activity to ${fullDayNames[selectedDay as keyof typeof fullDayNames]}`}
                    </ModalHeader>
                    <ModalBody>
                        <>
                            <Nav tabs className="mb-4 custom-nav-tabs">
                                <NavItem>
                                    <NavLink
                                        className={activeModalTab === 'public' ? 'active text-primary' : ''}
                                        onClick={() => handleModalTabChange('public')}
                                    >
                                        Public Activities
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeModalTab === 'my-activities' ? 'active text-primary' : ''}
                                        onClick={() => handleModalTabChange('my-activities')}
                                    >
                                        My Activities
                                    </NavLink>
                                </NavItem>
                                {userNurseries.map(nursery => (
                                    <NavItem key={nursery.id}>
                                        <NavLink
                                            className={activeModalTab === `nursery-${nursery.id}` ? 'active text-primary' : ''}
                                            onClick={() => handleModalTabChange(`nursery-${nursery.id}`)}
                                        >
                                            {nursery.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>

                            {/* Improved filter card layout */}
                            <Card className="filter-card shadow-sm mb-4">
                                <CardBody className="p-4">
                                    <Row>
                                        <Col lg={4} md={12} className="mb-3 mb-lg-0">
                                            <Label className="form-label">Search</Label>
                                            <Input
                                                type="text"
                                                placeholder="Search activities..."
                                                value={searchTerm}
                                                onChange={handleLibrarySearch}
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col lg={8} md={12}>
                                            <Row>
                                                <Col sm={12} md={4} className="mb-2 mb-md-0">
                                                    <div className="form-group">
                                                        <label className="form-label">Age in months</label>
                                                        <div className="d-flex age-inputs">
                                                            <Input
                                                                type="number"
                                                                placeholder="From"
                                                                className="age-input me-2"
                                                                value={fromAge}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFromAge(e.target.value)}
                                                            />
                                                            <span className="my-auto px-1">-</span>
                                                            <Input
                                                                type="number"
                                                                placeholder="To"
                                                                className="age-input ms-2"
                                                                value={toAge}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToAge(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={12} md={4} className="mb-2 mb-md-0">
                                                    <div className="form-group">
                                                        <label className="form-label">Learning area</label>
                                                        <Input
                                                            type="select"
                                                            value={learningArea}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLearningArea(e.target.value)}
                                                        >
                                                            <option value="">Select...</option>
                                                            {learningAreas.map((area) => (
                                                                <option key={area.id} value={area.id}>
                                                                    {area.name}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </div>
                                                </Col>

                                                <Col sm={12} md={4} className="mb-2 mb-md-0">
                                                    <div className="form-group">
                                                        <label className="form-label">Language</label>
                                                        <Input
                                                            type="select"
                                                            value={language}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLanguage(e.target.value)}
                                                        >
                                                            <option value="">Select...</option>
                                                            {languages.map((lang) => (
                                                                <option key={lang.id} value={lang.id}>
                                                                    {lang.name}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </div>
                                                </Col>

                                                <Col sm={12} md={12} className="mt-3">
                                                    <div className="d-flex justify-content-end w-100">
                                                        <Button color="primary" onClick={handleFilterButtonClick} className="d-flex align-items-center me-2">
                                                            <Filter size={16} className="me-1" /> Filter
                                                        </Button>
                                                        <Button outline color="secondary" className="d-flex align-items-center" onClick={handleFilterReset}>
                                                            <X size={16} className="me-1" /> Reset
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Improved activities grid layout */}
                            <Row>
                                {libraryActivities.length === 0 ? (
                                    <Col>
                                        <div className="text-center py-5">
                                            <p>No activities found. Try adjusting your filters.</p>
                                        </div>
                                    </Col>
                                ) : (
                                    <div className="activities-grid row">
                                        {libraryActivities.map((activity) => {
                                            const isSelected = isEditMode && selectedActivity?.activityId == activity.id;
                                            return (
                                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={activity.id}>
                                                    <Card className={`h-100 shadow-sm activity-card ${isSelected ? 'selected' : ''}`}>
                                                        {activity.thumbnailUrl && (
                                                            <div className="activity-image-container" style={{ maxHeight: '250px' }}>
                                                                <AsyncImage src={activity.thumbnailUrl} alt={activity.title} className="activity-image" folder="activity-library" style={{ maxHeight: '250px', objectFit: 'cover', width: '100%', height: '100%' }} />
                                                            </div>
                                                        )}
                                                        <CardBody>
                                                            <div className="d-flex justify-content-between mb-2">
                                                                <Badge color={activity.isPublic ? 'success' : 'warning'} className="mb-2">
                                                                    {activity.isPublic ? 'Public' : 'Private'}
                                                                </Badge>
                                                                <Badge color="info" className="mb-2">
                                                                    {activity.ageRangeStart} - {activity.ageRangeEnd} months
                                                                </Badge>
                                                            </div>

                                                            <h5 className="card-title">{activity.title}</h5>
                                                            <p className="card-text text-muted mb-3">{activity.description}</p>

                                                            <div className="mb-3 d-flex flex-wrap gap-1">
                                                                <Badge color="primary" className="me-1">
                                                                    {getLearningAreaName(activity.learningAreaId)}
                                                                </Badge>
                                                                <Badge color="secondary">
                                                                    {activity.languages.map((lang: any) => getLanguageName(lang.languageCode)).join(', ')}
                                                                </Badge>
                                                            </div>

                                                            <div className="d-grid">
                                                                {isSelected ? (
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={removeActivityFromPlan}
                                                                    >
                                                                        Remove Activity
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => addActivityToPlan(activity)}
                                                                    >
                                                                        Add to Plan
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleAddActivityModal}>
                            {isEditMode ? 'Close' : 'Cancel'}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

// Interface declaration for Form component
interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
    onSubmit: (e: React.FormEvent) => void;
}

// Form component
const Form: React.FC<FormProps> = ({ children, onSubmit, ...props }) => {
    return (
        <form onSubmit={onSubmit} {...props}>
            {children}
        </form>
    );
};

export default ActivityPlanner; 