import api from "./api"; // Use the Axios instance with interceptor

export default class EnquiryAPi {
    static async createEnquiry(data: any) {
        try {
            const response = await api.post(`/enquiry/add`, data); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async fetchEnquiry(data: any) {
        try {
            const response = await api.get(`/enquiry`, { params: data }); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async deleteEnquiry(id: any) {
        try {
            const response = await api.delete(`/enquiry/delete/${id}`); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async updateEnquiry(id: any, data: any) {
        try {
            const response = await api.patch(`/enquiry/update/${id}`, data); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }
}
