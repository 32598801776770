import api from "./api"; // Use the Axios instance with interceptor

export default class RoomOverviewApi {
  static async getRoomOverview(params: any) {
    try {
      const response = await api.get("/room", {
        params,
      });
      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createRoomOverview(data: {
    roomName: string;
    description: string;
    color: string;
    capacity: number;
    staffRatio: number;
    nurseryId?: string;
    // createdBy: string;
  }) {
    try {
      // Ensure that capacity and staffRatio are numbers (in case they're passed as strings)
      const formData = {
        ...data,
        capacity:
          typeof data.capacity === "string"
            ? parseInt(data.capacity, 10)
            : data.capacity,
        staffRatio:
          typeof data.staffRatio === "string"
            ? parseInt(data.staffRatio, 10)
            : data.staffRatio,
      };

      const response = await api.post("/room/add", formData);
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateRoomOverview(data: {
    id: string;
    roomName?: string;
    description?: string;
    color?: string;
    capacity?: number;
    staffRatio?: number;
    nurseryId?: string;
    // updatedBy: string;
  }) {
    try {
      // Ensure that capacity and staffRatio are numbers (in case they're passed as strings)
      const formData = {
        ...data,
        capacity:
          typeof data.capacity === "string"
            ? parseInt(data.capacity, 10)
            : data.capacity,
        staffRatio:
          typeof data.staffRatio === "string"
            ? parseInt(data.staffRatio, 10)
            : data.staffRatio,
      };

      const response = await api.patch(`/room/update`, formData);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Deletes a specific room overview (soft delete)
  static async deleteRoomOverview(id: string, deletedBy: string) {
    try {
      const response = await api.patch(`/room/${id}`, {
        isDeleted: 1,
        deletedBy: deletedBy,
        deletedAt: new Date().toISOString(),
      });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
