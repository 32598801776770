import api from "./api";

interface SessionTime {
    id: string;
    startTime: Date;
    endTime: Date;
    remarks?: string;
    status: number;
    nurseryId: string;
}

export default class SessionTimeApi {
    static async getSessionTimes(nurseryId: string) {
        try {
            const response = await api.get(`/session-times?nurseryId=${nurseryId}`);
            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async createSessionTime(data: Omit<SessionTime, 'id'>) {
        try {
            const response = await api.post("/session-times", data);
            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async updateSessionTime(id: string, data: Partial<SessionTime>) {
        try {
            const response = await api.put(`/session-times/${id}`, data);
            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }
} 