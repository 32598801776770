import { connect } from "react-redux";
import {
  addForm,
  FETCH_EYFS,
  SET_MESSAGE,
  updateForm,
} from "../../../ReduxToolkit/Slices/Eyfs/EyfsReducer";

const mapStateToProps = (state: any) => {
  return {
    eyfs: state.eyfs.eyfs,
    isLoading: state.eyfs.isLoading,
    error: state.eyfs.error,
    success: state.eyfs.success,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchEyfs: (value: any) => dispatch(FETCH_EYFS(value)),
    addForm: (value: any) => dispatch(addForm(value)),
    updateForm: (value: any) => dispatch(updateForm(value)),
    setMessage: (value: any) => {
      dispatch(SET_MESSAGE(value));
    },
  };
};

export const connectComponent = (Component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
};
