import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../Data/Tables/DataTables/DataSource";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { columnsDetail } from "./Helper/column";

function DetailDocuments() {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const filteredItems: HtmlColumnsInterface[] = htmlColumnsTableData.filter(
    (item: HtmlColumnsInterface) => {
      return Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Uploaded Documents Details`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <div className="d-flex">
                  <Button
                    color="dark"
                    className="btn-md d-flex align-items-center"
                    onClick={() => {
                      navigate(
                        `${process.env.PUBLIC_URL}/dashboard/documents/all`
                      );
                    }}
                  >
                    <i
                      className="iconly-Arrow-Left icli"
                      style={{ marginRight: "0.5rem" }}
                    ></i>{" "}
                    Back
                  </Button>
                  <Button
                    color="primary"
                    className="btn-md m-4"
                    onClick={() => {
                      navigate(
                        `${process.env.PUBLIC_URL}/dashboard/documents/all`
                      );
                    }}
                  >
                    Upload New Documents
                  </Button>
                </div>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columnsDetail}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DetailDocuments;
