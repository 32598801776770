import { Card, CardBody, Col, FormFeedback, Label, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

const SocialMedia = (props: any) => {
  const navigate = useNavigate();

  return (
    <Row className="g-3 mt-2">
      <Col md={4}>
        <Label for="facebook">
          Facebook Link <span className="text-danger">*</span>
        </Label>
        <input
          type="text"
          className={`form-control ${
            props.error.facebookLink ? "is-invalid" : ""
          }`}
          placeholder="https://facebook.com/yournurserypage"
          value={props.form.facebookLink}
          onChange={(e) => props.saveDataForm("facebookLink", e.target.value)}
        />
        {props.error.facebookLink && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {"Facebook Link is required"}{" "}
          </FormFeedback>
        )}
      </Col>
      <Col md={4}>
        <Label for="instagram">
          Instagram Link <span className="text-danger">*</span>
        </Label>
        <input
          type="text"
          className={`form-control ${
            props.error.instagramLink ? "is-invalid" : ""
          }`}
          placeholder="https://instagram.com/yournurseryprofile"
          value={props.form.instagramLink}
          onChange={(e) => props.saveDataForm("instagramLink", e.target.value)}
        />
        {props.error.instagramLink && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {"Instagram Link is required"}{" "}
          </FormFeedback>
        )}
      </Col>
      <Col md={4}>
        <Label for="twitter">Twitter Link</Label>
        <input
          type="text"
          className={`form-control`}
          placeholder="https://twitter.com/yournurseryprofile"
          value={props.form.twitterLink}
          onChange={(e) => props.saveDataForm("twitterLink", e.target.value)}
        />
      </Col>
      <Col md={4}>
        <Label for="linkedin">LinkedIn Link</Label>
        <input
          type="text"
          className={`form-control`}
          placeholder="https://linkedin.com/in/yournurseryprofile"
          value={props.form.linkedinLink}
          onChange={(e) => props.saveDataForm("linkedinLink", e.target.value)}
        />
      </Col>
      <Col md={4}>
        <Label for="youtube">YouTube Link</Label>
        <input
          type="text"
          className={`form-control`}
          placeholder="https://youtube.com/yournurserychannel"
          value={props.form.youtubeLink}
          onChange={(e) => props.saveDataForm("youtubeLink", e.target.value)}
        />
      </Col>
    </Row>
  );
};

export default SocialMedia;
