import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PricingBrandsAPi from "src/services/PricingBrandsApi";
import { connectComponent } from "../connector";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Btn, LI, UL } from "src/AbstractElements";
import EyfApi from "src/services/EyfApi";
import { statusColors } from "src/Utils/Constants";
import Status from "src/Components/Common/Status";

interface Heading {
    id: string;
    name: string;
    color: string;
    status: number;
}

const CustomHeader = ({ title }: { title: string }) => (
    <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
        {title}
    </span>
);

function Heading(props: any) {
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState("");
    const [heading, setHeading] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedHeading, setSelectedHeading] = useState<Partial<Heading>>({});
    const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});
    const toggleDropdownOne = (id: any) => {
        setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
    };

    const filteredItems: any = heading.filter((item: any) => {
        return Object.values(item).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const fetchHeading = async () => {
        try {
            const response = await EyfApi.getHeadings();
            if (response.success && Array.isArray(response.data)) {
                setHeading(response.data);
            } else setHeading([]);
        } catch (error: any) {
            setHeading([]);
        }
    };

    useEffect(() => {
        fetchHeading();
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Heading>();

    const toggleModal = () => {
        setIsModalOpen((prevState) => !prevState);
        // When modal closes, reset the form
        if (isModalOpen) {
            reset({}); // Reset form to empty state when closing
            setSelectedHeading({}); // Clear selected Price Brand
        } else {
            // If opening modal, reset with selected Price Brand data for edit, otherwise with empty data for add
            reset(selectedHeading);
        }
    };

    const handleEditHeading = (heading: Heading) => {
        setSelectedHeading(heading);
        toggleModal();
    };

    // Handle form submission for both create and update
    const onSubmit = async (data: Heading) => {
        setIsLoading(true);
        try {
            setTimeout(async () => {
                if (selectedHeading.id) {
                    // Update Price Brand
                    await EyfApi.updateHeading(selectedHeading.id, { ...data });
                    Swal.fire({
                        title: "Success!",
                        text: "Heading updated successfully",
                        icon: "success",
                    });
                } else {
                    // Create new Price Brand
                    await EyfApi.createHeading({ ...data });
                    Swal.fire({
                        title: "Success!",
                        text: "Heading created successfully",
                        icon: "success",
                    });

                }
                // Reload the data after successful operation
                fetchHeading();
                toggleModal();
            }, 2000);
        } catch (error: any) {
            Swal.fire({
                title: "Error!",
                text: error.message || "Something went wrong",
                icon: "error",
            });
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2300);
        }
    };

    const handleStatusChange = async (row: any, status: string) => {
        const currentStatus = status === "ACTIVE" ? 0 : 1; // Determine the current status as a string
        const id = row.id;
        try {
            // Call the API to update the status, passing the number value
            await EyfApi.updateHeading(id, {
                status: currentStatus,
            });
            toggleDropdownOne(id);
            // Update the local state to reflect the status change
            await fetchHeading();
        } catch (error: any) {
            Swal.fire("Error", error.message || "Failed to update status", "error");
        }
    };

    const columns: TableColumn<any>[] = [
        {
            name: <CustomHeader title="Name" />,
            selector: (row) => row.name,
            style: { maxWidth: "50%" },
            sortable: true,
            center: false,
        },
        {
            name: <CustomHeader title="Color" />,
            selector: (row) => row.color,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <div style={{ backgroundColor: row.color, width: "25px", height: "25px", borderRadius: "50%" }}></div>
            ),
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => (row.status === 0 ? "ACTIVE" : "INACTIVE"),
            style: { maxWidth: "50%" },
            sortable: true,
            center: false,
            cell: (row) => (
                <Status
                    position={row.status === 0 ? "ACTIVE" : "INACTIVE"}
                    color={row.status === 0 ? statusColors.active : statusColors.inactive}
                    row={row}
                    id={row.id}
                    newUI={true}
                    statusList={["ACTIVE", "INACTIVE"]}
                    handleStatusChange={handleStatusChange}
                    toggleDropdownOne={toggleDropdownOne}
                    dropdownOpenOne={dropdownOpenOne}
                />
            ),
        },
        {
            name: <CustomHeader title="Action" />,
            sortable: true,
            center: true,
            style: { maxWidth: "50%" },
            cell: (row) => (
                <UL className="action simple-list flex-row">
                    <LI className="edit">
                        <a
                            className="icon"
                            href="javascript:void(0);"
                            onClick={() => handleEditHeading(row)}
                        >
                            <Btn color="primary" size="sm">
                                <i
                                    className="fa-solid fa-pencil text-white me-1"
                                    style={{ fontSize: "12px" }}
                                ></i>
                                {"Edit"}
                            </Btn>
                        </a>
                    </LI>
                </UL>
            ),
        },
    ];

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ borderBottom: "5px solid #eee" }}
                            >
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`View All Heading`}
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                                <Button
                                    color="primary"
                                    className="btn-md m-4"
                                    onClick={toggleModal}
                                >
                                    Add New Heading
                                </Button>
                            </div>
                            <CardBody>
                                <Row className="justify-content-end">
                                    <Col md={4}>
                                        <FilterComponent
                                            onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFilterText(e.target.value)
                                            }
                                            filterText={filterText}
                                        />
                                    </Col>
                                </Row>

                                <div className="table-responsive">
                                    <DataTable
                                        data={filteredItems}
                                        columns={columns}
                                        pagination
                                        className="display"
                                        conditionalRowStyles={[
                                            {
                                                when: (row) => true, // This applies to all rows
                                                style: {
                                                    "&:hover": {
                                                        backgroundColor: "rgba(48, 142, 135, 0.2)",
                                                        cursor: "pointer",
                                                    },
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Modal for Adding Price Brand */}
            <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static">
                <ModalHeader toggle={toggleModal}>
                    {selectedHeading.id ? "Edit Heading" : "Add Heading"}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label for="title">
                                Name <span className="text-danger">*</span>
                            </Label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? "is-invalid" : ""
                                    }`}
                                id="name"
                                {...register("name", {
                                    required: "Heading Name is required",
                                })}
                                defaultValue={selectedHeading.name}
                            />
                            {errors.name && (
                                <FormFeedback>{errors.name.message}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="color">
                                Color <span className="text-danger">*</span>
                            </Label>
                            <input
                                type="color"
                                className={`form-control ${errors.color ? "is-invalid" : ""
                                    }`}
                                id="color"
                                {...register("color", {
                                    required: "Color is required",
                                })}
                                defaultValue={selectedHeading.color}
                            />
                            {errors.color && (
                                <FormFeedback>{errors.color.message}</FormFeedback>
                            )}
                        </FormGroup>

                        <ModalFooter>
                            <Button color="primary" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <span className="d-flex gap-2">
                                        <Spinner color="white" size="sm" /> Loading...
                                    </span>
                                ) : (
                                    <>
                                        {selectedHeading.id ? "Update Heading" : "Add Heading"}
                                    </>
                                )}
                            </Button>
                            <Button color="secondary" onClick={toggleModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default connectComponent(Heading);
