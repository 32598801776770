import { FaComments } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import React from 'react'
import { Badges, LI } from 'src/AbstractElements'
import SvgIcon from 'src/Utils/CommonComponents/CommonSvgIcons'

function Inbox(props: any) {
    const navigate = useNavigate()
    const AllowedToChat = props.user.role === 'admin' || props.user.role === 'employee' || props.user.role === 'parent'
    return (
        AllowedToChat ? (
            <LI style={{
                backgroundColor: "#f9f7f6",
                border: "1px solid #dddddd",
                padding: "7px",
                borderRadius: "50%",
            }} >
                <Badges color="primary" pill>
                    {"8"}
                </Badges>
                <button onClick={() => navigate('/dashboard/inbox')} style={{ all: 'unset', display: 'inline-block', cursor: 'pointer' }}>
                    <SvgIcon iconId="Chat" />
                </button>
            </LI>
        ) : null
    )
}

export default Inbox