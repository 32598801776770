import api from './api'; // Use the axios instance with interceptors

interface WeeklyPlan {
    weekStartDate: string;
    weekEndDate: string;
    roomId: string | null;
    sections: {
        sectionId: string;
        sectionTitle: string;
        activities: {
            day: string;
            activities: Array<{
                id: string;
                activityId: string;
                title: string;
                description: string;
                badges: Array<{ text: string; color: string }>;
            }>;
        }[];
    }[];
    status: 'draft' | 'published';
}

class ActivityLibraryApi {
    // Create a new activity
    static async createActivity(data: any) {
        try {
            const response = await api.post('/activity-library', data);
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error ||
                error.message ||
                'An unknown error occurred';
            throw new Error(errorMessage);
        }
    }

    // Get all activities with optional filters
    static async getActivities(params: any = {}) {
        try {
            // Convert any special parameters as needed
            const queryParams = {
                ...params,
                page: params.page || 1,
                limit: params.limit || 10,
                // Add any other parameter transformations here
            };

            const response = await api.get('/activity-library', { params: queryParams });
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error ||
                error.message ||
                'An unknown error occurred';
            throw new Error(errorMessage);
        }
    }

    // Get a specific activity by ID
    static async getActivityById(id: string) {
        try {
            const response = await api.get(`/activity-library/byId/${id}`);
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error ||
                error.message ||
                'An unknown error occurred';
            throw new Error(errorMessage);
        }
    }

    // Update an activity
    static async updateActivity(id: string, data: any) {
        try {
            const response = await api.put(`/activity-library/${id}`, data);
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error ||
                error.message ||
                'An unknown error occurred';
            throw new Error(errorMessage);
        }
    }

    // Delete an activity
    static async deleteActivity(id: string) {
        try {
            const response = await api.delete(`/activity-library/${id}`);
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error ||
                error.message ||
                'An unknown error occurred';
            throw new Error(errorMessage);
        }
    }

    // Get available languages
    static async getLanguages() {
        try {
            const response = await api.get('/activity-library/languages');
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error ||
                error.message ||
                'An unknown error occurred';
            throw new Error(errorMessage);
        }
    }

    // Get learning areas
    static async getLearningAreas() {
        try {
            const response = await api.get('/efys/sub-heading');
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error ||
                error.message ||
                'An unknown error occurred';
            throw new Error(errorMessage);
        }
    }

    static async getUserNurseries() {
        try {
            const response = await api.get('/activity-library/nurseries');
            return response.data;
        } catch (error) {
            console.error('Error fetching user nurseries:', error);
            throw error;
        }
    }

    static async saveWeeklyPlan(plan: WeeklyPlan, publish: boolean = false) {
        try {
            // Update the status based on publish parameter
            const planData = {
                ...plan,
                status: publish ? 'published' : 'draft'
            };

            const response = await api.post('/activityPlanner/weekly-plans', planData);
            return response.data;
        } catch (error) {
            console.error('Error saving weekly plan:', error);
            // Return a standardized error response instead of throwing
            return {
                success: false,
                message: error instanceof Error ? error.message : 'An unknown error occurred'
            };
        }
    }

    static async getWeeklyPlans(params: any = {}) {
        try {
            const response = await api.get('/activityPlanner/weekly-plans', { params });
            return response.data;
        } catch (error) {
            console.error('Error fetching weekly plans:', error);
            return {
                success: false,
                message: error instanceof Error ? error.message : 'An unknown error occurred'
            };
        }
    }

    static async getWeeklyPlanById(planId: string) {
        try {
            const response = await api.get(`/activityPlanner/weekly-plans/${planId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching weekly plan:', error);
            return {
                success: false,
                message: error instanceof Error ? error.message : 'An unknown error occurred'
            };
        }
    }

    static async updateWeeklyPlan(planId: string, plan: WeeklyPlan, publish: boolean = false) {
        try {
            // Update the status based on publish parameter
            const planData = {
                ...plan,
                status: publish ? 'published' : 'draft'
            };

            const response = await api.patch(`/activityPlanner/weekly-plans/${planId}`, planData);
            return response.data;
        } catch (error) {
            console.error('Error updating weekly plan:', error);
            return {
                success: false,
                message: error instanceof Error ? error.message : 'An unknown error occurred'
            };
        }
    }

    static async deleteWeeklyPlan(planId: string) {
        try {
            const response = await api.delete(`/activityPlanner/weekly-plans/${planId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting weekly plan:', error);
            return {
                success: false,
                message: error instanceof Error ? error.message : 'An unknown error occurred'
            };
        }
    }

    static async getWeeklyPlanByDateRange(startDate: string, endDate: string, nurseryId: string, roomId: string | null = null) {
        try {
            // Validate parameters
            if (!startDate || !endDate || !nurseryId) {
                console.error('Missing required parameters in getWeeklyPlanByDateRange:', {
                    startDate,
                    endDate,
                    nurseryId
                });
                return {
                    success: false,
                    message: 'Missing required parameters: startDate, endDate, nurseryId'
                };
            }

            const params: any = {
                startDate,
                endDate,
                nurseryId
            };

            if (roomId) {
                params.roomId = roomId;
            }

            console.log('API request params:', params);
            const response = await api.get('/activityPlanner/weekly-plans/by-date-range', { params });
            return response.data;
        } catch (error) {
            console.error('Error fetching weekly plan by date range:', error);
            return {
                success: false,
                message: error instanceof Error ? error.message : 'An unknown error occurred'
            };
        }
    }
}

export default ActivityLibraryApi; 