import api from "./api"; // Use the Axios instance with interceptor

export default class DropDownAPi {
  static async getJobTitle(count: any, nurseryId: any) {
    try {
      const response = await api.get(
        `/jobTitle?employeeCount=${count || "true"}&nurseryId=${nurseryId}`
      );

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getNurseryOptions(count: any) {
    try {
      const response = await api.get(
        `/nursery?employeeCount=${count || "true"}`
      );

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getClassRoomsOptions(nurseryId: any) {
    try {
      const response = await api.get(`room/dropDownRooms/${nurseryId}`);

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
