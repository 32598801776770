import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import CommonModal from "../../../Components/UiKits/Modal/Common/CommonModal";
import { Btn } from "../../../AbstractElements";
import { baseUrl } from "../../../Utils/Constants/config";
import EventAPi from "../../../services/EventApi";
import moment from "moment";
import Swal from "sweetalert2";
import { connectComponent } from "./connector";

const MyCalendar = ({ nurseryId }: any) => {
  const navigate = useNavigate();
  const [date, setDate] = useState<Date>(new Date());
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [modal, setModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const toggle = () => setModal(!modal);

  // Helper function to format date as "YYYY-MM-DD"
  const formatDate = (date: any) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  const fetchEvents = async () => {
    try {
      const response = await EventAPi.fetchEvent({
        searchText: "",
        nurseryId: "",
      });

      if (response.success && Array.isArray(response.events)) {
        // Filter events based on conditions:
        // 1. Events created by current nursery
        // 2. Events with visibility "EVERYONE"
        const filteredEvents = response.events.filter((event: any) =>
          event.nurseryId === nurseryId || event.visibility === "EVERYONE"
        );
        setEvent(filteredEvents);
      } else {
        throw new Error("Expected an array of Event");
      }
    } catch (error: any) {
      setError(error.message || "Error fetching event");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [nurseryId]);

  const tileContent = ({ date, view }: any) => {
    if (view !== "month") return null;

    // Filter events for the specific date
    const filteredEvents: any = event.filter((row: any) => {
      const eventDate = new Date(row.eventDate);
      return formatDate(eventDate) === formatDate(date);
    });
    console.log('filteredEvents: ', filteredEvents);

    if (filteredEvents.length > 0) {
      return (
        <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", gap: "1rem", paddingTop: "10px" }}>
          {filteredEvents.map((event: any) => (
            <div>
              <div
                className="event-marker"
                style={{
                  backgroundColor: "#FF69B4",
                  cursor: "pointer"
                }}
                onClick={() => handleEventClick(event)}
              >
                <span className="event-icon" style={{ fontSize: "18px" }}>
                  🎉
                </span>
              </div>
              <span>{event?.title || ""}</span>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const handleEventClick = (event: any) => {
    setSelectedEvent(event);
    toggle();
  };

  const handleRemove = (deleteId: string) => {
    Swal.fire({
      title: "Deleting Event",
      text: "Are you sure you want to delete this Event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await EventAPi.deleteEvent(deleteId);
          await fetchEvents();
          toggle();
          Swal.fire("Deleted!", "The event has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting event:", error);
          Swal.fire("Error", "There was an issue deleting the event.", "error");
        }
      }
    });
  };

  const data = {
    isOpen: modal,
    center: true,
    toggler: toggle,
  };

  // Check if the event belongs to current nursery
  const isOwnEvent = (event: any) => event?.nurseryId === nurseryId;

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`My Calendar`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar/add`
                    );
                  }}
                >
                  Add New Event
                </Button>
              </div>
              <CardBody>
                {loading ? (
                  <div className="text-center p-4">Loading events...</div>
                ) : error ? (
                  <div className="text-center text-danger p-4">{error}</div>
                ) : (
                  <Calendar
                    onChange={(value) => setDate(value as Date)}
                    value={date}
                    tileContent={tileContent}
                    className="custom-calendar w-100"
                    nextLabel="Next Month"
                    prevLabel="Previous Month"
                  />
                )}

                {selectedEvent && (
                  <CommonModal modalData={data}>
                    <div className="modal-toggle-wrapper booking-modal">
                      <h2>Event Details</h2>
                      <p>Please review the event details</p>
                      <div className="booking-details">
                        <p>
                          <span>Event Title:</span>{" "}
                          {selectedEvent.title || "No title available"}
                        </p>
                        <p>
                          <span>Description:</span>{" "}
                          {selectedEvent.description || "No description available"}
                        </p>
                        <p>
                          <span>Visibility:</span> {selectedEvent.visibility}
                        </p>
                        <p>
                          <span>Media Type:</span> {selectedEvent.mediaType}
                        </p>
                        <p>
                          <span>Media URL:</span>{" "}
                          {selectedEvent.mediaUrl ? (
                            <a
                              href={baseUrl + selectedEvent.mediaUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Media
                            </a>
                          ) : (
                            "No media available"
                          )}
                        </p>
                        <p>
                          <span>Event Date:</span>{" "}
                          {moment(selectedEvent.eventDate).format("MMMM D, YYYY")}
                        </p>
                        <p>
                          <span>Start Time:</span>{" "}
                          {moment(selectedEvent.startAt, "x").format("hh:mm A")}
                        </p>
                        <p>
                          <span>End Time:</span>{" "}
                          {moment(selectedEvent.endAt, "x").format("hh:mm A")}
                        </p>
                      </div>
                      <div className="d-flex mt-4">
                        <Btn color="secondary" className="d-flex" onClick={toggle}>
                          Close
                        </Btn>
                        {isOwnEvent(selectedEvent) && (
                          <>
                            <Btn
                              color="dark"
                              type="button"
                              className="d-flex"
                              style={{ marginLeft: "0.5rem" }}
                              onClick={() => handleRemove(selectedEvent.id)}
                            >
                              Remove
                            </Btn>
                            <Btn
                              color="primary"
                              type="button"
                              className="d-flex"
                              style={{ marginLeft: "0.5rem" }}
                              onClick={() =>
                                navigate(
                                  `${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar/edit/${selectedEvent.id}`
                                )
                              }
                            >
                              Edit
                            </Btn>
                          </>
                        )}
                      </div>
                    </div>
                  </CommonModal>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <style>
        {`
          .booking-modal {
            padding: 20px;
          }
          .booking-modal h2 {
            color: #308e87;
            margin-bottom: 10px;
          }
          .booking-details {
            margin: 20px 0;
            padding: 15px;
            background: #f8f9fa;
            border-radius: 8px;
          }
          .booking-details p {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .booking-details span {
            font-weight: bold;
            color: #308e87;
          }
          .event-marker {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 2px auto;
          }
          .event-icon {
            font-size: 12px;
          }
        `}
      </style>
    </div>
  );
};

export default connectComponent(MyCalendar);
