import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserApi from "src/services/UserApi";
import { connectComponent } from "./connector";
import { Btn, H2, H3, H5, Image, LI, P, UL } from "src/AbstractElements";
import {
  bytesToMB,
  commonKeysObject,
  dynamicImage,
  fetchImage,
  getUserActivityFormattedData,
} from "src/Utils";
import ReactApexChart from "react-apexcharts";
import { bitcoinChart } from "src/Data/Dashboard/Project";
import { toast } from "react-toastify";
import UploadAPi from "src/services/UploadApi";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import { baseUrl } from "src/Utils/Constants/config";
import Breadcrumbs from "src/CommonElements/Breadcrumbs";
import {
  DashboardTitle,
  EducationDashboardTitle,
  EducationTitle,
} from "src/Utils/Constants";

function Children(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [saveData, setSaveData] = useState([]);
  const [selectedChildrenId, setSelectedChildrenId] = useState("");
  const [filterText, setFilterText] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const filteredItems: any[] = saveData.filter((item: any) => {
    item = {
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    };
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });
  console.log('filteredItems: ', filteredItems);
  const [parentData, setParentData] = useState([]);
  const [parentInfo, setParentInfo] = useState<any>({});
  useEffect(() => {
    const fetchParents = async () => {
      try {
        const response = await UserApi.getParentById(id);
        if (response.success && Array.isArray(response.data)) {
          const formData = response.data.map((row: any, index: any) => {
            const modifiedObj = commonKeysObject(row, {
              id: "",
              firstName: "",
              middleName: "",
              lastName: "",
              relation: "",
              phoneNo: "",
            });

            if (index === 0) {
              setParentInfo({
                parentNames: [
                  `${modifiedObj.firstName} ${modifiedObj.lastName}`,
                ],
                parentPhoneNo: [`${modifiedObj.phoneNo}`],
              });
            }

            return { ...modifiedObj, password: row.backupPassword };
          });
          setParentData(formData);
        }
      } catch (error: any) {
      } finally {
      }
    };
    fetchParents();
  }, [id]);
  const fetchChildren = async () => {
    try {
      const response = await UserApi.getAllChildren({
        familyId: id,
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.children)) {
        const dataWithImage: any = await Promise.all(
          response.children.map(async (row: any) => {
            let avatarUrlData = "";
            if (!!row.avatarUrl) {
              // Fetch the image if avatarUrl exists
              avatarUrlData = await fetchImage(baseUrl + row.avatarUrl);
            }
            return { ...row, avatarUrl: avatarUrlData };
          })
        );
        setSaveData(dataWithImage);
      } else {
        throw new Error("Expected an array of Children");
      }
    } catch (error: any) { }
  };

  useEffect(() => {
    fetchChildren();
  }, []);

  useEffect(() => {
    fetchChildren();
  }, [props.nurseryId]);

  const getFile = (childrenId: string) => {
    if (inputRef.current) {
      inputRef.current?.click();
      setSelectedChildrenId(childrenId);
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setIsUploading(true);
    const files: any = e.target.files;
    try {
      const file = files[0];
      if (bytesToMB(file.size) > 5)
        return toast.error("Can't upload file above 5 MB");
      const formData: any = new FormData();
      formData.append("file", file);
      formData.append("folder", "avatar");
      const response = await UploadAPi.uploadFile(formData, "upload-avatar");
      const documentAddedResp = await UserApi.createAndUpdateChild({
        id: selectedChildrenId,
        avatarUrl: response.filePath,
      });
      if (documentAddedResp.success) {
        Swal.fire({
          title: "Success!",
          text: "Profile Picture updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Children",
              `Profile Picture updated successfully`
            )
          );
          await fetchChildren();
          if (inputRef.current) {
            inputRef.current.value = ""; // Reset the file input
            setSelectedChildrenId("");
          }
        });
      }
    } catch (error: any) {
      if (typeof error === "string") {
        toast.error(error);
      } else {
        toast.error(error.message);
      }
    } finally {
      // setIsUploading(false);
    }
  };

  return (
    <div className="page-body">
      {id && (
        <Row
          className="pt-5 d-flex align-items-center"
          style={{ marginLeft: "15px" }}
        >
          <H2>
            {"Parent Name: "}
            <span className="text-primary">
              {parentInfo?.parentNames?.join(", ")}
            </span>
          </H2>
        </Row>
      )}
      <Container className={`dashboard-3 ${id ? "pt-0" : "pt-5"}`} fluid>
        <Row className="justify-content-end">
          <Col md={4}>
            <FilterComponent
              onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFilterText(e.target.value)
              }
              filterText={filterText}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          {id && (
            <Col md={4} style={{ marginTop: "-19px" }}>
              <Card
                className="overflow-hidden dropzone"
                style={{ height: "280px" }}
              >
                <CardBody
                  className="text-center"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/parents/children/add/${id}`
                    );
                  }}
                >
                  <i className="fa-solid fa-plus dropzone-icon"></i>
                  <CardTitle tag="h5">Click to add a new child.</CardTitle>
                  <p className="text-muted">
                    (This will take you to the child registration page where you
                    can enter the child's details.)
                  </p>
                </CardBody>
              </Card>
            </Col>
          )}

          {filteredItems.map((item) => {
            item = {
              ...item,
              chartClass: "bitcoin",
              chart: {
                ...bitcoinChart,
                series: bitcoinChart.series,
                type: bitcoinChart.chart?.type,
                height: bitcoinChart.chart?.height,
              },
              child: [
                {
                  id: 1,
                  icon: "Document",
                  text: "10 Lessons",
                },
                {
                  id: 2,
                  icon: "Time-Square",
                  text: "8 Hours",
                },
                {
                  id: 3,
                  icon: "User3",
                  text: "16 Students",
                },
                {
                  id: 4,
                  icon: "Star",
                  text: "3.6",
                },
              ],
            };
            return (
              <Col xxl={4} xl={4} md={6} key={item?.id}>
                <Card className="graphic-design overflow-hidden">
                  <CardHeader className="card-no-border pb-0">
                    <div className="header-top">
                      <div className="d-flex align-items-center gap-2">
                        <div className="flex-shrink-0 border-0">
                          <div className="icon position-relative">
                            <Image
                              className="img-fluid"
                              src={
                                !!item.avatarUrl
                                  ? item.avatarUrl
                                  : dynamicImage(`default-profile-old.jpg`)
                              }
                              alt="chair"
                            />

                            <div
                              className="icon-wrapper bg-primary"
                              style={{
                                padding: "3px",
                                borderRadius: "50px",
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                              }}
                              onClick={() => getFile(item.id)}
                            >
                              <i className="iconly-Edit icli text-white"></i>

                              <div
                                style={{
                                  height: "0px",
                                  width: "0px",
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  id="upfile"
                                  type="file"
                                  ref={inputRef}
                                  onChange={(event) => onFileChange(event)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <H5>
                            {item?.firstName} {item?.lastName}
                          </H5>
                          <P className="mb-0">{item?.gender}</P>
                        </div>
                      </div>
                      <Btn
                        color="primary"
                        size="sm"
                        onClick={() => {
                          navigate(
                            `${process.env.PUBLIC_URL}/dashboard/${id ? "parents/" : ""
                            }children/edit/${item.id}`
                          );
                        }}
                      >
                        Edit
                      </Btn>
                    </div>
                  </CardHeader>
                  <CardBody className="p-3">
                    {/* <UL className="simple-list flex-row">
                      {item?.child?.map((list: any) => (
                        <LI key={list?.id}>
                          <i className={`iconly-${list?.icon} icli me-1`} />
                          <H5>{list?.text}</H5>
                        </LI>
                      ))}
                    </UL>
                    <div className="crypto-dashborad-chart">
                      <div
                        className={`${item?.chartClass}-chart`}
                        id={`${item?.chartClass}-chart`}
                      >
                        <ReactApexChart
                          type={item?.chart?.type}
                          options={item?.chart}
                          series={item?.chart?.series}
                          height={item?.chart?.height}
                        />
                      </div>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default connectComponent(Children);
