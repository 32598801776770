import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormFeedback,
    InputGroup,
    InputGroupText,
    Spinner,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import UserApi from "src/services/UserApi";
import { commonKeysObject, getUserActivityFormattedData } from "src/Utils";
import Swal from "sweetalert2";
import MedicationAPi from "src/services/MedicationApi";
import UserActivityAPi from "src/services/UserActivityApi";

type PrescribedMedicationForm = {
    id?: string,
    child: string,
    medication: string,
    frequency: string,
    times: string[],
    doseAmount: string | number,
    doseUnit: string | number,
    reason: string,
    administrationDate: any,
    expirationDate: any,
    openedDate: any,
    sideEffects: string,
    notes: string,
};

const PrescribedMedicationForm = ({ isOpen, toggle, nurseryId, fetchMedication, id, medication }: any) => {
    const initialState = {
        child: "",
        medication: "",
        frequency: "unscheduled",
        times: [""],
        doseAmount: "",
        doseUnit: "",
        reason: "",
        administrationDate: "",
        expirationDate: "",
        openedDate: "",
        sideEffects: "",
        notes: "",
    };

    const { control, handleSubmit, register, setValue, reset, watch, formState: { errors }, clearErrors } = useForm<PrescribedMedicationForm>({
        defaultValues: initialState,
    });
    console.log('errors: ', errors);

    const [form, setForm] = useState<PrescribedMedicationForm>(initialState);
    const [childrenData, setChildrenData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const saveDataForm = (name: any, value: any) => {
        setForm({ ...form, [name]: value });
        setValue(name, value);
    };

    const onSubmit = async (data: any) => {
        console.log(data);

        try {
            setIsLoading(true);
            if (!form.id) {
                const response = await MedicationAPi.createMedication({
                    ...form,
                    times: form.frequency == "unscheduled" ? [""] : form.times,
                    nurseryId: nurseryId,
                });
                if (response.success) {
                    Swal.fire({
                        title: "Success!",
                        text: response.message,
                        icon: "success",
                    }).then(async () => {
                        await UserActivityAPi.addUserActivity(
                            getUserActivityFormattedData("Safegurading", response.message)
                        );
                        toggle();
                        await fetchMedication();
                    });
                }
            } else {

                const response = await MedicationAPi.updateMedication({
                    ...form,
                    times: form.frequency == "unscheduled" ? [""] : form.times,
                });
                if (response.success) {
                    Swal.fire({
                        title: "Success!",
                        text: response.message,
                        icon: "success",
                    }).then(async () => {
                        await UserActivityAPi.addUserActivity(
                            getUserActivityFormattedData("Safegurading", response.message)
                        );
                        toggle();
                        await fetchMedication();
                    });
                }
            }
        } catch (error: any) {
            const errorDetails = JSON.parse(error.message); // Parse the JSON to access object properties
            const tt: any =
                typeof errorDetails == "string"
                    ? errorDetails
                    : Object.values(errorDetails)[0];
            Swal.fire({
                title: "Error!",
                text: tt || "Something went wrong",
                icon: "error",
            });

        } finally {
            setIsLoading(false);
        }
    };

    const addTimeField = () => {
        setForm({ ...form, times: [...form.times, ""] });
    };

    const removeTimeField = (index: any) => {
        const updatedTimes = form.times.filter((_, i) => i !== index); // Remove the specified time
        setForm({ ...form, times: updatedTimes });
        // Clear all errors for the times array
        clearErrors("times");

        // Update the value and re-validate the field
        setValue("times", updatedTimes, { shouldValidate: true });
    };

    const fetchChildren = async () => {
        try {
            const response = await UserApi.getAllChildren({
                nurseryId: nurseryId,
            });
            if (response.success && Array.isArray(response.children)) {
                const formData = response.children.map((row: any, index: any) => {
                    const modifiedObj = commonKeysObject(row, {
                        id: "",
                        firstName: "",
                        lastName: "",
                    });
                    return {
                        label: `${modifiedObj.firstName} ${modifiedObj.lastName}`,
                        value: modifiedObj.id,
                    };
                });
                setChildrenData(formData);
            } else {
                setChildrenData([]);
            }
        } catch (error: any) {
            setChildrenData([]);
        }
    };

    useEffect(() => {
        fetchChildren();
    }, [nurseryId]);

    useEffect(() => {
        if (!isOpen && id == "") {
            setForm(initialState);
            reset(initialState);
        }
    }, [isOpen]);

    useEffect(() => {
        if (id) {
            const findData: any = medication.find((m: any) => m.id === id);
            if (findData) {
                const modifiedObj: any = commonKeysObject(findData, form);

                setForm({ ...modifiedObj, child: findData.children.id, id: findData.id });
                reset({ ...modifiedObj, child: findData.children.id, id: findData.id });
            } else {
                setForm(initialState);
                reset(initialState);
            }
        }
    }, [id]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>Prescribed Medication</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Label>
                            {"Child"} <span className="text-danger">*</span>
                        </Label>
                        <Controller
                            name="child"
                            control={control}
                            rules={{
                                required: "Please select a child",
                            }} // Validation rules
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    id="child"
                                    className={`${errors.child ? "is-invalid" : ""
                                        }`}
                                    options={childrenData} // children options
                                    value={
                                        childrenData.find(
                                            (option: any) => option.value === form.child
                                        ) || null
                                    } // Set current value, handle null
                                    onChange={(selectedOption) => {
                                        // Check if selectedOption is not null
                                        if (selectedOption) {
                                            field.onChange(selectedOption.value); // Update the field value
                                            saveDataForm(
                                                "child",
                                                selectedOption.value
                                            ); // Save the data
                                        }
                                    }} // Handle value change
                                />
                            )}
                        />
                        {errors.child && (
                            <FormFeedback
                                style={{ display: "block", color: "#dc3545" }}
                            >
                                {`${errors?.child?.message}`}
                            </FormFeedback>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label for="medication">
                            Medication <span className="text-danger">*</span>
                        </Label>
                        <input
                            type="text"
                            className={`form-control ${errors.medication ? "is-invalid" : ""}`}
                            id="medication"
                            {...register("medication", { required: "Please enter medication" })}
                            value={form.medication}
                            onChange={(e) => saveDataForm(
                                "medication",
                                e.target.value
                            )
                            }
                        />
                        {errors.medication && (
                            <FormFeedback>{`${errors.medication.message}`}</FormFeedback>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label>Frequency</Label>
                        <div>
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        type="radio"
                                        value="unscheduled"
                                        {...register("frequency")}
                                        checked={form.frequency === "unscheduled"}
                                        onChange={(e) => saveDataForm(
                                            "frequency",
                                            e.target.value
                                        )
                                        }
                                    />
                                    Unscheduled {" "}
                                    <br />
                                    <small className="text-secondary ms-2">"As Needed" medication, e.g.,EplPen</small>
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        type="radio"
                                        value="scheduled"
                                        {...register("frequency")}
                                        checked={form.frequency === "scheduled"}
                                        onChange={(e) => saveDataForm(
                                            "frequency",
                                            e.target.value
                                        )
                                        }
                                    />
                                    Scheduled
                                    <br />
                                    <small className="text-secondary ms-2">This medication should be given every day at:</small>
                                </Label>
                            </FormGroup>
                        </div>
                    </FormGroup>

                    {form.frequency === "scheduled" && (
                        <FormGroup>
                            {form.times.map((time, index) => (
                                <Row key={index} style={{ alignItems: 'center', marginBottom: '0.6rem' }}>
                                    <Col md={12}>
                                        <div className={`d-flex align-items-center gap-2 justify-content-center ${errors.times?.[index] ? " is-invalid" : ""}`}>
                                            <Input
                                                type="time"
                                                value={time}
                                                {...register(`times.${index}`, { required: "Please enter time" })}
                                                className={`form-control ${errors.times?.[index] ? "is-invalid" : ""}`}
                                                style={{ ...(errors.times?.[index] ? { backgroundPosition: "right calc(0.375em + 2.1875rem) center" } : {}) }}
                                                onChange={(e) => {
                                                    const updatedTimes = [...form.times]; // Create a copy of the current times array
                                                    updatedTimes[index] = e.target.value; // Update the specific index
                                                    saveDataForm("times", updatedTimes); // Save the updated array
                                                }}
                                            />
                                            <Button
                                                color="light"
                                                className="text-dark"
                                                onClick={() => removeTimeField(index)}
                                            >
                                                X
                                            </Button>
                                        </div>
                                        {errors.times?.[index] && (
                                            <FormFeedback>{`${errors.times?.[index]?.message}`}</FormFeedback>
                                        )}
                                    </Col>
                                </Row>
                            ))}
                            <Button color="primary" onClick={addTimeField}>
                                Add time
                            </Button>
                        </FormGroup>
                    )}

                    <FormGroup>
                        <Label for="doseAmount">Dose (amount) <span className="text-danger">*</span></Label>
                        <Input
                            type="number"
                            id="doseAmount"
                            className={`form-control ${errors.doseAmount ? "is-invalid" : ""}`}
                            {...register("doseAmount", { required: "Please enter dose amount" })}
                            value={form.doseAmount}
                            min={0}
                            onChange={(e) => saveDataForm(
                                "doseAmount",
                                e.target.value
                            )
                            }
                        />
                        {errors.doseAmount && (
                            <FormFeedback>{`${errors.doseAmount.message}`}</FormFeedback>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label for="doseUnit">Dose (unit) <span className="text-danger">*</span></Label>
                        <Input
                            type="text"
                            id="doseUnit"
                            className={`form-control ${errors.doseUnit ? "is-invalid" : ""}`}
                            {...register("doseUnit", { required: "Please enter dose unit" })}
                            placeholder="mg, ml, squeeze, spoonful, drop"
                            value={form.doseUnit}
                            min={0}
                            onChange={(e) => saveDataForm(
                                "doseUnit",
                                e.target.value
                            )
                            }
                        />
                        {errors.doseUnit && (
                            <FormFeedback>{`${errors.doseUnit.message}`}</FormFeedback>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label for="reason">Reason for medication <span className="text-danger">*</span></Label>
                        <Input
                            type="textarea"
                            className={`form-control ${errors.reason ? "is-invalid" : ""}`}
                            id="reason"
                            {...register("reason", { required: "Please enter reason" })}
                            value={form.reason}
                            onChange={(e) => saveDataForm(
                                "reason",
                                e.target.value
                            )
                            }
                        />
                        {errors.reason && (
                            <FormFeedback>{`${errors.reason.message}`}</FormFeedback>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label for="administrationDate">Final day of administration</Label>
                        <InputGroup className="flatpicker-calender">
                            <Controller
                                control={control}
                                name="administrationDate"
                                rules={{
                                    required: false,
                                }}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control flatpickr-input ${errors.administrationDate ? "is-invalid" : ""
                                                }`}
                                            selected={form.administrationDate}
                                            onChange={(date) => {
                                                field.onChange(date);
                                                saveDataForm("administrationDate", date);
                                            }}
                                            placeholderText="Select a date"
                                            dateFormat="MMMM d, yyyy"
                                        />
                                    );
                                }}
                            />
                            <InputGroupText className="list-light-primary custom-calender-icon">
                                <i className="fa-solid fa-calendar text-primary" />
                            </InputGroupText>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label for="expirationDate">Medication expiration date</Label>
                        <InputGroup className="flatpicker-calender">
                            <Controller
                                control={control}
                                name="expirationDate"
                                rules={{
                                    required: false,
                                }}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control flatpickr-input ${errors.expirationDate ? "is-invalid" : ""
                                                }`}
                                            selected={form.expirationDate}
                                            onChange={(date) => {
                                                field.onChange(date);
                                                saveDataForm("expirationDate", date);
                                            }}
                                            placeholderText="Select a date"
                                            dateFormat="MMMM d, yyyy"
                                        />
                                    );
                                }}
                            />
                            <InputGroupText className="list-light-primary custom-calender-icon">
                                <i className="fa-solid fa-calendar text-primary" />
                            </InputGroupText>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label for="openedDate">
                            Once opened, do not use after this date
                        </Label>
                        <InputGroup className="flatpicker-calender">
                            <Controller
                                control={control}
                                name="openedDate"
                                rules={{
                                    required: false,
                                }}
                                render={({ field }) => {
                                    return (
                                        <DatePicker
                                            className={`form-control flatpickr-input ${errors.openedDate ? "is-invalid" : ""
                                                }`}
                                            selected={form.openedDate}
                                            onChange={(date) => {
                                                field.onChange(date);
                                                saveDataForm("openedDate", date);
                                            }}
                                            placeholderText="Select a date"
                                            dateFormat="MMMM d, yyyy"
                                        />
                                    );
                                }}
                            />
                            <InputGroupText className="list-light-primary custom-calender-icon">
                                <i className="fa-solid fa-calendar text-primary" />
                            </InputGroupText>
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label for="sideEffects">Known side effects <span className="text-danger">*</span></Label>
                        <Input
                            type="textarea"
                            className={`form-control ${errors.sideEffects ? "is-invalid" : ""}`}
                            id="sideEffects"
                            {...register("sideEffects", { required: "Please enter sideEffects" })}
                            value={form.sideEffects}
                            onChange={(e) => saveDataForm(
                                "sideEffects",
                                e.target.value
                            )
                            }
                        />
                        {errors.sideEffects && (
                            <FormFeedback>{`${errors.sideEffects.message}`}</FormFeedback>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label for="notes">Other notes</Label>
                        <Input
                            type="textarea"
                            id="notes"
                            {...register("notes")}
                            value={form.notes}
                            onChange={(e) => saveDataForm(
                                "notes",
                                e.target.value
                            )
                            }
                        />
                    </FormGroup>

                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <span className="d-flex gap-2">
                                    <Spinner color="white" size="sm" /> Loading...
                                </span>
                            ) : (
                                <>
                                    {id ? "Update" : "Save"}
                                </>
                            )}
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>

        </Modal>
    );
};

export default PrescribedMedicationForm;
