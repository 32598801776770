import React from 'react';
import Select from 'react-select';
import { Controller, Control } from 'react-hook-form';

interface TimeDropdownProps {
    name: string;
    control: Control<any>;
    error?: string;
}

const TimeDropdown: React.FC<TimeDropdownProps> = ({ name, control, error }) => {
    // Generate time options from 06:00 to 20:00 (6 AM to 8 PM) with 15-minute intervals
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 6; hour <= 20; hour++) {
            for (let minutes = 0; minutes < 60; minutes += 15) {
                const displayHour = String(hour).padStart(2, '0');
                const displayMinutes = String(minutes).padStart(2, '0');
                const time = `${displayHour}:${displayMinutes}`;

                options.push({
                    value: time,
                    label: time
                });
            }
        }
        return options;
    };

    const timeOptions = generateTimeOptions();

    return (
        <div className="form-group">
            <Controller
                name={name}
                control={control}
                rules={{ required: 'Time is required' }}
                render={({ field }) => (
                    <Select
                        {...field}
                        options={timeOptions}
                        value={timeOptions.find(option => option.value === field.value)}
                        onChange={(option) => field.onChange(option?.value)}
                        placeholder="Select time"
                        isClearable={false}
                        className="time-dropdown"
                        classNamePrefix="select"
                    />
                )}
            />
            {error && <div className="invalid-feedback d-block">{error}</div>}
        </div>
    );
};

export default TimeDropdown;