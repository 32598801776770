import { EyfsApiSlice } from "./EyfsApiSlice";

export const fetchEyfs = async (params: any, dispatch: any) => {
  return dispatch(
    EyfsApiSlice.endpoints.getAllEYFS.initiate(params, {
      forceRefetch: true,
    })
  );
};

export const createEyfs = async (params: any, thunkApi: any) => {
  return thunkApi.dispatch(EyfsApiSlice.endpoints.createEYFS.initiate(params));
};

export const updateEyfs = async (params: any, thunkApi: any) => {
  return thunkApi.dispatch(EyfsApiSlice.endpoints.updateEYFS.initiate(params));
};
