import React, { useState, useEffect, useCallback } from 'react';
import {
    Container, Row, Col, Card, CardBody, CardTitle, Form,
    Input, InputGroup, InputGroupText, Button, Badge, Nav, NavItem, NavLink,
    Label, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, FormFeedback, Alert
} from 'reactstrap';
import { Plus, Search, Grid, List, Filter, X, Upload, Edit, ChevronDown, Eye } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import ActivityLibraryApi from '../../../services/ActivityLibraryApi';
import UploadApi from '../../../services/UploadApi';
import ActivityCard from './ActivityCard';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface Activity {
    id: string;
    title: string;
    description: string;
    thumbnailUrl?: string;
    thumbnail?: string;
    mediaType?: string;
    ageRangeStart: number;
    ageRangeEnd: number;
    fromAge?: number;
    toAge?: number;
    learningArea: string;
    learningAreaId?: string;
    language: string;
    languages?: string | string[];
    isPublic: boolean;
    assignedToUserNurseries?: boolean;
    visibleToCreatorOnly?: boolean;
    visibilityLevel?: 'self' | 'assigned' | 'public';
    status?: string | number;
    createdAt?: string;
    updatedAt?: string;
    languageCode?: string;
    activityLanguages?: Array<{
        id: string;
        activityLibraryId: string;
        languageCode: string;
        createdAt: string;
    }>;
}

interface LearningArea {
    id: string;
    name: string;
}

interface Language {
    id: string;
    name: string;
    languageCode?: string;
}

const ActivityLibrary = ({ user, nurseryId }: { user: any, nurseryId: any }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [allActivities, setAllActivities] = useState<Activity[]>([]);
    const [filteredActivities, setFilteredActivities] = useState<Activity[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [totalPages, setTotalPages] = useState(1);
    const [viewMode, setViewMode] = useState('card');
    const [searchTerm, setSearchTerm] = useState('');
    const [fromAge, setFromAge] = useState('');
    const [toAge, setToAge] = useState('');
    const [learningArea, setLearningArea] = useState('');
    const [language, setLanguage] = useState('');
    const [learningAreas, setLearningAreas] = useState<LearningArea[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [activeTab, setActiveTab] = useState('public');
    const [userNurseries, setUserNurseries] = useState<any[]>(user.nursery);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchActivities();
        fetchLearningAreas();
        fetchLanguages();
        fetchUserNurseries();
    }, [activeTab]);

    useEffect(() => {
        filterActivities();
    }, [allActivities, searchTerm, fromAge, toAge, learningArea, language, page, pageSize]);

    const filterActivities = () => {
        let filtered = [...allActivities];

        if (searchTerm.trim()) {
            const search = searchTerm.trim().toLowerCase();
            filtered = filtered.filter(activity =>
                activity.title.toLowerCase() === search ||
                activity.title.toLowerCase().includes(search) ||
                activity.description.toLowerCase().includes(search)
            );
        }

        if (fromAge) {
            filtered = filtered.filter(activity =>
                activity.ageRangeEnd >= parseInt(fromAge)
            );
        }
        if (toAge) {
            filtered = filtered.filter(activity =>
                activity.ageRangeStart <= parseInt(toAge)
            );
        }

        if (learningArea) {
            filtered = filtered.filter(activity =>
                activity.learningArea === learningArea
            );
        }

        if (language) {
            filtered = filtered.filter(activity => {
                if (Array.isArray(activity.activityLanguages)) {
                    return activity.activityLanguages.some(lang => lang.languageCode === language);
                }
                if (Array.isArray(activity.languages)) {
                    if (typeof activity.languages[0] === 'string') {
                        return activity.languages.includes(language);
                    }
                    const languagesArray = activity.languages as unknown as Array<{ languageCode: string }>;
                    return languagesArray.some(lang => lang.languageCode === language);
                }
                return activity.language === language || activity.languageCode === language;
            });
        }

        const total = filtered.length;
        setTotalPages(Math.ceil(total / pageSize));

        const start = (page - 1) * pageSize;
        const paginatedResults = filtered.slice(start, start + pageSize);

        setFilteredActivities(paginatedResults);
    };

    const fetchUserNurseries = async () => {
        try {
            const response = await ActivityLibraryApi.getUserNurseries();
            console.log('response: ', response);
            if (response && response.success) {
                setUserNurseries(response.data);
            }
        } catch (error) {
            console.error('Error fetching user nurseries:', error);
        }
    };

    const fetchActivities = async () => {
        setLoading(true);
        try {
            const params = {
                visibility: activeTab === 'public' ? 'all' :
                    activeTab === 'my-activities' ? 'self' :
                        activeTab.startsWith('nursery-') ? 'assigned' : undefined,
                nurseryId: activeTab.startsWith('nursery-') ? activeTab.replace('nursery-', '') : undefined
            };

            const response = await ActivityLibraryApi.getActivities(params);

            if (response && response.success) {
                setAllActivities(response.data.data);
                setFilteredActivities(response.data.data);
                setTotalPages(Math.ceil(response.data.meta.totalCount / pageSize));
            } else {
                setError('Failed to fetch activities');
            }
        } catch (error) {
            console.error('Error fetching activities:', error);
            setError('Error fetching activities. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const fetchLearningAreas = async () => {
        try {
            const response = await ActivityLibraryApi.getLearningAreas();
            if (response && response.success) {
                setLearningAreas(response.data);
            }
        } catch (error) {
            console.error('Error fetching learning areas:', error);
        }
    };

    const fetchLanguages = async () => {
        try {
            const response = await ActivityLibraryApi.getLanguages();
            if (response && response.success) {
                const formattedLanguages = response.data.map((lang: any) => ({
                    id: lang.id || lang.languageCode || lang.code,
                    name: lang.name || getLanguageDisplayName(lang.languageCode || lang.code),
                    languageCode: lang.languageCode || lang.code || lang.id
                }));
                setLanguages(formattedLanguages);
            }
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setPage(1);
        filterActivities();
    };

    const handleFilterReset = () => {
        setFromAge('');
        setToAge('');
        setLearningArea('');
        setLanguage('');
        setSearchTerm('');
        setPage(1);
    };

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
        setPage(1);
        filterActivities();
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Container fluid className="activity-library-container px-4 py-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0">Activity Library</h3>
                <Button color="primary" onClick={() => navigate(`${process.env.PUBLIC_URL}/dashboard/activity-library/new`)}>
                    <Plus size={14} className="me-1" /> Add Activity
                </Button>
            </div>

            <div className="activity-tabs-container">
                <Nav tabs className="mb-4 activity-tabs">
                    <NavItem>
                        <NavLink
                            className={activeTab === 'public' ? 'active text-primary' : ''}
                            onClick={() => handleTabChange('public')}
                        >
                            Public Activities
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === 'my-activities' ? 'active text-primary' : ''}
                            onClick={() => handleTabChange('my-activities')}
                        >
                            My Activities
                        </NavLink>
                    </NavItem>
                    {userNurseries.map((nursery) => (
                        <NavItem key={nursery.id}>
                            <NavLink
                                className={activeTab === `nursery-${nursery.id}` ? 'active text-primary' : ''}
                                onClick={() => handleTabChange(`nursery-${nursery.id}`)}
                            >
                                {nursery.name}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>

            <Card className="filter-card shadow-sm mb-4">
                <CardBody className="p-4">
                    <Row>
                        <Col lg={4} md={12} className="mb-3 mb-lg-0">
                            <Form onSubmit={handleSearch}>
                                <Label className="form-label">Search</Label> <br />
                                <InputGroup>
                                    <Input
                                        type="text"
                                        placeholder="Search activities..."
                                        value={searchTerm}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                                        className="border-end-0"
                                    />
                                </InputGroup>
                            </Form>
                        </Col>
                        <Col lg={8} md={12}>
                            <Row>
                                <Col sm={12} md={4} className="mb-2 mb-md-0">
                                    <div className="form-group">
                                        <label className="form-label">Age in months</label>
                                        <div className="d-flex age-inputs">
                                            <Input
                                                type="number"
                                                placeholder="From"
                                                className="age-input me-2"
                                                value={fromAge}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFromAge(e.target.value)}
                                            />
                                            <span className="my-auto px-1">-</span>
                                            <Input
                                                type="number"
                                                placeholder="To"
                                                className="age-input ms-2"
                                                value={toAge}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToAge(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={12} md={4} className="mb-2 mb-md-0">
                                    <div className="form-group">
                                        <label className="form-label">Learning area</label>
                                        <Input
                                            type="select"
                                            value={learningArea}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLearningArea(e.target.value)}
                                        >
                                            <option value="">Select...</option>
                                            {learningAreas.map((area) => (
                                                <option key={area.id} value={area.id}>
                                                    {area.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>

                                <Col sm={12} md={4} className="mb-2 mb-md-0">
                                    <div className="form-group">
                                        <label className="form-label">Language</label>
                                        <Input
                                            type="select"
                                            value={language}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLanguage(e.target.value)}
                                        >
                                            <option value="">All Languages</option>
                                            {languages.map((lang) => (
                                                <option key={lang.id} value={lang.languageCode || lang.id}>
                                                    {lang.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>

                                <Col sm={12} md={12} className="mt-3">
                                    <div className="d-flex justify-content-end w-100">
                                        <Button color="primary" onClick={filterActivities} className="d-flex align-items-center me-2">
                                            <Filter size={16} className="me-1" /> Filter
                                        </Button>
                                        <Button outline color="secondary" className="d-flex align-items-center" onClick={handleFilterReset}>
                                            <X size={16} className="me-1" /> Reset
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <span className="text-muted">Showing {filteredActivities.length} activities</span>
                </div>
                <div className="view-toggle">
                    <Button
                        color={viewMode === 'card' ? 'primary' : 'outline-primary'}
                        className="me-2"
                        onClick={() => setViewMode('card')}
                    >
                        <Grid size={16} />
                    </Button>
                    <Button
                        color={viewMode === 'list' ? 'primary' : 'outline-primary'}
                        onClick={() => setViewMode('list')}
                    >
                        <List size={16} />
                    </Button>
                </div>
            </div>

            {loading ? (
                <div className="text-center py-5">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <Row>
                    <Col>
                        <div className="alert alert-danger">{error}</div>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row>
                        {filteredActivities.length === 0 ? (
                            <Col>
                                <div className="text-center py-5">
                                    <p>No activities found. Try adjusting your filters.</p>
                                </div>
                            </Col>
                        ) : (
                            viewMode === 'card' ? (
                                <Col md={12}>
                                    <div className="activities-grid row">
                                        {filteredActivities.map((activity) => (
                                            <Col key={activity.id} lg={4} md={6} sm={12} className="mb-4">
                                                <ActivityCard
                                                    activity={activity}
                                                    learningAreas={learningAreas}
                                                    languages={languages}
                                                />
                                            </Col>
                                        ))}
                                    </div>
                                </Col>
                            ) : (
                                <Col md={12}>
                                    <Card className="shadow-sm">
                                        <CardBody>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Age Range (months)</th>
                                                            <th>Learning Area</th>
                                                            <th>Language</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredActivities.map((activity: any) => (
                                                            <tr key={activity.id}>
                                                                <td>{activity.title}</td>
                                                                <td>
                                                                    {activity.ageRangeStart || activity.fromAge || 0} -
                                                                    {activity.ageRangeEnd || activity.toAge || 0} months
                                                                </td>
                                                                <td>
                                                                    <Badge className={`bg-${getLearningAreaColor(activity.learningAreaId || activity.learningArea || '')}`}>
                                                                        {getLearningAreaName(activity.learningAreaId || activity.learningArea || '', learningAreas)}
                                                                    </Badge>
                                                                </td>
                                                                <td>
                                                                    {Array.isArray(activity.activityLanguages)
                                                                        ? activity.activityLanguages.map((lang: any) =>
                                                                            getLanguageNameByCode(lang.languageCode, languages)
                                                                        ).join(', ')
                                                                        : Array.isArray(activity.languages)
                                                                            ? activity.languages.map((code: any) =>
                                                                                getLanguageNameByCode(code.languageCode, languages)
                                                                            ).join(', ')
                                                                            : getLanguageNameByCode(activity.languageCode || activity.languages, languages) || 'N/A'}
                                                                </td>
                                                                <td>
                                                                    <Badge color={activity.isPublic ? 'success' : 'warning'}>
                                                                        {activity.isPublic ? 'Public' : 'Private'}
                                                                    </Badge>
                                                                </td>
                                                                <td>
                                                                    <Link to={`${process.env.PUBLIC_URL}/dashboard/activity-library/edit/${activity.id}`} className="btn btn-sm btn-outline-info me-2">
                                                                        <Edit size={14} className="me-1" /> Edit
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        )}
                    </Row>

                    {totalPages > 1 && (
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center mt-4">
                                    <Button
                                        outline
                                        color="primary"
                                        onClick={handlePrevPage}
                                        disabled={page === 1}
                                        className="me-2"
                                    >
                                        Previous
                                    </Button>
                                    <span className="mx-3 d-flex align-items-center">
                                        Page {page} of {totalPages}
                                    </span>
                                    <Button
                                        outline
                                        color="primary"
                                        onClick={handleNextPage}
                                        disabled={page === totalPages}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </Container>
    );
};

const getLearningAreaColor = (learningArea: string): string => {
    switch (learningArea) {
        case 'PHYSICAL_DEVELOPMENT':
            return 'danger';
        case 'COMMUNICATION_AND_LANGUAGE':
            return 'primary';
        case 'PERSONAL_SOCIAL_EMOTIONAL':
            return 'warning';
        case 'LITERACY':
            return 'info';
        case 'MATHEMATICS':
            return 'success';
        case 'UNDERSTANDING_THE_WORLD':
            return 'dark';
        case 'EXPRESSIVE_ARTS_AND_DESIGN':
            return 'purple';
        default:
            return 'secondary';
    }
};

const getLearningAreaName = (learningArea: string, learningAreas: LearningArea[]): string => {
    const area = learningAreas.find(a => a.id === learningArea);
    return area ? area.name : learningArea;
};

const getLanguageDisplayName = (code: string): string => {
    const languageMap: Record<string, string> = {
        'en-GB': 'English (UK)',
        'en-US': 'English (US)',
        'fr-FR': 'French',
        'de-DE': 'German',
        'es-ES': 'Spanish',
        'it-IT': 'Italian',
        'ar-SA': 'Arabic',
        'zh-CN': 'Chinese (Simplified)',
        'hi-IN': 'Hindi'
    };

    return languageMap[code] || code;
};

const getLanguageNameByCode = (code: string | undefined, languageList: Language[]): string => {
    if (!code) return 'N/A';

    const language = languageList.find(l =>
        l.languageCode === code || l.id === code
    );

    if (language?.name) {
        return language.name;
    }

    return getLanguageDisplayName(code);
};

export default ActivityLibrary; 