import { connect } from "react-redux";
import {
  SET_USER_CLOCK_IN,
  SET_USER_IP,
} from "../ReduxToolkit/Slices/Auth/AuthReducer";
import Routers from ".";

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserIP: (value: any) => dispatch(SET_USER_IP(value)),
    setClockIn: (value: any) => dispatch(SET_USER_CLOCK_IN(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routers);
