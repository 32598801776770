import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Badge, Label } from "reactstrap";
import Calendar from "react-calendar";
import RotaApi from "../../../../../services/RotaApi";
import UserApi from "../../../../../services/UserApi";
import { useParams } from "react-router-dom";
import { H4 } from "../../../../../AbstractElements";
import { FaClock, FaStopwatch, FaCoffee, FaCalendarCheck, FaPause } from "react-icons/fa"; // Import Font Awesome icons

function RotaCalendar(props: any) {
  const { id } = useParams(); // id is the employeeId

  const [rotas, setRotas] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [nurseryId, setNurseryId] = useState<string | null>(null);

  // Fetch employee details to get nurseryId
  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      if (id) {
        try {
          const response = await UserApi.getEmployeeById(id);
          if (response.success && response.data) {
            setNurseryId(response.data.nurseryId); // Set nurseryId from employee data
          } else {
            throw new Error("Failed to fetch employee details");
          }
        } catch (error: any) {
          setError(error.message || "Error fetching employee details");
        }
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  // Fetch rota data
  useEffect(() => {
    const fetchRotas = async () => {
      if (nurseryId && id) {
        try {
          const response = await RotaApi.getRotas({ nurseryId });
          if (response.success && Array.isArray(response.data)) {
            // Filter rota data for the specific employeeId
            const filteredRotas = response.data.filter(
              (rota: any) =>
                rota.rotaEmployees.some((emp: any) => emp.employeeId === id) &&
                rota.rotaStatus === 0
            );
            setRotas(filteredRotas);
          } else {
            throw new Error("No rota data available");
          }
        } catch (error: any) {
          setError(error.message || "Error fetching rota data");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchRotas();
  }, [nurseryId, id]);

  // Format date to YYYY-MM-DD
  const formatDate = (date: Date) => date.toISOString().split("T")[0];

  // Get rota employees for a specific date
  const getRotasForDate = (date: Date) => {
    const formattedDate = new Date(date).getTime();
    // Check if any rota overlaps with the selected date
    const rota = rotas.find(
      (rota: any) =>
        formattedDate >= rota.startDate && formattedDate <= rota.endDate
    );
    return (
      rota?.rotaEmployees.filter((emp: any) => emp.employeeId === id) || []
    );
  };

  // Tile content to display rota details for selected date
  const tileContent = ({ date, view }: any) => {
    if (view === "month") {
      const rotaEmployees = getRotasForDate(date);
      return rotaEmployees.length > 0 ? (
        <div className="tile-badges">
          {rotaEmployees.map((employee: any, index: number) => (
            <div key={index} className="badge-container">
              <Badge color="success" className="badge">
              <FaClock />{employee.startTime}
              </Badge>
              <Badge color="info" className="badge">
              <FaCalendarCheck />{employee.endTime}
              </Badge>
              <Badge color="warning" className="badge mb-0">
              <FaPause />{employee.breakTime} min
              </Badge>
            </div>
          ))}
        </div>
      ) : null;
    }
    return null;
  };

  return (
    <Card>
      <CardBody>
        {/* <div style={{ display: "flex", justifyContent: "center" }} className="mb-2">
          <H4>Employee Rota Management</H4>
        </div> */}
        {loading ? (
          <p>Loading rotas...</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <Calendar
            onChange={(value) => setSelectedDate(value as Date)}
            value={selectedDate}
            tileContent={tileContent}
          />
        )}
      </CardBody>
    </Card>
  );
}

export default RotaCalendar;
