import api from "./api";

class OverviewApi {
    static async createOverview(data: any) {
        try {
            const response = await api.post('/learning-overview/create', data);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async updateOverview(id: string, data: any) {
        try {
            const response = await api.put(`/learning-overview/update/${id}`, data);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async deleteOverview(id: string) {
        try {
            const response = await api.delete(`/learning-overview/delete/${id}`);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async getOverviews(params?: any) {
        try {
            const response = await api.get('/learning-overview/list', { params });
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async getOverviewById(id: string) {
        try {
            const response = await api.get(`/learning-overview/${id}`);
            return response.data;
        } catch (error: any) {
            return {
                success: false,
                message: error.response?.data?.message || 'Something went wrong'
            };
        }
    }

    static async updateOverviewStatus(id: string, data: { status: number }) {
        try {
            const response = await api.put(`/learning-overview/update/status/${id}`, data);
            return response.data;
        } catch (error: any) {
            throw new Error(error.response?.data?.error || 'Failed to update overview status');
        }
    }
}

export default OverviewApi; 