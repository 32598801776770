import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "../../../Utils/Constants/config";

export const ReportsApiSlice = createApi({
  reducerPath: "reportsApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getAllVisitors: builder.query({
      query: (params) => ({
        url: `/visitor/all/`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
    getVisitorsGraph: builder.query({
      query: (params) => ({
        url: `/visitor/graph/`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    getRevenueGraph: builder.query({
      query: (params) => ({
        url: `/user/dashboard/revenueGraph`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    getUsersGraph: builder.query({
      query: (params) => ({
        url: `/user/dashboard/userReports`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    getDashboardCards: builder.query({
      query: (params) => ({
        url: `/user/dashboard/details`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),

    getSubsSummary: builder.query({
      query: (params) => ({
        url: `/user/dashboard/subsSummary`,
        method: "GET",
        params: params,
      }),
      async onQueryStarted({ dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          return data;
          // Here you can dispatch actions or update the cache if needed
        } catch (error) {
          return error;
        }
      },
    }),
  }),
});
