import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../ReduxToolkit/Store";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { Col } from "reactstrap";
import HorizontalLogo from "./HeaderLeft/HorizontalLogo";

export default function Header(props: any) {
  const { sideBarToggle } = useSelector((state: RootState) => state.layout);

  return (
    <header className={`page-header row ${sideBarToggle ? "close_icon" : ""}`} >
      <HorizontalLogo />
      <Col className="page-main-header" style={{ padding: "10px 20px" }}>
        <HeaderLeft
          user={props.user}
          setNursery={props.setNursery}
          selectedNursery={props.selectedNursery}
        />
        <HeaderRight
          user={props.user}
          setNursery={props.setNursery}
          selectedNursery={props.selectedNursery}
          logout={props.logout}
          setClockIn={props.setClockIn}
          userClockIn={props.userClockIn}
          userClockInDate={props.userClockInDate}
          attendanceId={props.attendanceId}
          userClockOut={props.userClockOut}
          empClockIn={props.empClockIn}
          empClockOut={props.empClockOut}
          setEmpClockOut={props.setEmpClockOut}
        />
      </Col>
    </header>
  );
}
