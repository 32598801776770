import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText, Badge, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Eye, Edit, Book } from 'react-feather';
import AsyncImage from '../../../Components/AsyncImage';
import { baseUrl } from 'src/Utils/Constants/config';
import EyfApi from '../../../services/EyfApi';
import './style.scss';

// Updated interface to match potential API response variations
interface Activity {
    id: string;
    title: string;
    description?: string;
    thumbnailUrl?: string;
    thumbnail?: string; // Alternative field name
    mediaType?: string;
    ageRangeStart?: number | string;
    ageRangeEnd?: number | string;
    fromAge?: number | string; // Alternative field name
    toAge?: number | string; // Alternative field name
    learningArea?: string;
    learningAreaId?: string; // Alternative field name
    language?: string | string[];
    languages?: string | string[]; // Alternative field name
    isPublic?: boolean;
    assignedToUserNurseries?: boolean;
    visibleToCreatorOnly?: boolean;
    visibilityLevel?: 'self' | 'assigned' | 'public'; // Alternative field name
    status?: string | number; // Potential status field
    createdAt?: string;
    updatedAt?: string;
    languageCode?: string;
    activityLanguages?: Array<{
        id: string;
        activityLibraryId: string;
        languageCode: string;
        createdAt: string;
    }>;
    // Add any other fields that might be returned by the API
}

// Create a type for learning areas
interface LearningAreaType {
    id: string;
    name: string;
    color?: string;
    headingId?: string;
    status?: number;
}

interface ActivityCardProps {
    activity: Activity;
    learningAreas?: LearningAreaType[];
    languages?: Array<{ id: string, name: string, languageCode?: string }>;
}

const ActivityCard: React.FC<ActivityCardProps> = ({ activity, learningAreas = [], languages = [] }) => {
    const [localLearningAreas, setLocalLearningAreas] = useState<LearningAreaType[]>([]);

    // Fetch learning areas if not provided as props
    useEffect(() => {
        if (learningAreas.length === 0) {
            const fetchLearningAreas = async () => {
                try {
                    const response = await EyfApi.getSubHeadings();
                    if (response && response.success) {
                        setLocalLearningAreas(response.data);
                    }
                } catch (error) {

                }
            };

            fetchLearningAreas();
        } else {
            setLocalLearningAreas(learningAreas);
        }
    }, [learningAreas]);

    // Helper function to get the learning area name from ID
    const getLearningAreaById = (id: string): LearningAreaType | undefined => {
        const areas = learningAreas.length > 0 ? learningAreas : localLearningAreas;
        return areas.find(area => area.id === id);
    };

    // Helper function to get the thumbnail URL
    const getThumbnailUrl = (): string | undefined => {
        return activity.thumbnailUrl || activity.thumbnail;
    };

    // Helper function to get age range
    const getAgeRange = (): { start: number, end: number } => {
        const start = Number(activity.ageRangeStart || activity.fromAge || 0);
        const end = Number(activity.ageRangeEnd || activity.toAge || 0);
        return { start, end };
    };

    // Enhanced helper function to determine visibility status
    const getVisibilityInfo = (): { isPublic: boolean, label: string, color: string } => {
        // Check explicit visibility flags first
        if (activity.visibleToCreatorOnly === true && activity.isPublic !== true) {
            return { isPublic: false, label: 'Private', color: 'warning' };
        }

        if (activity.assignedToUserNurseries === true) {
            return { isPublic: false, label: 'My Nurseries', color: 'info' };
        }

        if (activity.isPublic === true) {
            return { isPublic: true, label: 'Public', color: 'success' };
        }

        // Default to private if no clear indicator
        return { isPublic: false, label: 'Private', color: 'warning' };
    };

    // Get visibility status
    const { label: visibilityLabel, color: visibilityColor } = getVisibilityInfo();

    // Get age range values
    const { start: ageStart, end: ageEnd } = getAgeRange();
    const learningAreaValue = activity.learningAreaId || activity.learningArea || '';

    // Get color for learning area badge
    const getLearningAreaColor = (learningAreaId: string): string => {
        const learningArea = getLearningAreaById(learningAreaId);

        // Map known learning areas to Reactstrap color names
        switch (learningAreaId) {
            case 'PHYSICAL_DEVELOPMENT':
                return 'danger';
            case 'COMMUNICATION_AND_LANGUAGE':
                return 'primary';
            case 'PERSONAL_SOCIAL_EMOTIONAL':
                return 'warning';
            case 'LITERACY':
                return 'info';
            case 'MATHEMATICS':
                return 'success';
            case 'UNDERSTANDING_THE_WORLD':
                return 'dark';
            case 'EXPRESSIVE_ARTS_AND_DESIGN':
                return 'purple';
            default:
                // If we have API data with color but it's a hex code, convert to a basic color
                if (learningArea?.color) {
                    // Convert hex to a Reactstrap color name or use a default
                    return mapHexToColorName(learningArea.color);
                }
                return 'secondary';
        }
    };

    // Helper function to map hex colors to Reactstrap color names
    const mapHexToColorName = (hexColor: string): string => {
        // Simple mapping logic - you can expand this
        const colorMap: Record<string, string> = {
            '#dc3545': 'danger',
            '#007bff': 'primary',
            '#ffc107': 'warning',
            '#17a2b8': 'info',
            '#28a745': 'success',
            '#343a40': 'dark',
            '#7952b3': 'purple'
        };

        return colorMap[hexColor.toLowerCase()] || 'secondary';
    };

    // Get learning area name
    const getLearningAreaName = (learningAreaId: string): string => {
        const learningArea = getLearningAreaById(learningAreaId);

        // If we have the learning area name from API data, use it
        if (learningArea?.name) {
            return learningArea.name;
        }

        // Otherwise use our fallback formatting
        switch (learningAreaId) {
            case 'PHYSICAL_DEVELOPMENT':
                return 'Physical Development';
            case 'COMMUNICATION_AND_LANGUAGE':
                return 'Communication & Language';
            case 'PERSONAL_SOCIAL_EMOTIONAL':
                return 'Personal, Social & Emotional';
            case 'LITERACY':
                return 'Literacy';
            case 'MATHEMATICS':
                return 'Mathematics';
            case 'UNDERSTANDING_THE_WORLD':
                return 'Understanding the World';
            case 'EXPRESSIVE_ARTS_AND_DESIGN':
                return 'Expressive Arts & Design';
            default:
                return learningAreaId;
        }
    };

    const getLanguageDisplay = (): string => {

        if (Array.isArray(activity.activityLanguages)) {
            return activity.activityLanguages.map(lang =>
                getLanguageNameByCode(lang.languageCode, languages)
            ).join(', ');
        }

        if (Array.isArray(activity.languages)) {
            return activity.languages.map((code: any) =>
                getLanguageNameByCode(code.languageCode, languages)
            ).join(', ');
        }

        return getLanguageNameByCode(activity.languageCode || activity.languages, languages) || 'N/A';
    };

    // Helper function to get language name from code
    const getLanguageNameByCode = (code: string | undefined, languageList: any[]): string => {
        if (!code) return 'N/A';

        // Try finding the language in our API data
        const language = languageList.find(l =>
            l.languageCode === code || l.id === code
        );

        if (language?.name) {
            return language.name;
        }

        // Fall back to common language code formats
        const languageMap: Record<string, string> = {
            'en-GB': 'English (UK)',
            'en-US': 'English (US)',
            'fr-FR': 'French',
            'de-DE': 'German',
            'es-ES': 'Spanish',
            'it-IT': 'Italian',
            'ar-SA': 'Arabic',
            'zh-CN': 'Chinese (Simplified)',
            'hi-IN': 'Hindi'
        };

        return languageMap[code] || code;
    };

    return (
        <Card className="activity-card h-100">
            <div className="activity-card-thumbnail position-relative">
                {getThumbnailUrl() ? (
                    <AsyncImage
                        src={getThumbnailUrl() || ''}
                        alt={activity.title}
                        className="card-img-top"
                        folder={'activity-library'}
                        onError={(e) => {

                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = '/images/placeholder-image.png';
                        }}
                    />
                ) : (
                    <div className="placeholder-thumbnail d-flex align-items-center justify-content-center">
                        <Book size={40} className="text-muted" />
                    </div>
                )}

                {/* Age range badge - top left */}
                <Badge
                    color="secondary"
                    className="position-absolute top-0 start-0 m-2 age-badge p-2"
                    style={{ borderRadius: "100px" }}
                >
                    {ageStart}-{ageEnd} months
                </Badge>

                {/* Public/Private badge - top right */}
                <Badge
                    color={visibilityColor}
                    className="position-absolute top-0 end-0 m-2 p-2"
                >
                    {visibilityLabel}
                </Badge>
            </div>

            <CardBody>
                <CardTitle tag="h5" className="mb-1">{activity.title}</CardTitle>
                <CardText className="text-muted small mb-3 activity-description">
                    {activity.description || 'No description available'}
                </CardText>

                <small className="d-block text-muted mb-2">
                    Languages: {getLanguageDisplay()}
                </small>

                <div className="d-flex justify-content-between flex-column gap-3 align-items-start">
                    <Badge
                        className={`category-badge bg-${getLearningAreaColor(learningAreaValue)}`}
                    >
                        {getLearningAreaName(learningAreaValue)}
                    </Badge>

                    <div className="action-buttons justify-content-between w-100">
                        {/* <Link
                            to={`${process.env.PUBLIC_URL}/dashboard/activity-library/view/${activity.id}`}
                            className="btn btn-sm btn-outline-primary me-2"
                        >
                            <Eye size={14} className="me-1" /> View
                        </Link> */}
                        <Link
                            to={`${process.env.PUBLIC_URL}/dashboard/activity-library/edit/${activity.id}`}
                            className="btn btn-sm btn-outline-info"
                        >
                            <Edit size={14} className="me-1" /> Edit
                        </Link>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default ActivityCard; 