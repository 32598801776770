import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Row } from "reactstrap";
import Swal from "sweetalert2";
import NotificationApi from "../../../services/NotificationApi";
import store from "../../../ReduxToolkit/Store";
import UserApi from "../../../services/UserApi";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";

const Notification: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [demoRequestActive, setDemoRequestActive] = useState(false);
  const [newEventsActive, setNewEventsActive] = useState(false);
  const [newMessagesActive, setNewMessagesActive] = useState(false);

  // Fetch user info and notification settings on component mount
  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        // Fetch user info to get the current notification settings
        const response = await UserApi.getUserInfo();
        const userDetails = response.userDetails; // Extract userDetails

        // Set notification settings from userDetails
        setDemoRequestActive(userDetails.demoRequestNotification || false);
        setNewEventsActive(userDetails.newEventNotification || false);
        setNewMessagesActive(userDetails.messagesNotification || false);
        setLoading(false);
      } catch (error: any) {
        Swal.fire({
          title: "Error!",
          text: error.message || "Failed to load user info.",
          icon: "error",
        });
        setLoading(false);
      }
    };

    fetchUserSettings();
  }, []);

  const handleSubmit = async () => {
    try {
      const updatedSettings = {
        demoRequestNotification: demoRequestActive,  // Setting for demoRequest
        newEventNotification: newEventsActive,        // Setting for newEvents
        messagesNotification: newMessagesActive,      // Setting for messages
      };
  
      // Get user ID from Redux store
      const state = store.getState();
      const userID = state.auth.user?.id; // Assuming the user ID is stored in the Redux state under auth.user.id
  
      // Ensure userID is available before making the request
      if (!userID) {
        Swal.fire({
          title: "Error!",
          text: "User ID is missing.",
          icon: "error",
        });
        return;
      }
  
      // Call the API to update notification settings
      const response = await NotificationApi.updateNotification(userID, updatedSettings);
  
      // If settings updated successfully, set the new values
      if (response) {
        setDemoRequestActive(updatedSettings.demoRequestNotification);
        setNewEventsActive(updatedSettings.newEventNotification);
        setNewMessagesActive(updatedSettings.messagesNotification);
  
        Swal.fire({
          title: "Success!",
          text: "Notification settings updated successfully.",
          icon: "success",
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to update notification settings.",
        icon: "error",
      });
    }
  };
  

  if (loading) {
    return <div>Loading notification settings...</div>;
  }

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card className="shadow-sm border-0">
            <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Notification Settings`}
                  titleClass="mb-3"
                />
              </div>
              <CardBody>
                <Form onSubmit={(e) => e.preventDefault()}>
                  {/* Demo Request Notification */}
                  <div className="notification-item border rounded p-3 mb-3">
                    <Row className="align-items-center">
                      <Col md={11}>
                        <Label
                          className="form-label fw-bold text-dark"
                          style={{ fontSize: "1.2rem" }}
                        >
                          Demo Request
                        </Label>
                        <p className="text-muted mb-0">
                        Receive timely alerts whenever a new event is posted

                        </p>
                      </Col>
                      <Col md={1} className="text-center">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={demoRequestActive}
                            onChange={() => setDemoRequestActive(!demoRequestActive)}
                            id="demoRequestSwitch"
                            style={{ width: "3rem", height: "1.5rem", borderRadius: "1rem" }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* New Events Notification */}
                  <div className="notification-item border rounded p-3 mb-3">
                    <Row className="align-items-center">
                      <Col md={11}>
                        <Label
                          className="form-label fw-bold text-dark"
                          style={{ fontSize: "1.2rem" }}
                        >
                          New Events
                        </Label>
                        <p className="text-muted mb-0">
                        Receive timely alerts whenever a new event is posted

                        </p>
                      </Col>
                      <Col md={1} className="text-center">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={newEventsActive}
                            onChange={() => setNewEventsActive(!newEventsActive)}
                            id="newEventsSwitch"
                            style={{ width: "3rem", height: "1.5rem", borderRadius: "1rem" }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Messages Notification */}
                  <div className="notification-item border rounded p-3 mb-3">
                    <Row className="align-items-center">
                      <Col md={11}>
                        <Label
                          className="form-label fw-bold text-dark"
                          style={{ fontSize: "1.2rem" }}
                        >
                          New Messages
                        </Label>
                        <p className="text-muted mb-0">
                          Receive instant alerts when you receive a new message, ensuring you never miss important communications
                        </p>
                      </Col>
                      <Col md={1} className="text-center">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={newMessagesActive}
                            onChange={() => setNewMessagesActive(!newMessagesActive)}
                            id="messagesSwitch"
                            style={{ width: "3rem", height: "1.5rem", borderRadius: "1rem" }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Submit Button */}
                  <Button color="primary" onClick={handleSubmit} className="float-end">
                    Save
                  </Button>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notification;
