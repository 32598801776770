import React from "react";

export default function SvgIcon(props: any) {
  return (
    <>
      {props?.skipSvg ? (
        <i
          className={`${props.iconId} stroke-icon`}
          style={{ fontSize: "18px" }}
        ></i>
      ) : (
        <svg
          className={props.className}
          style={props.style}
          onClick={props.onClick}
        >
          <use
            href={`${process.env.PUBLIC_URL}/assets/svg/iconly-sprite.svg#${props.iconId}`}
          ></use>
        </svg>
      )}
    </>
  );
}
