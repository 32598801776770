import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "src/Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  commonKeysObject,
  convertToTitleCase,
  getUserActivityFormattedData,
} from "src/Utils";
import Status from "src/Components/Common/Status";
import { statusColors } from "src/Utils/Constants";
import { Btn } from "src/AbstractElements";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { baseUrl } from "src/Utils/Constants/config";
import Swal from "sweetalert2";
import UserActivityAPi from "src/services/UserActivityApi";
import UserApi from "src/services/UserApi";
import UploadAPi from "src/services/UploadApi";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

function ChildrenPermission({ id, tabId, parentData }: any) {
  const [action, setAction] = useState({
    id: "",
    action: "List",
    data: {},
  }); // * action: list, add, edit
  return (
    <>
      {action.action === "List" && (
        <ChildrenPermissionList
          setAction={setAction}
          childrenId={id}
          tabId={tabId}
        />
      )}

      {action.action === "Add" && (
        <ChildrenPermissionForm
          setAction={setAction}
          id={action.id}
          action={action.action}
          childrenId={id}
          data={action.data}
          parentData={parentData}
        />
      )}

      {action.action === "Edit" && (
        <ChildrenPermissionForm
          setAction={setAction}
          action={action.action}
          id={action.id}
          data={action.data}
          childrenId={id}
          parentData={parentData}
        />
      )}
    </>
  );
}

function ChildrenPermissionList(props: any) {
  const [permission, setPermission] = useState<any[]>([]);
  const [filterText, setFilterText] = useState("");
  const filteredItems = permission.filter((permission) =>
    permission.name.toLowerCase().includes(filterText.toLowerCase())
  );
  const [dropdownOpenOne, setDropdownOpenOne] = useState<{ [key: string]: boolean }>({});
  const toggleDropdownOne = (id: string) => {
    setDropdownOpenOne((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const STATUS: any = {
    0: "Published",
    1: "Draft",
    2: "Inactive",
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Permission Name" />,
      selector: (row) => `${row.name}`,
      style: {
        minWidth: "420px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "420px", // Set consistent width
      maxWidth: "420px",
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => STATUS[row.status],
      style: {
        minWidth: "140px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "140px", // Set consistent width
      maxWidth: "140px",
      sortable: true,
      center: false,

      cell: (row) => {
        return (
          <Status
            position={STATUS[row.status]}
            color={
              row?.status === 1
                ? {
                  text: statusColors.suspended.text,
                  background: statusColors.suspended.background,
                }
                : row?.status === 0
                  ? {
                    text: statusColors.active.text,
                    background: statusColors.active.background,
                  }
                  : {
                    text: statusColors.inactive.text,
                    background: statusColors.inactive.background,
                  }
            }
            id={row.id}
            row={row}
            handleStatusChange={handleStatusChange}
            newUI={true}
            statusList={["PUBLISHED", "DRAFT", "INACTIVE"]}
            toggleDropdownOne={toggleDropdownOne}
            dropdownOpenOne={dropdownOpenOne}
          />
        );
      },
    },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      center: false, // Center-aligns content in both header and cells
      style: {
        minWidth: "100px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "100px", // Set consistent width
      maxWidth: "100px",
      cell: (row) => (
        <td>
          {/* Edit Button */}
          <a
            className="icon"
            href="javascript:void(0);"
            onClick={() => handleEdit(row)}
          >
            <Btn color="primary" size="sm">
              <i className="fa-solid fa-pencil me-1"></i>
              {"Edit"}
            </Btn>
          </a>
        </td>
      ),
    },
  ];

  const handleStatusChange = async (row: any, status: string) => {
    // Convert the selected status ("active" or "inactive") to a boolean value
    const updatedStatus = status == "PUBLISHED" ? 0 : status == "DRAFT" ? 1 : 2; // true for "active", false for "inactive"

    try {
      // Call the API to update the status, passing the boolean value
      await UserApi.createAndUpdateChildrenPermission({
        id: row.id,
        status: Number(updatedStatus),
      });

      // Update the local state to reflect the status change
      await fetchChildrenPermission();
      toggleDropdownOne(row.id);
      // Swal.fire("Success", "Status updated successfully!", "success");
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const handleEdit = (row: any) => {
    props.setAction({ id: row.id, action: "Edit", data: row });
  };

  const fetchChildrenPermission = async () => {
    try {
      const response = await UserApi.getChildrenPermission({
        childrenId: props.childrenId,
      });
      if (response.success && Array.isArray(response.children)) {
        setPermission(response.children);
      } else {
        setPermission([]);
      }
    } catch (error: any) {
      setPermission([]);
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Children",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchChildrenPermission();
  }, [props.childrenId]);

  return (
    <Col md={12} xl={12}>
      <Card className="card-absolute">
        <CommonCardHeader
          headClass="bg-primary"
          titleClass="text-light"
          title={"Children Permission"}
        />
        <CardBody>
          <Card>
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ borderBottom: "5px solid #eee" }}
            >
              {/* Button to toggle LeaveReport */}
              <Button
                color="primary"
                className="btn-md mb-4"
                style={{ marginLeft: "10px" }}
                onClick={() => props.setAction({ id: "", action: "Add" })}
              >
                <i className="fa fa-plus me-2" /> Add Permission
              </Button>
            </div>
            <CardBody className="p-0">
              <Row className="justify-content-end">
                <Col md={6}>
                  <FilterComponent
                    onFilter={(e) => setFilterText(e.target.value)}
                    filterText={filterText}
                  />
                </Col>
              </Row>
              <div className="table-responsive p-0">
                <DataTable
                  data={filteredItems}
                  columns={columns}
                  pagination
                  className="display"
                  conditionalRowStyles={[
                    {
                      when: (row) => true,
                      style: {
                        "&:hover": {
                          backgroundColor: "rgba(48, 142, 135, 0.2)",
                          cursor: "pointer",
                        },
                      },
                    },
                  ]}
                />
              </div>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Col>
  );
}

function ChildrenPermissionForm(props: any) {
  const initialState: any = {
    id: "",
    name: "",
    description: "",
    mediaUrl: "",
    isSendAllParent: "All",
    parentIds: [],
    childrenId: props.childrenId,
  };

  const parentOptions = props.parentData.map((row: any) => ({
    label: `${row.firstName} ${row.middleName} ${row.lastName}`,
    value: row.id,
  }));

  const [form, setForm] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      isSendAllParent: "All",
    },
  });

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const onEditSubmit = async () => {
    try {
      setIsLoading(true);
      let url = "";
      if (form?.files && form?.files?.length > 0) {
        const { mediaUrl } = await uploadImage(form?.files[0]);
        url = mediaUrl;
      } else {
        url = form.mediaUrl;
      }

      const addedResponse = await UserApi.createAndUpdateChildrenPermission({
        ...form,
        mediaUrl: url,
        files: undefined,
        parentIds: form.parentIds.join(","),
      });
      if (addedResponse.success) {
        Swal.fire({
          title: "Success!",
          text: "Permission submitted successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Children",
              `Permission submitted successfully`
            )
          );

          props.setAction({ id: "", action: "List" });
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImage = async (file: any) => {
    const formData: any = new FormData();
    formData.append("file", file);
    formData.append("folder", "childrenPermission");
    try {
      const response = await UploadAPi.uploadFile(
        formData,
        "upload-childrenPermission"
      );

      return { mediaUrl: response.filePath, mediaType: "IMAGE" };
    } catch (err) {
      console.log("err: ", err);
      return { mediaUrl: "", mediaType: "" };
    }
  };

  useEffect(() => {
    if (props.id) {
      const modifiedObj = commonKeysObject(props.data, initialState);
      setForm({ ...modifiedObj, parentIds: modifiedObj.parentIds.split(",") });
      setValue("name", modifiedObj.name);
      setValue("isSendAllParent", modifiedObj.isSendAllParent);
    } else {
      setForm(initialState);
      reset(initialState);
    }
  }, [props.id, props.data]);

  return (
    <Form onSubmit={handleSubmit(onEditSubmit)}>
      <Col md={12} xl={12}>
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={`${!props.id ? "Add " : "Edit "}Children Permission`}
          />
          <CardBody>
            <Row className="mt-3">
              <Col md={6}>
                <FormGroup>
                  <Label>{"Permission Name"}</Label>{" "}
                  <span className="text-danger">*</span>
                  <input
                    className={`form-control ${errors.name ? "is-invalid" : ""
                      }`}
                    type="text"
                    placeholder="Permission Name"
                    {...register("name", { required: true })}
                    value={form.name}
                    onChange={(e) => saveDataForm("name", e.target.value)}
                  />
                  {errors.name && (
                    <FormFeedback
                      style={{ color: "orangeRed", display: "block" }}
                    >
                      {"Permission Name is required"}{" "}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={12} className="position-relative">
                <FormGroup>
                  <Label>{"Description"}</Label>
                  <textarea
                    rows={3}
                    className={`form-control`}
                    placeholder="Enter your description"
                    {...register("description")}
                    value={form.description || ""}
                    onChange={(e) =>
                      saveDataForm("description", e.target.value)
                    }
                  ></textarea>

                  {errors.description && (
                    <span style={{ color: "red" }}>
                      {"Description is required"}{" "}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6} className="position-relative">
                <Label>{"Attachment"}</Label>
                <div style={{ display: "flex" }}>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    {...register("mediaUrl")}
                    onChange={(e) => {
                      saveDataForm("files", e.target.files);
                    }}
                  />
                  {errors.mediaUrl && (
                    <span style={{ color: "red" }}>
                      {"Attachment is required"}{" "}
                    </span>
                  )}
                  {form.mediaUrl && (
                    <a
                      href={baseUrl + form.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-block"
                    >
                      View Media
                    </a>
                  )}
                </div>
              </Col>

              <Col md={6} className="position-relative">
                <Label style={{ marginRight: "1.5rem" }}>
                  Parents Selection <span className="text-danger">*</span>
                </Label>
                <br />
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id={`isSendAllParent-YES`}
                    className="form-check-input"
                    {...register(`isSendAllParent`, {
                      required: false,
                    })}
                    value={"All"}
                    checked={form.isSendAllParent === "All"}
                    onChange={(e) =>
                      saveDataForm("isSendAllParent", e.target.value)
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor={`isSendAllParent-YES`}
                  >
                    All
                  </Label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id={`isSendAllParent-NO`}
                    value="Specific"
                    className="form-check-input"
                    {...register(`isSendAllParent`, {
                      required: false,
                    })}
                    checked={form.isSendAllParent === "Specific"}
                    onChange={(e) =>
                      saveDataForm("isSendAllParent", e.target.value)
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor={`isSendAllParent-NO`}
                  >
                    Specific
                  </Label>
                </div>
                {errors.isSendAllParent && (
                  <span style={{ color: "red" }}>
                    {"Parents Selection is required"}{" "}
                  </span>
                )}
              </Col>

              {form.isSendAllParent === "Specific" && (
                <Col md={12} className="position-relative mt-3">
                  <FormGroup>
                    <Label>{"Parents"}</Label>{" "}
                    <span className="text-danger">*</span>
                    <Controller
                      name="parentIds"
                      control={control}
                      rules={{
                        required: false,
                      }} // Validation rules
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="parentIds"
                          options={parentOptions} // Options for selection
                          isMulti // Enable multiple selection
                          value={parentOptions.filter((option: any) =>
                            form.parentIds?.includes(option.value)
                          )} // Set current values, handling multiple selected options
                          onChange={(selectedOptions: any) => {
                            // Check if selectedOptions is not null
                            const selectedValues = selectedOptions
                              ? selectedOptions.map(
                                (option: any) => option.value
                              )
                              : [];
                            field.onChange(selectedValues); // Update the field value
                            saveDataForm("parentIds", selectedValues); // Save the data
                          }} // Handle value changes
                        />
                      )}
                    />
                    {errors.ParentIds && (
                      <span style={{ color: "red" }}>
                        {"Live With is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              )}
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            <Btn color="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <span className="d-flex gap-2">
                  <Spinner color="white" size="sm" /> Loading...
                </span>
              ) : (
                <>{!!props.id ? "Update & Save" : "Save & Update"}</>
              )}
            </Btn>
            <Btn
              color="dark"
              type="button"
              style={{ marginLeft: "1rem" }}
              onClick={() => props.setAction({ id: "", action: "List" })}
            >
              {"Back"}
            </Btn>
          </CardFooter>
        </Card>
      </Col>
    </Form>
  );
}

export default ChildrenPermission;
