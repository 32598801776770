import React, { useEffect, useState } from "react";
import MealPlanApi from "../../../services/MealPlanApi"; // Adjusted path
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row, Tooltip } from "reactstrap";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, LI, UL } from "../../../AbstractElements";
import Status from "../../../Components/Common/Status";
import { statusColors } from "../../../Utils/Constants";
import Swal from "sweetalert2";
import UserApi from "src/services/UserApi";
import { connectComponent } from "./connector";
import { commonKeysObject } from "src/Utils";
import DropDownAPi from "src/services/DropdownApi";
import { jsPDF } from "jspdf";
import { timeStamp } from "console";

// Adding status to the MealPlan interface
interface MealPlan {
  id: string;
  title: string;
  assignTo: "children" | "rooms";
  selectedOptions: string[];
  startDate: string;
  endDate: string;
  mealPlan: {
    [day: string]: {
      breakfast: string;
      lunch: string;
      tea: string;
      snack: string;
      bottle: string;
    };
  };
  status: 0 | 1; // 0 for ACTIVE, 1 for INACTIVE
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ListMealPlan = (props: any) => {
  const [mealPlan, setMealPlan] = useState<MealPlan[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState<any>({});

  const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});

  const toggleDropdownOne = (id: any) => {
    setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
  };

  const toggleTooltip = (id: any) => {
    setTooltipOpen((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };


  const fetchMealPlans = async () => {
    try {
      let roomNames: any = [];
      const responseRooms = await DropDownAPi.getClassRoomsOptions(
        props.nurseryId || ""
      );

      if (responseRooms.success && responseRooms?.data?.length > 0) {
        const roomNameOptions = responseRooms?.data?.map((item: any) => ({
          name: item.roomName,
          id: item.id,
        }));
        roomNames = roomNameOptions;
      }

      let childrenData: any = [];
      const responseChildren = await UserApi.getAllChildren({
        nurseryId: props.nurseryId,
      });
      if (
        responseChildren.success &&
        Array.isArray(responseChildren.children)
      ) {
        const formData = responseChildren.children.map(
          (row: any, index: any) => {
            const modifiedObj = commonKeysObject(row, {
              id: "",
              firstName: "",
              lastName: "",
            });
            return {
              name: `${modifiedObj.firstName} ${modifiedObj.lastName}`,
              id: modifiedObj.id,
            };
          }
        );
        childrenData = formData;
      }

      const response = await MealPlanApi.getMealPlans(props.nurseryId);
      if (response.success && Array.isArray(response.data)) {
        const modifiedObj = response.data.map((row: any) => {
          const rooms: any = [];
          const children: any = [];

          if (!!row.children) {
            row.children.split(",").forEach((row1: any) => {
              const foundData = childrenData.find(
                (child: any) => child.id === row1
              );
              if (foundData) {
                children.push(foundData);
              }
            });
          }
          if (!!row.rooms) {
            row.rooms.split(",").forEach((row1: any) => {
              const foundData = roomNames.find((room: any) => room.id === row1);
              if (foundData) {
                rooms.push(foundData);
              }
            });
          }

          return { ...row, rooms, children };
        });
        setMealPlan(modifiedObj);
      } else setMealPlan([]);
    } catch (error: any) {
      setMealPlan([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMealPlans();
  }, [props.nurseryId]);

  const filteredItems = mealPlan.filter((mealPlan) =>
    mealPlan.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleDetailPage = (id: string, type: string = "Edit") => {
    navigate(
      `${process.env.PUBLIC_URL}/dashboard/meal-plans/${type === "Edit" ? "edit" : "copy"}/${id}`
    );
  };

  const handleStatusChange = async (row: any, status: string) => {
    const currentStatus = status === "ACTIVE" ? 0 : 1; // Determine the current status as a string
    const id = row.id;
    try {
      // Call the API to update the status, passing the number value
      await MealPlanApi.updateMealPlan({
        status: currentStatus,
        id,
        nurseryId: props.nurseryId,
      });
      toggleDropdownOne(id);
      // Update the local state to reflect the status change
      await fetchMealPlans();
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      sortable: true,
      width: '20%'
    },
    {
      name: <CustomHeader title="Assigned To" />,
      selector: (row) => row.assignTo,
      width: '30%',
      sortable: true,
      cell: (row) => (
        <>
          {row.assignTo === "children" &&
            row.children.map((child: any) => child.name).join(", ")}

          {row.assignTo === "rooms" &&
            row.rooms.map((rooms: any) => rooms.name).join(", ")}
        </>
      ),
    },
    {
      name: <CustomHeader title="Date Range" />,
      selector: (row) => `${row.startDate} - ${row.endDate}`,
      width: '20%',
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => (row.status === 0 ? "ACTIVE" : "INACTIVE"),
      width: '10%',
      sortable: true,
      center: true,
      cell: (row) => (
        <Status
          position={row.status === 0 ? "ACTIVE" : "INACTIVE"}
          color={row.status === 0 ? statusColors.active : statusColors.inactive}
          row={row}
          id={row.id}
          newUI={true}
          statusList={["ACTIVE", "INACTIVE"]}
          handleStatusChange={handleStatusChange}
          toggleDropdownOne={toggleDropdownOne}
          dropdownOpenOne={dropdownOpenOne}
        />
      ),
    },
    {
      name: <CustomHeader title="Action" />,
      width: '20%',
      center: true,
      cell: (row) => (
        <UL
          className="action simple-list w-100 d-flex justify-content-end"
          style={{ flexDirection: "row" }}
        >
          <LI className="edit" style={{ marginRight: "0.5rem" }}>
            <a
              href="javascript:void(0);"
              onClick={() => handleDetailPage(row.id)}
              className="icon"
            >
              <Btn color="primary" size="sm" id={`editTooltip_${row.id}`}>
                <i
                  className="fa-solid fa-pencil me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
              </Btn>
            </a>
            <Tooltip
              isOpen={tooltipOpen[`editTooltip_${row.id}`]}
              toggle={() => toggleTooltip(`editTooltip_${row.id}`)}
              target={`editTooltip_${row.id}`}
            >
              Edit
            </Tooltip>
          </LI>
          <LI className="copy" style={{ marginRight: "0.5rem" }}>
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                e.preventDefault(); // Prevent page refresh
                handleDetailPage(row.id, "copy");
              }}
              className="icon"
            >
              <Btn color="warning" size="sm" id={`copyTooltip_${row.id}`}>
                <i
                  className="fa-solid fa-copy me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
              </Btn>
            </a>
            <Tooltip
              isOpen={tooltipOpen[`copyTooltip_${row.id}`]}
              toggle={() => toggleTooltip(`copyTooltip_${row.id}`)}
              target={`copyTooltip_${row.id}`}
            >
              Copy
            </Tooltip>
          </LI>
          <LI className="download" style={{ marginRight: "0.5rem" }}>
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                e.preventDefault(); // Prevent page refresh
                generateAndPrintPDF(row, "download");
              }}
              className="icon"
            >
              <Btn color="danger" size="sm" id={`downloadTooltip_${row.id}`}>
                <i className="fa-solid fa-file-pdf me-1 text-white" style={{ fontSize: "12px" }}></i>
              </Btn>
            </a>
            <Tooltip
              isOpen={tooltipOpen[`downloadTooltip_${row.id}`]}
              toggle={() => toggleTooltip(`downloadTooltip_${row.id}`)}
              target={`downloadTooltip_${row.id}`}
            >
              Download PDF
            </Tooltip>
          </LI>
          <LI className="print">
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                e.preventDefault(); // Prevent page refresh
                generateAndPrintPDF(row, "print");
              }}
              className="icon"
            >
              <Btn color="dark" size="sm" id={`printTooltip_${row.id}`}>
                <i className="fa-solid fa-print me-1 text-white" style={{ fontSize: "12px" }}></i>
              </Btn>
            </a>
            <Tooltip
              isOpen={tooltipOpen[`printTooltip_${row.id}`]}
              toggle={() => toggleTooltip(`printTooltip_${row.id}`)}
              target={`printTooltip_${row.id}`}
            >
              Print PDF
            </Tooltip>
          </LI>
        </UL>
      ),
    },
  ];
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const generateAndPrintPDF = (row: any, type: any) => {
    const doc = new jsPDF({ orientation: "landscape" });
    const margin = 10;
    const cellPadding = 4;
    const boxWidth = 85; // Box width for each day
    const boxHeight = 135; // Box height for each day
    const boxSpacing = 5; // Spacing between boxes
    const startX = margin;
    const startY = margin + 10;

    // Title
    doc.setFontSize(28);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 128, 128); // Dark teal
    doc.text("MEAL PLAN:", margin, margin + 5);

    // Format the start and end dates
    const startDate = new Date(row.startDate); // Assuming row.startDate is in a recognizable format (e.g., '2/2/2024')
    const endDate = new Date(row.endDate); // Assuming row.endDate is in a recognizable format (e.g., '3/2/2024')

    // Format as MM/DD/YYYY
    const formatDate = (date: Date) => {
      const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
      const day = date.getDate();
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    const dateRange = `${formattedStartDate} - ${formattedEndDate}`;

    // Add "[startDate] - [endDate]"
    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 128, 128); // Dark teal
    doc.text(` ${dateRange}`, margin, margin + 15); // Adjust the y position to be below "MEAL PLAN:"

    const data = JSON.parse(row.mealPlan);
    const days = Object.keys(data);
    const categories = ["Break Fast", "Lunch", "Tea", "Snack", "Bottle"];
    let x = startX;
    let y = startY + 12; // Adjust the starting y position to make room for the date range

    days.forEach((day) => {
      // Calculate total height required for all categories
      let totalCategoryHeight = 0;
      let hasMealValues = false; // Flag to check if there are any meal values

      categories.forEach((category) => {
        const meals = data[day][category] || [];
        if (meals.length > 0) {
          hasMealValues = true; // Mark that at least one category has meals
        }

        const mealText =
          meals.length > 0 ? meals.join(", ") : "No meals planned";

        // Wrap meal text to fit inside the box
        const wrappedMealText = doc.splitTextToSize(
          mealText,
          boxWidth + 2 * cellPadding
        );

        // Calculate dynamic box height based on wrapped text
        const dynamicBoxHeight = wrappedMealText.length * 5 + cellPadding * 2;

        // Add the height of this category to the total
        totalCategoryHeight += dynamicBoxHeight + 7; // Adding spacing between categories
      });

      // If no meal values, use default box height (120); otherwise, calculate dynamic height
      const mainDayBoxHeight = hasMealValues
        ? Math.max(totalCategoryHeight + cellPadding * 2, boxHeight)
        : boxHeight;

      // Check if the current box will exceed the page width or height
      if (x + boxWidth + margin > doc.internal.pageSize.width) {
        x = startX; // Reset to the left margin
        y += mainDayBoxHeight + boxSpacing; // Move down to the next row
      }

      if (y + mainDayBoxHeight + margin > doc.internal.pageSize.height) {
        doc.addPage();
        x = startX;
        y = startY;
      }

      // Main Day Box Border
      doc.setDrawColor(0, 128, 128); // Teal color
      doc.setLineWidth(0.5);
      doc.roundedRect(x, y, boxWidth, mainDayBoxHeight, 5, 5, "S");

      // Day Title
      doc.setFontSize(20);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 128, 128); // Dark teal
      doc.text(day, x + cellPadding, y + cellPadding + 6);

      // Underline for Day Title
      const dayTextWidth = doc.getTextWidth(day); // Get the width of the day text
      doc.setDrawColor(40, 40, 100);
      doc.setLineWidth(0.5);
      doc.line(x + cellPadding, y + 12, x + cellPadding + dayTextWidth, y + 12); // Draw line

      let categoryY = y + cellPadding + 14;

      // Categories and Meals
      categories.forEach((category) => {
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.setTextColor(0, 128, 128); // Dark teal
        doc.text(category + ":", x + cellPadding, categoryY);

        // Meal Values
        const meals = data[day][category] || [];
        const mealText =
          meals.length > 0 ? meals.join(", ") : "No meals planned";

        // Wrap meal text to fit inside the box
        const wrappedMealText = doc.splitTextToSize(
          mealText,
          boxWidth + 2 * cellPadding
        );

        // Calculate dynamic box height based on wrapped text
        const dynamicBoxHeight = wrappedMealText.length * 5 + cellPadding * 2;

        // Draw meal box with dynamic height
        doc.setDrawColor(100, 100, 100);
        doc.setLineWidth(0.3);
        doc.roundedRect(
          x + cellPadding,
          categoryY + 2,
          boxWidth - 2 * cellPadding,
          dynamicBoxHeight,
          3, // Horizontal corner radius
          3, // Vertical corner radius
          "S"
        );

        // Render each line of wrapped text inside the meal box
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.setTextColor(60, 60, 60);
        wrappedMealText.forEach((line: any, lineIndex: any) => {
          doc.text(line, x + cellPadding + 2, categoryY + 8 + lineIndex * 5);
        });

        // Adjust position for the next category
        categoryY += dynamicBoxHeight + 10; // Add spacing for the next category
      });

      x += boxWidth + boxSpacing; // Move to the next column
    });

    // Generate the PDF
    const pdfBlob = doc.output("blob");

    if (type === "download") {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = `${row.title.replace(
        /[^a-zA-Z0-9]/g,
        ""
      )}_${Date.now()}.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    } else {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const printWindow: any = window.open(pdfUrl, "_blank");
      printWindow.onload = function () {
        printWindow.print();
      };
    }
  };



  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center">
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Meal Plans`}
                  titleClass="mb-0"
                />
                <Button
                  color="primary"
                  outline={true}
                  className="btn-md mt-3 me-3"
                  onClick={() =>
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/meal-plans/add`
                    )
                  }
                >
                  <i className="fa fa-plus mr-2"></i> Add Meal Plan
                </Button>
              </div>
              <hr />
              <CardBody>
                <Row className="justify-content-end  search-border-bottom">
                  <Col md={2}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(ListMealPlan);
