import api from "./api";

export default class ConversationApi {
    static async getConversations(userId: string, params?: any) {
        try {
            const response = await api.get(`/conversation/${userId}`, { params });

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async updateConversation(conversationId: string, data: any) {
        try {
            const response = await api.patch(`/conversation/${conversationId}`, data);

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async checkConversationExists(receiver: string, sender: string, nurseryId: string) {
        try {
            const response = await api.get(`/conversation/isExists`, {
                params: { receiver, sender, nurseryId }
            });

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async createConversation(data: any) {
        try {
            const response = await api.post(`/conversation`, data);

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async getMessages(id: string, params?: any) {
        try {
            const response = await api.get(`/message/${id}`, { params });

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }

    static async createMessage(data: any) {
        try {
            const response = await api.post(`/message`, data);

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(errorMessage);
        }
    }
} 