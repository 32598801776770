import React, { useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    Table,
    Input,
    Collapse,
    Button,
    UncontrolledTooltip,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
    Label
} from "reactstrap";
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader';
import { connectComponent } from './connector';
import { format, addDays, startOfWeek, eachDayOfInterval, addWeeks, subWeeks } from 'date-fns';
import { FaThermometer, FaChevronDown, FaChevronUp, FaInfoCircle, FaFileExport, FaArrowLeft, FaCheck, FaSun, FaExclamationTriangle } from 'react-icons/fa';
import './ChildAttendance.css';
import FilterComponent from 'src/Components/Tables/DataTables/Common/FilterComponent';
import UserApi from 'src/services/UserApi';
interface DayAttendance {
    date: string;
    expectedDuration: string;
    completedDuration: string;
    isPresent: boolean;
    temperature?: number;
}

interface Child {
    id: number;
    name: string;
    age: string;
    weeklyAttendance: DayAttendance[];
    totalExpectedHours: number;
    totalCompletedHours: number;
}

interface Room {
    id: number;
    name: string;
    totalChildren: number;
    children: Child[];
    totalExpectedHours: number;
    totalCompletedHours: number;
}

function ChildAttendance({ user, nurseryId }: any) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [expandedRooms, setExpandedRooms] = useState<{ [key: string]: boolean }>({});
    const [showLeave, setShowLeave] = useState(false);
    const [allCollapsed, setAllCollapsed] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [rooms, setRooms] = useState<Room[]>([]);

    const weekDays = Array.from({ length: 5 }, (_, i) => {
        const day = addDays(startOfWeek(selectedDate, { weekStartsOn: 1 }), i);
        return {
            date: day,
            formattedDate: format(day, 'dd MMMM'),
            dayName: format(day, 'EEEE')
        };
    });


    const fetchChildAttendance = async () => {
        try {
            const response = await UserApi.getChildAttendance({
                dateRange: JSON.stringify(weekDays),
                nurseryId: nurseryId
            });

            console.log('response: ', response);
            if (response.success) {
                setRooms(response.data);
            } else {
                setRooms([]);
            }
        } catch (error) {

            setRooms([]);
        }
    }

    useEffect(() => {
        fetchChildAttendance();
    }, []);

    useEffect(() => {
        fetchChildAttendance();
    }, [selectedDate]);

    // const rooms: Room[] = [
    //     {
    //         id: 1,
    //         name: 'Discoverers',
    //         totalChildren: 3,
    //         totalExpectedHours: 120,
    //         totalCompletedHours: 110,
    //         children: [
    //             {
    //                 id: 1,
    //                 name: 'Annie Sophia Laing',
    //                 age: '1 year',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 35,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '09:03 - 23:59',
    //                         isPresent: true,
    //                         temperature: 37.2
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 17:30',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '',
    //                         isPresent: false
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '08:00 - 17:15',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:50 - 17:45',
    //                         isPresent: true,
    //                         temperature: 37.0
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 2,
    //                 name: 'John Smith',
    //                 age: '1.2 years',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 38,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 18:15',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 37.0
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 18:15',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 3,
    //                 name: 'Emma Wilson',
    //                 age: '11 months',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 37,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '',
    //                         isPresent: false
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 37.8
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 18:15',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     }
    //                 ]
    //             }
    //         ]
    //     },
    //     {
    //         id: 2,
    //         name: 'Explorers',
    //         totalChildren: 3,
    //         totalExpectedHours: 120,
    //         totalCompletedHours: 115,
    //         children: [
    //             {
    //                 id: 4,
    //                 name: 'Oliver Brown',
    //                 age: '2 years',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 38,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 18:15',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 37.9
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 5,
    //                 name: 'Sophia Davis',
    //                 age: '1.8 years',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 39,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 18:15',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 6,
    //                 name: 'Lucas Miller',
    //                 age: '2.1 years',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 38,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 18:15',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '',
    //                         isPresent: false
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     }
    //                 ]
    //             }
    //         ]
    //     },
    //     {
    //         id: 3,
    //         name: 'Adventurers',
    //         totalChildren: 3,
    //         totalExpectedHours: 120,
    //         totalCompletedHours: 118,
    //         children: [
    //             {
    //                 id: 7,
    //                 name: 'Mia Taylor',
    //                 age: '2.5 years',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 39,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:45 - 18:15',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 8,
    //                 name: 'Ethan Anderson',
    //                 age: '2.3 years',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 40,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     }
    //                 ]
    //             },
    //             {
    //                 id: 9,
    //                 name: 'Ava Thompson',
    //                 age: '2.4 years',
    //                 totalExpectedHours: 40,
    //                 totalCompletedHours: 39,
    //                 weeklyAttendance: [
    //                     {
    //                         date: '2025-02-03',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     },
    //                     {
    //                         date: '2025-02-04',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-05',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:30 - 18:30',
    //                         isPresent: true,
    //                         temperature: 36.7
    //                     },
    //                     {
    //                         date: '2025-02-06',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:35 - 18:25',
    //                         isPresent: true,
    //                         temperature: 36.8
    //                     },
    //                     {
    //                         date: '2025-02-07',
    //                         expectedDuration: '07:30 - 18:30',
    //                         completedDuration: '07:40 - 18:20',
    //                         isPresent: true,
    //                         temperature: 36.9
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // ];

    const toggleAllRooms = () => {
        const newState = !allCollapsed;
        setAllCollapsed(newState);
        const newExpandedRooms = rooms.reduce((acc, room) => ({
            ...acc,
            [room.id]: !newState
        }), {});
        setExpandedRooms(newExpandedRooms);
    };

    const toggleRoom = (roomId: number) => {
        setExpandedRooms(prev => ({
            ...prev,
            [roomId]: !prev[roomId]
        }));
    };

    const getAttendanceCounts = (room: Room, date: Date) => {
        const dateStr = format(date, 'd MMMM');
        let expectedCount = 0;
        let actualCount = 0;
        let sickLeaveCount = 0;
        let holidayCount = 0;

        room.children.forEach(child => {
            const attendance = child.weeklyAttendance.find(day => day.date === dateStr);
            if (attendance && attendance.expectedDuration) {
                if (attendance.completedDuration) {
                    // expectedCount++; // ! If you want to count the expected duration
                    if (attendance.completedDuration === 'Sick Leave') {
                        sickLeaveCount++;
                    } else if (attendance.completedDuration === 'Holiday') {
                        holidayCount++;
                    } else {
                        actualCount++;
                    }
                }
            }
            expectedCount++;
        });

        return {
            expectedCount,
            actualCount,
            sickLeaveCount,
            holidayCount
        };
    };

    const getTemperatureCount = (room: Room, date: Date) => {
        const dateStr = format(date, 'yyyy-MM-dd');
        return room.children.filter(child => {
            const attendance = child.weeklyAttendance.find(day => day.date === dateStr);
            return attendance?.temperature && attendance.temperature > 37.5;
        }).length;
    };

    // Add this helper function to convert 12-hour format to 24-hour format
    const convertTo24Hour = (time12h: string) => {
        if (!time12h) return '';

        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = modifier === 'PM' ? '12' : '00';
        } else {
            hours = modifier === 'PM' ? String(parseInt(hours, 10) + 12) : hours.padStart(2, '0');
        }

        return `${hours}:${minutes}`;
    };

    const isEndTimeGreaterThanExpected = (expectedDuration: string, completedDuration: string) => {
        if (!completedDuration) return false;
        if (!expectedDuration) return false;

        // Convert times to 24-hour format
        const expectedEndTime = convertTo24Hour(expectedDuration.split(' - ')[1]);
        const actualEndTime = convertTo24Hour(completedDuration.split(' - ')[1]);

        const [expectedHour, expectedMinute] = expectedEndTime.split(':').map(Number);
        const [actualHour, actualMinute] = actualEndTime.split(':').map(Number);

        if (actualHour > expectedHour) return true;
        if (actualHour === expectedHour && actualMinute > expectedMinute) return true;
        return false;
    };

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "5px solid #eee" }}>
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`Child Attendance`}
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                            </div>
                            <CardBody>
                                {/* Filters Section */}
                                <Row className="mb-3 g-3">
                                    <Col md={2}>
                                        <Label>From</Label>
                                        <FilterComponent
                                            onFilter={(date: any) => {
                                                setSelectedDate(date);
                                            }}
                                            filterText={selectedDate}
                                            type="date"
                                            showDateLabel={false}
                                            placeholder="Select From Date"
                                        />
                                    </Col>
                                </Row>

                                {/* Checkboxes */}
                                <Row className="mb-3">
                                    <Col>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" /> Show leave when not expected
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <div style={{
                                    overflowX: 'auto',
                                    overflowY: 'auto',
                                    maxHeight: 'calc(100vh - 300px)',
                                    position: 'relative',
                                    width: '100%',
                                    zIndex: 0,
                                }}>
                                    <Table responsive bordered hover={false} className="attendance-table" style={{
                                        position: 'relative',
                                        minWidth: '1500px'
                                    }}>
                                        <thead >
                                            <tr>
                                                <th style={{ width: "120px" }}>
                                                    <div className="room-name font-weight-bold">
                                                        Room
                                                    </div>
                                                </th>
                                                {weekDays.map((day, index) => (
                                                    <th key={index} style={{
                                                        minWidth: '180px',
                                                        width: '180px',
                                                        backgroundColor: 'white'
                                                    }}>
                                                        <div>{format(day.date, 'd MMMM')}</div>
                                                        <div>{format(day.date, 'EEEE')}</div>
                                                    </th>
                                                ))}
                                                <th style={{ width: "80px" }}>Total Hours</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rooms.map(room => (
                                                <React.Fragment key={room.id}>
                                                    <tr className="room-row">
                                                        <td>
                                                            <div className="room-info">
                                                                <div className="room-name">
                                                                    {room.name}
                                                                </div>
                                                                <div onClick={() => toggleRoom(room.id)} style={{ cursor: 'pointer' }}>
                                                                    {expandedRooms[room.id] ? <FaChevronUp /> : <FaChevronDown />}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {weekDays.map((day, index) => {
                                                            const counts = getAttendanceCounts(room, day.date);
                                                            return (
                                                                <td key={index} style={{
                                                                    minWidth: '200px',
                                                                    width: '200px',
                                                                    position: 'relative',
                                                                    zIndex: 1
                                                                }}>
                                                                    <div className="attendance-counts">
                                                                        <div className="count-item bg-light-dark" style={{ maxWidth: "50px" }}>
                                                                            <FaCheck className="icon" /> {counts.expectedCount}
                                                                        </div>
                                                                        <div className="count-item bg-light-success" style={{ maxWidth: "50px" }}>
                                                                            <FaCheck className="icon" /> {counts.actualCount}
                                                                        </div>
                                                                    </div>
                                                                    <div className="attendance-counts">
                                                                        <div className="count-item bg-light-danger" style={{ maxWidth: "50px" }}>
                                                                            <FaThermometer className="icon" /> {counts.sickLeaveCount}
                                                                        </div>
                                                                        <div className="count-item bg-light-warning" style={{ maxWidth: "50px" }}>
                                                                            <FaSun className="icon" /> {counts.holidayCount}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            );
                                                        })}
                                                        <td>
                                                            <div className="total-hours">
                                                                <span className="expected">{room.totalExpectedHours}h</span>
                                                                <span className="completed">{room.totalCompletedHours}h</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {expandedRooms[room.id] && room.children.map(child => (
                                                        <tr key={child.id} className="child-row">
                                                            <td>
                                                                <div className="child-info">
                                                                    <div className="child-name">{child.name}</div>
                                                                    <div className="child-age">{child.age}</div>
                                                                </div>
                                                            </td>
                                                            {weekDays.map((day, index) => {
                                                                const attendance = child.weeklyAttendance.find(
                                                                    att => att.date === format(day.date, 'd MMMM')
                                                                );
                                                                return (
                                                                    <td key={index} style={{
                                                                        minWidth: '200px',
                                                                        width: '200px',
                                                                        position: 'relative',
                                                                        zIndex: 1
                                                                    }}>
                                                                        {attendance && attendance.expectedDuration && (
                                                                            <div className="attendance-counts children">
                                                                                <div className="count-item bg-light-dark" style={{ maxWidth: "150px" }}>
                                                                                    <FaCheck className="icon" /> {attendance.expectedDuration}
                                                                                </div>
                                                                                {attendance.isPresent && (
                                                                                    <div className={`count-item ${isEndTimeGreaterThanExpected(attendance.expectedDuration, attendance.completedDuration) ? 'bg-light-warning' : 'bg-light-success'}`}
                                                                                        style={{ maxWidth: "150px" }}>
                                                                                        {isEndTimeGreaterThanExpected(attendance.expectedDuration, attendance.completedDuration) ?
                                                                                            <FaExclamationTriangle className="icon" /> :
                                                                                            <FaCheck className="icon" />
                                                                                        }
                                                                                        {attendance.completedDuration}
                                                                                    </div>
                                                                                )}
                                                                                {!attendance.isPresent && (!!attendance.completedDuration) && (
                                                                                    <div className={`count-item ${attendance.completedDuration == 'Holiday' ? 'bg-light-warning' : 'bg-light-danger'}`} style={{ maxWidth: "150px" }}>
                                                                                        <FaExclamationTriangle className="icon" /> {attendance.completedDuration}
                                                                                    </div>
                                                                                )}
                                                                                {attendance.temperature && attendance.temperature > 37.5 && (
                                                                                    <div className="count-item bg-light-danger" style={{ maxWidth: "150px" }}>
                                                                                        <FaThermometer className="icon" /> {attendance.temperature}°C
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                            <td>
                                                                <div className="total-hours">
                                                                    <span className="expected">{child.totalExpectedHours}h</span>
                                                                    <span className="completed">{child.totalCompletedHours}h</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                            <tr className="total-row">
                                                <td>
                                                    <div className="room-info">
                                                        <div className="room-name">
                                                            Total
                                                        </div>
                                                    </div>
                                                </td>
                                                {weekDays.map((day, index) => {
                                                    const totalCounts = rooms.reduce((acc, room) => {
                                                        const counts = getAttendanceCounts(room, day.date);
                                                        return {
                                                            expectedCount: acc.expectedCount + counts.expectedCount,
                                                            actualCount: acc.actualCount + counts.actualCount,
                                                            sickLeaveCount: acc.sickLeaveCount + counts.sickLeaveCount,
                                                            holidayCount: acc.holidayCount + counts.holidayCount
                                                        };
                                                    }, { expectedCount: 0, actualCount: 0, sickLeaveCount: 0, holidayCount: 0 });
                                                    return (
                                                        <td key={index} style={{ minWidth: '200px', width: '200px' }}>
                                                            <div className="attendance-counts">
                                                                <div className="count-item bg-light-dark" style={{ maxWidth: "50px" }}>
                                                                    <FaCheck className="icon" /> {totalCounts.expectedCount}
                                                                </div>
                                                                <div className="count-item bg-light-success" style={{ maxWidth: "50px" }}>
                                                                    <FaCheck className="icon" /> {totalCounts.actualCount}
                                                                </div>
                                                            </div>
                                                            <div className="attendance-counts">
                                                                <div className="count-item bg-light-danger" style={{ maxWidth: "50px" }}>
                                                                    <FaThermometer className="icon" /> {totalCounts.sickLeaveCount}
                                                                </div>
                                                                <div className="count-item bg-light-warning" style={{ maxWidth: "50px" }}>
                                                                    <FaSun className="icon" /> {totalCounts.holidayCount}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    );
                                                })}
                                                <td>
                                                    <div className="total-hours">
                                                        <span className="expected">{rooms.reduce((sum, room) => sum + room.totalExpectedHours, 0)}h</span>
                                                        <span className="completed">{rooms.reduce((sum, room) => sum + room.totalCompletedHours, 0)}h</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>


    );
}

export default connectComponent(ChildAttendance)