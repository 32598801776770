import { TableColumn } from "react-data-table-component";
import { LI, UL } from "../../../../AbstractElements";
import moment from "moment";
import { statusColors } from "../../../../Utils/Constants";
import Status from "../../../../Components/Common/Status";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns = ({
  handleDetailPage,
  handleStatusChange,
}: {
  handleDetailPage: (id: number | string) => void;
  handleStatusChange: (row: any) => void;
}) => {
  const columnList: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => row.firstName,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Email" />,
      selector: (row) => row.email,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => (row?.isActive === true ? "ACTIVE" : "INACTIVE"),
      style: { maxWidth: "50%" },
      cell: (row) => {
        return (
          <Status
            position={row?.isActive === true ? "ACTIVE" : "INACTIVE"}
            color={
              row?.isActive
                ? {
                    text: statusColors.active.text,
                    background: statusColors.active.background,
                  }
                : {
                    text: statusColors.inactive.text,
                    background: statusColors.inactive.background,
                  }
            }
            id={row.id}
            row={row}
            handleStatusChange={handleStatusChange}
          />
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "150px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "150px", // Set consistent width
      maxWidth: "150px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href={"javascript:void(0);"}
              onClick={() => handleDetailPage(row.id)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
          {/* <LI className="delete">
            <a
              href={""}
              className="btn btn-danger d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-trash text-white" />
            </a>
          </LI> */}
        </UL>
      ),
    },
  ];

  return columnList;
};
