import { AnyKindOfDictionary } from "lodash";
import api from "./api"; // Use the Axios instance with interceptor

export default class DocumentAPi {
  static async getFoldersByUserId(params: any, id: any) {
    try {
      const response = await api.get(
        `/documentFolder/getAllDocumentsByUserId/${id}`,
        { params }
      );

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createFile(data: any) {
    try {
      const response = await api.post("/documentFile/add", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getFiles(params: AnyKindOfDictionary) {
    try {
      const response = await api.get(`/documentFile/`, { params });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async addCertificate(data: any) {
    try {
      const response = await api.post("/documentFile/addCertificate", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateCertificate(data: any) {
    try {
      const response = await api.post("/documentFile/updateCertificate", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getAllCertificates(params: AnyKindOfDictionary) {
    try {
      const response = await api.get(`/documentFile/allCertificate`, {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async addFolder(data: any) {
    try {
      const response = await api.post("/documentFolder/add", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      console.log("error-addFolder: ", error);
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  static async updateFolder(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/documentFolder/${id}`, body); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  // * CHILDREN

  static async getFoldersByChildrenId(params: any, id: any) {
    try {
      const response = await api.get(
        `/documentFolder/getAllDocumentsByChildrenId/${id}`,
        { params }
      );

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async addChildrenFolder(data: any) {
    try {
      const response = await api.post(
        "/documentFolder/addChildrenFolder",
        data
      ); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      console.log("error-addFolder: ", error);
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  static async updateChildrenFolder(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/documentFolder/update/${id}`, body); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      console.log("error-addFolder: ", error);
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  static async createChildrenFile(data: any) {
    try {
      const response = await api.post("/documentFile/addChildrenFile", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getChildrenFiles(params: AnyKindOfDictionary) {
    try {
      const response = await api.get(`/documentFile/allChildrenFiles/`, {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
