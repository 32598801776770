import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../../Data/Tables/DataTables/DataSource";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Helper/column";
import { Btn } from "../../../../AbstractElements";

function RevenueReport() {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("0-2");
  const filteredItems: HtmlColumnsInterface[] = htmlColumnsTableData.filter(
    (item: HtmlColumnsInterface) => {
      return Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Users Report`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => {}}
                >
                  Download Report
                </Button>
              </div>
              <CardBody>
                <Card color="light" inverse={true} className="custom-card">
                  <CardBody>
                    <span className="h4 text-bold">Filter : </span>
                    <div
                      className="d-flex align-items-center mt-3"
                      style={{ gap: "1.5rem" }}
                    >
                      <FilterComponent
                        onFilter={(value) => setSelectedOption(value)}
                        filterText={new Date()}
                        type="datepicker"
                      />
                      <FilterComponent
                        onFilter={(value) => setSelectedOption(value)}
                        filterText={""}
                        type="dropdown"
                        dropdown={{
                          id: "status",
                          options: [
                            {
                              value: "active",
                              label: "Active",
                            },
                            {
                              value: "inactive",
                              label: "Inactive",
                            },
                          ],
                          title: "Status",
                        }}
                      />
                      <FilterComponent
                        onFilter={(value) => setSelectedOption(value)}
                        filterText={""}
                        type="dropdown"
                        dropdown={{
                          id: "plan",
                          options: [
                            {
                              value: "Diamond Plan",
                              label: "Diamond Plan",
                            },
                            {
                              value: "Demo Plan",
                              label: "Demo Plan",
                            },
                          ],
                          title: "Plans",
                        }}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center mt-4"
                      style={{ gap: "1rem" }}
                    >
                      <Btn color="dark">Apply Filter</Btn>
                      <Btn color="primary" outline={true}>
                        Clear
                      </Btn>
                    </div>
                  </CardBody>
                </Card>

                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />

                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RevenueReport;
