import { TableColumn } from "react-data-table-component";
import { Badges, LI, UL } from "../../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";
import { calculateJoinDateToCurrentDateDuration, convertDateTimeToTime, showNames } from "../../../../../Utils";
import JustifyContent from "src/Components/UiKits/Dropdown/JustifyContent";

// More modern and colorful TimeDisplay component
const TimeDisplay = ({ time, isRota = false }: { time: string, isRota?: boolean }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '5px 12px',
    backgroundColor: isRota ? 'rgba(245, 245, 245, 0.7)' : 'rgba(45, 206, 137, 0.1)',
    borderRadius: '8px',
    fontSize: '13px',
    fontWeight: '600',
    color: isRota ? '#666' : '#2dce89',
    boxShadow: '0 2px 4px rgba(0,0,0,0.03)',
    border: isRota ? '1px solid rgba(0,0,0,0.06)' : '1px solid rgba(45, 206, 137, 0.2)',
    transition: 'all 0.2s ease',
  }}>
    <i
      className={isRota ? "fa fa-calendar-check-o" : "fa fa-check-circle"}
      style={{
        fontSize: '13px',
        color: isRota ? '#888' : '#2dce89'
      }}
    />
    <span>{time}</span>
  </div>
);

// Enhanced LeaveDisplay
const LeaveDisplay = ({ type }: { type: string }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '5px 12px',
    backgroundColor: 'rgba(245, 54, 92, 0.1)',
    borderRadius: '8px',
    fontSize: '13px',
    fontWeight: '600',
    color: '#f5365c',
    boxShadow: '0 2px 4px rgba(0,0,0,0.03)',
    border: '1px solid rgba(245, 54, 92, 0.2)',
    transition: 'all 0.2s ease',
  }}>
    <i className="fa fa-exclamation-triangle" style={{ fontSize: '13px', color: '#f5365c' }} />
    <span>{type}</span>
  </div>
);

const NoScheduleDisplay = () => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '5px 12px',
    backgroundColor: 'rgba(136, 136, 136, 0.08)',
    borderRadius: '8px',
    fontSize: '13px',
    fontWeight: '500',
    color: '#888',
    boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
    border: '1px solid rgba(0,0,0,0.06)',
    transition: 'all 0.2s ease',
  }}>
    <i className="fa fa-minus-circle" style={{ fontSize: '13px', color: '#aaa' }} />
    <span>No Schedule</span>
  </div>
);

const NoLeaveDisplay = () => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '5px 12px',
    backgroundColor: 'rgba(136, 136, 136, 0.08)',
    borderRadius: '8px',
    fontSize: '13px',
    fontWeight: '500',
    color: '#888',
    boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
    border: '1px solid rgba(0,0,0,0.06)',
    transition: 'all 0.2s ease',
  }}>
    <i className="fa fa-check" style={{ fontSize: '13px', color: '#aaa' }} />
    <span>No Leave</span>
  </div>
);

const StatusBadge = ({ status }: { status: 'present' | 'absent' | 'leave' }) => {
  const styles = {
    present: {
      bg: 'rgba(45, 206, 137, 0.1)',
      color: '#2dce89',
      border: 'rgba(45, 206, 137, 0.3)',
      icon: 'fa-check-circle',
      text: 'Present'
    },
    absent: {
      bg: 'rgba(245, 54, 92, 0.1)',
      color: '#f5365c',
      border: 'rgba(245, 54, 92, 0.3)',
      icon: 'fa-times-circle',
      text: 'Absent'
    },
    leave: {
      bg: 'rgba(251, 99, 64, 0.1)',
      color: '#fb6340',
      border: 'rgba(251, 99, 64, 0.3)',
      icon: 'fa-calendar-minus-o',
      text: 'On Leave'
    }
  };

  const style = styles[status];

  return (
    <div style={{
      padding: '6px 14px',
      backgroundColor: style.bg,
      borderRadius: '20px',
      fontSize: '13px',
      fontWeight: '600',
      color: style.color,
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      justifyContent: 'center',
      border: `1px solid ${style.border}`,
      boxShadow: '0 2px 4px rgba(0,0,0,0.03)',
      minWidth: '110px',
    }}>
      <i className={`fa ${style.icon}`}></i>
      <span>{style.text}</span>
    </div>
  );
};

const CustomBadge = ({ position, color }: CustomCellInterFace) => {
  const [model, setModel] = useState(false);
  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(255, 237, 236)",
          width: "100px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <span
          style={{
            padding: "4px 0px",
            color: "rgb(224, 49, 55)",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "10px",
          }}
        >
          {position}
        </span>
      </div>
      <span
        style={{ padding: "4px 4px", fontWeight: "700", fontSize: "16px" }}
        onClick={() => setModel(!model)}
      >
        <i className="iconly-Arrow-Down-2 icli"></i>
      </span>
      {model && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            zIndex: "1",
            width: "150px",
            height: "90px",
            background: "#000",
          }}
        >
          <h2>Hello</h2>
        </div>
      )}
    </>
  );
};

const CustomHeader = ({ title, style }: { title: string; style: any }) => (
  <span style={{
    fontWeight: "700",
    fontSize: "14px",
    color: "#495057",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    ...style
  }}>
    {title}
  </span>
);

const EmployeeAvatar = ({ firstName, lastName, joinDate }: { firstName: string, lastName: string, joinDate: string }) => {
  // Get initials for the avatar
  const getInitials = () => {
    return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`;
  };

  // Generate a consistent color based on the name
  const getAvatarColor = () => {
    const colors = [
      '#5e72e4', '#2dce89', '#11cdef', '#fb6340', '#f5365c',
      '#8965e0', '#f3a4b5', '#ffd600', '#f53c56', '#5603ad'
    ];

    const hashCode = (firstName + lastName).split('').reduce(
      (hash, char) => char.charCodeAt(0) + ((hash << 5) - hash), 0
    );

    return colors[Math.abs(hashCode) % colors.length];
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: '14px'
    }}>
      <div style={{
        width: '42px',
        height: '42px',
        borderRadius: '50%',
        backgroundColor: getAvatarColor(),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: '600',
        fontSize: '14px',
        boxShadow: '0 3px 6px rgba(0,0,0,0.12)',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(rgba(255,255,255,0.18), rgba(255,255,255,0))'
        }}></div>
        <span style={{ position: 'relative', zIndex: 1 }}>{getInitials()}</span>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <span style={{
          fontWeight: '600',
          fontSize: '14px',
          color: '#333'
        }}>{`${firstName} ${lastName}`}</span>
        <span style={{
          fontSize: '13px',
          color: '#6c757d',
          marginTop: '3px'
        }}>
          {calculateJoinDateToCurrentDateDuration(
            new Date(joinDate).getTime(),
            new Date().getTime()
          )}
        </span>
      </div>
    </div>
  );
};

export const columns = ({
  handleStatusChange,
}: {
  handleStatusChange: (row: any) => void;
}) => {
  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Employee" style={{}} />,
      selector: (row) => `${row.firstName} ${row.lastName}`,
      style: { maxWidth: "50%", justifyContent: "start" },
      cell: (row: any) => {
        return <EmployeeAvatar firstName={row.firstName} lastName={row.lastName} joinDate={row.joinDate} />;
      },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Rota & Attendance" style={{ justifyContent: "center", width: "100%", display: "flex" }} />,
      style: { justifyContent: "center" },
      cell: (row: any) => {
        const todayRota = row.rotaEmployees?.find((rota: any) => rota.rota !== null);
        const actualInTime = convertDateTimeToTime(row?.attendance?.[0]?.clockInTime);
        const actualOutTime = convertDateTimeToTime(row?.attendance?.[0]?.clockOutTime);

        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px 0' }}>
            {todayRota && (
              <TimeDisplay
                time={`${todayRota.startTime} - ${todayRota.endTime}`}
                isRota={true}
              />
            )}
            {(actualInTime || actualOutTime) && (
              <TimeDisplay
                time={`${actualInTime || 'Not In'}${actualOutTime ? ` - ${actualOutTime}` : ''}`}
              />
            )}
            {!todayRota && !actualInTime && !actualOutTime && (
              <NoScheduleDisplay />
            )}
          </div>
        );
      },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Leave Status" style={{ justifyContent: "center", width: "100%", display: "flex" }} />,
      style: { justifyContent: "center" },
      cell: (row: any) => {
        const leaveStatus: any = row.EmployeeLeave.length > 0 ? showNames(row?.EmployeeLeave) : null;

        return leaveStatus ?
          <LeaveDisplay type={leaveStatus} /> :
          <NoLeaveDisplay />;
      },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" style={{ justifyContent: "center", width: "100%", display: "flex" }} />,
      style: { justifyContent: "center" },
      cell: (row: any) => {
        const actualInTime = convertDateTimeToTime(row?.attendance?.[0]?.clockInTime);
        const isPresent = !!actualInTime;
        const hasLeave = row.EmployeeLeave.length > 0;

        if (hasLeave) {
          return <StatusBadge status="leave" />;
        } else if (isPresent) {
          return <StatusBadge status="present" />;
        } else {
          return <StatusBadge status="absent" />;
        }
      },
      sortable: true,
      center: false,
    }
  ];
  return columns;
};
