import React, { useState } from "react";
import RolesPermissionList from "./list";
import RolesPermissionForm from "./form";

const RolesPermission = (props: any) => {
  const [action, setAction] = useState({
    id: "",
    action: props.openAddForm ? "add" : "list",
  }); // * action: list, add, edit

  return (
    <>
      {action.action === "list" && (
        <RolesPermissionList
          setAction={setAction}
          nurseryId={props.nurseryId}
        />
      )}

      {action.action === "add" && (
        <RolesPermissionForm
          setAction={setAction}
          id={action.id}
          nurseryId={props.nurseryId}
        />
      )}

      {action.action === "edit" && (
        <RolesPermissionForm
          setAction={setAction}
          id={action.id}
          nurseryId={props.nurseryId}
        />
      )}
    </>
  );
};

export default RolesPermission;
