import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable from "react-data-table-component";
import { HtmlColumnsInterface } from "../../../Types/Tables.type";
import { htmlColumnsTableData } from "../../../Data/Tables/DataTables/DataSource";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectComponent } from "./connector";
import AnnouncementApi from "src/services/AnnouncementApi";
import { columns } from "./Helper/column";
import { lowerCase } from "lodash";
import Swal from "sweetalert2";
import { announcementStatus } from "src/Utils/Constants";

function Announcements(props: any) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const [announcementList, setAnnouncementList] = useState([]);
  const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});
  const toggleDropdownOne = (id: any) => {
    setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
  };
  const filteredItems: any[] = announcementList.filter((item: any) => {
    return Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const fetchAnnouncement = async () => {
    try {
      const response = await AnnouncementApi.getAnnouncement({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.data)) {
        setAnnouncementList(response.data);
      } else {
        setAnnouncementList([]);
      }
    } catch (error: any) {
      setAnnouncementList([]);
    }
  };

  useEffect(() => {
    fetchAnnouncement();
  }, [props.nurseryId]);

  const handleStatusChange = async (row: any, status: string) => {

    // Convert the selected status ("active" or "inactive") to a boolean value
    const updatedStatus = status; // true for "active", false for "inactive"

    try {
      // Call the API to update the status, passing the boolean value
      await AnnouncementApi.updateAnnouncement({
        id: row.id,
        status: updatedStatus.toLocaleUpperCase(),
      });
      toggleDropdownOne(row.id);
      // Update the local state to reflect the status change
      await fetchAnnouncement();
      // Swal.fire("Success", "Status updated successfully!", "success");
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };


  return (
    <div className="page-body" >
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div className="d-flex justify-content-between align-items-center">
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`View All Announcements`}
                  titleClass="mb-0"
                />
                <Button
                  color="primary"
                  outline={true}
                  className="btn-md mt-3 me-3"
                  onClick={() => {
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/announcements/add`
                    );
                  }}
                >
                  Add New Announcement
                </Button>
              </div>
              <hr />
              <CardBody className="pt-0">
                <Row className="justify-content-end search-border-bottom">
                  <Col md={2}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns({
                      handleStatusChange: (row: any, status: string) => handleStatusChange(row, status),
                      toggleDropdownOne: (row: any) => toggleDropdownOne(row),
                      dropdownOpenOne: dropdownOpenOne
                    })}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true, // This applies to all rows
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default connectComponent(Announcements);
