import React, { useEffect, useState } from "react";
import FaqApi from "../../../services/FaqApi"; // Adjusted path
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { LI, UL } from "../../../AbstractElements";
import Status from "../../../Components/Common/Status";
import { statusColors } from "../../../Utils/Constants";
import Swal from "sweetalert2";

interface Faq {
  id: string;
  title: string;
  content: string;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const ListFaq: React.FC = () => {
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await FaqApi.getFaqs();
        if (response.success && Array.isArray(response.data)) {
          setFaqs(response.data);
        } else {
          throw new Error("Expected an array of FAQs");
        }
      } catch (error: any) {
        setError(error.message || "Error fetching FAQs");
      } finally {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  const filteredItems = faqs.filter((faq) =>
    faq.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleDetailPage = (id: string) => {
    navigate(`/dashboard/help-center/faq/edit/${id}`);
  };

  const handleStatusChange = async (row: any) => {
    const currentStatus = row.status === 0 ? 1 : 0; // Determine the current status as a string
    const id = row.id;
    try {
      // Call the API to update the status, passing the number value
      await FaqApi.updateFaq(id, { status: currentStatus });
      // Update the local state to reflect the status change
      setFaqs((prevFaq) =>
        prevFaq.map((faq) =>
          faq.id === row.id ? { ...faq, status: currentStatus } : faq
        )
      );
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      style: {
        minWidth: "60vw", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "60vw", // Set consistent width
      maxWidth: "60vw", // Center-aligns content in both header and cells
      sortable: true,
      cell: (row) => (
        <div
          style={{
            maxWidth: "50%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.title}
        </div>
      ),
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => (row?.status === 0 ? "ACTIVE" : "INACTIVE"),
      style: {
        minWidth: "10vw", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "10vw", // Set consistent width
      maxWidth: "10vw",
      cell: (row) => (
        <Status
          position={row?.status === 0 ? "ACTIVE" : "INACTIVE"}
          color={row.status === 0 ? statusColors.active : statusColors.inactive}
          row={row}
          id={row.id}
          handleStatusChange={handleStatusChange}
        />
      ),
    },
    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "10vw", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "10vw", // Set consistent width
      maxWidth: "10vw",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href={"javascript:void(0);"}
              onClick={() => handleDetailPage(row.id)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`FAQ's`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() =>
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/help-center/faq/add`
                    )
                  }
                >
                  <i className="fa fa-plus mr-2"></i> Add Faq
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFilterText(e.target.value)
                  }
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListFaq;
