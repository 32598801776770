import { Card, CardBody, Col, FormFeedback, Label, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

const Information = (props: any) => {
  const navigate = useNavigate();
  return (
    <Row className="g-3 mt-2">
      {/* Name Field */}
      <Col md={4}>
        <Label for="name">
          Name <span className="text-danger">*</span>
        </Label>
        <input
          type="text"
          className={`form-control ${props.error.name ? "is-invalid" : ""}`}
          placeholder="Enter Name"
          value={props.form.name}
          onChange={(e) => props.saveDataForm("name", e.target.value)}
        />
        {props.error.name && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {"Name is required"}{" "}
          </FormFeedback>
        )}
      </Col>

      <Col md={4} className="position-relative mt-3">
        <Label for="phone">
          Phone <span className="text-danger">*</span>
        </Label>
        <input
          type="text"
          className={`form-control ${props.error.phoneNo ? "is-invalid" : ""}`}
          placeholder="Enter your phone number"
          value={props.form.phoneNo}
          onInput={(e: any) => {
            const input = e.target.value;
            return (e.target.value = input
              .replace(/[^0-9.]/g, "")
              .replace(/(\..*?)\..*/g, "$1"));
          }}
          onChange={(e) => props.saveDataForm("phoneNo", e.target.value)}
        />
        {props.error.phoneNo && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {props.invalid.phone
              ? "Please enter a valid 11-digit phone number"
              : "PhoneNo is required"}{" "}
          </FormFeedback>
        )}
      </Col>
      <Col md={4} className="position-relative">
        <Label for="email">
          Email <span className="text-danger">*</span>
        </Label>
        <input
          type="email"
          className={`form-control ${props.error.email ? "is-invalid" : ""}`}
          placeholder="Enter your email"
          value={props.form.email}
          onChange={(e) => props.saveDataForm("email", e.target.value)}
        />
        {props.error.email && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {props.invalid.email
              ? "Please enter a valid email address"
              : "Email is required"}{" "}
          </FormFeedback>
        )}
      </Col>
      <Col md={4}>
        <Label for="contactPersonName">Contact Person Name</Label>
        <input
          type="text"
          className={`form-control ${props.error.religion ? "is-invalid" : ""}`}
          placeholder="Enter Contact Person Name"
          value={props.form.contactPersonName}
          onChange={(e) =>
            props.saveDataForm("contactPersonName", e.target.value)
          }
        />
      </Col>
      <Col md={4}>
        <Label for="ofstedNo">
          Ofsted Number <span className="text-danger">*</span>
        </Label>
        <input
          type="text"
          className={`form-control ${props.error.ofstedNo ? "is-invalid" : ""}`}
          placeholder="Enter Ofsted Number"
          value={props.form.ofstedNo}
          onChange={(e) => props.saveDataForm("ofstedNo", e.target.value)}
        />
        {props.error.ofstedNo && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {"OfstedNo is required"}{" "}
          </FormFeedback>
        )}
      </Col>
      <Col md={4}>
        <Label for="vatNo">VAT Number</Label>
        <input
          type="text"
          className={`form-control`}
          placeholder="Enter VAT No."
          value={props.form.vatNo}
          onChange={(e) => props.saveDataForm("vatNo", e.target.value)}
        />
      </Col>
      <Col md={4}>
        <Label for="link">Link To Privacy Policy</Label>
        <input
          type="text"
          className={`form-control `}
          placeholder="https://"
          value={props.form.link}
          onChange={(e) => props.saveDataForm("link", e.target.value)}
        />
      </Col>
      <Col md={4}>
        <Label for="description">
          Description <span className="text-danger">*</span>
        </Label>
        <textarea
          className={`form-control ${
            props.error.description ? "is-invalid" : ""
          }`}
          placeholder="Enter Description"
          value={props.form.description}
          onChange={(e) => props.saveDataForm("description", e.target.value)}
        />
        {props.error.description && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {"Description is required"}{" "}
          </FormFeedback>
        )}
      </Col>
      <Col md={4}>
        <Label for="address">
          Address <span className="text-danger">*</span>
        </Label>
        <textarea
          className={`form-control ${props.error.address ? "is-invalid" : ""}`}
          placeholder="Enter Address"
          value={props.form.address}
          onChange={(e) => props.saveDataForm("address", e.target.value)}
        />
        {props.error.address && (
          <FormFeedback style={{ color: "orangeRed", display: "block" }}>
            {"Address is required"}{" "}
          </FormFeedback>
        )}
      </Col>
    </Row>
  );
};

export default Information;
