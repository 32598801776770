import api from "./api"; // Use the Axios instance with interceptor

export default class DemoRequestAPi {
  static async getDemoRequest(params: any) {
    try {
      const response = await api.get("/demoRequest/all", { params }); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateDemoRequest(data: any, id: any) {
    try {
      const response = await api.patch(`/demoRequest/${id}`, data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async deleteDemoRequest(id: string, deletedBy: string | undefined) {
    try {
      const response = await api.delete(
        `/demoRequest/${id}${deletedBy ? `/${deletedBy}` : ""}`
      ); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
