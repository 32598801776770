/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Loader from "./Loader";
import Taptop from "./Taptop";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../ReduxToolkit/Store";
import Header from "./Header";
import ThemeCustomizer from "./ThemeCustomizer";
import { useDispatch } from "react-redux";
import { setSideBarToggle } from "../../ReduxToolkit/Reducers/Layout/LayoutReducer";
import { addSidebarTypes } from "../../ReduxToolkit/Reducers/Layout/ThemeCustomizerReducer";
import { toast } from "react-toastify";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import AttendanceAPi from "../../services/AttendanceApi";
import {
  convertTimeToDateTime,
  formatDateToYYYYMMDD,
  getUserActivityFormattedData,
} from "../../Utils";
import Swal from "sweetalert2";
import UserActivityAPi from "../../services/UserActivityApi";
import moment from "moment-timezone";

export default function Layout(props: any) {
  const { sideBarToggle } = useSelector((state: RootState) => state.layout);
  const { sidebar_types } = useSelector(
    (state: RootState) => state.themeCustomizer
  );
  const [showClock, setShowClock] = useState(props.auth.selectedNursery); // Time selection state
  const [ukTime, setUKTime] = useState(
    moment.tz("Europe/London").format("HH:mm")
  );
  const [ukDateTime, setUKDateTime] = useState(
    moment.tz("Europe/London").format("YYYY-MM-DD HH:mm:00")
  );

  const dispatch = useDispatch();

  const updateSidebarBasedOnWidth = () => {
    const windowWidth = window.innerWidth;
    if (sidebar_types === "compact-wrapper") {
      if (windowWidth <= 1200) {
        dispatch(setSideBarToggle(true));
      } else {
        dispatch(setSideBarToggle(false));
      }
    } else if (sidebar_types === "horizontal-wrapper") {
      if (windowWidth <= 992) {
        dispatch(setSideBarToggle(true));
        dispatch(addSidebarTypes("compact-wrapper"));
      } else {
        dispatch(setSideBarToggle(false));
        dispatch(addSidebarTypes("horizontal-wrapper"));
      }
    }
  };
  useEffect(() => {
    updateSidebarBasedOnWidth();
    window.addEventListener("resize", () => {
      updateSidebarBasedOnWidth();
    });
  }, [sidebar_types]);

  useEffect(() => {
    const handleStatus = async () => {
      if (props.success) {
        toast.success(props.success);
        props.setSuccess("");
      }
    };

    // Call the async function to handle the status
    handleStatus();

    // Optional: Return a cleanup function if needed (synchronous)
    return () => {
      // Cleanup logic if required (can be synchronous)
      props.setSuccess("");
      props.setError("");
    };
  }, [props.error, props.success]);

  useEffect(() => {
    const interval = setInterval(() => {
      setUKTime(moment.tz("Europe/London").format("HH:mm"));
      setUKDateTime(moment.tz("Europe/London").format("YYYY-MM-DD HH:mm:00"));
    }, 1000);

    return () => clearInterval(interval); // Cleanup
  }, []);

  const handleEmployeeAttendance = async () => {
    try {
      const response = await AttendanceAPi.addClockIn({
        nurseryId: props.auth.selectedNursery,
        employeesId: [{ empId: props.auth?.user?.id, attendanceId: "" }],
        clockInTime: ukDateTime,
        attendanceDate: formatDateToYYYYMMDD(new Date()),
      });

      if (response?.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          // Add user activity log
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );

          props.setClockIn({
            clockIn: false,
            clockInDate: formatDateToYYYYMMDD(new Date()),
          });
        });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    setShowClock(props.auth.userClockIn);
  }, [props.auth.userClockIn, props.auth.userClockInDate]);

  return (
    <>
      {showClock && (
        <Modal isOpen={true} toggle={() => {}} backdrop="static" centered>
          <ModalBody className="text-center">
            <div className="d-flex flex-column gap-1">
              <h2 className="h2">Clock In </h2>
              <h6 className="h6">
                Date & Time: {moment.tz("Europe/London").format("DD-MM-YYYY")}
                {", "}
                {ukTime}
              </h6>
            </div>
            <Button
              color="primary"
              className="mt-3"
              onClick={handleEmployeeAttendance}
            >
              Clock In
            </Button>
          </ModalBody>
        </Modal>
      )}
      <Taptop />
      <Loader />
      <div
        className={`page-wrapper ${
          sideBarToggle ? "compact-wrapper sidebar-open" : sidebar_types
        }`}
        id="pageWrapper"
      >
        <Header
          user={props.auth.user}
          setNursery={props.setNursery}
          selectedNursery={props.auth.selectedNursery}
          logout={props.logout}
          setClockIn={props.setClockIn}
          userClockIn={props.auth.userClockIn}
          userClockInDate={props.auth.userClockInDate}
          attendanceId={props.auth.attendanceId}
          userClockOut={props.auth.userClockOut}
          empClockIn={props.auth.empClockIn}
          empClockOut={props.auth.empClockOut}
          setEmpClockOut={props.setEmpClockOut}
        />
        <div className="page-body-wrapper">
          <Sidebar user={props.auth.user} logout={props.logout} />
          <Outlet />
          <Footer />
        </div>
      </div>
      <ThemeCustomizer />
    </>
  );
}
