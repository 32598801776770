// src/services/api.ts
import axios from "axios";
import { baseUrl } from "../Utils/Constants/config";
import store from "../ReduxToolkit/Store";
import Swal from "sweetalert2";
import { LOGOUT } from "src/ReduxToolkit/Slices/Auth/AuthReducer";

// Create an Axios instance with default config
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to add token to headers automatically
api.interceptors.request.use(
  (config) => {
    const state = store.getState(); // Get the current state
    const token = state.auth.token; // Access the token from the auth slice

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 unauthorized responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Show sweet alert
      Swal.fire({
        title: 'Session Expired',
        text: 'Your session has expired. Please log in again.',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6'
      }).then((result) => {
        if (result.isConfirmed) {
          // Dispatch logout action
          store.dispatch(LOGOUT());
          // Redirect to login page
          window.location.href = '/login';
        }
      });
    }
    return Promise.reject(error);
  }
);

export default api;
