import { TableColumn } from "react-data-table-component";
import { Badges, Btn, LI, UL } from "../../../../../AbstractElements";
import { CustomCellInterFace } from "./types";
import { useState } from "react";
import Status from "src/Components/Common/Status";
import { statusColors } from "src/Utils/Constants";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

// export const htmlColumns: TableColumn<HtmlColumnsInterface>[] = [
const CustomBadge = ({ position, color }: CustomCellInterFace) => {
  const [model, setModel] = useState(false);
  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(255, 237, 236)",
          width: "100px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <span
          style={{
            padding: "4px 0px",
            color: "rgb(224, 49, 55)",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "10px",
          }}
        >
          {position}
        </span>
      </div>
      <span
        style={{ padding: "4px 4px", fontWeight: "700", fontSize: "16px" }}
        onClick={() => setModel(!model)}
      >
        <i className="iconly-Arrow-Down-2 icli"></i>
      </span>
      {model && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            zIndex: "1",
            width: "150px",
            height: "90px",
            background: "#000",
          }}
        >
          <h2>Hello</h2>
        </div>
      )}
    </>
  );
};

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

export const columns = ({
  handleStatusChange,
  toggleDropdownOne,
  dropdownOpenOne
}: {
  handleStatusChange: (row: any, status: string) => void;
  toggleDropdownOne: (row: any) => void;
  dropdownOpenOne: any;
}) => {
  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => `${row.firstName} ${row.middleName} ${row.lastName}`,
      width: "20%",
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Phone" />,
      selector: (row) => row.phoneNo,
      width: "15%",
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Email" />,
      selector: (row) => row.email,
      width: "30%",
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => (row.status === 0 ? "ACTIVE" : "INACTIVE"),
      sortable: true,
      center: true,
      width: "20%",
      cell: (row) => {
        console.log('row: ', row, dropdownOpenOne[row.id]);
        return (
          <>
            <Status
              position={row?.status === 0 ? "ACTIVE" : "INACTIVE"}
              color={
                row?.status === 0
                  ? {
                    text: statusColors.active.text,
                    background: statusColors.active.background,
                  }
                  : {
                    text: statusColors.inactive.text,
                    background: statusColors.inactive.background,
                  }
              }
              id={row.id}
              row={row}
              newUI={true}
              statusList={["ACTIVE", "INACTIVE"]}
              handleStatusChange={handleStatusChange}
              toggleDropdownOne={toggleDropdownOne}
              dropdownOpenOne={dropdownOpenOne}
            />
          </>
        );
      },
    },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      center: true,
      width: "15%",
      cell: (row) => (
        <td className="text-end" style={{ whiteSpace: "nowrap" }}>
          <a className="icon me-2" href={`/dashboard/parents/edit/${row.id}`}>
            <Btn color="primary" size="sm">
              <i className="fa-solid fa-pencil"></i>

            </Btn>
          </a>
          <a
            className="icon"
            href={`/dashboard/parents/children/${row.id}`}
            style={{ marginLeft: "2px" }}
          >
            <Btn color="secondary" size="sm" >
              <i className="fa-solid fa-user"></i>

            </Btn>
          </a>
          {/* <a className="icon" href="#javascript">
            <Btn color="danger" size="sm">
              <i className="fa-solid fa-trash me-1"></i>
              {"Delete"}
            </Btn>
          </a> */}
        </td>
      ),
    },
  ];

  return columns;
};
