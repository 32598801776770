import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row, Tooltip } from "reactstrap";
import FilterComponent from "../../../Components/Tables/DataTables/Common/FilterComponent";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, LI, UL } from "../../../AbstractElements";
import Status from "../../../Components/Common/Status";
import { statusColors } from "../../../Utils/Constants";
import Swal from "sweetalert2";
import { connectComponent } from "./connector";
import EnquiryApi from "src/services/EnquiryApi";
import moment from "moment";

// Adding status to the Registration interface
interface Registration {
  id: string;
  title: string;
  assignTo: "children" | "rooms";
  selectedOptions: string[];
  startDate: string;
  endDate: string;
  Registration: {
    [day: string]: {
      breakfast: string;
      lunch: string;
      tea: string;
      snack: string;
      bottle: string;
    };
  };
  status: 0 | 1; // 0 for ACTIVE, 1 for INACTIVE
}

const CustomHeader = ({ title, className }: any) => (
  <span
    className={className ? className : ''} // Apply className if provided
    style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}
  >
    {title}
  </span>
);

const ListRegistration = (props: any) => {
  const [Registration, setRegistration] = useState<Registration[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();
  const [enquiryCount, setEnquiryCount] = useState<any>([]);
  const [tooltipOpen, setTooltipOpen] = useState<any>(false);

  const [tooltipOpenAction, setTooltipOpenAction] = useState<any>({});
  const toggleTooltipAction = (id: any) => {
    setTooltipOpenAction((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const [dropdownOpenOne, setDropdownOpenOne] = useState<any>({});

  const [filterObj, setFilterObj] = useState<any>({
    status: "",
    createdAt: "",
    startDate: "",
    nurseryId: props.nurseryId,
  });

  const [statusDropDown, setStatusDropDown] = useState<any>({
    id: "status",
    options: [
      { label: "NEW", value: "NEW" },
      { label: "VIEWED", value: "VIEWED" },
      { label: "CONTACTED", value: "CONTACTED" },
      { label: "LOST", value: "LOST" },
      { label: "WAITING LIST", value: "WAITING LIST" },
      { label: "CONFIMED", value: "CONFIMED" },
      { label: "ENROLLED", value: "ENROLLED" },
      { label: "CLOSED", value: "CLOSED" },
    ],
    title: "Select Status",
  });


  const toggleDropdownOne = (id: any) => {
    setDropdownOpenOne({ [id]: !dropdownOpenOne[id] });
  };


  const fetchRegistrations = async () => {
    try {
      const response = await EnquiryApi.fetchEnquiry(filterObj);
      if (response.success && Array.isArray(response.data)) {
        setRegistration(response.data);

      } else setRegistration([]);

      if (response.success && Array.isArray(response.enquiryCount)) {
        setEnquiryCount(response.enquiryCount);
      } else setEnquiryCount([]);
    } catch (error: any) {
      setRegistration([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRegistrations();
  }, [props.nurseryId, filterObj]);

  const filteredItems: any = Registration.filter((item: any) => {
    const newItem: any = {};
    newItem['source'] = item.source;
    newItem['childName'] = item.childName;
    newItem['childGender'] = item.childGender;
    newItem['parentName'] = item.parentName;
    newItem['parentPhoneNo'] = item.parentPhoneNo;
    newItem['parentEmail'] = item.parentEmail;

    return Object.values(newItem).some(
      (value) =>
        value &&
        value
          .toString()
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );
  });

  const handleDetailPage = (id: string) => {
    navigate(
      `/dashboard/registration/edit/${id}`
    );
  };

  const handleStatusChange = async (row: any, status: string) => {
    const id = row.id;
    try {
      if (status === "ENROLLED") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this action!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Enroll it!",
          cancelButtonText: "Cancel",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await EnquiryApi.updateEnquiry(id, {
              enquiryStatus: status,
            });
            toggleDropdownOne(id);
            await fetchRegistrations();
          }
        });
      } else {
        await EnquiryApi.updateEnquiry(id, {
          enquiryStatus: status,
        });
        toggleDropdownOne(id);
        await fetchRegistrations();
      }
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await EnquiryApi.deleteEnquiry(id); // API call to delete the comment
          fetchRegistrations();
          Swal.fire("Deleted!", "Enquiry has been deleted.", "success");
        } catch (error: any) {
          Swal.fire(
            "Error!",
            error.message || "Failed to delete the comment.",
            "error"
          );
        }
      }
    });
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Created At" />,
      selector: (row) => moment(row.createdAt).format('DD-MM-YYYY'),
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Start" />,
      selector: (row) => moment(row.childExpectedStartDate).format('DD-MM-YYYY'),
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Child" />,
      selector: (row) => row.childName,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
      cell: (row) => (
        <div className="d-flex flex-column align-items-start">
          <span>{row.childName}</span>
          <span>{row.childGender}</span>
          <span>{row.childDateOfBirth ? moment(row.childDateOfBirth).format('DD-MM-YYYY') : ""}</span>
        </div>
      ),
    },
    {
      name: <CustomHeader title="Contact" />,
      selector: (row) => row.parentName,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
      cell: (row) => (
        <div className="d-flex flex-column align-items-start">
          <span>{row.parentName}</span>
          <span>{row.parentPhoneNo}</span>
          <span>{row.parentEmail}</span>
        </div>
      ),
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => row.enquiryStatus,
      style: { maxWidth: "15%" },
      sortable: true,
      center: true,
      cell: (row) => (
        <Status
          position={row.enquiryStatus}
          color={statusColors.active}
          row={row}
          id={row.id}
          disable={row.enquiryStatus === "ENROLLED"}
          newUI={true}
          statusList={["CLOSED", "NEW", "VIEWED", "CONTACTED", "LOST", "WAITING LIST", "CONFIMED", "ENROLLED"]}
          handleStatusChange={handleStatusChange}
          toggleDropdownOne={toggleDropdownOne}
          dropdownOpenOne={dropdownOpenOne}
        />
      ),
    },
    {
      name: <CustomHeader title="Action" className="action-column" />,
      cell: (row) => (
        <UL
          className="action simple-list w-100 d-flex justify-content-end"
          style={{ flexDirection: "row" }}
        >
          <LI className="edit" style={{ marginRight: "0.5rem" }}>
            <a
              href="javascript:void(0);"
              onClick={() => handleDetailPage(row.id)}
              className="icon"
            >
              <Btn color="primary" size="sm" id={`editTooltip_${row.id}`}>
                <i
                  className="fa-solid fa-pencil me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
              </Btn>
              <Tooltip
                isOpen={tooltipOpenAction[`editTooltip_${row.id}`]}
                toggle={() => toggleTooltipAction(`editTooltip_${row.id}`)}
                target={`editTooltip_${row.id}`}
              >
                Edit
              </Tooltip>
            </a>
          </LI>
          <LI className="delete">
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                e.preventDefault(); // Prevent page refresh
                handleDelete(row?.id); // Call your delete handler
              }}
              className="icon"
            >
              <Btn color="danger" size="sm" id={`deleteTooltip_${row.id}`}>
                <i
                  className="fa-solid fa-trash me-1 text-white"
                  style={{ fontSize: "12px" }}
                ></i>
              </Btn>
              <Tooltip
                isOpen={tooltipOpenAction[`deleteTooltip_${row.id}`]}
                toggle={() => toggleTooltipAction(`deleteTooltip_${row.id}`)}
                target={`deleteTooltip_${row.id}`}
              >
                Delete
              </Tooltip>
            </a>
          </LI>
        </UL>
      ),
    },
  ];
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="pb-0 card-no-border"
                  title={`Registration & Enquiries`}
                  subTitle={[]}
                  titleClass="mb-3"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() =>
                    navigate(
                      `${process.env.PUBLIC_URL}/dashboard/registration/add`
                    )
                  }
                >
                  <i className="fa fa-plus mr-2"></i> New Enquiry
                </Button>
              </div>
              <CardBody>
                <Row>
                  {enquiryCount && enquiryCount.length > 0 && enquiryCount.map((key: any) => (
                    <Col md={3}>
                      <Card className="shadow" style={{ borderRadius: "8px" }}>
                        <CardBody>
                          <h5><b>{Object.keys(key)[0]}</b></h5>
                          <span style={{ fontSize: "48px", fontWeight: "bold", fontFamily: "Nunito Sans, sans- serif", lineHeight: "3rem", paddingTop: "12px" }} className={`${Object.keys(key)[0] == "Showaround" ? "text-primary" : Object.keys(key)[0] == "Enrolled" ? "text-success" : Object.keys(key)[0] == "Lost" ? "text-danger" : ""}`}>{key?.[Object.keys(key)?.[0]] ?? 0}</span>
                          {Object.keys(key)[0] == "Total" &&
                            <span className="text-muted d-block" style={{ fontSize: "16px", fontFamily: "Nunito Sans, sans- serif", fontWeight: "600" }}>
                              {"In the given period"}
                            </span>
                          }
                          {Object.keys(key)[0] !== "Total" && (
                            <span className="text-muted d-block" style={{ fontSize: "16px", fontFamily: "Nunito Sans, sans- serif", fontWeight: "600" }}>
                              {((key?.[Object.keys(key)?.[0]] / enquiryCount.find((k: any) => Object.keys(k)[0] === "Total")?.[Object.keys(enquiryCount.find((k: any) => Object.keys(k)[0] === "Total"))[0]] || 0) * 100).toFixed(1)}% of total
                            </span>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row className="pt-4 pb-4">
                  <Col md={2}>
                    <FilterComponent
                      onFilter={(date: any) => {
                        setFilterObj({ ...filterObj, createdAt: date });
                      }}
                      filterText={filterObj.createdAt}
                      type="date"
                      placeholder="Created At"
                    />
                  </Col>
                  <Col md={2}>
                    <FilterComponent
                      onFilter={(date: any) => {
                        setFilterObj({ ...filterObj, startDate: date });
                      }}
                      filterText={filterObj.startDate}
                      type="date"
                      placeholder="Start Date"
                    />
                  </Col>
                  <Col md={3}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) => {


                        setFilterObj({ ...filterObj, status: e });
                      }}
                      filterText={filterObj.status}
                      type="select"
                      dropdown={{ ...statusDropDown, title: "" }} // Ensure title is not passed
                      placeholder="Select Status" // Custom placeholder text
                    />
                  </Col>
                  <Col md={1}>
                    <Tooltip target="refresh" isOpen={tooltipOpen} toggle={() => setTooltipOpen(!tooltipOpen)}>
                      Reset Filter
                    </Tooltip>
                    <Button color="primary" size="md" id="refresh" onClick={() => {
                      setFilterObj({ ...filterObj, startDate: "", createdAt: "", status: "" });
                    }}>
                      <i className="fa fa-refresh mr-2"></i>
                    </Button>
                  </Col>
                  <Col md={4}>
                    <FilterComponent
                      onFilter={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFilterText(e.target.value)
                      }
                      filterText={filterText}
                    />
                  </Col>
                </Row>
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connectComponent(ListRegistration);

