import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Input, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, CardFooter, Modal, ModalHeader, ModalBody } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaTimes, FaCheck, FaQuestionCircle, FaUpload, FaSave } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddOverview.css';
import OverviewApi from 'src/services/OverviewApi';
import Swal from 'sweetalert2';
import UserActivityAPi from 'src/services/UserActivityApi';
import { getUserActivityFormattedData } from 'src/Utils';
import Btn from 'src/CommonElements/Button';
import UserApi from 'src/services/UserApi';
import { connectComponent } from '../../MealPlan/connector';
import EyfApi from 'src/services/EyfApi';
import UploadAPi from 'src/services/UploadApi';
import { baseUrl } from 'src/Utils/Constants/config';

interface Props {
    nurseryId: string;
}

interface EyfsOption {
    id: number;
    shortName: string;
    fullName: string;
    color: string;
    subheadings: string[];
}

function AddOverview({ nurseryId }: Props) {
    const navigate = useNavigate();
    const [selectedEYFS, setSelectedEYFS] = useState<string[]>([]);
    const [selectedSubheadings, setSelectedSubheadings] = useState<string[]>([]);
    const [editorContent, setEditorContent] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedChildren, setSelectedChildren] = useState<any[]>([]);
    const [eyfsDropdownOpen, setEyfsDropdownOpen] = useState(false);
    const [subheadingDropdownOpen, setSubheadingDropdownOpen] = useState(false);
    const [showDevelopmentMatters, setShowDevelopmentMatters] = useState(false);
    const [expandedHeadings, setExpandedHeadings] = useState<string[]>([]);
    const [showObservationCheckpoints, setShowObservationCheckpoints] = useState(false);
    const [showBirthToFive, setShowBirthToFive] = useState(false);
    const [showReferenceSection, setShowReferenceSection] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [children, setChildren] = useState<any[]>([]);
    const [isLoadingChildren, setIsLoadingChildren] = useState(false);
    const [eyfsOptions, setEyfsOptions] = useState<EyfsOption[]>([]);
    const [isLoadingEyfs, setIsLoadingEyfs] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({});
    const [referenceMaterials, setReferenceMaterials] = useState<any[]>([]);
    const [expandedAgeBands, setExpandedAgeBands] = useState<string[]>([]);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const toggleChild = (child: any) => {
        if (selectedChildren.find(c => c.id === child.id)) {
            setSelectedChildren(selectedChildren.filter(c => c.id !== child.id));
        } else {
            setSelectedChildren([...selectedChildren, child]);
        }
    };

    const toggleEyfsDropdown = () => setEyfsDropdownOpen(prevState => !prevState);

    const toggleSubheadingDropdown = () => setSubheadingDropdownOpen(prevState => !prevState);

    const handleEyfsSelection = (id: number) => {
        setSelectedEYFS(prev => {
            if (prev.includes(id.toString())) {
                return prev.filter(item => item !== id.toString());
            }
            return [...prev, id.toString()];
        });
    };

    const handleSubheadingSelection = (subheading: string) => {
        setSelectedSubheadings(prev => {
            if (prev.includes(subheading)) {
                return prev.filter(item => item !== subheading);
            }
            return [...prev, subheading];
        });
    };

    const getAvailableSubheadings = () => {
        const selectedEyfsItems = eyfsOptions.filter(eyfs => selectedEYFS.includes(eyfs.id.toString()));
        return selectedEyfsItems.reduce((acc, curr) => {
            return [...acc, ...curr.subheadings.map(subheading => ({
                eyfs: curr,
                subheading
            }))];
        }, [] as { eyfs: typeof eyfsOptions[0], subheading: string }[]);
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            [{ 'header': [1, 2, 3, false] }],
        ]
    };

    const formats = [
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'link',
        'header'
    ];

    const toggleHeading = (id: string) => {
        setExpandedHeadings(prev =>
            prev.includes(id) ? prev.filter(h => h !== id) : [...prev, id]
        );
    };

    const getAreaColor = (heading: string) => {
        const eyfsOption = eyfsOptions.find(opt => opt.fullName === heading);
        return eyfsOption ? eyfsOption.color : '#e0e0e0';
    };

    const uploadImage = async (file: any) => {
        const formData: any = new FormData();
        formData.append("file", file);
        formData.append("folder", "overview");
        try {
            const response = await UploadAPi.uploadFile(formData, "upload-overview");
            return response.filePath;
        } catch (err) {
            console.error('Error uploading file:', err);
            return "";
        }
    };

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }

        try {
            setIsUploading(true);

            const imageUrl = await uploadImage(file);

            if (imageUrl) {
                setUploadedImages(prev => [...prev, imageUrl]);
                toast.success('Image uploaded successfully');
            } else {
                toast.error('Failed to upload image');
            }

            // Reset input
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Failed to upload image');
        } finally {
            setIsUploading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            if (selectedChildren.length === 0) {
                toast.error('Please select at least one child');
                return;
            }

            if (selectedEYFS.length === 0) {
                toast.error('Please select at least one EYFS area');
                return;
            }

            if (selectedSubheadings.length === 0) {
                toast.error('Please select at least one subheading');
                return;
            }

            if (!editorContent.trim()) {
                toast.error('Please add some content');
                return;
            }

            setIsLoading(true);

            const formData = {
                children: selectedChildren.map(child => child.id),
                eyfs: selectedEYFS.map(eyfsId => ({
                    id: eyfsId,
                    subheadings: selectedSubheadings.filter(subheading => {
                        const eyfs = eyfsOptions.find(opt =>
                            opt.id.toString() === eyfsId &&
                            opt.subheadings.includes(subheading)
                        );
                        return !!eyfs;
                    })
                })),
                content: editorContent,
                images: uploadedImages,
                nurseryId: nurseryId
            };

            setTimeout(async () => {
                try {
                    const response = await OverviewApi.createOverview(formData);

                    if (response.success) {
                        Swal.fire({
                            title: "Success!",
                            text: "Overview created successfully",
                            icon: "success",
                        }).then(async () => {
                            await UserActivityAPi.addUserActivity(
                                getUserActivityFormattedData(
                                    "Overview",
                                    "Overview Created successfully"
                                )
                            );
                            navigate(`${process.env.PUBLIC_URL}/dashboard/learning/overview`);
                        });
                    } else {
                        toast.error(response.message);
                    }
                } catch (error) {
                    console.error('Error creating overview:', error);
                    toast.error('Failed to create overview. Please try again.');
                }
            }, 2000);

        } catch (error) {
            console.error('Error in form submission:', error);
            toast.error('Failed to process form. Please try again.');
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2300);
        }
    };

    const fetchChildren = async () => {
        try {
            setIsLoadingChildren(true);
            const response = await UserApi.getAllChildren({
                nurseryId: nurseryId
            });

            if (response.success) {
                const formattedChildren = response.children.map((child: any) => ({
                    id: child.id,
                    name: `${child.firstName} ${child.lastName}`,
                    room: child.childrenRoomMoves?.[0]?.room?.roomName ? `Child in ${child.childrenRoomMoves[0].room.roomName}` : 'No Room Assigned',
                    image: child.avatarUrl || 'https://dummyimage.com/35x35/000/fff.png'
                }));
                setChildren(formattedChildren);
            } else {
                toast.error('Failed to fetch children');
            }
        } catch (error) {
            console.error('Error fetching children:', error);
            toast.error('Failed to fetch children');
        } finally {
            setIsLoadingChildren(false);
        }
    };

    const fetchEyfsData = async () => {
        try {
            setIsLoadingEyfs(true);
            // Use the EyfApi.getEyfs() endpoint for EYFS data
            const eyfsResponse = await EyfApi.getEyfs();
            const response = await EyfApi.getHeadings();

            if (response.success) {
                // Generate a random color if not provided
                const colors = ['#FFD700', '#98FB98', '#87CEEB', '#DDA0DD', '#F08080', '#B8860B', '#20B2AA'];
                const randomColor = colors[Math.floor(Math.random() * colors.length)];

                // Transform the API data into the required format for dropdown
                const formattedEyfs = response.data.map((heading: any) => {
                    return {
                        id: heading.id,
                        shortName: heading.shortName || heading.name.split(' ').map((word: string) => word[0]).join(''),
                        fullName: heading.name,
                        color: heading.color || randomColor,
                        subheadings: heading.subHeadings.map((sub: any) => sub.name)
                    };
                });

                // First, group EYFS data by headings to build the hierarchy
                const eyfsData = eyfsResponse.data || [];

                // Get unique headings from eyfs data
                const uniqueHeadings = Array.from(new Set(
                    eyfsData.map((item: any) => item.headingId)
                )).map(headingId => {
                    // Find the first item with this heading to get the heading details
                    const headingItem = eyfsData.find((item: any) => item.headingId === headingId);
                    return headingItem ? headingItem.heading : null;
                }).filter(Boolean);

                // Build the hierarchical structure
                const hierarchicalData = {
                    headings: uniqueHeadings.map((heading: any) => {
                        // Get all EYFS items directly under this heading (Scenario 1)
                        const headingEyfs = eyfsData.filter((item: any) =>
                            item.headingId === heading.id &&
                            !item.subHeadingId &&
                            !item.subHeadingId2 &&
                            !item.ageBandId
                        ).map((item: any) => ({
                            id: item.id,
                            name: item.name,
                            description: item.description || ''
                        }));

                        // Get unique subHeadings for this heading
                        const subHeadings = Array.from(new Set(
                            eyfsData
                                .filter((item: any) => item.headingId === heading.id && item.subHeadingId)
                                .map((item: any) => item.subHeadingId)
                        )).map(subHeadingId => {
                            const subHeadingItem = eyfsData.find((item: any) =>
                                item.subHeadingId === subHeadingId && item.headingId === heading.id
                            );
                            return subHeadingItem ? subHeadingItem.subHeading : null;
                        }).filter(Boolean);

                        return {
                            id: heading.id,
                            name: heading.name,
                            color: heading.color || randomColor,
                            eyfs: headingEyfs,
                            subHeadings: subHeadings.map((subHeading: any) => {
                                // Get all EYFS items under this subHeading (Scenario 2)
                                const subHeadingEyfs = eyfsData.filter((item: any) =>
                                    item.headingId === heading.id &&
                                    item.subHeadingId === subHeading.id &&
                                    !item.subHeadingId2 &&
                                    !item.ageBandId
                                ).map((item: any) => ({
                                    id: item.id,
                                    name: item.name,
                                    description: item.description || ''
                                }));

                                // Get unique subHeadings2 for this subHeading
                                const subHeadings2 = Array.from(new Set(
                                    eyfsData
                                        .filter((item: any) =>
                                            item.headingId === heading.id &&
                                            item.subHeadingId === subHeading.id &&
                                            item.subHeadingId2
                                        )
                                        .map((item: any) => item.subHeadingId2)
                                )).map(subHeadingId2 => {
                                    const subHeading2Item = eyfsData.find((item: any) =>
                                        item.subHeadingId2 === subHeadingId2 &&
                                        item.subHeadingId === subHeading.id &&
                                        item.headingId === heading.id
                                    );
                                    return subHeading2Item ? subHeading2Item.subHeading2 : null;
                                }).filter(Boolean);

                                return {
                                    id: subHeading.id,
                                    name: subHeading.name,
                                    color: subHeading.color || heading.color || randomColor,
                                    eyfs: subHeadingEyfs,
                                    subHeadings2: subHeadings2.map((subHeading2: any) => {
                                        // Get all EYFS items under this subHeading2 (Scenario 3)
                                        const subHeading2Eyfs = eyfsData.filter((item: any) =>
                                            item.headingId === heading.id &&
                                            item.subHeadingId === subHeading.id &&
                                            item.subHeadingId2 === subHeading2.id &&
                                            !item.ageBandId
                                        ).map((item: any) => ({
                                            id: item.id,
                                            name: item.name,
                                            description: item.description || ''
                                        }));

                                        // Get unique ageBands for this subHeading2
                                        const ageBands = Array.from(new Set(
                                            eyfsData
                                                .filter((item: any) =>
                                                    item.headingId === heading.id &&
                                                    item.subHeadingId === subHeading.id &&
                                                    item.subHeadingId2 === subHeading2.id &&
                                                    item.ageBandId
                                                )
                                                .map((item: any) => item.ageBandId)
                                        )).map(ageBandId => {
                                            const ageBandItem = eyfsData.find((item: any) =>
                                                item.ageBandId === ageBandId &&
                                                item.subHeadingId2 === subHeading2.id &&
                                                item.subHeadingId === subHeading.id &&
                                                item.headingId === heading.id
                                            );
                                            return ageBandItem ? ageBandItem.ageBand : null;
                                        }).filter(Boolean);

                                        return {
                                            id: subHeading2.id,
                                            name: subHeading2.name,
                                            color: subHeading2.color || subHeading.color || heading.color || randomColor,
                                            eyfs: subHeading2Eyfs,
                                            ageBands: ageBands.map((ageBand: any) => {
                                                // Get all EYFS items under this ageBand (Scenario 4)
                                                const ageBandEyfs = eyfsData.filter((item: any) =>
                                                    item.headingId === heading.id &&
                                                    item.subHeadingId === subHeading.id &&
                                                    item.subHeadingId2 === subHeading2.id &&
                                                    item.ageBandId === ageBand.id
                                                ).map((item: any) => ({
                                                    id: item.id,
                                                    name: item.name,
                                                    description: item.description || ''
                                                }));

                                                return {
                                                    id: ageBand.id,
                                                    name: ageBand.name,
                                                    color: ageBand.color || subHeading2.color || subHeading.color || heading.color || randomColor,
                                                    eyfs: ageBandEyfs
                                                };
                                            })
                                        };
                                    })
                                };
                            })
                        };
                    })
                };

                console.log('hierarchicalData: ', hierarchicalData);
                setReferenceMaterials(hierarchicalData.headings);
                setEyfsOptions(formattedEyfs);
            } else {
                toast.error('Failed to fetch EYFS data');
            }
        } catch (error) {
            console.error('Error fetching EYFS data:', error);
            toast.error('Failed to fetch EYFS data');
            setEyfsOptions([]);
        } finally {
            setIsLoadingEyfs(false);
        }
    };

    const getImageUrl = (path: string) => {
        if (!path) return '';
        if (path.startsWith('http')) return path;
        return `${baseUrl}/${path.replace(/^\//, '')}`;
    };

    const loadImage = async (url: string) => {
        try {
            const fullUrl = getImageUrl(url);
            return new Promise<string>((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(fullUrl);
                img.onerror = () => reject('https://dummyimage.com/100x100/000/fff.png');
                img.src = fullUrl;
            });
        } catch (error) {
            return 'https://dummyimage.com/100x100/000/fff.png';
        }
    };

    const handleImageLoad = async (url: string, index: number) => {
        try {
            const loadedUrl = await loadImage(url);
            setImageUrls(prev => ({
                ...prev,
                [index]: loadedUrl
            }));
        } catch (error) {
            console.error('Error loading image:', error);
            setImageUrls(prev => ({
                ...prev,
                [index]: 'https://dummyimage.com/100x100/000/fff.png'
            }));
        }
    };

    useEffect(() => {
        fetchChildren();
        fetchEyfsData();
        // Load all images when uploadedImages changes
        uploadedImages.forEach((url, index) => {
            handleImageLoad(url, index);
        });
    }, [nurseryId, uploadedImages]);

    // Add this component for rendering reference material
    const ReferenceSection = ({ referenceMaterials, setShowReferenceSection }: { referenceMaterials: any, setShowReferenceSection: (show: boolean) => void }) => {
        const [expandedHeadings, setExpandedHeadings] = useState<string[]>([]);
        const [expandedSubheadings, setExpandedSubheadings] = useState<string[]>([]);
        const [expandedSubheadings2, setExpandedSubheadings2] = useState<string[]>([]);
        const [expandedAgeBands, setExpandedAgeBands] = useState<string[]>([]);
        const [selectedEyfs, setSelectedEyfs] = useState<any>(null);
        const [navigationStack, setNavigationStack] = useState<any[]>([]);

        const toggleHeading = (id: string) => {
            setExpandedHeadings(prev =>
                prev.includes(id) ? prev.filter(h => h !== id) : [...prev, id]
            );
        };

        const toggleSubheading = (id: string) => {
            setExpandedSubheadings(prev =>
                prev.includes(id) ? prev.filter(s => s !== id) : [...prev, id]
            );
        };

        const toggleSubheading2 = (id: string) => {
            setExpandedSubheadings2(prev =>
                prev.includes(id) ? prev.filter(s => s !== id) : [...prev, id]
            );
        };

        const toggleAgeBand = (id: string, eyfs: any) => {
            if (eyfs) {
                setSelectedEyfs(eyfs);
                setNavigationStack(prev => [...prev, { type: 'main' }]);
            } else {
                setExpandedAgeBands(prev =>
                    prev.includes(id) ? prev.filter(a => a !== id) : [...prev, id]
                );
            }
        };

        const handleBack = () => {
            const newStack = [...navigationStack];
            newStack.pop();
            setNavigationStack(newStack);
            setSelectedEyfs(null);
        };

        return (
            <div className="reference-material">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                        <h6 className="mb-1">Reference material</h6>
                        <p className="text-muted small mb-0">Browse through supporting documents.</p>
                    </div>
                    <div
                        className="close-reference-btn cursor-pointer"
                        onClick={() => setShowReferenceSection(false)}
                    >
                        <i className="fa fa-times"></i>
                    </div>
                </div>

                {selectedEyfs ? (
                    <div className="eyfs-detail-view">
                        <div
                            className="back-button mb-3 d-flex align-items-center cursor-pointer"
                            onClick={handleBack}
                        >
                            <i className="fa fa-arrow-left me-2"></i>
                            <span>Back to list</span>
                        </div>

                        <div className="eyfs-content p-3" style={{ backgroundColor: `${selectedEyfs.color}10`, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                            <h6 className="mb-3" style={{ color: selectedEyfs.color }}>{selectedEyfs.name}</h6>
                            <div className="description mb-3">{selectedEyfs.description}</div>
                        </div>
                    </div>
                ) : (
                    <div className="reference-items">
                        {referenceMaterials.map((heading: any) => (
                            <div key={heading.id} className="reference-item mb-3">
                                <div
                                    className="d-flex align-items-center cursor-pointer"
                                    onClick={() => toggleHeading(heading.id)}
                                    style={{ borderLeft: `4px solid ${heading.color}` }}
                                >
                                    <i className={`fa fa-chevron-${expandedHeadings.includes(heading.id) ? 'down' : 'right'} me-2`}></i>
                                    <span>{heading.name}</span>
                                </div>

                                {/* Scenario 1: If heading has eyfs items directly under it */}
                                {expandedHeadings.includes(heading.id) && heading.eyfs && heading.eyfs.length > 0 && (
                                    <div className="ms-4 mt-2">
                                        {heading.eyfs.map((eyfsItem: any) => (
                                            <div
                                                key={eyfsItem.id}
                                                className="p-2 cursor-pointer eyfs-item mb-2"
                                                onClick={() => toggleAgeBand(eyfsItem.id, eyfsItem)}
                                                style={{
                                                    borderLeft: `2px solid ${heading.color}`,
                                                    backgroundColor: `${heading.color}10`,
                                                    borderRadius: '4px'
                                                }}
                                            >
                                                <div className="fw-bold">{eyfsItem.name}</div>
                                                {eyfsItem.description && (
                                                    <div className="small text-muted">
                                                        {eyfsItem.description.length > 50
                                                            ? `${eyfsItem.description.substring(0, 50)}...`
                                                            : eyfsItem.description
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Display subHeadings if they exist */}
                                {expandedHeadings.includes(heading.id) && heading.subHeadings && heading.subHeadings.map((subHeading: any) => (
                                    <div key={subHeading.id} className="ms-4 mt-2">
                                        <div
                                            className="d-flex align-items-center cursor-pointer"
                                            onClick={() => toggleSubheading(subHeading.id)}
                                            style={{ borderLeft: `3px solid ${subHeading.color || heading.color}` }}
                                        >
                                            <i className={`fa fa-chevron-${expandedSubheadings.includes(subHeading.id) ? 'down' : 'right'} me-2`}></i>
                                            <span>{subHeading.name}</span>
                                        </div>

                                        {/* Scenario 2: If subHeading has eyfs items directly under it */}
                                        {expandedSubheadings.includes(subHeading.id) && subHeading.eyfs && subHeading.eyfs.length > 0 && (
                                            <div className="ms-4 mt-2">
                                                {subHeading.eyfs.map((eyfsItem: any) => (
                                                    <div
                                                        key={eyfsItem.id}
                                                        className="p-2 cursor-pointer eyfs-item mb-2"
                                                        onClick={() => toggleAgeBand(eyfsItem.id, eyfsItem)}
                                                        style={{
                                                            borderLeft: `2px solid ${subHeading.color || heading.color}`,
                                                            backgroundColor: `${subHeading.color || heading.color}10`,
                                                            borderRadius: '4px'
                                                        }}
                                                    >
                                                        <div className="fw-bold">{eyfsItem.name}</div>
                                                        {eyfsItem.description && (
                                                            <div className="small text-muted">
                                                                {eyfsItem.description.length > 50
                                                                    ? `${eyfsItem.description.substring(0, 50)}...`
                                                                    : eyfsItem.description
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {/* Display subHeadings2 if they exist */}
                                        {expandedSubheadings.includes(subHeading.id) && subHeading.subHeadings2 && subHeading.subHeadings2.map((subHeading2: any) => (
                                            <div key={subHeading2.id} className="ms-4 mt-2">
                                                <div
                                                    className="d-flex align-items-center cursor-pointer"
                                                    onClick={() => toggleSubheading2(subHeading2.id)}
                                                    style={{ borderLeft: `3px solid ${subHeading2.color || subHeading.color || heading.color}` }}
                                                >
                                                    <i className={`fa fa-chevron-${expandedSubheadings2.includes(subHeading2.id) ? 'down' : 'right'} me-2`}></i>
                                                    <span>{subHeading2.name}</span>
                                                </div>

                                                {/* Scenario 3: If subHeading2 has eyfs items directly under it */}
                                                {expandedSubheadings2.includes(subHeading2.id) && subHeading2.eyfs && subHeading2.eyfs.length > 0 && (
                                                    <div className="ms-4 mt-2">
                                                        {subHeading2.eyfs.map((eyfsItem: any) => (
                                                            <div
                                                                key={eyfsItem.id}
                                                                className="p-2 cursor-pointer eyfs-item mb-2"
                                                                onClick={() => toggleAgeBand(eyfsItem.id, eyfsItem)}
                                                                style={{
                                                                    borderLeft: `2px solid ${subHeading2.color || subHeading.color || heading.color}`,
                                                                    backgroundColor: `${subHeading2.color || subHeading.color || heading.color}10`,
                                                                    borderRadius: '4px'
                                                                }}
                                                            >
                                                                <div className="fw-bold">{eyfsItem.name}</div>
                                                                {eyfsItem.description && (
                                                                    <div className="small text-muted">
                                                                        {eyfsItem.description.length > 50
                                                                            ? `${eyfsItem.description.substring(0, 50)}...`
                                                                            : eyfsItem.description
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                {/* Display ageBands if they exist */}
                                                {expandedSubheadings2.includes(subHeading2.id) && subHeading2.ageBands && subHeading2.ageBands.map((ageBand: any) => (
                                                    <div key={ageBand.id} className="ms-4 mt-2">
                                                        <div
                                                            className="d-flex align-items-center cursor-pointer"
                                                            onClick={() => toggleAgeBand(ageBand.id, null)}
                                                            style={{
                                                                borderLeft: `2px solid ${ageBand.color || subHeading2.color || subHeading.color || heading.color}`,
                                                                backgroundColor: `${ageBand.color || subHeading2.color || subHeading.color || heading.color}10`
                                                            }}
                                                        >
                                                            <i className={`fa fa-chevron-${expandedAgeBands.includes(ageBand.id) ? 'down' : 'right'} me-2`}></i>
                                                            <div className="fw-bold">{ageBand.name}</div>
                                                        </div>

                                                        {/* Scenario 4: Display eyfs items under ageBand */}
                                                        {expandedAgeBands.includes(ageBand.id) && ageBand.eyfs && ageBand.eyfs.length > 0 && (
                                                            <div className="ms-4 mt-2">
                                                                {ageBand.eyfs.map((eyfsItem: any) => (
                                                                    <div
                                                                        key={eyfsItem.id}
                                                                        className="p-2 cursor-pointer eyfs-item mb-2"
                                                                        onClick={() => toggleAgeBand(eyfsItem.id, eyfsItem)}
                                                                        style={{
                                                                            borderLeft: `2px solid ${ageBand.color || subHeading2.color || subHeading.color || heading.color}`,
                                                                            backgroundColor: `${ageBand.color || subHeading2.color || subHeading.color || heading.color}05`,
                                                                            borderRadius: '4px'
                                                                        }}
                                                                    >
                                                                        <div className="fw-bold">{eyfsItem.name}</div>
                                                                        {eyfsItem.description && (
                                                                            <div className="small text-muted">
                                                                                {eyfsItem.description.length > 50
                                                                                    ? `${eyfsItem.description.substring(0, 50)}...`
                                                                                    : eyfsItem.description
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}

                <style>
                    {`
                        .reference-material {
                            background: white;
                            border-radius: 8px;
                            padding: 20px;
                            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                        }
                        .reference-item {
                            padding: 10px;
                            border-radius: 4px;
                        }
                        .cursor-pointer {
                            cursor: pointer;
                            padding: 8px;
                            border-radius: 4px;
                        }
                        .cursor-pointer:hover {
                            background-color: #f8f9fa;
                        }
                        .back-button {
                            color: #666;
                            transition: color 0.3s ease;
                        }
                        .back-button:hover {
                            color: #333;
                        }
                        .eyfs-detail-view {
                            animation: fadeIn 0.3s ease;
                        }
                        .eyfs-item {
                            transition: all 0.2s ease;
                        }
                        .eyfs-item:hover {
                            transform: translateX(2px);
                            box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                        }
                        @keyframes fadeIn {
                            from {
                                opacity: 0;
                                transform: translateY(10px);
                            }
                            to {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    `}
                </style>
            </div>
        );
    };

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ borderBottom: "5px solid #eee" }}
                            >
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`Add Observation`}
                                    titleClass="mb-3"
                                />
                                <Button
                                    color="dark"
                                    className="btn-md m-4 d-flex align-items-center"
                                    onClick={() => {
                                        navigate(
                                            `${process.env.PUBLIC_URL}/dashboard/learning/overview`
                                        );
                                    }}
                                >
                                    <i
                                        className="iconly-Arrow-Left icli"
                                        style={{ marginRight: "0.5rem" }}
                                    ></i>{" "}
                                    Back
                                </Button>
                            </div>

                            <div className="observation-header">
                                <div className="d-flex align-items-center gap-3 p-4">
                                    <div className="avatar">
                                        <img
                                            src="https://dummyimage.com/35x35/000/fff.png"
                                            alt="Admin"
                                            className="rounded-circle"
                                            width="40"
                                            height="40"
                                        />
                                    </div>
                                    <div>
                                        <h6 className="mb-1">Head Office Admin</h6>
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="text-muted small">
                                                My drafts <i className="fa fa-chevron-down ms-1"></i>
                                            </div>
                                            <div className="text-muted small">
                                                Date: {new Date().toLocaleDateString()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <CardBody className="pt-0">
                                <Row>
                                    <Col md={showReferenceSection ? 8 : 11} className="transition-width">
                                        <div className="observation-form pe-4">
                                            <FormGroup className="mb-4">
                                                <div className="custom-select-container">
                                                    <div className="selected-children-tags mb-2">
                                                        <Label className="text-muted mb-2">To:</Label>
                                                        {selectedChildren.map((child) => (
                                                            <div key={child.id} className="selected-child-tag">
                                                                <img
                                                                    src={child.image}
                                                                    alt={child.name}
                                                                    className="rounded-circle"
                                                                    width="20"
                                                                    height="20"
                                                                />
                                                                <span>{child.name}</span>
                                                                <Button
                                                                    close
                                                                    size="sm"
                                                                    className="ms-2"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleChild(child);
                                                                    }}
                                                                >
                                                                    <FaTimes size={12} />
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="w-100">
                                                        <DropdownToggle caret className="custom-select-toggle w-100" style={{ height: "38px", borderRadius: "4px" }}>
                                                            {isLoadingChildren ? (
                                                                <span className="d-flex align-items-center gap-2">
                                                                    <Spinner size="sm" /> Loading children...
                                                                </span>
                                                            ) : (
                                                                <span className="text-muted">Select children...</span>
                                                            )}
                                                        </DropdownToggle>
                                                        <DropdownMenu className="custom-select-menu w-100">
                                                            {children.filter(child => !selectedChildren.find(c => c.id === child.id)).map((child) => (
                                                                <DropdownItem
                                                                    key={child.id}
                                                                    onClick={() => toggleChild(child)}
                                                                    className="custom-select-item"
                                                                >
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <img
                                                                            src={child.image}
                                                                            alt={child.name}
                                                                            className="rounded-circle"
                                                                            width="35"
                                                                            height="35"
                                                                        />
                                                                        <div className="child-info">
                                                                            <div className="child-name">{child.name}</div>
                                                                            <div className="child-room">{child.room}</div>
                                                                        </div>
                                                                    </div>
                                                                </DropdownItem>
                                                            ))}
                                                            {children.filter(child => !selectedChildren.find(c => c.id === child.id)).length === 0 && (
                                                                <div className="text-center py-2 text-muted">
                                                                    {isLoadingChildren ? 'Loading...' : 'No more children to select'}
                                                                </div>
                                                            )}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            </FormGroup>

                                            <FormGroup className="mb-4">
                                                <div className="custom-select-container">
                                                    <div className="d-flex gap-3">
                                                        <div style={{ width: '400px' }}>
                                                            <Dropdown isOpen={eyfsDropdownOpen} toggle={toggleEyfsDropdown} className="w-100">
                                                                <DropdownToggle caret className="custom-select-toggle w-100" style={{ height: "38px", borderRadius: "4px" }}>
                                                                    {isLoadingEyfs ? (
                                                                        <span className="d-flex align-items-center gap-2">
                                                                            <Spinner size="sm" /> Loading EYFS...
                                                                        </span>
                                                                    ) : (
                                                                        <span className="text-muted">EYFS</span>
                                                                    )}
                                                                </DropdownToggle>
                                                                <DropdownMenu className="custom-select-menu w-100">
                                                                    {eyfsOptions.map((eyfs) => (
                                                                        <DropdownItem
                                                                            key={eyfs.id}
                                                                            onClick={() => handleEyfsSelection(eyfs.id)}
                                                                            className="custom-select-item"
                                                                        >
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div className="d-flex align-items-center gap-2">
                                                                                    <span
                                                                                        style={{
                                                                                            backgroundColor: eyfs.color,
                                                                                            padding: '4px 8px',
                                                                                            borderRadius: '4px',
                                                                                            fontWeight: 'bold',
                                                                                            color: '#fff',
                                                                                        }}
                                                                                    >
                                                                                        {eyfs.shortName}
                                                                                    </span>
                                                                                    <span>{eyfs.fullName}</span>
                                                                                </div>
                                                                                {selectedEYFS.includes(eyfs.id.toString()) && (
                                                                                    <FaCheck size={14} className="text-success" />
                                                                                )}
                                                                            </div>
                                                                        </DropdownItem>
                                                                    ))}
                                                                    {isLoadingEyfs && (
                                                                        <div className="text-center py-3">
                                                                            <Spinner size="sm" /> Loading...
                                                                        </div>
                                                                    )}
                                                                    {!isLoadingEyfs && eyfsOptions.length === 0 && (
                                                                        <div className="text-center py-3 text-muted">
                                                                            No EYFS data available
                                                                        </div>
                                                                    )}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </div>

                                                        {selectedEYFS.length > 0 && (
                                                            <div style={{ width: '300px' }}>
                                                                <Dropdown isOpen={subheadingDropdownOpen} toggle={toggleSubheadingDropdown} className="w-100">
                                                                    <DropdownToggle caret className="custom-select-toggle w-100" style={{ height: "38px", borderRadius: "4px" }}>
                                                                        <span className="text-muted">Select Subheading</span>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="custom-select-menu w-100">
                                                                        {getAvailableSubheadings().map(({ eyfs, subheading }) => (
                                                                            <DropdownItem
                                                                                key={`${eyfs.id}-${subheading}`}
                                                                                onClick={() => handleSubheadingSelection(subheading)}
                                                                                className="custom-select-item"
                                                                            >
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <span
                                                                                            style={{
                                                                                                backgroundColor: eyfs.color,
                                                                                                padding: '4px 8px',
                                                                                                borderRadius: '4px',
                                                                                                fontWeight: 'bold',
                                                                                                color: '#fff',
                                                                                            }}
                                                                                        >
                                                                                            {eyfs.shortName}
                                                                                        </span>
                                                                                        <span>{subheading}</span>
                                                                                    </div>
                                                                                    {selectedSubheadings.includes(subheading) && (
                                                                                        <FaCheck size={14} className="text-success" />
                                                                                    )}
                                                                                </div>
                                                                            </DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {selectedEYFS.length > 0 && (
                                                        <>
                                                            <div className="selected-eyfs-container mt-3">
                                                                <div className="d-flex gap-2">
                                                                    {selectedEYFS.map((eyfsId) => {
                                                                        const eyfs = eyfsOptions.find(opt => opt.id.toString() === eyfsId);
                                                                        if (!eyfs) return null;
                                                                        return (
                                                                            <div
                                                                                key={eyfs.id}
                                                                                className="selected-eyfs-item"
                                                                                style={{
                                                                                    flex: `1 1 ${100 / selectedEYFS.length}%`,
                                                                                    minWidth: '150px'
                                                                                }}
                                                                            >
                                                                                <div className="eyfs-content">
                                                                                    <span className="eyfs-short-name" style={{ backgroundColor: eyfs.color, color: '#fff' }}>
                                                                                        {eyfs.shortName}
                                                                                    </span>
                                                                                    <span className="eyfs-full-name text-truncate">{eyfs.fullName}</span>
                                                                                </div>
                                                                                <Button
                                                                                    close
                                                                                    size="sm"
                                                                                    onClick={() => handleEyfsSelection(eyfs.id)}
                                                                                    className="remove-eyfs-btn"
                                                                                >
                                                                                </Button>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>

                                                            {selectedSubheadings.length > 0 && (
                                                                <div className="selected-subheadings-container mt-3">
                                                                    <div className="selected-eyfs-grid">
                                                                        {selectedSubheadings.map((subheading) => {
                                                                            const eyfs = eyfsOptions.find(opt =>
                                                                                opt.subheadings.includes(subheading) &&
                                                                                selectedEYFS.includes(opt.id.toString())
                                                                            );
                                                                            if (!eyfs) return null;
                                                                            return (
                                                                                <div
                                                                                    key={`${eyfs.id}-${subheading}`}
                                                                                    className="selected-eyfs-item"
                                                                                >
                                                                                    <div className="eyfs-content">
                                                                                        <span className="eyfs-short-name" style={{ backgroundColor: eyfs.color, color: '#fff' }}>
                                                                                            {eyfs.shortName}
                                                                                        </span>
                                                                                        <span className="eyfs-full-name">{subheading}</span>
                                                                                    </div>
                                                                                    <Button
                                                                                        close
                                                                                        size="sm"
                                                                                        onClick={() => handleSubheadingSelection(subheading)}
                                                                                        className="remove-eyfs-btn"
                                                                                    >
                                                                                    </Button>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </FormGroup>

                                            <div className="editor-container">
                                                <ReactQuill
                                                    theme="snow"
                                                    value={editorContent}
                                                    onChange={setEditorContent}

                                                    modules={modules}
                                                    formats={formats}
                                                    placeholder="Text..."
                                                    className="observation-editor"
                                                />
                                            </div>

                                            <div className="mt-4 d-flex gap-3">
                                                <Button
                                                    color="light"
                                                    className="d-flex align-items-center gap-2"
                                                    onClick={() => fileInputRef.current?.click()}
                                                    disabled={isUploading}
                                                >
                                                    {isUploading ? (
                                                        <span className="d-flex align-items-center gap-2">
                                                            <Spinner size="sm" /> Uploading...
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <FaUpload size={14} />
                                                            Upload
                                                        </>
                                                    )}
                                                </Button>

                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={handleUpload}
                                                    multiple
                                                />

                                                {uploadedImages.length > 0 && (
                                                    <div className="mt-3">
                                                        <Label>Uploaded Images</Label>
                                                        <div className="d-flex flex-wrap gap-2">
                                                            {uploadedImages.map((url, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="position-relative"
                                                                    style={{
                                                                        width: '400px',
                                                                        height: '50px',
                                                                        border: '1px solid #dee2e6',
                                                                        borderRadius: '4px',
                                                                        overflow: 'hidden',
                                                                        fontSize: '10px'
                                                                    }}
                                                                >
                                                                    {url}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>

                                    {!showReferenceSection && (
                                        <Col md={1} className="d-flex align-items-start justify-content-center pt-4">
                                            <div
                                                className="toggle-reference-btn cursor-pointer"
                                                onClick={() => setShowReferenceSection(true)}
                                            >
                                                <i className="fa fa-bars"></i>
                                            </div>
                                        </Col>
                                    )}

                                    {showReferenceSection && (
                                        <Col md={4} className="reference-material-col">
                                            <ReferenceSection
                                                referenceMaterials={referenceMaterials}
                                                setShowReferenceSection={setShowReferenceSection}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                            <CardFooter style={{ borderTop: "5px solid #eee" }}>
                                <Button
                                    color="dark"
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                    style={{
                                        minWidth: '120px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        transition: 'all 0.3s ease'
                                    }}
                                >
                                    {isLoading ? (
                                        <span className="d-flex gap-2">
                                            <Spinner color="white" size="sm" /> Loading...
                                        </span>
                                    ) : (
                                        <>
                                            <span>Save Overview</span>
                                        </>
                                    )}
                                </Button>
                                <Btn
                                    color="primary"
                                    outline={true}
                                    type="button"
                                    onClick={() => {
                                        navigate(
                                            `${process.env.PUBLIC_URL}/dashboard/learning/overview`
                                        );
                                    }}
                                    className="ms-3 btn-md"
                                >
                                    {`Cancel`}
                                </Btn>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default connectComponent(AddOverview);

<style>
    {`
        .reference-material {
            background: white;
            border-radius: 8px;
            padding: 20px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .reference-item {
            padding: 10px;
            border-radius: 4px;
        }
        .cursor-pointer {
            cursor: pointer;
            padding: 8px;
            border-radius: 4px;
        }
        .cursor-pointer:hover {
            background-color: #f8f9fa;
        }
    `}
</style>