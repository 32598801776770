import api from "./api"; // Use the Axios instance with interceptor

export default class PackageApi {
  // Get all package plans
  static async getPackagePlan(params: { limit?: number; offset?: number }) {
    try {
      const response = await api.get("/package", { params });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // // Add new package plan
  // static async addPlan(data: { name: string; price: number; details: string }) {
  //   try {
  //     const response = await api.post("/package/add", data);
  //     return response.data;
  //   } catch (error: any) {
  //     const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
  //     throw new Error(errorMessage);
  //   }
  // }

  // Add new package plan with dynamic parameters
  static async addPlan(params: any) {
    try {
      const response = await api.post("/package/add", params);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Update package plan
  // static async updatePlan(id: string, data: { name: string; price: number; details: string }) {
  //   try {
  //     const response = await api.patch(`/package/${id}`, data);
  //     return response.data;
  //   } catch (error: any) {
  //     const errorMessage = error?.response?.data?.error || error.message || "An unknown error occurred";
  //     throw new Error(errorMessage);
  //   }
  // }

  // Update package plan with dynamic parameters
  static async updatePlan(id: string, params: any) {
    try {
      const response = await api.patch(`/package/${id}`, params);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Update purchased package plan
  static async updatePurchasedPlan(id: string, data: { isActive: boolean }) {
    try {
      const response = await api.patch(`/package/purchasedPlan/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Get all purchased package plans
  static async getPurchasedPackagePlan(params: {
    limit?: number;
    offset?: number;
  }) {
    try {
      const response = await api.get("/package/purchasedPlan", { params });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Get purchased plan by child ID
  static async getPurchasedPlanByChildId(
    id: string,
    params: { limit?: number; offset?: number }
  ) {
    try {
      const response = await api.get(`/children/${id}`, { params });
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Delete a package plan (if needed in future)
  static async deletePackagePlan(id: string) {
    try {
      const response = await api.delete(`/package/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
