import React, { useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    Input,
    Table,
    FormGroup,
    Label,
    CardBody,
    Card,
} from "reactstrap";
import CommonCardHeader from 'src/Utils/CommonComponents/CommonCardHeader';
import { RoomData, RoomPlannerData, sampleData, TimeSlot } from './types';
import FilterComponent from 'src/Components/Tables/DataTables/Common/FilterComponent';
import moment from 'moment';
import UserApi from 'src/services/UserApi';
import { connectComponent } from './connector';
import SessionTimeApi from 'src/services/SessionTimesApi';

function RoomPlanner({ user, nurseryId }: any) {
    const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString());
    const [plannerData, setPlannerData] = useState<RoomPlannerData | any>({});
    const [weekDates, setWeekDates] = useState<{ [key: string]: string }>({});
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);

    const days = ['MON', 'TUE', 'WED', 'THU', 'FRI'] as const;

    const getWeekDates = (date: string, format: string = 'DD MM YYYY') => {
        const selectedMoment = moment(date);
        const monday = selectedMoment.clone().startOf('week').add(1, 'days');
        const weekDays: { [key: string]: string } = {};

        days.forEach((day, index) => {
            const currentDate = monday.clone().add(index, 'days');
            weekDays[day] = currentDate.format(format);
        });

        return weekDays;
    };

    const fetchSessionTimes = async () => {
        try {
            const response = await SessionTimeApi.getSessionTimes(nurseryId);
            if (response.success && Array.isArray(response.data)) {
                const formattedTimeSlots = response.data.map((time: any) => {
                    let start = time.startTime;
                    let end = time.endTime;
                    return { start, end };
                });
                setTimeSlots(formattedTimeSlots);
            }
        } catch (error) {

            setTimeSlots([]);
        }
    };

    const fetchRoomPlannerData = async (date: any) => {
        const dates = getWeekDates(date);
        const Weekdates = getWeekDates(date, 'DD MMM');
        setWeekDates(Weekdates);

        // Log the dates for verification


        try {
            const response = await UserApi.getRoomPlannerData({
                nurseryId: nurseryId,
                dateRange: JSON.stringify(dates)
            });

            if (response.success) {
                setPlannerData(response.data);
            }
        } catch (error) {

        }
    };

    const handleDateChange = (date: string) => {
        setSelectedDate(date);


        // Here you can make API call to fetch data for these dates
        // Example of dates object:
        // {
        //     MON: "27 Jan",
        //     TUE: "28 Jan",
        //     WED: "29 Jan",
        //     THU: "30 Jan",
        //     FRI: "31 Jan"
        // }
    };

    useEffect(() => {
        fetchSessionTimes();
        fetchRoomPlannerData(selectedDate);
    }, [selectedDate, nurseryId]);

    const renderRoomSection = (roomKey: string, roomData: RoomData) => {
        return (
            <>
                {/* Room Header */}
                <tr>
                    <td style={{ fontSize: "12px", backgroundColor: "#f0f0f0", paddingTop: 5, paddingBottom: 5 }}>
                        <strong>{roomData?.roomName}</strong>
                    </td>
                    {days.map(day => (
                        <td key={day} colSpan={timeSlots.length} style={{ backgroundColor: "#f0f0f0" }}></td>
                    ))}
                </tr>

                {/* Total Children */}
                <tr>
                    <td style={{ fontSize: "12px", paddingTop: 5, paddingBottom: 5 }}>
                        <strong>Total Children</strong>
                    </td>
                    {days.map(day => (
                        timeSlots.map((slot, index) => {
                            const timeSlotKey = `${slot.start}-${slot.end}`;

                            const convertedTimeSlots = roomData?.days[day]?.timeSlots;

                            return (
                                <td key={`${day}-${index}`} className="text-center" style={{ fontSize: '12px', paddingTop: 5, paddingBottom: 5 }}>
                                    <div>{convertedTimeSlots[timeSlotKey]?.totalChildren || 0}</div>
                                </td>
                            );
                        })
                    ))}
                </tr>

                {/* Current Children */}
                <tr>
                    <td style={{ fontSize: "12px", paddingTop: 5, paddingBottom: 5 }}>
                        <strong>Current Children</strong>
                    </td>
                    {days.map(day => (
                        timeSlots.map((slot, index) => {
                            const timeSlotKey = `${slot.start}-${slot.end}`;
                            const convertedTimeSlots = roomData?.days[day]?.timeSlots;
                            return (
                                <td key={`${day}-${index}`} className="text-center" style={{ fontSize: '12px', paddingTop: 5, paddingBottom: 5 }}>
                                    <div>{convertedTimeSlots[timeSlotKey]?.currentChildren || 0}</div>
                                </td>
                            );
                        })
                    ))}
                </tr>

                {/* Staff Required */}
                <tr>
                    <td style={{ fontSize: "12px", paddingTop: 5, paddingBottom: 5 }}>
                        <strong>Staff Required (Ratio {roomData?.staffRatio})</strong>
                    </td>
                    {days.map(day => (
                        timeSlots.map((slot, index) => {
                            const timeSlotKey = `${slot.start}-${slot.end}`;
                            const convertedTimeSlots = roomData?.days[day]?.timeSlots;
                            return (
                                <td key={`${day}-${index}`} className="text-center" style={{ fontSize: '12px', paddingTop: 5, paddingBottom: 5 }}>
                                    <div>{convertedTimeSlots[timeSlotKey]?.staffRequired || 0}</div>
                                </td>
                            );
                        })
                    ))}
                </tr>
            </>
        );
    };

    const renderTableHeader = () => (
        <thead>
            <tr>
                <th rowSpan={2} style={{ fontSize: "12px" }}>Room Details</th>
                {days.map(day => (
                    <th key={day} colSpan={timeSlots.length} style={{ fontSize: "12px", textAlign: "center" }}>
                        {day}<br />
                        {weekDates[day] || ''}
                    </th>
                ))}
            </tr>
            <tr>
                {days.map(day => (
                    timeSlots.map((slot, index) => (
                        <th key={`${day}-${index}`} className="text-center" style={{ fontSize: '12px', padding: '4px' }}>
                            {slot.start}<br />{slot.end}
                        </th>
                    ))
                ))}
            </tr>
        </thead>
    );

    return (
        <div className="page-body">
            <Container fluid>
                <Row className="pt-4">
                    <Col sm={12}>
                        <Card>
                            <div className="d-flex justify-content-between align-items-center" style={{ borderBottom: "5px solid #eee" }}>
                                <CommonCardHeader
                                    headClass="pb-0 card-no-border"
                                    title={`Room Planner`}
                                    subTitle={[]}
                                    titleClass="mb-3"
                                />
                            </div>
                            <CardBody>
                                {/* Filters Section */}
                                <Row className="mb-3 g-3">
                                    <Col md={2}>
                                        <Label>From</Label>
                                        <FilterComponent
                                            onFilter={(date: any) => {
                                                handleDateChange(date);
                                            }}
                                            filterText={selectedDate}
                                            type="date"
                                            showDateLabel={false}
                                            placeholder="Select From Date"
                                        />
                                    </Col>
                                </Row>

                                {/* Checkboxes */}
                                <Row className="mb-3">
                                    <Col>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" /> Show Allergies
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" /> Show Special Dietary Considerations
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" /> Child Signature Fields
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Input type="checkbox" /> Staff Signature Fields
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* Time Slots Table */}
                                <Table bordered responsive size="sm">
                                    {renderTableHeader()}
                                    <tbody>
                                        {/* Update the rooms mapping to use plannerData */}
                                        {plannerData.rooms && Object.entries(plannerData.rooms).map(([roomKey, roomData]) => (
                                            <React.Fragment key={roomKey}>
                                                {renderRoomSection(roomKey, roomData as RoomData)}
                                            </React.Fragment>
                                        ))}

                                        {/* Summary Section */}
                                        <tr>
                                            <td style={{ fontSize: "12px", backgroundColor: "#f0f0f0", paddingTop: 5, paddingBottom: 5 }}>
                                                <strong>Summary</strong>
                                            </td>
                                            {days.map(day => (
                                                <td key={day} colSpan={timeSlots.length} style={{ backgroundColor: "#f0f0f0" }}></td>
                                            ))}
                                        </tr>
                                        {['Total Children', 'Current Children', 'Staff Required'].map(label => (
                                            <tr key={label}>
                                                <td style={{ fontSize: "12px", paddingTop: 5, paddingBottom: 5 }}>
                                                    <strong>{label}</strong>
                                                </td>
                                                {days.map(day => (
                                                    timeSlots.map((slot, index) => {
                                                        const timeSlotKey = `${slot.start}-${slot.end}`;
                                                        const summaryData = plannerData?.summary?.[day];
                                                        const timeSlotData = summaryData?.timeSlots?.[timeSlotKey];

                                                        let value = 0;
                                                        if (timeSlotData) {
                                                            switch (label) {
                                                                case 'Total Children':
                                                                    value = timeSlotData.totalChildren ?? 0;
                                                                    break;
                                                                case 'Current Children':
                                                                    value = timeSlotData.currentChildren ?? 0;
                                                                    break;
                                                                case 'Staff Required':
                                                                    value = timeSlotData.staffRequired ?? 0;
                                                                    break;
                                                                default:
                                                                    value = 0;
                                                            }
                                                        }

                                                        return (
                                                            <td key={`${day}-${index}`} className="text-center" style={{ fontSize: '12px', paddingTop: 5, paddingBottom: 5 }}>
                                                                <div>{value}</div>
                                                            </td>
                                                        );
                                                    })
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                {/* Legend */}
                                <div className="mt-3">
                                    <p className="text-muted">
                                        <span className="text-danger">■</span> The child's age does not match the age specified in the room move rules.
                                    </p>
                                    <p className="text-muted">
                                        <span className="text-primary">↑</span> Child has changed age group during the selected week.
                                    </p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default connectComponent(RoomPlanner)